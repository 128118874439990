import app from '@/firebase'
import {
    getFirestore,
    collection,
    getDocs,
    doc,
    getDoc,
    query,
    where,
    runTransaction,
    updateDoc,
    onSnapshot,
    limit,
    orderBy
} from "firebase/firestore";
const db = getFirestore(app)
export default {
    state: {
        items: [], //todos los elementos cargados desde la BD
        composedItems: [],
        loadedFilters: [],
        filteredList: [], //todos los elementos filtrados
        currentItemList: [], //elementos que se muestran por pagina
        itemsPerPage: 8,
        currentPage: 1,
        order: 'name',
        indexedItems: [],
        listSize: 0,
        showFilters: false,
        currentItem: null,
        nextValue: 0,
        missingRange: [],
        filter: true,
        collections:[]
    },
    actions: {
        async getCollection({
            commit
        }) {
            try {
                let qS = await getDocs(collection(db, 'Categoria'))
                qS.forEach(async elem => {
                    commit('ADD_COLLECTION', {
                      id: elem.id,
                      name: elem.data().name,
                      description: elem.data().description,
                      nextValue:elem.data().nextValue
                    })
                  })
            } catch (e) {
                console.log("error", e)
            }
        },
        async updateFound() {
            let arr1 = [{
                    id: 'PC0001',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0002',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0003',
                    found: [{
                        label: "sitio",
                        value: "PHE"
                    }]
                },
                {
                    id: 'PC0004',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }]
                },
                {
                    id: 'PC0005',
                    found: [{
                        label: "sitio",
                        value: "PST"
                    }]
                },
                {
                    id: 'PC0006',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0007',
                    found: [{
                        label: "sitio",
                        value: "PHE"
                    }, {
                        label: "hab",
                        value: "HAB0002"
                    }]
                },
                {
                    id: 'PC0008',
                    found: [{
                        label: "sitio",
                        value: "BDP"
                    }]
                },
                {
                    id: 'PC0009',
                    found: [{
                        label: "sitio",
                        value: "PHE"
                    }]
                },
                {
                    id: 'PC0010',
                    found: [{
                        label: "sitio",
                        value: "PPR"
                    }, {
                        label: "hab",
                        value: "HAB0001"
                    }]
                },
                {
                    id: 'PC0011',
                    found: [{
                        label: "sitio",
                        value: "PHE"
                    }]
                },
                {
                    id: 'PC0012',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }]
                },
                {
                    id: 'PC0013',
                    found: [{
                        label: "sitio",
                        value: "BDP"
                    }]
                },
                {
                    id: 'PC0014',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }, {
                        label: "hab",
                        value: "HAB0001"
                    }]
                },
                {
                    id: 'PC0015',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }, {
                        label: "hab",
                        value: "HAB0001"
                    }]
                },
                {
                    id: 'PC0016',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }, {
                        label: "hab",
                        value: "HAB0001"
                    }]
                },
                {
                    id: 'PC0017',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0018',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0019',
                    found: [{
                        label: "sitio",
                        value: "PHE"
                    }]
                },
                {
                    id: 'PC0020',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0021',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }, {
                        label: "hab",
                        value: "HAB0001"
                    }]
                },
                {
                    id: 'PC0022',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0023',
                    found: [{
                        label: "sitio",
                        value: "VO2"
                    }]
                },
                {
                    id: 'PC0024',
                    found: [{
                        label: "sitio",
                        value: "VAG"
                    }]
                },
                {
                    id: 'PC0025',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0026',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0027',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0028',
                    found: [{
                        label: "sitio",
                        value: "ESC"
                    }]
                },
                {
                    id: 'PC0029',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0030',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0031',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0032',
                    found: [{
                        label: "sitio",
                        value: "VAG"
                    }]
                },
                {
                    id: 'PC0033',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0034',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0035',
                    found: [{
                        label: "sitio",
                        value: "PHE"
                    }]
                },
                {
                    id: 'PC0036',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0037',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0038',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0039',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0040',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0041',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0042',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0043',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0044',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0045',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0046',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0047',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0048',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0049',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0050',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0051',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0052',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0053',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0054',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0055',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0056',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0057',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0058',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0059',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0060',
                    found: [{
                        label: "sitio",
                        value: "PST"
                    }]
                },
                {
                    id: 'PC0061',
                    found: [{
                        label: "sitio",
                        value: "BDP"
                    }]
                },
                {
                    id: 'PC0062',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0063',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0064',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0065',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0066',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0067',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0068',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0069',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0070',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0071',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }]
                },
                {
                    id: 'PC0072',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0073',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }]
                },
                {
                    id: 'PC0074',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0075',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0076',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }]
                },
                {
                    id: 'PC0077',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0078',
                    found: [{
                        label: "sitio",
                        value: "PST"
                    }]
                },
                {
                    id: 'PC0079',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }]
                },
                {
                    id: 'PC0080',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0081',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0082',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0083',
                    found: [{
                        label: "sitio",
                        value: "BDP"
                    }]
                },
                {
                    id: 'PC0084',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0085',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0086',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0087',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0088',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0089',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }]
                },
                {
                    id: 'PC0090',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0091',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0092',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0093',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0094',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0095',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0096',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0097',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0098',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0099',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                }
            ]
            let arr2 = [{
                    id: 'PC0100',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0101',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0102',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }, {
                        label: "hab",
                        value: "HAB0001"
                    }]
                },
                {
                    id: 'PC0103',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }, {
                        label: "hab",
                        value: "HAB0001"
                    }]
                },
                {
                    id: 'PC0104',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }, {
                        label: "hab",
                        value: "HAB0001"
                    }]
                },
                {
                    id: 'PC0105',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }, {
                        label: "hab",
                        value: "HAB0001"
                    }]
                },
                {
                    id: 'PC0106',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }, {
                        label: "hab",
                        value: "HAB0001"
                    }]
                },
                {
                    id: 'PC0107',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }, {
                        label: "hab",
                        value: "HAB0001"
                    }]
                },
                {
                    id: 'PC0108',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }, {
                        label: "hab",
                        value: "HAB0001"
                    }]
                },
                {
                    id: 'PC0109',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }, {
                        label: "hab",
                        value: "HAB0001"
                    }]
                },
                {
                    id: 'PC0110',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }, {
                        label: "hab",
                        value: "HAB0001"
                    }]
                },
                {
                    id: 'PC0111',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }, {
                        label: "hab",
                        value: "HAB0001"
                    }]
                },
                {
                    id: 'PC0112',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }, {
                        label: "hab",
                        value: "HAB0001"
                    }]
                },
                {
                    id: 'PC0113',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }]
                },
                {
                    id: 'PC0114',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }, {
                        label: "hab",
                        value: "HAB0001"
                    }]
                },
                {
                    id: 'PC0115',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }, {
                        label: "hab",
                        value: "HAB0001"
                    }]
                },
                {
                    id: 'PC0116',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }]
                },
                {
                    id: 'PC0117',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }, {
                        label: "hab",
                        value: "HAB0001"
                    }]
                },
                {
                    id: 'PC0118',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }, {
                        label: "hab",
                        value: "HAB0001"
                    }]
                },
                {
                    id: 'PC0119',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }, {
                        label: "hab",
                        value: "HAB0001"
                    }]
                },
                {
                    id: 'PC0120',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }, {
                        label: "hab",
                        value: "HAB0001"
                    }]
                },
                {
                    id: 'PC0121',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }, {
                        label: "hab",
                        value: "HAB0001"
                    }]
                },
                {
                    id: 'PC0122',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }, {
                        label: "hab",
                        value: "HAB0001"
                    }]
                },
                {
                    id: 'PC0123',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }]
                },
                {
                    id: 'PC0124',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }]
                },
                {
                    id: 'PC0125',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }]
                },
                {
                    id: 'PC0126',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }]
                },
                {
                    id: 'PC0127',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }]
                },
                {
                    id: 'PC0128',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }]
                },
                {
                    id: 'PC0129',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }]
                },
                {
                    id: 'PC0130',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }]
                },
                {
                    id: 'PC0131',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }]
                },
                {
                    id: 'PC0132',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }]
                },
                {
                    id: 'PC0133',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0134',
                    found: [{
                        label: "sitio",
                        value: "PST"
                    }]
                },
                {
                    id: 'PC0135',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0136',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0137',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0138',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0139',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }]
                },
                {
                    id: 'PC0140',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }]
                },
                {
                    id: 'PC0141',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }]
                },
                {
                    id: 'PC0142',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }]
                },
                {
                    id: 'PC0143',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }]
                },
                {
                    id: 'PC0144',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }]
                },
                {
                    id: 'PC0145',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }]
                },
                {
                    id: 'PC0146',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }]
                },
                {
                    id: 'PC0147',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }]
                },
                {
                    id: 'PC0148',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }]
                },
                {
                    id: 'PC0149',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }]
                },
                {
                    id: 'PC0150',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }]
                },
                {
                    id: 'PC0151',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }]
                },
                {
                    id: 'PC0152',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }]
                },
                {
                    id: 'PC0153',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }]
                },
                {
                    id: 'PC0154',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }]
                },
                {
                    id: 'PC0155',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }]
                },
                {
                    id: 'PC0156',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }]
                },
                {
                    id: 'PC0157',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }]
                },
                {
                    id: 'PC0158',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }]
                },
                {
                    id: 'PC0159',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }]
                },
                {
                    id: 'PC0160',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0161',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }]
                },
                {
                    id: 'PC0162',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }]
                },
                {
                    id: 'PC0163',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0164',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }]
                },
                {
                    id: 'PC0165',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }]
                },
                {
                    id: 'PC0166',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }]
                },
                {
                    id: 'PC0167',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }]
                },
                {
                    id: 'PC0168',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0169',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0170',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }]
                },
                {
                    id: 'PC0171',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }]
                },
                {
                    id: 'PC0172',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }]
                },
                {
                    id: 'PC0173',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }]
                },
                {
                    id: 'PC0174',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }]
                },
                {
                    id: 'PC0175',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }]
                },
                {
                    id: 'PC0176',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }]
                },
                {
                    id: 'PC0177',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }]
                },
                {
                    id: 'PC0178',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0179',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0180',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0181',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }]
                },
                {
                    id: 'PC0182',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }]
                },
                {
                    id: 'PC0183',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }]
                },
                {
                    id: 'PC0184',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0185',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }]
                },
                {
                    id: 'PC0186',
                    found: [{
                        label: "sitio",
                        value: "PHE"
                    }]
                },
                {
                    id: 'PC0187',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0188',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0189',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }]
                },
                {
                    id: 'PC0190',
                    found: [{
                        label: "sitio",
                        value: "BDP"
                    }]
                },
                {
                    id: 'PC0191',
                    found: [{
                        label: "sitio",
                        value: "VCA"
                    }]
                },
                {
                    id: 'PC0192',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0193',
                    found: [{
                        label: "sitio",
                        value: "PST"
                    }]
                },
                {
                    id: 'PC0194',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0195',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0196',
                    found: [{
                        label: "sitio",
                        value: "PST"
                    }]
                },
                {
                    id: 'PC0197',
                    found: [{
                        label: "sitio",
                        value: "VCA"
                    }]
                },
                {
                    id: 'PC0198',
                    found: [{
                        label: "sitio",
                        value: "MAE"
                    }]
                },
                {
                    id: 'PC0199',
                    found: [{
                        label: "sitio",
                        value: "VCA"
                    }]
                },
                {
                    id: 'PC0200',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                }
            ]
            let arr3 = [{
                    id: 'PC0201',
                    found: [{
                        label: "sitio",
                        value: "VCA"
                    }]
                },
                {
                    id: 'PC0202',
                    found: [{
                        label: "sitio",
                        value: "MAE"
                    }]
                },
                {
                    id: 'PC0203',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0204',
                    found: [{
                        label: "sitio",
                        value: "BDP"
                    }]
                },
                {
                    id: 'PC0205',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0206',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0207',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0208',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0209',
                    found: [{
                        label: "sitio",
                        value: "MAE"
                    }]
                },
                {
                    id: 'PC0210',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0211',
                    found: [{
                        label: "sitio",
                        value: "MAE"
                    }]
                },
                {
                    id: 'PC0212',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0213',
                    found: [{
                        label: "sitio",
                        value: "MAE"
                    }]
                },
                {
                    id: 'PC0214',
                    found: [{
                        label: "sitio",
                        value: "SCM"
                    }]
                },
                {
                    id: 'PC0215',
                    found: [{
                        label: "sitio",
                        value: "PHE"
                    }]
                },
                {
                    id: 'PC0216',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0217',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0218',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0219',
                    found: [{
                        label: "sitio",
                        value: "MAE"
                    }]
                },
                {
                    id: 'PC0220',
                    found: [{
                        label: "sitio",
                        value: "MAE"
                    }]
                },
                {
                    id: 'PC0221',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0222',
                    found: [{
                        label: "sitio",
                        value: "MAE"
                    }]
                },
                {
                    id: 'PC0223',
                    found: [{
                        label: "sitio",
                        value: "MAE"
                    }]
                },
                {
                    id: 'PC0224',
                    found: [{
                        label: "sitio",
                        value: "MAE"
                    }]
                },
                {
                    id: 'PC0225',
                    found: [{
                        label: "sitio",
                        value: "BDP"
                    }]
                },
                {
                    id: 'PC0226',
                    found: [{
                        label: "sitio",
                        value: "MAE"
                    }]
                },
                {
                    id: 'PC0227',
                    found: [{
                        label: "sitio",
                        value: "BDP"
                    }]
                },
                {
                    id: 'PC0228',
                    found: [{
                        label: "sitio",
                        value: "MAE"
                    }]
                },
                {
                    id: 'PC0229',
                    found: [{
                        label: "sitio",
                        value: "BDP"
                    }]
                },
                {
                    id: 'PC0230',
                    found: [{
                        label: "sitio",
                        value: "MAE"
                    }]
                },
                {
                    id: 'PC0231',
                    found: [{
                        label: "sitio",
                        value: "MAE"
                    }]
                },
                {
                    id: 'PC0232',
                    found: [{
                        label: "sitio",
                        value: "MAE"
                    }]
                },
                {
                    id: 'PC0233',
                    found: [{
                        label: "sitio",
                        value: "MAE"
                    }]
                },
                {
                    id: 'PC0234',
                    found: [{
                        label: "sitio",
                        value: "PST"
                    }]
                },
                {
                    id: 'PC0236',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0237',
                    found: [{
                        label: "sitio",
                        value: "MAE"
                    }]
                },
                {
                    id: 'PC0238',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0239',
                    found: [{
                        label: "sitio",
                        value: "PST"
                    }]
                },
                {
                    id: 'PC0240',
                    found: [{
                        label: "sitio",
                        value: "PST"
                    }]
                },
                {
                    id: 'PC0241',
                    found: [{
                        label: "sitio",
                        value: "PST"
                    }]
                },
                {
                    id: 'PC0242',
                    found: [{
                        label: "sitio",
                        value: "MAE"
                    }]
                },
                {
                    id: 'PC0243',
                    found: [{
                        label: "sitio",
                        value: "PST"
                    }]
                },
                {
                    id: 'PC0244',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }]
                },
                {
                    id: 'PC0245',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0246',
                    found: [{
                        label: "sitio",
                        value: "PST"
                    }]
                },
                {
                    id: 'PC0247',
                    found: [{
                        label: "sitio",
                        value: "MAE"
                    }]
                },
                {
                    id: 'PC0248',
                    found: [{
                        label: "sitio",
                        value: "PST"
                    }]
                },
                {
                    id: 'PC0249',
                    found: [{
                        label: "sitio",
                        value: "VCA"
                    }]
                },
                {
                    id: 'PC0250',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0251',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0252',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0253',
                    found: [{
                        label: "sitio",
                        value: "PPR"
                    }]
                },
                {
                    id: 'PC0254',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0255',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0256',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0257',
                    found: [{
                        label: "sitio",
                        value: "PPR"
                    }]
                },
                {
                    id: 'PC0258',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0259',
                    found: [{
                        label: "sitio",
                        value: "PST"
                    }]
                },
                {
                    id: 'PC0260',
                    found: [{
                        label: "sitio",
                        value: "VCA"
                    }]
                },
                {
                    id: 'PC0261',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0262',
                    found: [{
                        label: "sitio",
                        value: "MAE"
                    }]
                },
                {
                    id: 'PC0263',
                    found: [{
                        label: "sitio",
                        value: "MAE"
                    }]
                },
                {
                    id: 'PC0264',
                    found: [{
                        label: "sitio",
                        value: "MAE"
                    }]
                },
                {
                    id: 'PC0265',
                    found: [{
                        label: "sitio",
                        value: "MAE"
                    }]
                },
                {
                    id: 'PC0266',
                    found: [{
                        label: "sitio",
                        value: "MAE"
                    }]
                },
                {
                    id: 'PC0267',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0268',
                    found: [{
                        label: "sitio",
                        value: "RDC"
                    }]
                },
                {
                    id: 'PC0269',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }]
                },
                {
                    id: 'PC0270',
                    found: [{
                        label: "sitio",
                        value: "CAM"
                    }]
                },
                {
                    id: 'PC0271',
                    found: [{
                        label: "sitio",
                        value: "VCA"
                    }, {
                        label: "hab",
                        value: "HAB001"
                    }]
                },
                {
                    id: 'PC0272',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }]
                },
                {
                    id: 'PC0273',
                    found: [{
                        label: "sitio",
                        value: "VCA"
                    }, {
                        label: "hab",
                        value: "HAB001"
                    }]
                },
                {
                    id: 'PC0274',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0275',
                    found: [{
                        label: "sitio",
                        value: "VAG"
                    }]
                },
                {
                    id: 'PC0276',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0277',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0278',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0279',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0280',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0281',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0282',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0283',
                    found: [{
                        label: "sitio",
                        value: "PST"
                    }]
                },
                {
                    id: 'PC0284',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0285',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0286',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0287',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0288',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0289',
                    found: [{
                        label: "sitio",
                        value: "PST"
                    }]
                },
                {
                    id: 'PC0290',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0291',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0292',
                    found: [{
                        label: "sitio",
                        value: "PST"
                    }]
                },
                {
                    id: 'PC0293',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0294',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0295',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0296',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0297',
                    found: [{
                        label: "sitio",
                        value: "PST"
                    }]
                },
                {
                    id: 'PC0298',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0299',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                }
            ]
            let arr4 = [{
                    id: 'PC0300',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0301',
                    found: [{
                        label: "sitio",
                        value: "VCA"
                    }]
                },
                {
                    id: 'PC0302',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0303',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0304',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0305',
                    found: [{
                        label: "sitio",
                        value: "IGC"
                    }]
                },
                {
                    id: 'PC0306',
                    found: [{
                        label: "sitio",
                        value: "BDP"
                    }]
                },
                {
                    id: 'PC0307',
                    found: [{
                        label: "sitio",
                        value: "PHE"
                    }]
                },
                {
                    id: 'PC0308',
                    found: [{
                        label: "sitio",
                        value: "BDP"
                    }]
                },
                {
                    id: 'PC0309',
                    found: [{
                        label: "sitio",
                        value: "LAB"
                    }, {
                        label: "hab",
                        value: "HAB0003"
                    }]
                },
                {
                    id: 'PC0310',
                    found: [{
                        label: "sitio",
                        value: "CDE"
                    }]
                },
                {
                    id: 'PC0311',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0312',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0313',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0314',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0315',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0316',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0317',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0318',
                    found: [{
                        label: "sitio",
                        value: "BDP"
                    }]
                },
                {
                    id: 'PC0319',
                    found: [{
                        label: "sitio",
                        value: "BDP"
                    }]
                },
                {
                    id: 'PC0320',
                    found: [{
                        label: "sitio",
                        value: "IGC"
                    }]
                },
                {
                    id: 'PC0321',
                    found: [{
                        label: "sitio",
                        value: "PPR"
                    }]
                },
                {
                    id: 'PC0322',
                    found: [{
                        label: "sitio",
                        value: "ESC"
                    }]
                },
                {
                    id: 'PC0323',
                    found: [{
                        label: "sitio",
                        value: "ESC"
                    }]
                },
                {
                    id: 'PC0324',
                    found: [{
                        label: "sitio",
                        value: "PPR"
                    }]
                },
                {
                    id: 'PC0325',
                    found: [{
                        label: "sitio",
                        value: "PST"
                    }]
                },
                {
                    id: 'PC0326',
                    found: [{
                        label: "sitio",
                        value: "PPR"
                    }]
                },
                {
                    id: 'PC0327',
                    found: [{
                        label: "sitio",
                        value: "BDP"
                    }]
                },
                {
                    id: 'PC0328',
                    found: [{
                        label: "sitio",
                        value: "PST"
                    }]
                },
                {
                    id: 'PC0330',
                    found: [{
                        label: "sitio",
                        value: "PST"
                    }]
                },
                {
                    id: 'PC0331',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0332',
                    found: [{
                        label: "sitio",
                        value: "PST"
                    }]
                },
                {
                    id: 'PC0333',
                    found: [{
                        label: "sitio",
                        value: "PPR"
                    }]
                },
                {
                    id: 'PC0334',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0335',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0336',
                    found: [{
                        label: "sitio",
                        value: "MAE"
                    }]
                },
                {
                    id: 'PC0337',
                    found: [{
                        label: "sitio",
                        value: "PST"
                    }]
                },
                {
                    id: 'PC0338',
                    found: [{
                        label: "sitio",
                        value: "PHE"
                    }]
                },
                {
                    id: 'PC0339',
                    found: [{
                        label: "sitio",
                        value: "MAE"
                    }]
                },
                {
                    id: 'PC0340',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0341',
                    found: [{
                        label: "sitio",
                        value: "PHE"
                    }]
                },
                {
                    id: 'PC0342',
                    found: [{
                        label: "sitio",
                        value: "ESC"
                    }]
                },
                {
                    id: 'PC0343',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0344',
                    found: [{
                        label: "sitio",
                        value: "MAE"
                    }]
                },
                {
                    id: 'PC0345',
                    found: [{
                        label: "sitio",
                        value: "MAE"
                    }]
                },
                {
                    id: 'PC0346',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0347',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0348',
                    found: [{
                        label: "sitio",
                        value: "PST"
                    }]
                },
                {
                    id: 'PC0349',
                    found: [{
                        label: "sitio",
                        value: "MAE"
                    }]
                },
                {
                    id: 'PC0350',
                    found: [{
                        label: "sitio",
                        value: "PST"
                    }]
                },
                {
                    id: 'PC0351',
                    found: [{
                        label: "sitio",
                        value: "PST"
                    }]
                },
                {
                    id: 'PC0352',
                    found: [{
                        label: "sitio",
                        value: "PST"
                    }]
                },
                {
                    id: 'PC0353',
                    found: [{
                        label: "sitio",
                        value: "PST"
                    }]
                },
                {
                    id: 'PC0354',
                    found: [{
                        label: "sitio",
                        value: "PST"
                    }]
                },
                {
                    id: 'PC0355',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0356',
                    found: [{
                        label: "sitio",
                        value: "PST"
                    }]
                },
                {
                    id: 'PC0357',
                    found: [{
                        label: "sitio",
                        value: "PST"
                    }]
                },
                {
                    id: 'PC0358',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0359',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0360',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0361',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0362',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0363',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0364',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0365',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0366',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0367',
                    found: [{
                        label: "sitio",
                        value: "ESC"
                    }]
                },
                {
                    id: 'PC0368',
                    found: [{
                        label: "sitio",
                        value: "CDO"
                    }]
                },
                {
                    id: 'PC0369',
                    found: [{
                        label: "sitio",
                        value: "ESC"
                    }]
                },
                {
                    id: 'PC0370',
                    found: [{
                        label: "sitio",
                        value: "ESC"
                    }]
                },
                {
                    id: 'PC0371',
                    found: [{
                        label: "sitio",
                        value: "ESC"
                    }]
                },
                {
                    id: 'PC0372',
                    found: [{
                        label: "sitio",
                        value: "ESC"
                    }]
                },
                {
                    id: 'PC0373',
                    found: [{
                        label: "sitio",
                        value: "ESC"
                    }]
                },
                {
                    id: 'PC0374',
                    found: [{
                        label: "sitio",
                        value: "ESC"
                    }]
                },
                {
                    id: 'PC0375',
                    found: [{
                        label: "sitio",
                        value: "ESC"
                    }]
                },
                {
                    id: 'PC0376',
                    found: [{
                        label: "sitio",
                        value: "ESC"
                    }]
                },
                {
                    id: 'PC0377',
                    found: [{
                        label: "sitio",
                        value: "EXT"
                    }, {
                        label: "hab",
                        value: "HAB0001"
                    }]
                },
                {
                    id: 'PC0378',
                    found: [{
                        label: "sitio",
                        value: "ESC"
                    }]
                },
                {
                    id: 'PC0379',
                    found: [{
                        label: "sitio",
                        value: "RDC"
                    }]
                },
                {
                    id: 'PC0380',
                    found: [{
                        label: "sitio",
                        value: "RDC"
                    }]
                },
                {
                    id: 'PC0381',
                    found: [{
                        label: "sitio",
                        value: "ESC"
                    }]
                },
                {
                    id: 'PC0382',
                    found: [{
                        label: "sitio",
                        value: "MAE"
                    }, {
                        label: "hab",
                        value: "HAB2"
                    }]
                },
                {
                    id: 'PC0383',
                    found: [{
                        label: "sitio",
                        value: "ESC"
                    }]
                },
                {
                    id: 'PC0384',
                    found: [{
                        label: "sitio",
                        value: "ESC"
                    }]
                },
                {
                    id: 'PC0385',
                    found: [{
                        label: "sitio",
                        value: "PST"
                    }]
                },
                {
                    id: 'PC0386',
                    found: [{
                        label: "sitio",
                        value: "CAR"
                    }]
                },
                {
                    id: 'PC0387',
                    found: [{
                        label: "sitio",
                        value: "EXT"
                    }, {
                        label: "hab",
                        value: "HAB0004"
                    }]
                },
                {
                    id: 'PC0388',
                    found: [{
                        label: "sitio",
                        value: "CAR"
                    }]
                },
                {
                    id: 'PC0389',
                    found: [{
                        label: "sitio",
                        value: "PST"
                    }]
                },
                {
                    id: 'PC0390',
                    found: [{
                        label: "sitio",
                        value: "PST"
                    }]
                },
                {
                    id: 'PC0391',
                    found: [{
                        label: "sitio",
                        value: "PHE"
                    }]
                },
                {
                    id: 'PC0392',
                    found: [{
                        label: "sitio",
                        value: "PST"
                    }]
                },
                {
                    id: 'PC0393',
                    found: [{
                        label: "sitio",
                        value: "PST"
                    }]
                },
                {
                    id: 'PC0394',
                    found: [{
                        label: "sitio",
                        value: "RDC"
                    }]
                },
                {
                    id: 'PC0395',
                    found: [{
                        label: "sitio",
                        value: "GDV"
                    }]
                },
                {
                    id: 'PC0396',
                    found: [{
                        label: "sitio",
                        value: "MAE"
                    }, {
                        label: "hab",
                        value: "HAB3"
                    }]
                },
                {
                    id: 'PC0397',
                    found: [{
                        label: "sitio",
                        value: "MAE"
                    }, {
                        label: "hab",
                        value: "HAB1"
                    }]
                },
                {
                    id: 'PC0398',
                    found: [{
                        label: "sitio",
                        value: "ESC"
                    }]
                },
                {
                    id: 'PC0399',
                    found: [{
                        label: "sitio",
                        value: "MAE"
                    }]
                }
            ]
            let arr5 = [{
                    id: 'PC0400',
                    found: [{
                        label: "sitio",
                        value: "ESC"
                    }]
                },
                {
                    id: 'PC0401',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0402',
                    found: [{
                        label: "sitio",
                        value: "VO3"
                    }]
                },
                {
                    id: 'PC0403',
                    found: [{
                        label: "sitio",
                        value: "BDP"
                    }]
                },
                {
                    id: 'PC0404',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0405',
                    found: [{
                        label: "sitio",
                        value: "CAM"
                    }]
                },
                {
                    id: 'PC0406',
                    found: [{
                        label: "sitio",
                        value: "PHE"
                    }, {
                        label: "hab",
                        value: "HAB0002"
                    }]
                },
                {
                    id: 'PC0407',
                    found: [{
                        label: "sitio",
                        value: "IGC"
                    }]
                },
                {
                    id: 'PC0408',
                    found: [{
                        label: "sitio",
                        value: "IGC"
                    }]
                },
                {
                    id: 'PC0409',
                    found: [{
                        label: "sitio",
                        value: "IGC"
                    }]
                },
                {
                    id: 'PC0410',
                    found: [{
                        label: "sitio",
                        value: "MAE"
                    }]
                },
                {
                    id: 'PC0411',
                    found: [{
                        label: "sitio",
                        value: "MIN"
                    }]
                },
                {
                    id: 'PC0412',
                    found: [{
                        label: "sitio",
                        value: "MIN"
                    }]
                },
                {
                    id: 'PC0413',
                    found: [{
                        label: "sitio",
                        value: "CDE"
                    }]
                },
                {
                    id: 'PC0414',
                    found: [{
                        label: "sitio",
                        value: "BDP"
                    }]
                },
                {
                    id: 'PC0415',
                    found: [{
                        label: "sitio",
                        value: "PHE"
                    }]
                },
                {
                    id: 'PC0416',
                    found: [{
                        label: "sitio",
                        value: "MIN"
                    }]
                },
                {
                    id: 'PC0417',
                    found: [{
                        label: "sitio",
                        value: "VE1"
                    }]
                },
                {
                    id: 'PC0418',
                    found: [{
                        label: "sitio",
                        value: "MIN"
                    }]
                },
                {
                    id: 'PC0419',
                    found: [{
                        label: "sitio",
                        value: "BDP"
                    }]
                },
                {
                    id: 'PC0420',
                    found: [{
                        label: "sitio",
                        value: "PHE"
                    }]
                },
                {
                    id: 'PC0421',
                    found: [{
                        label: "sitio",
                        value: "BDP"
                    }]
                },
                {
                    id: 'PC0422',
                    found: [{
                        label: "sitio",
                        value: "BDP"
                    }]
                },
                {
                    id: 'PC0423',
                    found: [{
                        label: "sitio",
                        value: "BDP"
                    }]
                },
                {
                    id: 'PC0424',
                    found: [{
                        label: "sitio",
                        value: "PHE"
                    }]
                },
                {
                    id: 'PC0425',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0426',
                    found: [{
                        label: "sitio",
                        value: "MIN"
                    }]
                },
                {
                    id: 'PC0427',
                    found: [{
                        label: "sitio",
                        value: "MIN"
                    }]
                },
                {
                    id: 'PC0428',
                    found: [{
                        label: "sitio",
                        value: "BDP"
                    }]
                },
                {
                    id: 'PC0429',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0430',
                    found: [{
                        label: "sitio",
                        value: "BDP"
                    }]
                },
                {
                    id: 'PC0431',
                    found: [{
                        label: "sitio",
                        value: "EXT"
                    }, {
                        label: "hab",
                        value: "HAB005"
                    }]
                },
                {
                    id: 'PC0432',
                    found: [{
                        label: "sitio",
                        value: "BDP"
                    }]
                },
                {
                    id: 'PC0433',
                    found: [{
                        label: "sitio",
                        value: "EXT"
                    }]
                },
                {
                    id: 'PC0434',
                    found: [{
                        label: "sitio",
                        value: "BDP"
                    }]
                },
                {
                    id: 'PC0435',
                    found: [{
                        label: "sitio",
                        value: "EXT"
                    }, {
                        label: "hab",
                        value: "HAB002"
                    }]
                },
                {
                    id: 'PC0436',
                    found: [{
                        label: "sitio",
                        value: "MUE"
                    }]
                },
                {
                    id: 'PC0437',
                    found: [{
                        label: "sitio",
                        value: "BDP"
                    }]
                },
                {
                    id: 'PC0438',
                    found: [{
                        label: "sitio",
                        value: "MIN"
                    }]
                },
                {
                    id: 'PC0439',
                    found: [{
                        label: "sitio",
                        value: "MUE"
                    }]
                },
                {
                    id: 'PC0440',
                    found: [{
                        label: "sitio",
                        value: "CAM"
                    }]
                },
                {
                    id: 'PC0441',
                    found: [{
                        label: "sitio",
                        value: "BDP"
                    }]
                },
                {
                    id: 'PC0442',
                    found: [{
                        label: "sitio",
                        value: "BDP"
                    }]
                },
                {
                    id: 'PC0443',
                    found: [{
                        label: "sitio",
                        value: "BDP"
                    }]
                },
                {
                    id: 'PC0444',
                    found: [{
                        label: "sitio",
                        value: "BDP"
                    }]
                },
                {
                    id: 'PC0445',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0446',
                    found: [{
                        label: "sitio",
                        value: "BDP"
                    }]
                },
                {
                    id: 'PC0447',
                    found: [{
                        label: "sitio",
                        value: "BDP"
                    }]
                },
                {
                    id: 'PC0448',
                    found: [{
                        label: "sitio",
                        value: "BDP"
                    }]
                },
                {
                    id: 'PC0449',
                    found: [{
                        label: "sitio",
                        value: "BDP"
                    }]
                },
                {
                    id: 'PC0450',
                    found: [{
                        label: "sitio",
                        value: "BDP"
                    }]
                },
                {
                    id: 'PC0451',
                    found: [{
                        label: "sitio",
                        value: "MUE"
                    }]
                },
                {
                    id: 'PC0452',
                    found: [{
                        label: "sitio",
                        value: "BOM"
                    }]
                },
                {
                    id: 'PC0453',
                    found: [{
                        label: "sitio",
                        value: "BDP"
                    }]
                },
                {
                    id: 'PC0454',
                    found: [{
                        label: "sitio",
                        value: "BDP"
                    }]
                },
                {
                    id: 'PC0455',
                    found: [{
                        label: "sitio",
                        value: "BDP"
                    }]
                },
                {
                    id: 'PC0456',
                    found: [{
                        label: "sitio",
                        value: "BDP"
                    }]
                },
                {
                    id: 'PC0457',
                    found: [{
                        label: "sitio",
                        value: "BDP"
                    }]
                },
                {
                    id: 'PC0458',
                    found: [{
                        label: "sitio",
                        value: "BDP"
                    }]
                },
                {
                    id: 'PC0459',
                    found: [{
                        label: "sitio",
                        value: "BDP"
                    }]
                },
                {
                    id: 'PC0460',
                    found: [{
                        label: "sitio",
                        value: "BDP"
                    }]
                },
                {
                    id: 'PC0461',
                    found: [{
                        label: "sitio",
                        value: "CAM"
                    }]
                },
                {
                    id: 'PC0462',
                    found: [{
                        label: "sitio",
                        value: "MUE"
                    }]
                },
                {
                    id: 'PC0463',
                    found: [{
                        label: "sitio",
                        value: "CDO"
                    }]
                },
                {
                    id: 'PC0464',
                    found: [{
                        label: "sitio",
                        value: "PHE"
                    }]
                },
                {
                    id: 'PC0465',
                    found: [{
                        label: "sitio",
                        value: "PHE"
                    }]
                },
                {
                    id: 'PC0466',
                    found: [{
                        label: "sitio",
                        value: "CDE"
                    }]
                },
                {
                    id: 'PC0467',
                    found: [{
                        label: "sitio",
                        value: "BDP"
                    }]
                },
                {
                    id: 'PC0468',
                    found: [{
                        label: "sitio",
                        value: "BDP"
                    }]
                },
                {
                    id: 'PC0469',
                    found: [{
                        label: "sitio",
                        value: "BDP"
                    }]
                },
                {
                    id: 'PC0470',
                    found: [{
                        label: "sitio",
                        value: "BDP"
                    }]
                },
                {
                    id: 'PC0471',
                    found: [{
                        label: "sitio",
                        value: "BDP"
                    }]
                },
                {
                    id: 'PC0472',
                    found: [{
                        label: "sitio",
                        value: "BDP"
                    }]
                },
                {
                    id: 'PC0473',
                    found: [{
                        label: "sitio",
                        value: "BDP"
                    }]
                },
                {
                    id: 'PC0474',
                    found: [{
                        label: "sitio",
                        value: "BDP"
                    }]
                },
                {
                    id: 'PC0475',
                    found: [{
                        label: "sitio",
                        value: "BDP"
                    }]
                },
                {
                    id: 'PC0476',
                    found: [{
                        label: "sitio",
                        value: "VE1"
                    }]
                },
                {
                    id: 'PC0477',
                    found: [{
                        label: "sitio",
                        value: "PHE"
                    }]
                },
                {
                    id: 'PC0478',
                    found: [{
                        label: "sitio",
                        value: "PHE"
                    }]
                },
                {
                    id: 'PC0479',
                    found: [{
                        label: "sitio",
                        value: "MUE"
                    }]
                },
                {
                    id: 'PC0480',
                    found: [{
                        label: "sitio",
                        value: "CAM"
                    }]
                },
                {
                    id: 'PC0481',
                    found: [{
                        label: "sitio",
                        value: "BDP"
                    }]
                },
                {
                    id: 'PC0482',
                    found: [{
                        label: "sitio",
                        value: "BDP"
                    }]
                },
                {
                    id: 'PC0483',
                    found: [{
                        label: "sitio",
                        value: "PST"
                    }]
                },
                {
                    id: 'PC0484',
                    found: [{
                        label: "sitio",
                        value: "VE1"
                    }]
                },
                {
                    id: 'PC0485',
                    found: [{
                        label: "sitio",
                        value: "CDO"
                    }]
                },
                {
                    id: 'PC0486',
                    found: [{
                        label: "sitio",
                        value: "CDO"
                    }]
                },
                {
                    id: 'PC0487',
                    found: [{
                        label: "sitio",
                        value: "CDO"
                    }]
                },
                {
                    id: 'PC0488',
                    found: [{
                        label: "sitio",
                        value: "CDO"
                    }]
                },
                {
                    id: 'PC0489',
                    found: [{
                        label: "sitio",
                        value: "CDO"
                    }]
                },
                {
                    id: 'PC0490',
                    found: [{
                        label: "sitio",
                        value: "CAM"
                    }]
                },
                {
                    id: 'PC0491',
                    found: [{
                        label: "sitio",
                        value: "EXT"
                    }, {
                        label: "hab",
                        value: "HAB005"
                    }]
                },
                {
                    id: 'PC0492',
                    found: [{
                        label: "sitio",
                        value: "BDP"
                    }]
                },
                {
                    id: 'PC0493',
                    found: [{
                        label: "sitio",
                        value: "BDP"
                    }]
                },
                {
                    id: 'PC0494',
                    found: [{
                        label: "sitio",
                        value: "BDP"
                    }]
                },
                {
                    id: 'PC0495',
                    found: [{
                        label: "sitio",
                        value: "PHE"
                    }]
                },
                {
                    id: 'PC0496',
                    found: [{
                        label: "sitio",
                        value: "PPR"
                    }]
                },
                {
                    id: 'PC0497',
                    found: [{
                        label: "sitio",
                        value: "BDP"
                    }]
                },
                {
                    id: 'PC0498',
                    found: [{
                        label: "sitio",
                        value: "MAE"
                    }, {
                        label: "hab",
                        value: "HAB3"
                    }]
                },
                {
                    id: 'PC0499',
                    found: [{
                        label: "sitio",
                        value: "MIN"
                    }]
                }
            ]
            let arr6 = [{
                    id: 'PC0500',
                    found: [{
                        label: "sitio",
                        value: "MIN"
                    }]
                },
                {
                    id: 'PC0501',
                    found: [{
                        label: "sitio",
                        value: "PPR"
                    }]
                },
                {
                    id: 'PC0502',
                    found: [{
                        label: "sitio",
                        value: "MIN"
                    }]
                },
                {
                    id: 'PC0503',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }]
                },
                {
                    id: 'PC0504',
                    found: [{
                        label: "sitio",
                        value: "MIN"
                    }]
                },
                {
                    id: 'PC0505',
                    found: [{
                        label: "sitio",
                        value: "CAM"
                    }]
                },
                {
                    id: 'PC0506',
                    found: [{
                        label: "sitio",
                        value: "VE1"
                    }]
                },
                {
                    id: 'PC0507',
                    found: [{
                        label: "sitio",
                        value: "PHE"
                    }, {
                        label: "hab",
                        value: "HAB0003"
                    }]
                },
                {
                    id: 'PC0508',
                    found: [{
                        label: "sitio",
                        value: "PHE"
                    }]
                },
                {
                    id: 'PC0509',
                    found: [{
                        label: "sitio",
                        value: "PHE"
                    }]
                },
                {
                    id: 'PC0510',
                    found: [{
                        label: "sitio",
                        value: "CAM"
                    }]
                },
                {
                    id: 'PC0511',
                    found: [{
                        label: "sitio",
                        value: "PHE"
                    }]
                },
                {
                    id: 'PC0512',
                    found: [{
                        label: "sitio",
                        value: "PHE"
                    }]
                },
                {
                    id: 'PC0513',
                    found: [{
                        label: "sitio",
                        value: "CAG"
                    }]
                },
                {
                    id: 'PC0514',
                    found: [{
                        label: "sitio",
                        value: "CAG"
                    }]
                },
                {
                    id: 'PC0515',
                    found: [{
                        label: "sitio",
                        value: "CAG"
                    }]
                },
                {
                    id: 'PC0516',
                    found: [{
                        label: "sitio",
                        value: "CAG"
                    }]
                },
                {
                    id: 'PC0517',
                    found: [{
                        label: "sitio",
                        value: "CAG"
                    }]
                },
                {
                    id: 'PC0518',
                    found: [{
                        label: "sitio",
                        value: "CAG"
                    }]
                },
                {
                    id: 'PC0519',
                    found: [{
                        label: "sitio",
                        value: "MAE"
                    }]
                },
                {
                    id: 'PC0520',
                    found: [{
                        label: "sitio",
                        value: "CAG"
                    }]
                },
                {
                    id: 'PC0521',
                    found: [{
                        label: "sitio",
                        value: "PHE"
                    }]
                },
                {
                    id: 'PC0522',
                    found: [{
                        label: "sitio",
                        value: "BDP"
                    }]
                },
                {
                    id: 'PC0523',
                    found: [{
                        label: "sitio",
                        value: "CDE"
                    }]
                },
                {
                    id: 'PC0524',
                    found: [{
                        label: "sitio",
                        value: "BDP"
                    }]
                },
                {
                    id: 'PC0525',
                    found: [{
                        label: "sitio",
                        value: "BDP"
                    }]
                },
                {
                    id: 'PC0526',
                    found: [{
                        label: "sitio",
                        value: "MAE"
                    }]
                },
                {
                    id: 'PC0527',
                    found: [{
                        label: "sitio",
                        value: "CAG"
                    }]
                },
                {
                    id: 'PC0528',
                    found: [{
                        label: "sitio",
                        value: "CAG"
                    }]
                },
                {
                    id: 'PC0529',
                    found: [{
                        label: "sitio",
                        value: "VHM"
                    }, {
                        label: "hab",
                        value: "HAB001"
                    }]
                },
                {
                    id: 'PC0530',
                    found: [{
                        label: "sitio",
                        value: "CAM"
                    }]
                },
                {
                    id: 'PC0531',
                    found: [{
                        label: "sitio",
                        value: "ADM"
                    }]
                },
                {
                    id: 'PC0532',
                    found: [{
                        label: "sitio",
                        value: "MAE"
                    }]
                },
                {
                    id: 'PC0533',
                    found: [{
                        label: "sitio",
                        value: "EXT"
                    }, {
                        label: "hab",
                        value: "HAB005"
                    }]
                },
                {
                    id: 'PC0534',
                    found: [{
                        label: "sitio",
                        value: "VAG"
                    }]
                },
                {
                    id: 'PC0535',
                    found: [{
                        label: "sitio",
                        value: "BDP"
                    }]
                },
                {
                    id: 'PC0536',
                    found: [{
                        label: "sitio",
                        value: "VAG"
                    }]
                },
                {
                    id: 'PC0537',
                    found: [{
                        label: "sitio",
                        value: "EXT"
                    }, {
                        label: "hab",
                        value: "HAB005"
                    }]
                },
                {
                    id: 'PC0538',
                    found: [{
                        label: "sitio",
                        value: "VHM"
                    }, {
                        label: "hab",
                        value: "HAB001"
                    }]
                },
                {
                    id: 'PC0539',
                    found: [{
                        label: "sitio",
                        value: "VHM"
                    }, {
                        label: "hab",
                        value: "HAB001"
                    }]
                },
                {
                    id: 'PC0540',
                    found: [{
                        label: "sitio",
                        value: "VHM"
                    }, {
                        label: "hab",
                        value: "HAB001"
                    }]
                },
                {
                    id: 'PC0541',
                    found: [{
                        label: "sitio",
                        value: "BOM"
                    }]
                },
                {
                    id: 'PC0542',
                    found: [{
                        label: "sitio",
                        value: "VAG"
                    }]
                },
                {
                    id: 'PC0543',
                    found: [{
                        label: "sitio",
                        value: "ADM"
                    }]
                },
                {
                    id: 'PC0544',
                    found: [{
                        label: "sitio",
                        value: "EXT"
                    }, {
                        label: "hab",
                        value: "HAB005"
                    }]
                },
                {
                    id: 'PC0545',
                    found: [{
                        label: "sitio",
                        value: "BOM"
                    }]
                },
                {
                    id: 'PC0546',
                    found: [{
                        label: "sitio",
                        value: "MIN"
                    }]
                },
                {
                    id: 'PC0548',
                    found: [{
                        label: "sitio",
                        value: "IGC"
                    }]
                },
                {
                    id: 'PC0550',
                    found: [{
                        label: "sitio",
                        value: "IGC"
                    }]
                },
                {
                    id: 'PC0553',
                    found: [{
                        label: "sitio",
                        value: "EXT"
                    }, {
                        label: "hab",
                        value: "HAB0003"
                    }]
                },
                {
                    id: 'PC0555',
                    found: [{
                        label: "sitio",
                        value: "EXT"
                    }, {
                        label: "hab",
                        value: "HAB0003"
                    }]
                },
                {
                    id: 'PC0557',
                    found: [{
                        label: "sitio",
                        value: "IGC"
                    }]
                },
                {
                    id: 'PC0558',
                    found: [{
                        label: "sitio",
                        value: "IGC"
                    }]
                },
                {
                    id: 'PC0559',
                    found: [{
                        label: "sitio",
                        value: "TEL"
                    }]
                },
                {
                    id: 'PC0560',
                    found: [{
                        label: "sitio",
                        value: "IGC"
                    }]
                },
                {
                    id: 'PC0561',
                    found: [{
                        label: "sitio",
                        value: "IGC"
                    }]
                },
                {
                    id: 'PC0563',
                    found: [{
                        label: "sitio",
                        value: "IGC"
                    }]
                },
                {
                    id: 'PC0565',
                    found: [{
                        label: "sitio",
                        value: "IGC"
                    }]
                },
                {
                    id: 'PC0567',
                    found: [{
                        label: "sitio",
                        value: "BDP"
                    }]
                },
                {
                    id: 'PC0568',
                    found: [{
                        label: "sitio",
                        value: "IGC"
                    }]
                },
                {
                    id: 'PC0569',
                    found: [{
                        label: "sitio",
                        value: "BDP"
                    }]
                },
                {
                    id: 'PC0570',
                    found: [{
                        label: "sitio",
                        value: "BDP"
                    }]
                },
                {
                    id: 'PC0571',
                    found: [{
                        label: "sitio",
                        value: "IGC"
                    }]
                },
                {
                    id: 'PC0572',
                    found: [{
                        label: "sitio",
                        value: "VO1"
                    }, {
                        label: "hab",
                        value: "HAB001"
                    }]
                },
                {
                    id: 'PC0573',
                    found: [{
                        label: "sitio",
                        value: "EXT"
                    }, {
                        label: "hab",
                        value: "HAB0001"
                    }]
                },
                {
                    id: 'PC0574',
                    found: [{
                        label: "sitio",
                        value: "EXT"
                    }, {
                        label: "hab",
                        value: "HAB0001"
                    }]
                },
                {
                    id: 'PC0575',
                    found: [{
                        label: "sitio",
                        value: "EXT"
                    }, {
                        label: "hab",
                        value: "HAB0001"
                    }]
                },
                {
                    id: 'PC0576',
                    found: [{
                        label: "sitio",
                        value: "IGC"
                    }]
                },
                {
                    id: 'PC0577',
                    found: [{
                        label: "sitio",
                        value: "MAE"
                    }]
                },
                {
                    id: 'PC0578',
                    found: [{
                        label: "sitio",
                        value: "VO1"
                    }, {
                        label: "hab",
                        value: "HAB001"
                    }]
                },
                {
                    id: 'PC0579',
                    found: [{
                        label: "sitio",
                        value: "IGC"
                    }]
                },
                {
                    id: 'PC0580',
                    found: [{
                        label: "sitio",
                        value: "VO1"
                    }, {
                        label: "hab",
                        value: "HAB001"
                    }]
                },
                {
                    id: 'PC0581',
                    found: [{
                        label: "sitio",
                        value: "IGC"
                    }]
                },
                {
                    id: 'PC0582',
                    found: [{
                        label: "sitio",
                        value: "VO1"
                    }, {
                        label: "hab",
                        value: "HAB002"
                    }]
                },
                {
                    id: 'PC0583',
                    found: [{
                        label: "sitio",
                        value: "IGC"
                    }]
                },
                {
                    id: 'PC0584',
                    found: [{
                        label: "sitio",
                        value: "VO1"
                    }, {
                        label: "hab",
                        value: "HAB002"
                    }]
                },
                {
                    id: 'PC0585',
                    found: [{
                        label: "sitio",
                        value: "VO1"
                    }, {
                        label: "hab",
                        value: "HAB002"
                    }]
                },
                {
                    id: 'PC0586',
                    found: [{
                        label: "sitio",
                        value: "VO1"
                    }, {
                        label: "hab",
                        value: "HAB004"
                    }]
                },
                {
                    id: 'PC0587',
                    found: [{
                        label: "sitio",
                        value: "IGC"
                    }]
                },
                {
                    id: 'PC0588',
                    found: [{
                        label: "sitio",
                        value: "IGC"
                    }]
                },
                {
                    id: 'PC0589',
                    found: [{
                        label: "sitio",
                        value: "VO1"
                    }, {
                        label: "hab",
                        value: "HAB001"
                    }]
                },
                {
                    id: 'PC0590',
                    found: [{
                        label: "sitio",
                        value: "IGC"
                    }]
                },
                {
                    id: 'PC0591',
                    found: [{
                        label: "sitio",
                        value: "VO1"
                    }, {
                        label: "hab",
                        value: "HAB002"
                    }]
                },
                {
                    id: 'PC0592',
                    found: [{
                        label: "sitio",
                        value: "VO1"
                    }, {
                        label: "hab",
                        value: "HAB002"
                    }]
                },
                {
                    id: 'PC0593',
                    found: [{
                        label: "sitio",
                        value: "IGC"
                    }]
                },
                {
                    id: 'PC0594',
                    found: [{
                        label: "sitio",
                        value: "VO1"
                    }, {
                        label: "hab",
                        value: "HAB001"
                    }]
                },
                {
                    id: 'PC0595',
                    found: [{
                        label: "sitio",
                        value: "VO1"
                    }, {
                        label: "hab",
                        value: "HAB001"
                    }]
                },
                {
                    id: 'PC0596',
                    found: [{
                        label: "sitio",
                        value: "VO1"
                    }, {
                        label: "hab",
                        value: "HAB001"
                    }]
                },
                {
                    id: 'PC0597',
                    found: [{
                        label: "sitio",
                        value: "CAM"
                    }]
                },
                {
                    id: 'PC0598',
                    found: [{
                        label: "sitio",
                        value: "CAM"
                    }]
                },
                {
                    id: 'PC0599',
                    found: [{
                        label: "sitio",
                        value: "IGC"
                    }]
                },
                {
                    id: 'PC0600',
                    found: [{
                        label: "sitio",
                        value: "CAM"
                    }]
                }
            ]

            let arr7 = [{
                    id: 'PC0329',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }],
                    others: 'El objeto viene del depósito provisorio. Encontrado en "vivienda", identificar cual.'
                },
                {
                    id: 'PC0547',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }],
                    others: "Encontrado en viviendas del sector bajo, identificar cual."
                },
                {
                    id: 'PC0549',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }],
                    others: "Encontrado en viviendas del sector bajo, identificar cual."
                },
                {
                    id: 'PC0551',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }],
                    others: "Encontrado en viviendas del sector bajo, identificar cual."
                },
                {
                    id: 'PC0552',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }],
                    others: "Encontrado en viviendas del sector bajo, identificar cual."
                },
                {
                    id: 'PC0554',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }],
                    others: "Encontrado en viviendas del sector bajo, identificar cual."
                },
                {
                    id: 'PC0556',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }],
                    others: "Encontrado en viviendas del sector bajo, identificar cual."
                },
                {
                    id: 'PC0562',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }],
                    others: "Encontrado en viviendas del sector bajo, identificar cual."
                },
                {
                    id: 'PC0564',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }],
                    others: "Encontrado en viviendas del sector bajo, identificar cual."
                },
                {
                    id: 'PC0566',
                    found: [{
                        label: "sitio",
                        value: "Desconocido"
                    }],
                    others: "Encontrado en viviendas del sector bajo, identificar cual."
                }
            ]


            console.log(arr1, arr2, arr3, arr4, arr5, arr6)

            arr7.forEach(async item => {
                await updateDoc(doc(db, 'ListaObjetos', item.id), {
                    found: item.found,
                    others: item.others
                })
                console.log(item.id + " actualizado")
            })

        },
        async getNextValue({
            commit
        }) {
            try {
                onSnapshot(doc(db, 'ListaObjetos', 'counter'), docRef => {
                    commit('SET_NEXT_VALUE', docRef.data().nextValue)
                })
            } catch (e) {
                console.log("error", e)
            }
        },
        async getAllObjects({
            commit,
            dispatch,
            getters
        }) {
            let data
            let i = 0
            while (i < getters.getMissingObjects.length) {
                data = await getDocs(query(collection(db, 'ListaObjetos'), where("inventory", ">", getters.getMissingObjects[i].min), where("inventory", "<", getters.getMissingObjects[i].max)))
                data.forEach(item => {
                    commit('ADD_ITEM', {
                        id: item.id,
                        ...item.data()
                    })
                    if (item.data().compuesto) {
                        dispatch("getObjectsFromConjunto", item.id)
                    }
                })
                i++
            }
            return true
        },
        async getAllObjectsByPublishedStatus({
            commit,
            dispatch,
            getters
        }, payload) {
            let data
            let i = 0
            while (i < getters.getMissingObjects.length) {
                data = await getDocs(query(collection(db, 'ListaObjetos'), where("inventory", ">", getters.getMissingObjects[i].min), where("inventory", "<", getters.getMissingObjects[i].max), where("published", "==", payload)))
                data.forEach(item => {
                    commit('ADD_ITEM', {
                        id: item.id,
                        ...item.data()
                    })
                    if (item.data().compuesto) {
                        dispatch("getObjectsFromConjunto", item.id)
                    }
                })
                i++
            }
            return true
        },
        async getAllObjectsByCompoundStatus({
            commit,
            dispatch,
            getters
        }, payload) {
            let data
            let i = 0
            while (i < getters.getMissingObjects.length) {
                data = await getDocs(query(collection(db, 'ListaObjetos'), where("inventory", ">", getters.getMissingObjects[i].min), where("inventory", "<", getters.getMissingObjects[i].max), where("compuesto", "==", payload)))
                data.forEach(item => {
                    commit('ADD_ITEM', {
                        id: item.id,
                        ...item.data()
                    })
                    if (item.data().compuesto) {
                        dispatch("getObjectsFromConjunto", item.id)
                    }
                })
                i++
            }
            return true
        },
        async getAllObjectsByOriginSite({
            commit,
            dispatch,
            getters
        }, payload) {
            let data
            let i = 0
            while (i < getters.getMissingObjects.length) {
                data = await getDocs(query(collection(db, 'ListaObjetos'), where("inventory", ">", getters.getMissingObjects[i].min), where("inventory", "<", getters.getMissingObjects[i].max), where("compuesto", "==", payload)))
                data.forEach(item => {
                    commit('ADD_ITEM', {
                        id: item.id,
                        ...item.data()
                    })
                    if (item.data().compuesto) {
                        dispatch("getObjectsFromConjunto", item.id)
                    }
                })
                i++
            }
            return true
        },
        async getObjectsFromConjunto({
            commit
        }, payload) {
            let list = await getDocs(query(collection(db, 'ObjetosCompuestos'), where("parent", "==", payload)))
            list.forEach(item => {
                commit("ADD_COMPOSED_ITEM", {
                    parent: item.data().parent,
                    id: item.id,
                    data: item.data().data
                })
            })
        },
        async createNewComposedObject({
            commit
        }, payload) {
            try {
                let composed = await doc(db, "ObjetosCompuestos", payload.parent + "." + payload.id)
                let parent = await doc(db, "ListaObjetos", payload.parent)
                let result = await runTransaction(db, async (transaction) => {
                    let item = await transaction.get(parent)
                    console.log("agregando", item)
                    let objetos = item.data().conjuntoObjetos
                    objetos.push(payload.parent + "." + payload.id)
                    let newElement = {
                        name: payload.name,
                        dimensions: [],
                        materials: [],
                        techniques: [],
                        description: "",
                        mainImage: "",
                        photos: []
                    }
                    transaction.set(composed, {
                        parent: payload.parent,
                        data: newElement
                    })
                    transaction.update(parent, {
                        conjuntoObjetos: objetos
                    })
                    return {
                        parent: payload.parent,
                        lista: objetos,
                        id: payload.parent + "." + payload.id,
                        data: {
                            ...newElement
                        }
                    }
                })
                commit("ADD_COMPOSED_ITEM", {
                    parent: result.parent,
                    id: result.id,
                    data: result.data
                })
                commit("UPDATE_LISTA_CONJUNTO", {
                    id: result.parent,
                    lista: parent.lista
                })
            } catch (e) {
                console.log('error', e)
            }
        },
        async createNewObject({
            commit
        }, payload) {
            try {
                let obj = await doc(db, "Colecciones", payload.collection);
                let count = await doc(db, "ListaObjetos", "counter")
                let lista = await doc(db, "ListaObjetos", "indexList")
                let result = await runTransaction(db, async (transaction) => {
                    let cont = await transaction.get(count)
                    let ind=await transaction.get(lista)
                    let indice=ind.data().list
                    let nextIndex=cont.data().nextValue
                    let newId = "";
                    if (nextIndex.toString().length === 1) {
                        newId = payload.collection + "000" + nextIndex;
                    } else if (nextIndex.toString().length === 2) {
                        newId = payload.collection + "00" + nextIndex;
                    } else if (nextIndex.toString().length === 3) {
                        newId = payload.collection + "0" + nextIndex;
                    } else {
                        newId = payload.collection + nextIndex;
                    }
                    
                    console.log("agregando", newId)
                    let objRef = await doc(db, "ListaObjetos", newId)
                    let path = "Categoria";
                    let i = 1;
                    payload.parents.forEach((elem) => {
                        if (i < payload.parents.length) {
                            path = path + "/" + elem.id + "/Categorias";
                        } else {
                            path = path + "/" + elem.id + "/items/";
                        }
                        console.log(path)
                        i++;
                    });
                    let objData = {
                        name: payload.name,
                        clasification: payload.parents,
                        collection: payload.collection,
                        creator: payload.userName,
                        inventory: nextIndex,
                        compuesto: false,
                        conjuntoObjetos: [],
                        conservaction: { requires: "", comments: "" },
                        description: "",
                        donant: "",
                        exhibition: "",
                        found: "",
                        identifier: "",
                        images: [],
                        keyWords: [],
                        location: "",
                        locationComments: "",
                        materials: [],
                        measurements: [],
                        alternativeNames: [],
                        origin: "",
                        others: "",
                        pieceStatus: {
                          comments: "",
                          status: "",
                        },
                        published: false,
                        referenceImage: "",
                        restoration: "",
                        techniques: [],
                        relatedObjects: [],
                      };
                    indice.push({
                        id:newId,
                        inventory:nextIndex,
                        name:objData.name,
                        origin:"Desconocido",
                        published:false
                    })
                    transaction.update(obj, { nextValue: nextIndex + 1 });
                    transaction.update(count, { nextValue: nextIndex + 1 });  
                    transaction.set(objRef,{...objData})
                    transaction.update(lista,{list:indice})
                    //transaction.set(getDoc(db, path, newId), { id: newId });
                    
                    return {
                        id: newId,
                        data:{...objData}
                    }
                })
                commit("ADD_ITEM", { id: result.id, ...result.data });
                return result
            } catch (e) {
                console.log('error', e)
            }
        },
        async setCompuesto({
            commit
        }, payload) {
            try {
                await updateDoc(doc(db, 'ListaObjetos', payload.id), {
                    compuesto: payload.value
                })
                commit('UPDATE_COMPUESTO_FROM_OBJECT', payload)
                console.log("Ojbjeto " + payload.id + " cambiado Compuesto: " + payload.value);
            } catch (e) {
                console.log("error" + e)
            }
        },
        
        async getInitialItems({
            commit,
            dispatch,
            getters,
            rootGetters,
            state
        }) {
            onSnapshot(query(collection(db, 'ListaObjetos'), orderBy('name'), limit(state.itemsPerPage)), qS => {
                let arr = []
                qS.docChanges().forEach(change => {
                    if (change.type === "added") {
                        commit('ADD_ITEM', {
                            id: change.doc.id,
                            ...change.doc.data()
                        })
                        arr.push({
                            id: change.doc.id,
                            ...change.doc.data()
                        })
                        if (change.doc.data().compuesto) {
                            dispatch("getObjectsFromConjunto", change.doc.id)
                        }

                        if (change.doc.data().relatedObjects && change.doc.data().relatedObjects.length > 0) {
                            change.doc.data().relatedObjects.forEach(item => {
                                if (!state.items.find(el => el.id === item.id)) {
                                    let query = doc(db, 'ListaObjetos', item.id)
                                    dispatch("getSingleItemByQuery", query)
                                }
                            })
                        }
                    }
                    if (change.type === "modified") {
                        commit('UPDATE_ITEM', {
                            id: change.doc.id,
                            data: change.doc.data()
                        })
                    }
                    if (change.type === "removed") {
                        commit('REMOVE_ITEM_BY_ID', change.doc.id)
                    }
                })
                if (rootGetters.user) {
                    commit('SET_CURRENT_LIST', arr)
                }
            })
            let list = await getDocs(query(collection(db, 'ListaObjetos'), orderBy('name'), where("published", "==", true), limit(getters.itemsPerPage)))
            let arr = []
            list.forEach(docRef => {
                commit('ADD_ITEM', {
                    id: docRef.id,
                    ...docRef.data()
                })
                arr.push({
                    id: docRef.id,
                    ...docRef.data()
                })
                if (docRef.data().compuesto) {
                    dispatch("getObjectsFromConjunto", docRef.id)
                }
                if (docRef.data().relatedObjects && docRef.data().relatedObjects.length > 0) {
                    docRef.data().relatedObjects.forEach(item => {
                        if (!state.items.find(el => el.id === item.id)) {
                            let query = doc(db, 'ListaObjetos', item.id)
                            dispatch("getSingleItemByQuery", query)
                        }
                    })
                }
            })
            if (!rootGetters.user) {
                commit('SET_CURRENT_LIST', arr)
            }
        },
        async getItemsByQuery({
            commit,
            dispatch,
            state
        }, query) {
            try {
                let qS = await getDocs(query)
                let arr = []
                qS.forEach(docRef => {
                    commit('ADD_ITEM', {
                        id: docRef.id,
                        ...docRef.data()
                    })
                    arr.push({
                        id: docRef.id,
                        ...docRef.data()
                    })
                    if (docRef.data().compuesto) {
                        dispatch("getObjectsFromConjunto", docRef.id)
                    }
                    if (docRef.data().relatedObjects && docRef.data().relatedObjects.length > 0) {
                        docRef.data().relatedObjects.forEach(item => {
                            if (!state.items.find(el => el.id === item.id)) {
                                let q = doc(db, 'ListaObjetos', item.id)
                                dispatch("getSingleItemByQuery", q)
                            }
                        })
                    }
                })
                return arr

            } catch (e) {
                console.log('error', e)
            }
        },
        async getSingleItemByQuery({
            commit,
            dispatch,
            state
        }, query) {
            try {
                let document = await getDoc(query)
                commit('ADD_ITEM', {
                    id: document.id,
                    ...document.data()
                })
                if (document.data().compuesto) {
                    dispatch("getObjectsFromConjunto", document.id)
                }
                if (document.data().relatedObjects && document.data().relatedObjects.length > 0) {
                    let i = 0
                    while (i < document.data().relatedObjects.length) {
                        let item = document.data().relatedObjects[i]
                        if (!state.items.find(el => el.id === item.id)) {
                            let q = doc(db, 'ListaObjetos', item.id)
                            await dispatch("getSingleItemByQuery", q)
                        }
                        i++
                    }

                }
                if (!document.data().keyWords) {
                    document.data().keyWords = []
                }
                if (!document.data().materials) {
                    document.data().materials = []
                }
                if (!document.data().techniques) {
                    document.data().techniques = []
                }
                if (!document.data().conservation) {
                    document.data().conservation = {
                        comments: "",
                        value: ""
                    }
                }
                if (!document.data().pieceStatus) {
                    document.data().pieceStatus = {
                        comments: "",
                        value: ""
                    }
                }
                if (!document.data().restoration) {
                    document.data().restoration = ""
                }
                if (!document.data().locationComments) {
                    document.data().locationComments = ""
                }
                if (!document.data().location) {
                    document.data().location = ""
                }

                if (!document.data().published) {
                    document.data().published = false
                }
                if (!document.data().relatedObjects) {
                    document.data().relatedObjects = []
                }
                return {
                    id: document.id,
                    ...document.data()
                }
            } catch (e) {
                console.log('error', e)
            }
        },
        async getSize({
            commit,
            dispatch
        }) {
            try {
                onSnapshot(doc(db, 'ListaObjetos', 'indexList'), docRef => {
                    commit('SET_INDEXED_ITEMS', docRef.data().list)
                    dispatch('getPublishedItems')
                })
            } catch (e) {
                console.log("error", e)
            }

        },
        async getPublishedItems({
            commit
        }) {
            try {
                onSnapshot(doc(db, 'ListaObjetos', 'published'), docRef => {
                    commit('ADD_PUBLISHED_ITEMS', docRef.data().list)
                })
            } catch (e) {
                console.log("error", e)
            }
        },
        async removePhotoFromItem({
            commit
        }, payload) {
            try {
                await updateDoc(doc(db, 'ListaObjetos', payload.id), {
                    images: payload.list
                })
                commit('ELIMINAR_FOTO_DE_LISTA', payload)
            } catch (e) {
                console.log('error', e)
            }
        },
        async removeReferenciaFromItem({
            commit
        }, payload) {
            await updateDoc(doc(db, 'ListaObjetos', payload), {
                referenceImage: {}
            })
            commit('ELIMINAR_IMAGEN_PRINCIPAL_OBJETO', payload)
        },
        async updateItemFromServer(commit, payload) {
            try {
                let docRef = await getDoc(doc(db, "ListaObjetos", payload))
                console.log(docRef.data())
                commit("UPDATE_ITEM", {
                    id: payload,
                    data: docRef.data()
                })
                commit("SET_CURRENT_ITEM", {
                    id: payload,
                    data: docRef.data()
                })
            } catch (e) {
                console.log('error', e)
            }
        },
        async updateDimensionsOfObjetoConjunto({
            commit
        }, payload) {
            try {
                await updateDoc(doc(db, 'ObjetosCompuestos', payload.id), {
                    'data.dimensions': payload.data
                })

                commit('ACTUALIZAR_DIMENSIONES_OBJETO_CONJUNTO', payload)
            } catch (e) {
                console.log('error', e)
            }
        },
        async updateFeaturesOfObjetoConjunto({
            commit
        }, payload) {
            try {
                await updateDoc(doc(db, 'ObjetosCompuestos', payload.id), {
                    'data.description': payload.data.description,
                    'data.name': payload.data.name,
                    'data.materials': payload.data.materials,
                    'data.techniques': payload.data.techniques
                })
                commit('ACTUALIZAR_CARACTERISTICAS_OBJETO_CONJUNTO', payload)
            } catch (e) {
                console.log('error', e)
            }
        },
        async updateReferenceImageConjunto({
            commit
        }, payload) {
            try {
                await updateDoc(doc(db, 'ObjetosCompuestos', payload.id), {
                    'data.mainImage': payload.data
                })

                commit('ACTUALIZAR_IMAGEN_PRINCIPAL_OBJETO_CONJUNTO', payload)
            } catch (e) {
                console.log("error", e)
            }
        },
        async updateImagesConjunto({
            commit
        }, payload) {
            try {
                await updateDoc(doc(db, 'ObjetosCompuestos', payload.id), {
                    'data.photos': payload.data
                })
                commit('ACTUALIZAR_LISTA_IMAGENES_OBJETO_CONJUNTO', payload)
            } catch (e) {
                console.log('error', e)
            }
        },
        async updateFeatures({
            commit
        }, payload) {
            try {
                await updateDoc(doc(db, 'ListaObjetos', payload.id), {
                    description: payload.data.descripcion,
                    keyWords: payload.data.palabras,
                    materials: payload.data.materiales,
                    techniques: payload.data.tecnicas
                })
                commit('ACTUALIZAR_CARACTERISTICAS', payload)
            } catch (error) {
                console.log('error', error)
            }
        },
        async updateDimensions({
            commit
        }, payload) {
            try {
                await updateDoc(doc(db, 'ListaObjetos', payload.id), {
                    'measurements': payload.data
                })
                commit('ACTUALIZAR_DIMENSIONES', payload)
            } catch (e) {
                console.log('error', e)
            }
        },
        async updateOrigin({
            commit
        }, payload) {
            try {
                await updateDoc(doc(db, 'ListaObjetos', payload.id), {
                    donant: payload.donante,
                    origin: payload.origen,
                    found: payload.encontrado
                })
                commit('ACTUALIZAR_ORIGEN', payload)
            } catch (e) {
                console.log('error', e)
            }
        },
        async updateConservation({
            commit
        }, payload) {
            try {
                await updateDoc(doc(db, 'ListaObjetos', payload.id), {
                    conservation: payload.conservacion,
                    pieceStatus: payload.estado,
                    restoration: payload.restauracion,
                    identifier: payload.rotulado
                })
                commit('ACTUALIZAR_ESTADO_CONSERVACION', payload)
            } catch (e) {
                console.log('error', e)
            }
        },
        async updateLocation({
            commit
        }, payload) {
            console.log(payload)
            try {
                await updateDoc(doc(db, 'ListaObjetos', payload.id), {
                    exhibition: payload.exhibicion,
                    location: payload.ubicacion,
                    locationComments: payload.comentarios,
                    others: payload.notas
                })
                commit('ACTUALIZAR_UBICACION', payload)
            } catch (e) {
                console.log('error', e)
            }
        },
        async addRelatedObject({
            commit,
            dispatch
        }, payload) {
            try {
                let actual = doc(db, 'ListaObjetos', payload.id)
                let related = doc(db, 'ListaObjetos', payload.newItem)
                let result = await runTransaction(db, async transaction => {
                    let relItem = await transaction.get(related)
                    let array = relItem.data().relatedObjects
                    if (array) {
                        if (!array.find(el => el.id === payload.id)) {
                            array.push({
                                id: payload.id,
                                relation: payload.newRelation
                            })
                        }
                    } else {
                        array = [{
                            id: payload.id,
                            relation: payload.newRelation
                        }]
                    }
                    transaction.update(related, {
                        relatedObjects: array
                    })
                    transaction.update(actual, {
                        relatedObjects: payload.list
                    })
                    return payload
                })
                commit('ACTUALIZAR_LISTA_OBJETOS_RELACIONADOS', result)
                let query = doc(db, 'ListaObjetos', result.newItem)
                dispatch("getSingleItemByQuery", query)
            } catch (e) {
                console.log('error', e)
            }
        },
        async removeRelatedObjectLink({
            commit
        }, payload) {
            try {
                let actual = doc(db, 'ListaObjetos', payload.id)
                let related = doc(db, 'ListaObjetos', payload.rId)
                let result = await runTransaction(db, async transaction => {
                    let relItem = await transaction.get(related)
                    let relArr = relItem.data().relatedObjects
                    let i = relArr.findIndex(el => el.id === payload.id)
                    if (i > -1) {
                        relArr.splice(i, 1)
                    }
                    let actualItem = await transaction.get(actual)
                    let actArr = actualItem.data().relatedObjects
                    let j = actArr.findIndex(el => el.id === payload.rId)
                    if (j > -1) {
                        actArr.splice(j, 1)
                    }
                    transaction.update(actual, {
                        relatedObjects: actArr
                    })
                    transaction.update(related, {
                        relatedObjects: relArr
                    })
                    return {
                        item: {
                            id: payload.id,
                            list: actArr
                        },
                        rel: {
                            id: payload.rId,
                            list: relArr
                        }
                    }
                })
                commit('ACTUALIZAR_LISTA_OBJETOS_RELACIONADOS', result.item)
                commit('ACTUALIZAR_LISTA_OBJETOS_RELACIONADOS', result.rel)
            } catch (e) {
                console.log('error', e)
            }
        },
        async updateRelatedObjectRelation({
            commit
        }, payload) {
            try {
                let actual = doc(db, 'ListaObjetos', payload.id)
                let related = doc(db, 'ListaObjetos', payload.rId)
                let result = await runTransaction(db, async transaction => {
                    let relItem = await transaction.get(related)
                    let relArr = relItem.data().relatedObjects
                    relArr.find(el => el.id === payload.id).relation = payload.relation
                    let actualItem = await transaction.get(actual)
                    let actArr = actualItem.data().relatedObjects
                    actArr.find(el => el.id === payload.rId).relation = payload.relation
                    transaction.update(actual, {
                        relatedObjects: actArr
                    })
                    transaction.update(related, {
                        relatedObjects: relArr
                    })
                    return {
                        item: {
                            id: payload.id,
                            list: actArr
                        },
                        rel: {
                            id: payload.rId,
                            list: relArr
                        }
                    }
                })
                commit('ACTUALIZAR_LISTA_OBJETOS_RELACIONADOS', result.item)
                commit('ACTUALIZAR_LISTA_OBJETOS_RELACIONADOS', result.rel)
            } catch (e) {
                console.log('error', e)
            }

        },
        async updateRelatedObjectList({
            commit
        }, payload) {
            try {
                await updateDoc(doc(db, 'ListaObjetos', payload.id), {
                    relatedObjects: payload.list
                })
                commit('ACTUALIZAR_LISTA_OBJETOS_RELACIONADOS', payload)
            } catch (e) {
                console.log('error', e)
            }
        },
        async updateDescriptions() {
            let arr1 = [{
                    id: 'PC0001',
                    description: 'Herramienta de uso manual, utilizada a modo de tenaza en la extracción de materiales. Está compuesta por dos partes alargadas, unidas a una bisagra en el sector distal, permitiendo el abrir y cerrar. Fue elaborado en acero ensamblado y forjado.'
                },
                {
                    id: 'PC0002',
                    description: 'Herramienta utilizada para apretar o aflojar tornillos de manera manual, mediante torsión. Fue fabricada en acero y posee una forma abierta en cada extremo para tornillos hexagonales. Tiene impresas en relieve las medidas de la llave con la inscripción de 150 A/F y 167 A/F. Presenta la Marca HOLMAN.'
                },
                {
                    id: 'PC0003',
                    description: 'Objeto utilizado en conjunto con una balanza para generar contrapeso. Su forma es de cilindro, con una ranura recta y horizontal que va desde el centro hasta uno de sus lados, permitiéndole tener un agarre en la balanza. La pieza está fabricada en acero. En la parte superior, tiene las inscripciones 200 Kg, 2 Kg y AD y en la parte posterior se observa la inscripción de fabricación ARGENTINA INDUSTRIAL.'
                },
                {
                    id: 'PC0004',
                    description: 'Objeto utilizado para sostener o almacenar tubos del tipo Eppendorf en un laboratorio. Compuesto por dos bases circulares unidas por el centro a una pieza cilíndrica. Posee cuatro patas a modo de soporte. Cada base circular fue confeccionada con 16 agujeros a la misma altura, pero de diferentes tamaños. El objeto fue elaborado completamente en madera y pintado en tonos verdes, a excepción de su cara inferior. '
                },
                {
                    id: 'PC0005',
                    description: 'Recipiente utilizado para contener y servir líquidos. El objeto tiene un cuerpo de forma globular, seguido por un cuello corto con un bisel que termina en un pico pronunciado con forma redondeada para facilitar la acción de verter el líquido interior. En el lateral opuesto posee un asa remachada que nace a un cuarto de su altura. Fue completamente elaborada en metal enlozado blanco, exceptuando el cuello y el asa color azul. La base presenta desgaste por uso y agujeros. El recipiente posee una masilla adherida a un costado, posiblemente debido a una reparación cuando el objeto estaba aún en uso.'
                },
                {
                    id: 'PC0006',
                    description: 'Objeto utilizado para cerrar o cubrir un empalme eléctrico. Su forma es rectangular. En la parte superior se observa una apertura también rectangular, enmarcada en plástico a modo de visor, y en la parte inferior un ahuecamiento con forma elíptica. El objeto fue elaborado en hierro esmaltado mediante fundición. Posee una inscripción en la superficie superior derecha “SAIME", y una salida cilíndrica en la parte posterior. '
                },
                {
                    id: 'PC0007',
                    description: 'Sistema de iluminación individual para mineros de metales y del carbón. El sistema consiste en un cabezal con lámpara de enfoque ajustable y una batería de larga duración. La lámpara para casco modelo ML-2 se carga directamente en el bastidor, a través del cabezal; el cabezal que se conecta al casco es de plástico de policarbonato moldeado; el aro de plástico translúcido refleja la luz cuando la lámpara está encendida; el lente se ajusta contra un empaque de caucho empotrado en una ranura del aro. La batería MineSpot tiene forma rectangular, con esquinas inferiores reforzadas y presillas moldeadas en la caja de la batería para suspenderla del cinturón; dos ventanillas transparentes con orificios integrales de entrada facilitan llenar la pila con agua rápidamente, sin retirar piezas; la cubierta negra de policarbonato se ajusta a la batería mediante dos grapas de sujeción.'
                },
                {
                    id: 'PC0008',
                    description: 'Instrumento utilizado para la medición eléctrica. Está compuesto por dos partes, un cuerpo cilíndrico negro, con una superficie cuadrada de vidrio y una etiquetada de papel adherida por un cordel. El objeto fue fabricado mediante ensambles de plástico, de color negro, y vidrio,  Además, de la etiqueta de papel. En la superficie frontal se observan variaciones de mediciones entre los 0 a los 400 mA, y en la etiqueta de inventario se observan especificaciones y detalles de cantidad y repuestos.'
                },
                {
                    id: 'PC0009',
                    description: 'Instrumento utilizado para la medición eléctrica. Su forma es cilíndrica, con una superficie redonda de vidrio. Fue fabricado mediante ensambles de plástico y vidrio de color negro. En la cara superior se observan variaciones de medición entre los 0 a los 300 mA,  Además, de las inscripciones relativas a la marca y serie “Klasse15. DS. 193".'
                },
                {
                    id: 'PC0010',
                    description: 'Concreción mineral utilizada como muestra de material. Tiene forma irregular y se compone por una pieza. Tiene adosada cinta adhesiva de manera envolvente con la inscripción “Con ley 50" y “Blenda" escrita de manera manual en plumón negro. (Texto escrito por la Corporación Memoria Austral, en el año 2014)'
                },
                {
                    id: 'PC0011',
                    description: 'Objeto utilizado como aislante de tensión en instalaciones eléctricas. Está compuesto por dos piezas: la primera tiene forma cilíndrica y concavidades concéntricas para facilitar el enrolle del cableado, fabricado en cerámica, de color blanco; la segunda pieza es un soporte de acero adosado mediante un tubo que atraviesa la primera pieza de forma horizontal. El objeto fue fabricado mediante técnicas de ensamblado y vaciado.'
                },
                {
                    id: 'PC0012',
                    description: 'Utensilio utilizado para sostener tubos de ensayo, es de forma alargada, en base a una estructura de madera conformada por dos caras laterales de apoyo con forma de llave inglesa invertida y, perpendicular a éstas, dos láminas de madera horizontales colocadas en paralelo una por sobre de la otra, que contienen ocho orificios circulares que las atraviesan. Fue pintada en color verde y se puede observar una mancha de color rojo en una de sus caras laterales.'
                },
                {
                    id: 'PC0013',
                    description: 'Objeto utilizado como aislante de alta tensión en instalaciones eléctricas. Está compuesto por una sola pieza, cuya base se conforma por un cuerpo cilíndrico que contiene un anillo de goma, seguido por una secuencia de discos en espiral ordenados de mayor a menor tamaño. El objeto fue fabricado principalmente de porcelana con técnica de vaciado, pintado por fuera de color granate oscuro. Posee una etiqueta de papel adosada mediante un cordel con las inscripciones “Empresa Minera de AYSEN" y el código “04003-7" impreso en letras negras.'
                },
                {
                    id: 'PC0014',
                    description: 'Recipiente utilizado para contener muestras de mineral. Tiene forma cilíndrica y lleva un asa de agarre en la parte superior confeccionada con un cable. El objeto es metálico y está manufacturado de modo artesanal. Tiene evidencia de desgaste por uso, pátina por exposición ambiental y abolladuras.'
                },
                {
                    id: 'PC0015',
                    description: 'Accesorio de seguridad utilizado para proteger la cabeza de la persona en el combate contra el fuego. El casco tiene una forma semiesférica cóncava y fue compuesto en su mayoría de resina de poliéster de fibra de vidrio con superficie de pigmento aluminizado. Es de color negro casi en su totalidad. Posee, como protección interna, una malla plástica con ajustes de alambre para acomodarse al tamaño de la cabeza.'
                },
                {
                    id: 'PC0016',
                    description: 'Objeto utilizado para proteger la cabeza de heridas y contusiones. El objeto tiene dos componentes básicos: la copa y la suspensión. La copa tiene una forma semiesférica cóncava con cresta y en la parte frontal se pueden ver tres orificios y un alambre enganchado de manera horizontal. En su cavidad interior posee la suspensión que se compone de banda para la cabeza ajustable y correas de corona dobles, cuatro sujetadores entran en las ranuras profundas de la copa y sostienen la banda de la cabeza. La copa fue confeccionada en policarbonato moldeado de color amarillo y la suspensión en policarbonato blanco. En la parte interna de la visera se observa la inscripción en relieve “PROSEG MR Mod 1005".'
                },
                {
                    id: 'PC0017',
                    description: 'Herramienta utilizada para sujetar al menos una bureta de manera vertical. Está compuesta por dos piezas en forma de V con una sujeción doble que evita que se encorve. Cada una de estas pinzas pose un tornillo y contra tensiones que las atraviesa de manera vertical en cada lateral. En la base se observa un soporte universal. El cuerpo de la herramienta fue fabricado en acero mediante fundición y torneado y el soporte universal en plástico negro ensamblado. '
                },
                {
                    id: 'PC0018',
                    description: 'Contenedor utilizado para conservar crema. El envase es de cuerpo cilíndrico, ahuecado, fabricado en metal moldeado azul .La parte interior carece de color. Mantiene una inscripción con letras blancas, identificando la marca de fabricación como "NIVEA Creme". Su estado de conservación es regular pues carece de tapa y presenta abolladuras y deformaciones. '
                },
                {
                    id: 'PC0019',
                    description: 'Accesorio utilizado para reflejar la luz u otro tipo de onda. El objeto tiene forma de cono truncado, compuesto por una sola pieza. Fue fabricado en aluminio mediante técnica de moldeado. Se observa una superficie brillante en comparación a la opacidad del interior. Posee una inscripción manual, con el número 20 en tinta, en los límites superficiales de la apertura de menor diámetro. '
                },
                {
                    id: 'PC0020',
                    description: 'Utensilio utilizado en deportes de pelota, específicamente fútbol. El objeto es circular icosaedro truncado. En uno de sus lados se observa el orificio de válvula de inflado y en tres puntos de la superficie se aprecian parches de color azul de distintos tamaños y de confección artesanal. El balón es de cuero blanco. Los hexágonos de tela se encuentran adheridos mediante costuras de hilo. Su estado de conservación es malo debido a que algunas de las uniones se encuentran rasgadas y posee zonas descosidas, lo que impide que mantenga su forma esférica original, asimismo se aprecian restos de adhesivo seco.'
                },
                {
                    id: 'PC0021',
                    description: 'Objeto utilizado para proteger la cabeza de heridas y contusiones. El objeto tiene dos componentes básicos: la copa y la suspensión. La copa tiene una forma semiesférica cóncava con cresta. En su cavidad interior posee la suspensión que se compone de banda para la cabeza ajustable y correas de corona dobles, cuatro sujetadores entran en las ranuras profundas de la copa y sostienen la banda de la cabeza. Tanto la copa como la suspensión fueron confeccionados en policarbonato moldeado de color blanco. En el exterior lateral izquierdo de la copa, se identifica una pegatina de la Asociación Chilena de Seguridad y en el borde posterior derecho se encuentra un faltante y una grieta. En la visera, por la zona interior, se encuentra una inscripción en relieve de la marca PROSEG MR Mod 1005. En la cavidad interior izquierda se observa una pegatina de papel con información rasgada. '
                },
                {
                    id: 'PC0022',
                    description: 'Objeto utilizado para sujetar parte del riel sobre el que circulaban los carritos que transportaban mineral, de forma delgada, alargada, de sección cuadrada, con punta filosa en un extremo y cabeza plana ligeramente circular en el otro. El objeto es de hierro fundido, se encuentra oxidado y presenta golpes de uso.'
                },
                {
                    id: 'PC0023',
                    description: 'Prenda de vestir de infante utilizada para cubrir los pies. Está compuesta por una superficie con dos ojetes por lado, una lengüeta y una suela. Fue confeccionada principalmente en cuero cosido, lo ojetes son de metal y la suela es de goma carbonizada. La prenda es principalmente de color blanco, a excepción de la suela de color negro que se encuentra con indicios de desprendimiento. Posee diseños de flores y puntos realizados mediante calado. El objeto se encuentra desgastado por el uso. En el depósito se encuentran dos unidades correspondientes al pie izquierdo y al pie derecho que conserva aún el cordón. '
                },
                {
                    id: 'PC0024',
                    description: 'Medio de almacenamiento analógico de señales sonoras utilizado junto a un tocadiscos para producir música. Su forma es circular y plana, fabricado con resina plastificada, de color negro. Tiene una etiqueta adherida en el centro de color amarillo que contiene una inscripción manual en lápiz pasta azul "MADIPRO", y un timbraje en cada cara en que se observa la palabra "DISCOTECA". En el depósito se encuentran diversos ejemplares que varían en contenido, tamaño y color de la etiqueta.'
                },
                {
                    id: 'PC0025',
                    description: 'Prenda de vestir de infante utilizada para cubrir los pies. Está compuesta por una superficie con tres ojetes por lado, una tobillera recubierta y una suela contra resbalones. Fue principalmente confeccionada en plástico cosido, con ojetes metálicos y felpa en la recubierta superior. La prenda es completamente blanca, y pose una costura ornamental en la parte frontal. En el depósito se encuentran dos unidades correspondientes al pie izquierdo y al derecho, ambas con agujeros en la tela plástica y ojetes oxidados. '
                },
                {
                    id: 'PC0026',
                    description: 'Calzado especialmente diseñado para ser utilizado en momentos de mayor actividad física. El objeto fue fabricado en su mayoría de lona blanca con un refuerzo semicircular en el tobillo y una planta de plástico con diseño antideslizante. Posee un diseño de franjas cruzadas azules en cada lateral. Las costuras fueron elaboradas en hilo blanco y azul. En la parte posterior se observa la inscripción “SENDA" que hace referencia a su origen industrial. Su estado de conservación es regular, presenta faltantes de cordones y lengüeta,  Además, de desgaste por uso. Se encuentra en el depósito un par correspondientes al calzado izquierdo y derecho.'
                },
                {
                    id: 'PC0027',
                    description: 'Formato de grabación y reproducción de sonido en cinta magnética. Es de forma rectangular, fabricado en plástico transparente moldeado. Tiene una pegatina de papel blanco adherida en su cara frontal que contiene texto informativo impreso y de escritura manual. En la parte inferior izquierda se identifica la marca Sony, en su lado izquierdo la letra “B", en el lado derecho el número 60 y en la zona superior se encuentra un texto a mano alzada con lápiz pasta.'
                },
                {
                    id: 'PC0028',
                    description: 'Objeto de uso cotidiano, utilizado para facilitar el descanso de un infante. Posee un asiento plano en forma de trapecio y un respaldo en marco horizontal. Las piezas de madera tienen biseles en las esquinas. El objeto fue pintado en un tono amarillo homogéneo. '
                },
                {
                    id: 'PC0029',
                    description: 'Herramienta utilizada para cortar madera o árboles. Está compuesta por un mango cilíndrico de madera y un cabezal de acero con forma trapezoide, de hoja afilada y delgada. Si bien el conjunto se aprecia como pieza única, ambas partes se encuentran desunidas. Su estado de conservación es regular ya que presenta oxidación y el mango presenta agrietamientos.'
                },
                {
                    id: 'PC0030',
                    description: 'Herramienta manual utilizada en carpintería para perforar maderas. Está compuesta por dos empuñaduras unidas a una manivela que gira mediante engranajes, un soporte en forma de placa y un mandril en la parte inferior para ajustar la broca. Fue elaborada en hierro y las empuñaduras en madera y se observan técnicas de ensamblado, tallado y moldeado. La pieza se encuentra incompleta, faltando una de las empuñaduras, una manivela y la broca.'
                },
                {
                    id: 'PC0031',
                    description: 'Prenda de vestir utilizada para cubrir los pies. Está compuesta por una puntera redondeada con costura frontal, una lengüeta continua, una suela y una plantilla. Fue principalmente elaborada en cuero de gamuza, sin embargo, la suela es de goma y la plantilla de metal. Se pueden identificar técnicas de cosido y ensamblado. El color predominante del objeto es verde oscuro y la suela negra. En el depósito se encuentra solo el ejemplar derecho.'
                },
                {
                    id: 'PC0032',
                    description: 'Objeto utilizado como medio o soporte para el almacenamiento de datos, tales como vídeos y audios. Fue compuesto por dos piezas plásticas circulares cada una con tres agujeros rectangulares alrededor de un adhesivo circular y, entre ambas piezas, un carrete de plástico negro con material magnetizado. El adhesivo circular es azul con la inscripción "Deutsche Welle", que hace referencia a la marca de origen. '
                },
                {
                    id: 'PC0033',
                    description: 'Prenda de vestir usada frecuentemente para proteger el pie y las articulaciones de los tobillos. El calzado es de cuero color café, la suela está fabricada con madera y caucho, cosida al cuero de la bota con doble costura. Posee siete pares de ojetes de metal donde se amarra el cordón. En total existen dos ejemplares, uno izquierdo y uno derecho, ambas botas tienen faltante de lengüeta y una plantilla desgastada de cuero negro,  Además, la caña posee tres filas de almohadillas y cuello adaptado a la pierna.'
                },
                {
                    id: 'PC0034',
                    description: 'Objeto mecánico utilizado para resolver y/o efectuar diversos cálculos del área de la aritmética. Fue fabricado en metal y plástico. Su forma es rectangular y se compone por un teclado de digitación manual con símbolos matemáticos y numéricos de color rojo y negro, un visor de vidrio en su centro izquierdo que permitía observar una pantalla digital, y en la parte superior una apertura que se usaba para imprimir con un mecanismo de rodillos. En la parte posterior se conserva la salida del cable del enchufe de conexión. Posee una inscripción en el lado derecho de la marca “FACIT", y un rotulado en el lateral izquierdo "Cod 3008".'
                },
                {
                    id: 'PC0035',
                    description: 'Cinta adhesiva utilizada como aislante eléctrico. El objeto está compuesto por un cartón cilíndrico y una cinta adhesiva enrollada a él. Fue fabricado mediante vinil plastificado, cartón y papel. Es de color amarillo y pose en su interior la inscripción “Marca SCOTH 3M (Electrical Tape 3M)". De este objeto se identifica su fabricación por la empresa Minnesota en Estados Unidos y se reconoce su elaboración entre 1946 y 1966, pues posterior a esta fecha se comienzan a producir solo en color negro. Actualmente, la cinta está fuera de su empaque original, ya no tiene pegamento y contiene hongos.'
                },
                {
                    id: 'PC0036',
                    description: 'Utensilio utilizado frecuentemente para calentar, freír o asar alimentos. Tiene forma circular cóncava poco profunda, con dos asas metálicas adheridas a cada costado y unidas por clavillos. El objeto fue fabricado en metal forjado. Aunque desgastada, se mantiene parte del color negro de las asas.'
                },
                {
                    id: 'PC0037',
                    description: 'Objeto utilizado para generar fuentes de calor para cocción mediante energía eléctrica. Su forma predominante es rectangular y está compuesto por una encimera sostenida por cuatro puntos de apoyo en formas de patas. Posee, además, dos placas circulares empotradas y dos interruptores en la cara frontal. En la zona lateral derecha se observa un orificio para la salida del cable de conexión. El soporte rectangular fue elaborado en metal ensamblado y forjado y las placas en cerámica. Su color predominante es azul, sin embargo, las placas son blancas. '
                },
                {
                    id: 'PC0038',
                    description: 'Objeto utilizado en la industria ganadera para marcar animales. Tiene una forma alargada donde en un extremo se encuentra una argolla a modo de asa y en el otro extremo tiene las iniciales JA. Esta herramienta fue manofacturada en hierro forjado y soldada en sus partes.'
                },
                {
                    id: 'PC0039',
                    description: 'Prenda de vestir de infante utilizada para cubrir los pies. El objeto fue confeccionado en cuero y se compone por una puntera, un empeine, una lengüeta de color negro, carrilleras cosidas en color rojo, un forro de cuero blanco, una plantilla de tela adherida a otro material, un cordón atado a lo ojetes de hierro en zigzag y una suela plástica, con poco desgaste, que se extiende hasta el talón a modo de refuerzo y con líneas paralelas horizontales a la altura de los dedos. Posee un parche cosido al lado exterior con un dibujo infantil y la marca “COLLOKY". También se observan las inscripciones “MADE IN CHILE", “21". En el depósito solo se encuentra la unidad derecha. '
                },
                {
                    id: 'PC0040',
                    description: 'Accesorio utilizado para llevar, con una cinta o cinturón, la batería de minería colgada al cuerpo o cintura. Tiene forma rectangular ahuecada sin tapa. Uno de sus lados más angostos posee dos broches de metal, mientras el opuesto a éste posee tres broches de metal. Tanto la cara superior como posterior poseen un agujero redondo. Fue fabricado de un material prensado, que podría ser madera, y protegida por una resina que le da su forma.'
                },
                {
                    id: 'PC0041',
                    description: 'Accesorio utilizado para conectar una red de aparatos de cableado eléctrico a una superficie de manera segura. Su forma es cilíndrica y en su cara superior tiene cinco orificios ubicados en forma de cruz, en la parte posterior se observan dos salidas para la conexión eléctrica. El objeto fue fabricado en porcelana mediante vaciado, es completamente blanco y pose las inscripciones "6A" y "250 V".'
                },
                {
                    id: 'PC0042',
                    description: 'Repuesto de linterna utilizado como protector de suciedad o filtro de luz. Su forma es circular de vidrio transparente y un refuerzo verde a su alrededor. Se perciben técnicas de fundición y moldeado en su elaboración. '
                },
                {
                    id: 'PC0043',
                    description: 'Calzado de infante especialmente diseñado para ser utilizado en momentos de mayor actividad física. El objeto fue confeccionado por una capellada de cuero sintético blanco y una suela de goma gruesa con pequeñas protuberancias en la base. Contiene un cordón que pasa por todos los ojales de la zapatilla para tener mayor agarre y para que el pie no se deslice. La lengüeta se encuentra enroscada y en sus partes laterales se observan franjas decorativas cosidas de color azul, en la parte posterior del talón se observa la inscripción de la marca “Bullets" en color azul. En la puntera se observan pequeños orificios en la superficie. Las costuras son de hilo blanco. El objeto presenta algunos descosidos y roturas. Existen dos ejemplares en el depósito correspondientes al calzado izquierdo y al derecho con distintos estados de conservación.'
                },
                {
                    id: 'PC0044',
                    description: 'Prenda de vestir de infante utilizada para cubrir los pies. El objeto fue confeccionado en una imitación de cuero blanco unido por hilos de tela negro. Está compuesto por un recubrimiento para los pies, cosido a una planta lisa. Posee tres pares de ojetes de metal para atar el cordón. La parte superior está cubierta por material felpa. En el depósito solo se encuentra la unidad izquierda.'
                },
                {
                    id: 'PC0045',
                    description: 'Prenda de vestir de infante utilizada para cubrir los pies. Está compuesto por diferentes correas sujetas a una suela que dan la forma del calzado o sandalia. Además, posee un forro interno, una plantilla, caña alta y una hebilla. Fue confeccionado mayoritariamente en cuero, a excepción de la planta que es plástica, la suela de caucho y la hebilla de hierro. Es mayoritariamente de color blanco. Posee diseños de flores y puntos realizados mediante calado. Se observa en relieve la inscripción de la marca BUSANC. En el depósito solo se encuentra el ejemplar izquierdo.'
                },
                {
                    id: 'PC0046',
                    description: 'Calzado de infante especialmente diseñado para ser utilizado en momentos de mayor actividad física. El objeto está compuesto por una pieza base color blanco con un ajuste de hebillas plásticas, una capellada de tela forrada en cuero sintético, una puntera con orificios como parte del diseño inicial, una lengüeta enroscada, cordones en los orificios de la zona de ajuste de agujetas y una suela de goma doble. Su confección es de origen industrial. En la zona del talón se observa la marca, impresa en relieve “Diadora". Se encuentra en el depósito un ejemplar correspondiente al calzado izquierdo.'
                },
                {
                    id: 'PC0047',
                    description: 'Prenda de vestir de infante utilizada para cubrir los pies. Está compuesto por diferentes correas sujetas a una suela que dan la forma del calzado. Posee, además, puntera, carrilleras unidas mediante un lazo decorativo, hebilla y caña alta. Fue confeccionado principalmente en cuero, a excepción del lazo decorativo de plástico, la suela de goma, la hebilla metálica y un refuerzo de espuma en el talón. Su color predominante es el azul y presenta presencia de barniz de charol. Posee una etiqueta interior de tela con la inscripción PONTUS, asociada a su fabricación. En el depósito solo se encuentra el ejemplar del calzado derecho.'
                },
                {
                    id: 'PC0048',
                    description: 'Recipiente usado para contener objetos. Su forma es de casco invertido, fabricado en cobre. En su interior se observan distintos elementos de diferentes materialidades y usos como: una tuerca, una tijera, dos anzuelos para pescar, dos trozos pequeños de madera, cinco clavos, diecinueve minerales o rocas, dos botones, cincuenta y dos monedas de diferentes valores y diecisiete bochas. Su estado de conservación es bueno, pero presenta diversas abolladuras en el objeto principal. '
                },
                {
                    id: 'PC0049',
                    description: 'Accesorio de seguridad utilizado para interrumpir instalaciones eléctricas cuando la corriente se vuelve excesiva. Su forma es de botella, con dos puntos de contacto en la parte superior y posterior. Fue elaborada por cocción en cerámica, a excepción del contacto que es metálico. El objeto es completamente blanco y posee en su base la inscripción relativa a su voltaje “500V y 30ª". '
                },
                {
                    id: 'PC0050',
                    description: 'Calzado especialmente diseñado para ser utilizado en momentos de mayor actividad física. El objeto se compone por una caña alta blanca con ajustes de hebillas, una capellada de tela forrada de cuero sintético blanco, una puntera con orificios a modo de diseño, lengüeta enroscada, cordones bicolores en los orificios de la zona de ajuste de agujetas y una suela de goma doble color amarillo y negro. Tiene detalles lineales grises a modo de decoración. Todas las costuras fueron elaboradas con hilo color crudo. Posee una inscripción en relieve en el talón con la marca “Diadora". El objeto está en mal estado de conservación, la suela se encuentra descosida en la parte frontal, impidiendo que mantenga la forma original. Se encuentra en el depósito un ejemplar correspondiente al calzado izquierdo.'
                }
            ]


            let arr2 = [{
                    id: 'PC0051',
                    description: 'Calzado de mujer usado en la vida cotidiana para vestir. El tacón es de tipo Stiletto de 10 cm de alto fabricado con acero y recubierto con cuero y con tapilla de metal ensamblada mediante tres clavos pequeños de hierro. La punta es triangular y posee una lengüeta a modo de ornamento. El exterior del calzado es de color negro tratado con barniz para dar un acabado brillante, y tanto el forro como la plantilla de color crea. Posee impresa la marca MELODY M.R. Se encuentra en el depósito un par correspondientes al calzado izquierdo y derecho.'
                },
                {
                    id: 'PC0052',
                    description: 'Objeto utilizado para entretención de infantes. Está compuesto por dos superficies rectangulares y dispuestas de modo horizontal, cada una con una puerta corrediza y con una manilla también rectangular, pero dispuesta de manera vertical. Ambas están unidas por una pieza en la parte posterior, de tal modo que se observa una sobre otra, con un espacio o mesón entre ambas. Fue elaborado completamente en metal esmaltado y moldeado, predominando el color celeste pastel, sin embargo, las manillas son de color rojo y el mesón amarillo. El objeto fue elaborado para representar un mueble de cocina. '
                },
                {
                    id: 'PC0053',
                    description: 'Recipiente utilizado para hervir agua. Está compuesto por un cuerpo cilíndrico ahuecado, con una boquilla curva en su parte frontal. En la parte superior se observa un mango (adherido por medio de un clavillo y un alambre de cobre) y una pieza circular cóncava a modo de tapa, con una pieza redonda adherida. Fue elaborado casi completamente de metal laminado y moldeado,  Además, se observan soldaduras. En cuanto al mango posee una zona de madera ensamblada. '
                },
                {
                    id: 'PC0054',
                    description: 'Herramienta utilizada para cavar, pero que también podía servir para recoger o trasladar materiales. El objeto está compuesto de tres partes fundamentales para el uso: una superficie metálica con un contorno semicircular y ligeramente cóncava, un mango de madera cilíndrico y un asa de metal. La pieza presenta oxidación en sus partes metálicas y abolladuras en la superficie de la pala. El asa se encuentra quebrado en unos de sus lados. El mango presenta fisuras producidas por la perforación de tornillos y clavos en cada uno de sus laterales. '
                },
                {
                    id: 'PC0055',
                    description: 'Objeto utilizado como contenedor de artículos de tamaño menor. Su forma predominante es rectangular. Se compone por una base y cuatro rectángulos situados de manera perpendicular entre sí. Fue elaborado completamente en madera ensamblada y tiene detalles frontales de técnica de labrado para simular manillas o asas. En el depósito existen dos objetos de iguales características.'
                },
                {
                    id: 'PC0056',
                    description: 'Pieza de cubertería utilizada como parte del servicio de mesa. Está compuesta por un objeto de terminación oval, con mango curvado y un leve ensanchamiento final a modo de ornamento. Fue elaborada completamente en metal forjado y bordes fileteados. En el mango se encuentra una inscripción circular grabada con la leyenda “República de Chile * Propiedad del Estado" y en reverso otra inscripción rectangular semiborrosa “Alpaca".'
                },
                {
                    id: 'PC0057',
                    description: 'Contenedor utilizado para almacenar y preservar licores en base a frutos y/o hierbas así también como para guardar agua. Se reconoce como una sola pieza de cuerpo cilíndrico de vidrio soplado color verde translúcido, de base plana, hombros curvos, cuello angosto con forma tronco-cónica truncada, con reborde en la parte superior, boca restringida y labio plano. Posee tapa rosca y asa lateral en el cuello. Se encuentra revestido de mimbre hasta la altura de los hombros. Tiene una etiqueta de papel adherida en la parte superior con la marca "La Ermita". Estos objetos son conocidos como damajuana, garrafa o chuica.'
                },
                {
                    id: 'PC0058',
                    description: 'Recipiente utilizado para hervir agua. Su forma es cilíndrica ahuecada, con una boquilla curva en su parte frontal. En la parte superior se observa un mango (adherido por medio de clavillos) y una pieza circular cóncava a modo de tapa, con una pieza redonda adherida. Fue elaborado completamente de metal laminado y moldeado,  Además, se observan soldaduras. La pieza se encuentra abollada en gran parte del cuerpo cilíndrico.'
                },
                {
                    id: 'PC0059',
                    description: 'Prenda de vestir usada frecuentemente para proteger los pies y las articulaciones de los tobillos. Está compuesta por puntera, talón, lengüeta, ocho pares de ojetes, suela, caña lisa y plantilla. Fue elaborada casi por completo en cuero color, a excepción de la puntera y el talón que están reforzados con metal y remaches de hierro, y la suela de caucho. El calzado es en su mayoría color café, exceptuando la lengüeta más amarillenta y la suela negra. Se observan técnicas de cosido y ensamblado. En total existen dos ejemplares, uno derecho que aún posee cordón y uno izquierdo.'
                },
                {
                    id: 'PC0060',
                    description: 'Dispositivo mecánico usado para determinar el peso de los bebés. Cuenta con una bandeja grande metálica moldeada sobre una superficie del mismo material donde se ubica el sistema de pesos. Todo el conjunto fue esmaltado en blanco. La pieza inferior contiene un adhesivo de EXARTA Record en la superficie delantera, una inscripción con plumón color rojo en su superficie superior "SNS-N° 52.- CRISTAL" y una inscripción con plumón negro en la superficie posterior "16-716". La pieza superior contiene una inscripción con plumón color rojo en su superficie inferior "SNS-N° 52.- CRISTAL". La balanza pertenecía al Servicio de Salud de Aysén.'
                },
                {
                    id: 'PC0061',
                    description: ''
                },
                {
                    id: 'PC0062',
                    description: 'Conjunto de objetos utilizados como parte del servicio de mesa para servir alimentos. Se compone de cuatro objetos independientes entre sí. Tienen forma circular ligeramente cóncava y un pie de apoyo también circular. Difieren en tamaños. Fueron elaborados en cerámica con moldeo en barbotina y esmaltado. El color predominante es el blanco, pero poseen diseños circulares concéntricos de variados colores: dorado, negro y rojo, café y crema, verde oscuro. Tres de los cuatro objetos posen la inscripción impresa “LOZA PENCO MADE IN CHILE".'
                },
                {
                    id: 'PC0063',
                    description: 'Envase utilizado para contener levadura en polvo. Su uso fue de carácter cotidiano y doméstico. Tiene forma cilíndrica y posee una etiqueta esmaltada color rojo donde se observa la marca de fabricación “ROYAL". El contenedor fue fabricado por ensamblado y es de metal. Su estado de conservación es regular pues no está completo; falta la tapa.'
                },
                {
                    id: 'PC0064',
                    description: 'Recipiente utilizado en la cocina para la preparación de alimentos. Tiene forma cilíndrica ahuecada con base plana. Está compuesto por la base y dos asas dispuestas de manera paralela entre sí. Fue elaborado en metal esmaltado con técnica de vaciado. La pieza es blanca en su exterior, café en su interior y, tanto las asas como el borde, son color negro. '
                },
                {
                    id: 'PC0065',
                    description: 'Envase utilizado para contener moluscos. Posee una forma cilíndrica, achatada. Es completamente metálico y se encuentra forrado por un etiquetado adhesivo color rojo con la inscripción “Choritos" “Yadran" “Common Mussels". La parte superior del envase se encuentra casi abierta en su totalidad. '
                },
                {
                    id: 'PC0066',
                    description: 'Objeto utilizado para entretención de infantes. El conjunto está compuesto por seis piezas rectangulares de madera independientes entre sí. Cada pieza está dividida a la mitad y posee dos figuras de animal. El objeto fue elaborado de forma artesanal y los diseños de animales fueron pintados a mano.'
                },
                {
                    id: 'PC0067',
                    description: 'Contenedor de pasta de zapatos. Es un objeto de cuerpo cilíndrico y base plana compuesto de un cuerpo base metálico y una tapa de metal moldeado, con un sistema mecánico de seguridad en el borde superior derecho para el cierre y la apertura del mismo. La tapa es de color negro, y posee impresa la marca NUGGET, en el borde se observa el código de barra identificador, la marca, y detalles de fabricación y contenido.'
                },
                {
                    id: 'PC0068',
                    description: 'Prenda de vestir usada para proteger la ropa de infante. La prenda protege desde los hombros hasta bajo la rodilla, pose mangas estilo globo. En la parte frontal se observa un cuello en “v" con terminaciones con vuelo. En la parte posterior cuatro botones puestos de manera vertical, desde la cintura se incorporó una cinta de ajuste. Fue confeccionada en tela cosida. Predominan los colores rojo y blanco con diseño cuadrillé en el torso y las mangas completamente blancas a excepción de las costuras finales. '
                },
                {
                    id: 'PC0069',
                    description: 'Objeto utilizado para contener té instantáneo. Se compone por un cuerpo cilíndrico metálico ensamblado a una tapa metálica a presión. Su exterior se encuentra forrado completamente con una etiqueta adhesiva de color verde con imagen de tazas y teteras y la marca ORJAS. Posee una inscripción en bajo relieve 05 A 87 T. Se desconoce el uso posterior del objeto.'
                },
                {
                    id: 'PC0070',
                    description: 'Recipiente utilizado para contener líquidos de uso diario. Es de carácter cilíndrico de metal moldeado y esmaltado de color crema con un asa lateral. En la parte superior tiene un borde más grueso cuyo esmaltado se ha desgastado y corroído. Presenta marcas de uso en su parte inferior ya que tiene saltaduras en su esmaltado, tiene un pequeño orificio en la base y marcas de metal y soldadura. También se le conoce como mate ya que se usa frecuentemente para preparar dicha infusión.'
                },
                {
                    id: 'PC0071',
                    description: 'Objeto utilizado para conducir líquidos u otras sustancias a contenedores con aperturas más estrechas evitando así el derramamiento accidental. Tiene forma de cono invertido con un tubo estrecho en el apéndice. Fue elaborado completamente en vidrio traslucido por técnica de cocción. Pertenece al conjunto de embudos de la gradilla.'
                },
                {
                    id: 'PC0072',
                    description: 'Prenda de vestir utilizada para cubrir o arropar infantes. Está cerrada por delante y por detrás, de manga corta y cuello rectangular. Fue confeccionada en tejido a crochet, con diseños geométricos de color gris, rojo, celeste, café, amarillo y azul. Presenta reparaciones artesanales.'
                },
                {
                    id: 'PC0073',
                    description: 'Utensilio utilizado para sostener embudos. Está compuesto por una pieza superior horizontal rectangular y dos patas de soporte. La pieza superior tiene 15 agujeros del mismo diámetro y puestos a la misma distancia. Está elaborado completamente de madera, pintado a mano en tono amarillo claro, exceptuando su cara inferior. '
                },
                {
                    id: 'PC0074',
                    description: 'Contenedor utilizado para contener licor de menta. Es de forma cilíndrica en cuello y cuerpo, con hombros redondeados y una base con relieves rectangulares. Está elaborado en vidrio soplado y moldeado con tapa de aluminio. Mantiene dos etiquetas de papel adheridas: una en la parte frontal y la otra en la posterior. Se observa la marca “DUVAL". Fue elaborado en Santiago de Chile. '
                },
                {
                    id: 'PC0075',
                    description: 'Objeto destinado a la entretención de infantes. El muñeco se encuentra conformado por cuatro partes unidas entre sí mediante hilo y agujas. La de mayor tamaño consta de una forma con cuatro lados, a modo de cuerpo y representaría brazos, piernas y torso, unido a éste en la parte superior y de forma más circular se representa la cabeza, y sobre la misma, dos piezas adyacentes que darían la forma de las orejas de un conejo. En la zona del rostro se identifican los ojos que miran hacia la izquierda, un círculo negro al medio que se asemeja a la nariz y desde el cual sale un trozo de tela hacia abajo a modo de lengua. De confección artesanal el objeto fu elaborado completamente de tela cosida y predomina el color ocre, a excepción del rostro e interior de las orejas en tonos cremas y la lengua de color rojo. Es posible observar puntos verdes en el rostro. '
                },
                {
                    id: 'PC0076',
                    description: 'Recipiente para contener y/o almacenar distintos materiales. La pieza está compuesta por un objeto circular, poco profundo, con fondo plano, lados cóncavos, de metal forjado, sin pintar ni esmaltar. En el depósito se encuentran dos ejemplares de la misma medida con distintos desgastes por uso.'
                },
                {
                    id: 'PC0077',
                    description: 'Objeto utilizado para contener líquidos. Su forma es cilíndrica con cuello estrecho que finaliza en rosca. Fue elaborado en vidrio soplado y moldeado y es completamente transparente. Se observa en su inferior la inscripción "11 TRIPONE MR". El objeto no posee tapa.'
                },
                {
                    id: 'PC0078',
                    description: 'Recipiente utilizado para contener y servir líquidos. Su forma es globular, seguido por un cuello corto con un bisel que termina en un pico pronunciado con forma redondeada para facilitar verter el líquido interior. En el lateral opuesto posee un asa remachada que nace a un cuarto de su altura. Fue completamente elaborada en metal enlozado blanco, exceptuando el cuello y el asa color azul. La base presenta desgaste y agujeros.'
                },
                {
                    id: 'PC0079',
                    description: 'Objeto utilizado en el laboratorio para mantener limpia y deshidratada una sustancia por medio del vacío. El objeto está compuesto principalmente por tres piezas: la primera de mayor tamaño posee forma cilíndrica donde es posible distinguir dos cavidades de diferentes tamaños, una tapa con un orificio central y finalmente una pieza interior con forma de tapón y un gancho adherido. Este objeto generalmente se utilizaba para secar mediante el uso del gel de sílice en la cavidad inferior. La superior era para contener las sustancias que se pretendía secar. El gancho servía para extraer el aire y mantener el vacío. Fue elaborado en vidrio por cocción. '
                },
                {
                    id: 'PC0080',
                    description: 'Botella utilizada para contener licor de frutilla colada. Está compuesta por un cuerpo de vidrio, una etiqueta de papel adherida a la superficie y una tapa de aluminio con el sello abierto. Tanto el cuerpo, con forma de prisma cuadrangular, como el cuello cilíndrico, son de vidrio soplado y la forma de los hombros es recta. Contiene en la base una inscripción en relieve con la leyenda LIQUOR BOTTLE ENGLAND 750 ML, 74 MM. '
                },
                {
                    id: 'PC0081',
                    description: 'Objeto utilizado como contenedor. Su forma predominante es rectangular. Se compone por una base y cuatro rectángulos situados de manera perpendicular entre sí. Fue elaborado completamente en madera ensamblada. En la cara frontal se observa la inscripción en letras blancas "16 ° 713" realizada de modo manual y una etiqueta adhesiva en tela con el texto manuscrito "Targetero".'
                },
                {
                    id: 'PC0082',
                    description: 'Piezas de cuberterías utilizadas como conjunto de servicios de mesa, compuesta por dos tenedores y una cuchara. El tenedor más pequeño (Alto 18.6 cm, Ancho 2.5 cm, Profundidad 0.2 cm) tiene cuatro dientes, con mango plano curvado en la unión y un ensanchamiento pequeño a manera de ornamento; el mango en forma de placa remata en una placa plana con bordes fileteados. El tenedor grande (Alto 20.1 cm, Ancho 2.3 cm, Profundidad 0.7 cm) consta de cuatro dientes, en la unión tiene filetes rectos, el mango tiene forma de placa que se ensancha hacia el final con una terminación redondeada. La cuchara (Alto 18.6 cm, Ancho 2.5 cm, Profundidad 0.2 cm) es de metal con el cuenco ancho, el mango tiene forma de placa y se ensancha desde la unión hacia la terminación, la cual termina en forma de hoja. Las piezas son de metal moldeado por compresión con detalles fileteados en los bordes y troquelado ornamental.'
                },
                {
                    id: 'PC0083',
                    description: 'Recorte de tela de un saco que era usado para envasar y/o transportar mineral. El recorte es de tela de arpillera y cosido a mano, de color ocre y gris. En la parte frontal posee un bolsillo de confección artesanal. Los maxisacos o “big bag" tenían capacidad para almacenar 1 tonelada de mineral en polvo.'
                },
                {
                    id: 'PC0084',
                    description: 'Accesorio de vestir que se utilizaba para proteger las manos en los procesos mineros. El objeto consiste en dos piezas (una para cada mano) conformadas por un cuerpo o palma, dorso, pulgar, dedos, puño o manguito cosidos con hilo, confeccionadas en cuero y de tonalidad amarilla. Existen dos ejemplares en el depósito, uno izquierdo y uno derecho. En el puño se puede ver la inscripción estampada "ALUVA".'
                },
                {
                    id: 'PC0085',
                    description: 'Objeto utilizado como parte de un juego de mesa. Su forma es rectangular de bordes y dimensiones irregulares. La parte superior es lisa y se encuentra dividida a en 64 partes a modo de cuadrícula pero que varían en tamaño. La parte inferior es irregular. La pieza es de confección artesanal, elaborada en madera aglomerada de cholguán de grosor pequeño y se encuentra pintado a mano con celdas blancas y negras de manera intercalada. '
                },
                {
                    id: 'PC0086',
                    description: 'Prenda de vestir para la cabeza utilizada como protección contra la lluvia. Consiste en una copa cóncava y un ala o visera que rodea el borde, siendo la misma más prominente en su zona posterior. En la parte interior de la copa se observan unas orejeras cosidas en la base posterior, en las que una de ellas finaliza con un cordel de amarre. Fue confeccionado en plástico con tonalidad amarillenta. '
                },
                {
                    id: 'PC0087',
                    description: 'Objeto utilizado como contenedor. Su forma es rectangular con todos sus bordes doblados hacia el interior. El objeto fue elaborado completamente en metal laminado y posee pestañas del mismo material tanto en las uniones como en los dobleces de los bordes para proteger las esquinas filosas. '
                },
                {
                    id: 'PC0088',
                    description: 'Herramienta de mano para cavar, remover o recoger partes de superficies en su mayoría blandas o pastosas. La pieza principal tiene forma semicilíndrica con punta redondeada y se une mediante soldadura a una empuñadura cilíndrica hueca. El objeto es completamente de hierro con color natural y posee desgaste por uso. '
                },
                {
                    id: 'PC0089',
                    description: 'Recipiente utilizado para contener o guardar objetos o alimentos. El objeto es circular, cóncavo. Posee un orificio en uno de sus lados. Fue elaborado en una sola pieza de metal moldeado por compresión. Su estado de conservación es bueno, aun cuando presenta oxidación y corrosión.'
                },
                {
                    id: 'PC0090',
                    description: 'Objeto utilizado como contenedor. Está compuesto por una lámina rectangular con todos sus bordes doblados hacia la parte interna. En el interior posee tres placas en forma de cajones para separar los objetos que contenía. El objeto fue elaborado completamente en metal laminado y posee pestañas del mismo material tanto en las uniones como en los dobleces de los bordes para proteger las esquinas filosas. '
                },
                {
                    id: 'PC0091',
                    description: 'Objeto facilitador de comunicación a distancia, de uso cotidiano. El objeto tiene forma de asa y está compuesto por dos partes ensambladas. La primera parte consiste en un cuerpo cilíndrico en cuya cara superior se ubica una placa circular levemente separada con 10 orificios y en su centro se identifica una placa plástica de forma circular con una secuencia de números correlativos del 1 al 0. La segunda parte es un objeto alargado con forma de asa que termina en dos semi esferas. El objeto presenta piezas elaboradas tanto en plástico como en metal, en técnica de moldeado por compresión, vaciado y troquelado.  Además, se pueden observar diferentes inscripciones grabadas: al costado de la pieza principal se observa la inscripción “Telefon A-B LM Ericsson", en la segunda pieza se observan los números “214414".'
                },
                {
                    id: 'PC0092',
                    description: 'Objeto utilizado como contenedor. Está compuesto por cuatro láminas rectangulares de distintos tamaños. Dos de las láminas más grandes se encuentran dobladas a modo de L y soldadas entre sí. En cada extremo se adhieren las otras dos láminas por soldadura otorgando la forma de contenedor. El objeto fue elaborado completamente en metal laminado y posee pestañas del mismo material tanto en las uniones como en los dobleces de los bordes para proteger las esquinas filosas.'
                },
                {
                    id: 'PC0093',
                    description: 'Objeto utilizado al interior de un zapato para otorgar mayor comodidad y/o aliviar dolores. Tiene forma de horma, ondulada al final y al centro para dar forma de pie. Fue elaborada completamente en metal troquelado y posee rastros de goma en su cara superior e inferior. Por el tamaño se asocia a una plantilla de adulto. En el depósito solo se encuentra el ejemplar izquierdo.'
                },
                {
                    id: 'PC0094',
                    description: 'Recipiente utilizado para hervir agua. Tiene forma de cilíndrico ahuecado, en la parte superior se observa un mango (adherido por medio de clavillos) y en su cara frontal sobresale una boquilla curva para la salida del agua. Fue elaborado completamente en hierro, con técnicas de soldadura, fundición y troquelado.  Además, posee un esmaltado en superficie de la pieza de color celeste. El asa fue esmaltada en color negro. El objeto presenta pequeños orificios en su base, corrosión y pérdida de pintura.'
                },
                {
                    id: 'PC0095',
                    description: 'Objeto utilizado para proteger la cabeza de la persona en el combate contra el fuego. Está compuesto por una cavidad cóncava con visera y una protección interna tipo malla ajustable al diámetro de la cabeza. Fue elaborada en plástico y resina de poliéster de fibra de vidrio con superficie de pigmento aluminizado, mediante moldeado. El objeto es de color negro.'
                },
                {
                    id: 'PC0096',
                    description: 'Objeto facilitador de comunicación a distancia, de uso cotidiano. La pieza está compuesta por un elemento de base rectangular en cuya zona inferior se encuentra un marcador de teclas cuadradas con símbolos matemáticos y numéricos de color gris y una pieza rectangular con acabados circulares a sus extremos conocido como auricular. Ambas piezas se encuentran unidas por medio de un cable helicoidal. Fue elaborada en plástico blanco envejecido. Sobre el teclado se ubica un rectángulo con la marca telefónica de Coyhaique y la inscripción representativa de la marca Siemens.'
                },
                {
                    id: 'PC0097',
                    description: 'Objeto utilizado para hilar fibras textiles, probablemente de oveja. Tiene forma redondeada y alargada que se aguza en sus extremos. En el centro tiene enrollada una fibra textil y una aguja. En la base tiene dos tuercas de metal que probablemente se utilizaban para darle vueltas. El cuerpo principal fue elaborado en madera tallada, la fibra blanca es identificada como lana y la aguja es de metal. '
                },
                {
                    id: 'PC0098',
                    description: 'Recipiente utilizado para hervir agua. Está compuesto por un cuerpo cilíndrico ahuecado, con una boquilla curva en su parte frontal. En la parte superior se observa un mango (adherido por medio de un clavillo y un alambre de cobre) y una pieza circular cóncava a modo de tapa, con una pieza redonda adherida. Fue elaborado casi completamente de metal laminado y moldeado,  Además, se observan soldaduras. Posee un esmaltado desgastado en tono pastel anaranjado y detalles negros como la tapa.'
                },
                {
                    id: 'PC0099',
                    description: 'Objeto facilitador de comunicación a distancia, de uso cotidiano. La pieza está compuesta por un elemento de base trapezoidal con definiciones en las caras laterales. En la cara superior se encuentra una pieza circular con orificios circulares más pequeños que conforman el marcador. Sobre ella se ubica el auricular, la segunda pieza del objeto en el cual en la parte del auricular se encuentra incompleto. En el frente hacia la base se ubica un elemento rectangular con pequeños orificios redondos. Fue elaborado en acero y metal, mediante moldeado por compresión, vaciado y troquelado. En la parte trasera se encuentra grabada la marca Ericsson LM.'
                },
                {
                    id: 'PC0100',
                    description: 'Objeto utilizado en la cocina para aplastar o triturar principalmente papas. Está compuesto por un contendor con forma de hoja puntiaguda, un mango de placa y una base con orificios. La pieza fue fabricada en hierro ensamblado. Se encuentra incompleta.'
                }
            ]

            let arr3 = [{
                    id: 'PC0101',
                    description: 'Objeto mueble de la vida cotidiana utilizado para descansar, sin respaldo. Está conformado por una superficie rectangular sostenida por cuatro patas en los vértices, reforzadas por la unión de fragmentos rectangulares en la parte superior bajo la cubierta, todo en madera ensamblada. Fue pintado en color celeste apastelado, presenta desgaste de la pintura, posiblemente asociado al uso del objeto. '
                },
                {
                    id: 'PC0102',
                    description: 'Objeto utilizado como base para soportar y sostener un mortero, el cual muele o machaca distintas sustancias químicas. El objeto es de forma rectangular con un lado curvo y contiene marco en tres de sus lados. Fue elaborado completamente en hierro forjado y uniones de soldadura. '
                },
                {
                    id: 'PC0103',
                    description: 'Objeto utilizado en el laboratorio para contener muestras. Tiene forma trapezoidal alargada y contiene seis agujeros en forma de cono truncado. Posee un mango de base cuadrada con terminación redondeada. Fue elaborado completamente en hierro moldeado.'
                },
                {
                    id: 'PC0104',
                    description: 'Sobre artesanal para muestra de estudio de laboratorio que se utilizaba para almacenar concentrados de mineral. Es de formato rectangular con dobleces en los laterales y luego uno introducido dentro de otro. Confeccionado en papel blanco con inscripciones en tinta negra: CONC. DE ZINC. LOTE N°23 10 BIC BAC. 18-4-27. '
                },
                {
                    id: 'PC0105',
                    description: 'Objeto utilizado para refinar, reducir y homogeneizar el tamaño de las partículas de la muestra de mineral. Se compone por dos partes: un bloque de hierro de forma rectangular y base curva, que se encuentra ensamblado, por medio de un objeto tubular metálico, a un cuerpo cilíndrico de madera.'
                },
                {
                    id: 'PC0106',
                    description: 'Objeto utilizado como base para soportar y sostener un mortero, el cual muele o machaca distintas sustancias químicas. El objeto es de forma rectangular, con un marco de esquinas curvas en tres de sus lados. Fue elaborado completamente en hierro forjado con unión de soldadura.  '
                },
                {
                    id: 'PC0107',
                    description: 'Recipiente utilizado para contener objetos, en este caso muestras químicas. Posee una forma rectangular y está compuesto por un fondo con borde bajo y redondeado. Pose un agujero en cada lateral. Fue elaborado en hierro moldeado por compresión y cerámica enlozada. Su color predominante es blanco, exceptuando los bordes azules. Presenta agujeros de desgaste por uso y oxidaciones en la base inferior.'
                },
                {
                    id: 'PC0108',
                    description: 'Objeto utilizado para proteger la cabeza de heridas y contusiones. El objeto tiene dos componentes básicos: la copa y la suspensión. La copa tiene una forma semiesférica cóncava con cresta y en la parte frontal se pueden ver tres orificios y un alambre enganchado de manera horizontal. En su cavidad interior no se encuentra la suspensión que se componía de banda para la cabeza ajustable y correas de corona dobles, cuatro sujetadores entraban en las ranuras profundas de la copa y sostenían la banda de la cabeza. La copa fue confeccionada en policarbonato moldeado de color rojo. Por la parte exterior contiene seis protuberancias rectangulares con adhesivos de distintos colores (amarillo, verde y azul) y una pieza plástica negra horizontal de mayor tamaño. En la parte interior de la visera se distingue la inscripción “PROSEG MR Mod 1005" y en la parte lateral, de confección artesanal en blanco, se observa el dibujo de una picota cruzada con un martillo y la palabra “Planta". '
                },
                {
                    id: 'PC0109',
                    description: 'Objeto utilizado para refinar, reducir y homogeneizar el tamaño de las partículas de la muestra de mineral. Se compone por dos partes: un bloque de hierro de forma rectangular y base curva, que se encuentra ensamblado, por medio de un objeto tubular metálico, a un cuerpo cilíndrico de madera.'
                },
                {
                    id: 'PC0110',
                    description: 'Equipo de laboratorio utilizado tanto para secar como esterilizar recipientes de vidrio o metal. Tiene forma rectangular con puerta. En la superficie derecha se observan las conexiones eléctricas y dos perillas de control. Fue fabricado con metal y plástico ensamblado. En la cara lateral izquierda se observa la inscripción “COD 3321"'
                },
                {
                    id: 'PC0111',
                    description: 'Objeto utilizado para refinar, reducir y homogeneizar el tamaño de las partículas de la muestra de mineral. Está compuesto por un bloque con forma cónica truncada y base curva que se encuentra unido a un cuerpo alargado de forma cilíndrica a modo de mango. Fue fabricado en hierro y acero ensamblado.'
                },
                {
                    id: 'PC0112',
                    description: 'Cuaderno de bitácora utilizado para registrar las acciones cotidianas de la investigación y el trabajo en el laboratorio. Las hojas son rectangulares, con líneas. Contiene datos de 1973 hasta 1979. El objeto fue fabricado y cosido, por lo que se observa que su composición principal es papel e hilo. Las tintas con que fue escrito son en su mayoría color azul, negro, rojo y grafito.'
                },
                {
                    id: 'PC0113',
                    description: 'Objeto utilizado para anunciar o indicar una información específica. El objeto es rectangular y se encuentra adosado a la puerta de entrada del laboratorio con seis clavos en distintas posiciones. Fue elaborado en madera. Es de color blanco. Contiene dos inscripciones, la primera en rojo y posteriormente una en negro "DPTO. CONTROL DE CALIDAD. LABORATORIO QUÍMICO Y METALÚRGICO", "ÁREA RESTRINGUIDA. SOLO PERSONAL AUTORIZADO".'
                },
                {
                    id: 'PC0114',
                    description: 'Objeto utilizado en un laboratorio para dividir o separar partículas de diferentes estructuras. El objeto posee un cuerpo cúbico conformado de divisiones, cabezales separadores y sostenidos por cuatro patas en diagonal unidas por cuatro placas de refuerzo. Fue elaborado en hierro ensamblado troquelado y sus uniones fueron soldadas. La pieza es de tonalidad rojiza.'
                },
                {
                    id: 'PC0115',
                    description: 'Objeto utilizado para calentar sustancias o materiales. El objeto es de forma rectangular y posee dos espacios, cada uno con su compuerta, puestos uno sobre el otro utilizados como quemadores. La compuerta inferior posee una ventanilla semicircular y la superior una cuadrada. El objeto fue elaborado en su mayor parte de ladrillo y cemento. La compuerta superior es de metal y la inferior es de madera.'
                },
                {
                    id: 'PC0116',
                    description: 'Objeto utilizado para anunciar o indicar una información específica. Fue confeccionado de forma manual en base a una madera en donde se adhiere papel mediante cinta adhesiva. En la parte inferior se observan dos clavos, uno en cada esquina. Tiene una inscripción escrita a mano de color negro " SE PROHIBE LA ENTRADA A TODA PERDONA AJENA SIN AUTORIZACIÓN. JEFE DE SECCIÓN".'
                },
                {
                    id: 'PC0117',
                    description: 'Objeto utilizado para seleccionar o separar aquellas partículas buenas o esenciales del resto. Tiene forma cilíndrica hueca con una malla adherida a la base que sirve de colador. La base tiene un diámetro más pequeño. Fue elaborado completamente en metal ensamblado y laminado. En la chumacera se observan dos ejemplares con diferentes estados se conservación.'
                },
                {
                    id: 'PC0118',
                    description: 'Objeto utilizado para contener o transportar diferentes materiales. El recipiente es cilíndrico y alargado de acero, en su parte superior tiene una huincha metálica unida con pernos utilizada como asa. Debido a la composición del metal se observan manchas producidas por el óxido; en la parte interna del cilindro se observa una costra de material solidificado producto del uso. El borde superior presenta irregularidades en su circunferencia posiblemente ocasionadas por golpes del uso cotidiano. '
                },
                {
                    id: 'PC0119',
                    description: 'Objeto utilizado para organizar, separar u ordenar distintos elementos. El objeto está compuesto por dos piezas circulares ahuecadas con una pieza romboidal soldada a cada costado y adheridas mediante tornillos y tuercas. La parte superior contiene una malla separadora. La pieza inferior posee una boquilla cilíndrica. En los costados de la pieza se observa una manija de transporte por lado. El objeto fue elaborado en metal ensamblado.'
                },
                {
                    id: 'PC0120',
                    description: 'Prenda de vestir utilizada para proteger de aceites, ácidos, frío y calor. Está compuesta por una sola pieza, diseñada para cubrir principalmente las piernas, con pechera, sin bragueta. Fabricada en nylon revestido de vinilo color amarillo. Los suspensores también son de vinilo. '
                },
                {
                    id: 'PC0121',
                    description: 'Objeto utilizado para contener o transportar diferentes materiales. Tiene forma cuadrada y alargada con esquinas curvas. Posee adherido un alambre en cada extremo del recipiente a modo de asa. El objeto está compuesto mayormente en plástico, exceptuando el asa que es metálico. Fue elaborado con técnica de laminado y ensamblado. Posee una etiqueta que recorre todo el borde lateral. Conserva residuos de material en su interior. '
                },
                {
                    id: 'PC0122',
                    description: 'Prenda de vestir utilizada para proteger de aceites, ácidos, frío y calor. Está compuesta por una sola pieza, de manga larga (30 pg de largo) y capucha, cubriendo cabeza, torso y cintura. Se encuentra abierta en su parte frontal y dispone de un sistema de cierre, con broches de esfera y casquillo, y amarras de ajuste para la parte de la capucha. Fabricada en nylon revestido de vinilo color amarillo. '
                },
                {
                    id: 'PC0123',
                    description: 'Objeto utilizado para medir volúmenes líquidos al interior del laboratorio. Consiste en una vasija globular incolora con un tubo estrecho en el apéndice, confeccionado completamente de vidrio mediante técnica de cocción y soplado. En la parte superior de la boquilla presenta una rotura y a un costado tiene inscrito 50 CCM 15°C.'
                },
                {
                    id: 'PC0124',
                    description: 'Objeto utilizado para sentarse. La pieza está conformada por dos tablas unidas con clavos a modo de asiento, sostenida en una estructura de cuatro patas que se encuentran reforzadas en su parte inferior por la unión de piezas horizontales. La pieza está manufacturada en madera y metal ensamblado. La estructura es de color café y el asiento es de color verde.'
                },
                {
                    id: 'PC0125',
                    description: 'Utensilio utilizado para sostener embudos de laboratorio. Está constituida por una pieza principal alargada que se apoya en dos patas laterales con forma de llave inglesa invertida. La pieza principal contiene veinticinco orificios circulares para sostener los embudos. Está fabricada en madera y fue pintada en color verde en todas sus partes, excepto su cara inferior.'
                },
                {
                    id: 'PC0126',
                    description: 'Objeto utilizado para distribuir dispositivos de protección, permitiendo una correcta operación de circuitos eléctricos. El objeto tiene forma rectangular con un marco que sobresale. Adheridos a él se observan fusibles antiguos e interruptores de distinto uso. El marco es completamente metálico, mientras la estructura interna es de madera y los objetos adheridos en su mayoría son de cerámica. Predomina el color burdeos en la base del tablero, el marco tiene tonos verdes y los elementos adheridos son de color principalmente blanco. Alrededor de toda la pieza se observan leyendas de lugares tales como COCINA, OFICINAS, LUZ DE BAÑO, entre otros.'
                },
                {
                    id: 'PC0127',
                    description: 'Objeto utilizado para destilar el agua potable y purificarla. El objeto es de forma paralelepípeda, en la esquina derecha se observa un interruptor. Por el lado izquierdo se ubican dos cables uno conectado al grifo de agua potable y otro del que salía el agua purificada. El objeto está elaborado principalmente de metal con elementos plásticos y se observan técnicas de esmaltado y fundición. Es casi completamente blanco, exceptuando la base que es color gris y el interruptor verde. Posee una inscripción en tinta negra en la parte inferior “COD 3320". En la parte superior se observa en tinta roja la leyenda “CO74".'
                },
                {
                    id: 'PC0128',
                    description: 'Objeto de combustión lenta utilizada para calentar. Tiene forma de cilindro con una pequeña compuerta en la parte inferior. Se coloca sobre una estructura rectangular con cuatro patas para ofrecer altura. Fue elaborada en hierro ensamblado con uniones de soldadura.'
                },
                {
                    id: 'PC0129',
                    description: 'Recipiente utilizado para contener líquidos de manera hermética. El envase es cilíndrico de fondo plano y boca más angosta, contiene en su parte superior una tapa rosca dentada. En la parte inferior de la cara exterior se observa una llave de paso de manilla redonda. El objeto fue elaborado en plástico con piezas metálicas. Se observan técnicas de moldeado y vaciado. La pieza es de color blanco, excepto la rosca dentada color negro. En la cara exterior se observa el texto manuscrito "Destilada".'
                },
                {
                    id: 'PC0130',
                    description: 'Equipo utilizado en el laboratorio, donde se requiere extraer vapores y generar ambientes controlados, limitando la exposición de las personas a las sustancias peligrosas o nocivas. Predomina la forma pentagonal y tiene una puerta corrediza. Está fabricado principalmente en madera y la puerta corrediza es de vidrio. Posee, además, elementos metálicos.'
                },
                {
                    id: 'PC0131',
                    description: 'Estructura utilizada para guardar objetos. El objeto tiene forma de paralelepípedo y está compuesto por cuatro puertas batientes, que se abren con bisagras hacia afuera. Las puertas superiores tienen cristales transparentes para observar el interior. La puerta inferior posee un pestillo para cerrar con candado. El objeto fue elaborado en madera, pero posee elementos de vidrio y metal. Está pintado en blanco y posee adherido en la parte inferior un afiche se seguridad. Pueden observarse a través del cristal instrumentos de vidrio, como balones, probetas, beakers, pipetas y otros instrumentos de laboratorio químico.'
                },
                {
                    id: 'PC0132',
                    description: 'Estructura utilizada para arreglar, organizar, regular u ordenar diferentes objetos, es este caso, es un muestrario de piedras. La estructura es rectangular de grandes proporciones, con un sistema de 127 compartimientos distribuidos en 9 filas, cada compartimiento tiene forma cuadrada. Fue elaborado con madera ensamblada y pintado en color celeste. '
                },
                {
                    id: 'PC0133',
                    description: 'Contenedor utilizado para contener alcohol destilado, específicamente pisco. Está compuesta por un cuerpo de vidrio soplado, una etiqueta de papel adherida y una abertura en la superficie que indica el posible uso de un corcho o tapa para cerrarlo. La forma es cilíndrica de cuello alargado y estrecha hacía la boca o corona. Posee un etiquetado rectangular de color blanco con letras de color rojo donde se alcanza a observar la marca del producto “Pisco Control" y una pequeña etiqueta redonda con el dibujo de un racimo de uvas. '
                },
                {
                    id: 'PC0134',
                    description: 'Instrumento médico utilizado para lograr un buen examen de la boca y la garganta mediante una leve presión en la lengua. Tiene forma de espátula, con uno de sus lados de mayor grosor. En cada extremo de su superficie presenta tres agüeros; en el extremo más ancho tienen forma rectangular y en el extremo más angosto los agüeros son de forma cuadrada. La pieza fue elaborada completamente en acero inoxidable mediante moldeado y troquelado. El objeto también es conocido como bajalengua. '
                },
                {
                    id: 'PC0135',
                    description: 'Contenedor utilizado para contener licor de menta. Posee una forma cilíndrica en cuello y cuerpo, con hombros redondeados. Aún conserva su tapa original. Está elaborado en vidrio soplado y moldeado, las etiquetas son de papel y la tapa es de aluminio. Mantiene tres etiquetas de papel adheridas, en una de ellas se observa que fue un producto de elaboración nacional. La marca de origen es MITJANS.'
                },
                {
                    id: 'PC0136',
                    description: 'Conjunto utilizado para sujetar o asegurar tubos o conductos de diferentes tipos. El objeto está compuesto por dos piezas alargadas y curvas, unidas entre sí por una huincha adhesiva . Las piezas fueron elaboradas en metal mediante fundición y moldeado. La banda adhesiva es de color amarillo y posee una inscripción en lápiz “12/71/90 x2".'
                },
                {
                    id: 'PC0137',
                    description: 'Herramienta química utilizada para mezclar o introducir líquidos de alta reacción, por medio de escurrimiento, evitando accidentes en el laboratorio. Se compone de dos varillas cilíndricas y alargadas, fabricadas con vidrio macizo e incoloro, resistentes al calor y al frio. La más grande tiene una goma para tomarla con los dedos y la más pequeña está rota.'
                },
                {
                    id: 'PC0138',
                    description: 'Objeto utilizado para la proyección de luz en conjunto con una linterna. Es de forma circular, con una banda gruesa a su alrededor a modo de protección. Fue elaborado en vidrio transparente y la protección en plástico. El objeto presenta inscripciones manuales con las inscripciones H MAC y otras ralladuras posiblemente generadas por uso.'
                },
                {
                    id: 'PC0139',
                    description: 'Instrumento usado en el laboratorio químico para conducir líquidos u otras sustancias de un recipiente a otro de boca más estrecha, evitando que se produzcan derrames accidentales. El objeto fue fabricado con vidrio incoloro. Tiene forma de cono invertido con un tubo estrecho en el apéndice. '
                },
                {
                    id: 'PC0140',
                    description: 'Instrumento usado en el laboratorio químico para medir líquidos con precisión. De forma cilíndrica y alargada, fue fabricado en vidrio macizo incoloro. Se conserva solo la forma más estrecha y cilíndrica de la pipeta, que termina con una estructura conoide, con una curvatura inicial de mayor ancho que va disminuyendo.'
                },
                {
                    id: 'PC0141',
                    description: 'Instrumento utilizado en el laboratorio químico para calentar, medir o traspasar sustancias líquidas. Es de forma cilíndrica con fondo plano. La parte superior del objeto tiene un acabado en forma de labio que permite verter el contenido de su interior con mayor facilidad y exactitud. Fue fabricado en vidrio incoloro mediante fundición y soplado de vidrio. Presenta una graduación en ml, que va desde el 100 hasta el 500. También contiene una inscripción donde se aprecia su capacidad máxima de volumen de 600 ml. Se observa la marca PYREX® y la numeración “No. 1000". Todas las inscripciones fueron elaboradas con letras blancas. '
                },
                {
                    id: 'PC0142',
                    description: 'Objeto utilizado para medir volúmenes líquidos. Tiene forma de vasija globular incolora con un tubo estrecho en el apéndice, confeccionado completamente de vidrio mediante técnica de cocción y soplado. En el centro de la esfera se ubica la marca del matraz "SCHOOT u.GEN JENA er GLAS".'
                },
                {
                    id: 'PC0143',
                    description: 'Recipiente empleado generalmente para preparar o calentar sustancia, así como también para medir o traspasar líquidos. El objeto tiene forma cilíndrica y fondo plano. La parte superior del objeto tiene un acabado en forma de labio que permite verter su interior con mayor facilidad y exactitud. Fue elaborado en vidrio soplado y fundición. Presenta una graduación en ml, que va desde el 50 hasta el 200, escrito con letras blancas. Posee una inscripción donde se aprecia su capacidad máxima de volumen de 250 ml, y la marca SCHOTT DURAN, bajo ésta se encuentra una forma blanca de óvalo que tiene inscrito el número 9 con lápiz, y bajo éste se puede leer “Made in Germany" en letras blancas. Posee una picadura en el borde superior de la superficie.'
                },
                {
                    id: 'PC0144',
                    description: 'Objeto utilizado para conducir líquidos u otras sustancias a contenedores con aperturas más estrechas, evitando así el derramamiento accidental. Tiene forma de cono invertido con un tubo estrecho en el apéndice. Fue elaborado completamente de vidrio traslucido por técnica de cocción.'
                },
                {
                    id: 'PC0145',
                    description: 'Recipiente empleado generalmente para preparar o calentar sustancia, así como también para medir o traspasar líquidos. El objeto tiene forma cilíndrica y fondo plano. La parte superior del objeto tiene un acabado en forma de labio que permitiría verter su interior con mayor facilidad y exactitud. Fue elaborado en vidrio soplado y fundición. Presenta una graduación en ml, que va desde el 50 hasta el 200, escrito con letras blancas. Posee una inscripción donde se aprecia su capacidad máxima de volumen de 250 ml, y la marca PYREX®, bajo ésta se encuentra una forma blanca de óvalo donde se puede apreciar la inscripción del número 1, y bajo la misma, la inscripción "No. 1000" en letras blancas.'
                },
                {
                    id: 'PC0146',
                    description: 'Objeto utilizado para llevar a cabo reacciones, tinción de bacterias o medición de pH. Tiene forma rectangular y está compuesto por una sola pieza con doce cavidades iguales dispuestas de manera simétrica. Fue elaborado en porcelana con técnica de vaciado y esmaltado blanco. Tiene la inscripción COORS USA y un código manual 13018-4.'
                },
                {
                    id: 'PC0147',
                    description: 'Objeto utilizado para llevar a cabo reacciones, tinción de bacterias o medición de pH. Tiene forma rectangular y está compuesto por una sola pieza con seis cavidades iguales, dispuestas de manera simétrica. Fue elaborado en porcelana con técnica de vaciado y esmaltado blanco. '
                },
                {
                    id: 'PC0148',
                    description: 'Objeto utilizado para llevar a cabo reacciones, tinción de bacterias o medición de pH en el laboratorio. Compuesto por una sola pieza, basado en una placa de porcelana esmaltada con doce cavidades, de color blanco para facilitar la observación y la limpieza. Es una placa maciza sin inscripciones visibles.'
                },
                {
                    id: 'PC0149',
                    description: 'Instrumento usado en el laboratorio químico para contener líquidos y medir volúmenes. El objeto tiene forma cilíndrica con una base hexagonal que le da estabilidad y una boca redonda con labio pronunciado para verter. Fue elaborado en vidrio de borosilicato, mediante cocción y soplado. La graduación es de color caramelo con una precisión de ±1.5 ml y tiene una capacidad máxima de almacenaje de 250 ml. Posee una inscripción en la parte superior: GLASWERK WERTHEIM GERMANY.'
                },
                {
                    id: 'PC0150',
                    description: 'Objeto utilizado en el laboratorio para calentar líquidos y evitar la pérdida de los mismos por evaporación. Es un recipiente de vidrio soplado incoloro con una forma cónica que finaliza en su parte superior con un cuello esmerilado estrecho de aspecto cilíndrico. En la superficie lateral posee diversas inscripciones, todas en letras blancas: la marca de origen SCHOTT DURAN, una forma blanca de óvalo que contiene inscrito el número 8 y el texto “Made in Germany". A un costado se puede observación la graduación aproximada que va del 200 hasta el 500 escrito en letras blancas. Se observa deterioro de uso en el cuello cilíndrico.'
                }
            ]
            let arr4 = [{
                    id: 'PC0151',
                    description: 'Objeto utilizado en el laboratorio para evaporar líquidos o bien para pesar elementos sólidos, también se utiliza como cubierta de vasos precipitados y para la contención de sustancias. El objeto tiene forma circular cóncava-convexa. Fue elaborado con un vidrio especial transparente de borosilicato que puede resistir hasta 150 °C, con técnica de cocción. Su nombre se debe al parecido con el vidrio de los relojes de bolsillo antiguos.'
                },
                {
                    id: 'PC0152',
                    description: 'Objeto utilizado para la medición precisa de soluciones en laboratorios. Compuesto por dos piezas: un recipiente incoloro con una forma cónica y fondo plano y una tapa. Fue elaborado en vidrio mediante soplado y cocción. Posee la línea de calibración e inscripciones en color azul, una inscripción de marca WESTERN GERMANY, y en la boca NS 19/26,  Además, se divisa la capacidad de 500 mL y precisión de ±0.25 mL.'
                },
                {
                    id: 'PC0153',
                    description: 'Objeto utilizado en el laboratorio para calentar líquidos y evitar la pérdida de los mismos por evaporación. Tiene forma cónica que finaliza en su parte superior con un cuello esmerilado estrecho de aspecto cilíndrico. Fue elaborado completamente en vidrio soplado. En la superficie lateral posee diversas inscripciones, todas en letras blancas; en éstas se aprecia primeramente la capacidad máxima de 500 ml, y luego la marca PYREX®, bajo esta inscripción es posible observar una forma blanca de óvalo que tiene inscrito el número 7 con lápiz, y finalmente se puede leer “No. 4980". Además, a un costado de las inscripciones ya expuestas se puede observar la graduación aproximada que va del 100 hasta el 500. Se observa deterioro de uso en el cuello cilíndrico. Se observa deterioro de uso en el cuello cilíndrico.'
                },
                {
                    id: 'PC0154',
                    description: 'Objeto utilizado en el laboratorio para evaporar líquidos o bien para pesar elementos sólidos, también se utiliza como cubierta de vasos precipitados y para la contención de sustancias. Es de forma circular cóncava-convexa. Fue elaborado con un vidrio especial transparente de borosilicato que puede resistir hasta 150 °C, con técnica de cocción. Su nombre se debe al parecido con el vidrio de los relojes de bolsillo antiguos.'
                },
                {
                    id: 'PC0155',
                    description: 'Objeto utilizado para la medición precisa de soluciones en laboratorios. Es de forma esférica de cuello largo sobre el cual se coloca la marca de aforo (medición), una boca esmerilada con forma de cono truncado e invertido y labios redondeados, que culmina con una base plana. En la parte superior posee una tapa de vidrio esmerilada con agarradera redonda, adherida mediante cinta blanca (masking tape). Fue elaborado en vidrio mediante soplado y cocción. Posee la línea de calibración e inscripciones en color blanco, una inscripción de la marca PYREX®,  Además, se divisa la capacidad de 1000 mL y precisión de ±0.30 mL. Se observa deterioro de uso en el cuello cilíndrico.'
                },
                {
                    id: 'PC0156',
                    description: 'Objeto utilizado para la medición precisa de soluciones en laboratorios. Es de forma esférica de cuello largo sobre el cual se coloca la marca de aforo (medición), una boca esmerilada con forma de cono truncado he invertido y labios redondeados, que culmina con una base plana. Fue elaborado en vidrio mediante soplado y cocción. Se divisa la capacidad de 1000 ccm, calibrado a 20 °C . Tanto la línea de calibración como la inscripción de capacidad fueron grabadas a mano.'
                },
                {
                    id: 'PC0157',
                    description: 'Objeto utilizado en el laboratorio para calentar líquidos y evitar la pérdida de los mismos por evaporación. Es de forma cónica que finaliza en su parte superior con un cuello esmerilado estrecho de aspecto cilíndrico. Fue elaborado completamente en vidrio soplado. En la superficie lateral posee diversas inscripciones, todas en letras blancas; en estas se aprecia primeramente la capacidad máxima de 1000 ml, y luego la marca PYREX®, bajo esta inscripción es posible observar una forma blanca de óvalo sin inscripción, y finalmente se puede leer “No. 4980". Además, a un costado de las inscripciones ya expuestas se puede observación la graduación aproximada que va del 300 hasta el 1000.'
                },
                {
                    id: 'PC0158',
                    description: 'Objeto utilizado en el laboratorio para evaporar líquidos o bien para pesar elementos sólidos, también se utiliza como cubierta de vasos precipitados y para la contención de sustancias. Tiene forma circular cóncava-convexa. Fue elaborado con un vidrio especial transparente de borosilicato que puede resistir hasta 150 °C, con técnica de cocción. Su nombre se debe al parecido con el vidrio de los relojes de bolsillo antiguos.'
                },
                {
                    id: 'PC0159',
                    description: 'Objeto utilizado en la preparación de soluciones o la medición de volúmenes de toda clase de líquidos. El objeto tiene forma cilíndrica hueca, una base hexagonal que le da estabilidad, y una boca redonda con labio pronunciado para verter. Fue elaborado en vidrio incoloro por medio de soplado y cocción y graduado en color caramelo con 250 mL de capacidad. Posee inscripciones de graduado de precisión de ±0.75 y una inscripción en la parte superior de W. GERMANY y OMNILAB con un logo de cuatro círculos inscritos en un cuadrado.'
                },
                {
                    id: 'PC0160',
                    description: 'Objeto utilizado para proteger su interior al mover o transportar. Es de aspecto plano e irregular, que al armarse adquiere la forma de una caja. Fue elaborado en cartón troquelado con impresiones de diversos tipos. Por dentro posee el color original de su materialidad y por fuera la impresión es principalmente rojo anaranjado con detalles blancos. Se observan diversas inscripciones como la marca KIENZLE. El embalaje correspondía a un despertador mecánico.'
                },
                {
                    id: 'PC0161',
                    description: 'Objeto utilizado en el laboratorio para calentar líquidos y evitar la pérdida de los mismos por evaporación. Compuesto como una única pieza, es un recipiente de vidrio soplado incoloro con una forma cónica que finaliza en su parte superior con un cuello esmerilado estrecho de aspecto cilíndrico. Posee diversas inscripciones en letras blancas: marca de origen (SHOTT DURAN), la graduación (del 100 hasta el 250 ml) , capacidad máxima de líquidos que puede albergar (250 ml), una forma blanca de óvalo que tiene inscrito el número 1 y finalmente se puede leer “West Germany".'
                },
                {
                    id: 'PC0162',
                    description: 'Objeto utilizado en la preparación de soluciones o la medición de volúmenes de toda clase de líquidos. El objeto tiene forma cilíndrica hueca, una base hexagonal que le da estabilidad, y una boca redonda con labio pronunciado para verter. Fue elaborado en vidrio incoloro por medio de soplado y cocción y graduado en color caramelo con 250 mL de capacidad. Posee inscripciones de graduado de precisión de ±2 mL y una inscripción en la parte superior de W. GERMANY. '
                },
                {
                    id: 'PC0163',
                    description: 'Objeto utilizado para registrar operaciones de una empresa. El objeto es de forma rectangular y está compuesto por una tapa y contratapa de papel craft unidas por corchetes en la zona superior, y una agrupación de papeles rosados y blancos en su interior. En la primera página del interior se puede observar la marca "Telefónica de Coyhaique, una empresa VTR" y "Control tráfico teléfonos públicos y unictel". Las primeras 7 hojas contienen información de conexión y observaciones. A mitad del talonario contiene una hoja suelta con la misma información.'
                },
                {
                    id: 'PC0164',
                    description: 'Objeto utilizado en el laboratorio químico para separar sustancias por medio de la filtración. Se encuentra en una caja de cartón cuadrada donde la parte delantera, trasera y dos de sus laterales opuestos son de color blanco, los otros dos laterales son rojos. La caja fue sellada con cinta adhesiva en forma de cruz. En la parte superior se observa la inscripción impresa en la caja: “Schleicher & Schuell" y, mediante adhesivo rectangular, se observa el texto"692 H Ref N°312610". '
                },
                {
                    id: 'PC0165',
                    description: 'Objeto utilizado en la preparación de soluciones o la medición de volúmenes de toda clase de líquidos. El objeto tiene forma cilíndrica hueca, una base hexagonal que le da estabilidad y una boca redonda con labio pronunciado para verter. Fue elaborado en vidrio de borosilicato incoloro por medio de soplado y cocción y graduado en color caramelo con 500 mL de capacidad. Posee inscripciones de graduado de precisión de ±3.75 mL., una inscripción en la parte superior de WESTER GERMANY y una marca redonda con la inscripción GLASWERK WERTHEIM.'
                },
                {
                    id: 'PC0166',
                    description: 'Objeto utilizado en el laboratorio para evaporar líquidos o bien para pesar elementos sólidos, también se utiliza como cubierta de vasos precipitados y para la contención de sustancias. Es de forma circular cóncava-convexa. Fue elaborado con un vidrio especial transparente de borosilicato que puede resistir hasta 150 °C, con técnica de cocción. Su nombre se debe al parecido con el vidrio de los relojes de bolsillo antiguos.'
                },
                {
                    id: 'PC0167',
                    description: 'Objeto utilizado en el laboratorio para evaporar líquidos o bien para pesar elementos sólidos, también se utiliza como cubierta de vasos precipitados y para la contención de sustancias. Es de forma circular cóncava-convexa. Fue elaborado con un vidrio especial transparente de borosilicato que puede resistir hasta 150 °C, con técnica de cocción. Su nombre se debe al parecido con el vidrio de los relojes de bolsillo antiguos.'
                },
                {
                    id: 'PC0168',
                    description: 'Objeto utilizado para cubrir almohadas o cojines, tanto para evitar que se adhiera la suciedad al cojín como con fines decorativos. El objeto tiene forma rectangular y está fabricado casi en su totalidad por lana. Está compuesto por una sola pieza, elaborado de manera manual con aguja y ganchillo en técnica crochet. Se observa que estuvo cosida mediante lana a otro textil, puesto que se visualizan dos piezas cosidas en dos de sus esquinas con un diseño floral blanco sobre fondo con tonalidad azul. Presenta múltiples variedades de colores: amarillo, azul, rojo y blanco; el patrón es simétrico y va desde un centro cuadrado amarillo que luego es envuelto en un patrón similar donde se van añadiendo los colores.'
                },
                {
                    id: 'PC0169',
                    description: 'Objeto utilizado para cubrir o tapar superficies. Es de forma rectangular. En la parte superior, se encuentran cosidas al dobladillo, cinco bandas de tela multicolor destinadas a enganchar la cortina a un soporte cilíndrico. Fue elaborado en tela e hilo y es principalmente color celeste con un patrón de flores blancas impreso por ambos lados. Se observa deterioro por luz y pérdida de tintes.'
                },
                {
                    id: 'PC0170',
                    description: 'Objeto utilizado en el laboratorio para evaporar líquidos o bien para pesar elementos sólidos, también se utiliza como cubierta de vasos precipitados y para la contención de sustancias. Es de forma circular cóncava-convexa. Fue elaborado con un vidrio especial transparente de borosilicato que puede resistir hasta 150 °C, con técnica de cocción. Su nombre se debe al parecido con el vidrio de los relojes de bolsillo antiguos.'
                },
                {
                    id: 'PC0171',
                    description: 'Objeto utilizado en el laboratorio para evaporar líquidos o bien para pesar elementos sólidos, también se utiliza como cubierta de vasos precipitados y para la contención de sustancias. Es de forma circular cóncava-convexa. Fue elaborado con un vidrio especial transparente de borosilicato que puede resistir hasta 150 °C, con técnica de cocción. Presenta, además, una inscripción de fábrica con el código Pirex, y se observan ralladuras y desgaste por uso. Su nombre se debe al parecido con el vidrio de los relojes de bolsillo antiguos.'
                },
                {
                    id: 'PC0172',
                    description: 'Objeto utilizado en el laboratorio para evaporar líquidos o bien para pesar elementos sólidos, también se utiliza como cubierta de vasos precipitados y para la contención de sustancias. Es de forma circular cóncava-convexa. Fue elaborado con un vidrio especial transparente de borosilicato que puede resistir hasta 150 °C, con técnica de cocción. Presenta además una inscripción de fábrica con el código PYREX®, y al otro lado se lee MADE IN U.S.A., y se observan ralladuras y desgaste por uso. Su nombre se debe al parecido con el vidrio de los relojes de bolsillo antiguos.'
                },
                {
                    id: 'PC0173',
                    description: 'Objeto utilizado en el laboratorio para evaporar líquidos o bien para pesar elementos sólidos, también se utiliza como cubierta de vasos precipitados y para la contención de sustancias. Es de forma circular cóncava-convexa. Fue elaborado con un vidrio especial transparente de borosilicato que puede resistir hasta 150 °C, con técnica de cocción. Su nombre se debe al parecido con el vidrio de los relojes de bolsillo antiguos.'
                },
                {
                    id: 'PC0174',
                    description: 'Objeto utilizado en el laboratorio para evaporar líquidos o bien para pesar elementos sólidos, también se utiliza como cubierta de vasos precipitados y para la contención de sustancias. Es de forma circular cóncava-convexa. Fue elaborado con un vidrio especial transparente de borosilicato que puede resistir hasta 150 °C, con técnica de cocción. Su nombre se debe al parecido con el vidrio de los relojes de bolsillo antiguos'
                },
                {
                    id: 'PC0175',
                    description: 'Objeto utilizado en el laboratorio para mantener limpia y deshidratada una sustancia por medio del vacío. Este objeto generalmente se utilizaba para secar mediante el uso del gel de sílice en la cavidad inferior. La superior era para contener las sustancias que se pretendía secar. El objeto está compuesto por varias piezas: la de mayor tamaño tiene forma cilíndrica y contiene dos cavidades de diferentes tamaños; una tapa con un orificio central; y una pieza interior a modo de base para muestras, con cuatro agujeros y tres patas, que sirve como soporte para separar las muestras del desecante. Fue elaborado en vidrio incoloro por cocción y la pieza interior fue elaborada en porcelana blanca.'
                },
                {
                    id: 'PC0176',
                    description: 'Objeto utilizado en el laboratorio para evaporar líquidos o bien para pesar elementos sólidos, también se utiliza como cubierta de vasos precipitados y para la contención de sustancias. Es de forma circular cóncava-convexa. Fue elaborado con un vidrio especial transparente de borosilicato que puede resistir hasta 150 °C, con técnica de cocción. Presenta, además, tres inscripciones de fábrica con el código PYREX®, más al costado otra que se observa con el código C-25, y al otro lado se lee MADE IN U.S.A. Su nombre se debe al parecido con el vidrio de los relojes de bolsillo antiguos'
                },
                {
                    id: 'PC0177',
                    description: 'Objeto utilizado para medición volumétrica a una temperatura determinada. Está compuesta por una pieza quebrada. Predomina la forma cilíndrica, en su extremo inferior el diámetro se achica. Posee una llave de paso, con forma de cono truncado esmerilado y una agarradera en forma de dos conos redondeados; en el interior la llave posee un agujero que la traspasa latitudinalmente y que permite a su vez, al abrir la válvula, dosificar el contenido gota a gota. Fue elaborado casi completamente en vidrio con técnica de cocción, excepto un seguro en forma de anillo de hule o goma. Posee una inscripción color ámbar de la marca RHG.'
                },
                {
                    id: 'PC0178',
                    description: 'Concreción mineral utilizada como muestra de material. Tiene forma irregular, se compone por dos piezas unidas mediante cinta adhesiva y posee inscrito, con plumón negro, la palabra BLENDA (Texto escrito por la Corporación Memoria Austral, en el año 2014).'
                },
                {
                    id: 'PC0179',
                    description: 'Objeto utilizado para anunciar o indicar una información específica. El objeto es rectangular y fue fabricado en metal. Posee tres orificios en la parte posterior y superior,  Además, de dos orificios al centro de cada lateral que permitían un buen agarre para ser colgado. Se observa la inscripción grabada “SERVICIO NACIONAL DE SALUD PUERTO CRISTAL".'
                },
                {
                    id: 'PC0180',
                    description: 'Concreción mineral utilizada como muestra de material. Tiene forma irregular y se compone por una pieza. Tiene adosada cinta adhesiva de manera envolvente con la inscripción “Blenda c/ Esteril (blanco) 10 %" escrito a mano con plumón negro (Texto escrito por la Corporación Memoria Austral, en el año 2014).'
                },
                {
                    id: 'PC0181',
                    description: 'Objeto utilizado para medición volumétrica a una temperatura determinada. Predomina la forma cilíndrica, en su extremo inferior el diámetro se achica y termina en forma de cono. Posee una llave de paso con forma de cono truncado esmerilado, con agarradera en forma de trapecio con filos redondeados; en el interior la llave posee un agujero que la traspasa latitudinalmente y que permite a su vez, al abrir la válvula, dosificar el contenido gota a gota. Fue elaborado casi completamente en vidrio con técnica de cocción, excepto un seguro en forma de anillo de hule o goma. Posee una inscripción que alude a capacidad máxima “capacidad de 25 mL “ y otra donde se divisa “NS 12.5 y 2.5 mm".'
                },
                {
                    id: 'PC0182',
                    description: 'Objeto utilizado para medición volumétrica a una temperatura determinada. Predomina la forma cilíndrica, en su extremo inferior el diámetro se achica y termina en forma de cono. Posee una llave de paso con forma de cono truncado esmerilado, con agarradera en forma de trapecio con filos redondeados; en el interior la llave posee un agujero que la traspasa latitudinalmente y que permite a su vez, al abrir la válvula, dosificar el contenido gota a gota. Fue elaborado casi completamente en vidrio con técnica de cocción, excepto un seguro en forma de anillo de hule o goma. Posee una inscripción que alude a capacidad máxima “capacidad de 25 mL “.'
                },
                {
                    id: 'PC0183',
                    description: 'Pieza utilizada en el extremo de una bureta para pasar fluidos y permitir un goteo dosificado. Se compone de tres partes: un cuerpo en forma de cono truncado con un agujero que lo traspasa en su ancho, una agarradera en forma de mariposa o trapezoidal y una base en rosca donde va una pieza para ajustarlo. La pieza fue elaborada casi completamente de vidrio por técnica de cocción, pero presenta un plástico verde en la zona de la rosca. Existen tres ejemplares en el depósito, dos en mariposa y una con agarradera en forma de pala o conos redondeados.'
                },
                {
                    id: 'PC0184',
                    description: 'Envase utilizado para contener y guardar objetos. Es de forma rectangular abolsada. Fue elaborado de manera industrial en plástico. Es de color blanco, exceptuando inscripciones impresas de color azul y rojo, como la que indica la marca de origen “Lobos", y que se asocia a la producción de sal fina de mesa. El objeto fue reutilizado para guardar tornillos que aún se conservan en su interior. '
                },
                {
                    id: 'PC0185',
                    description: 'Contrapeso utilizado para ser colgado en una balanza por medio de una ranura. Su forma circular, de acero, fue generada mediante moldeado. En la cara superior contiene una inscripción: 100 K; en la cara inferior tiene nueve agujeros. '
                },
                {
                    id: 'PC0186',
                    description: 'Piezas utilizadas como repuestos para las linternas de los cascos mineros. Son de forma circular. Fueron elaborados completamente en plástico moldeado. En el depósito se encuentras diversos ejemplares de distintos tamaños, espesores y colores. '
                },
                {
                    id: 'PC0187',
                    description: 'Envase utilizado para contener té instantáneo. Su forma es cilíndrica y posee una etiqueta esmaltada donde se observa la marca de fabricación “ORJAS". El contenedor fue fabricado por ensamblado y es de metal. La etiqueta es color verde y amarillo. Su uso fue de carácter cotidiano y doméstico. '
                },
                {
                    id: 'PC0188',
                    description: 'Objeto utilizado para cerrar los espacios entre los componentes estáticos y en movimiento de equipos mecánicos,  Además, ayudaba a evitar fugas de lubricantes e impedir el acceso de contaminantes dañinos a la maquinaria. Tiene forma circular y está envuelto en una bolsa. Fue elaborado en plástico, metal y pintura, mediante ensamblado. Posee una etiqueta con inscripción: SKR OIL SEAL, n° 85x117.5x14, tipo BB 618-A, cod.265.'
                },
                {
                    id: 'PC0189',
                    description: 'Objeto utilizado para impedir el paso de líquidos o gases a través de un conducto u orificio. Está compuesto por una cabeza octogonal y un cuerpo en forma de cono truncado con rosca. El cuerpo está vacío y posee en relieve círculos que sirven de rosca, la cabeza tiene un vaciado en la base. El objeto fue elaborado en plástico moldeado, principalmente color blanco. Posee un círculo de color rojo y la inscripción 18/26 en relieve. Existen tres ejemplares más en el depósito, cambiando el diseño de la cabeza y los tamaños.'
                },
                {
                    id: 'PC0190',
                    description: 'Elemento utilizado para el intercambio de líquidos o gases en las uniones entre piezas desmontables. Está compuesto por dos repuestos de juntas tóricas u o-rings insertos en una pieza plástica abolsada. El objeto fue elaborado en metal y plástico termo laminado. Se observan diferentes inscripciones en la bolsa como “industria FIAT" y “ARTICULO O RINGS 4107857, CODIGO 38251-5".'
                },
                {
                    id: 'PC0191',
                    description: 'Carnet utilizado para identificar a ciudadanos chilenos. Tiene forma rectangular y está fabricado en cartón termo-laminado. En la parte superior se observa el enunciado "CEDULA NACIONAL DE IDENTIDAD", bajo éste se encuentra una foto frontal de un hombre, que se identifica mediante la impresión del nombre JOSÉ CRISTOBAL ALVARADO CANICURA. También se observa, a mano y en lápiz negro, la firma del propietario. En la parte inferior un patrón simétrico de copihues que se interrumpe por la palabra CHILE. En la parte posterior del cartón se observa una fecha y una localidad. En la esquina superior derecha se reconoce una impresión del escudo nacional chileno y una huella dactilar. El patrón de copihues se repite en la parte inferior.'
                },
                {
                    id: 'PC0192',
                    description: 'Objeto utilizado para impedir que un objeto se golpee o ejecute un movimiento no deseado. La pieza está compuesta por un anillo circular dentro de una bolsa plástica cerrada con corchetes en el anverso. Fue elaborado en metal, plástico y papel mediante troquelado y moldeado por compresión. En la cara superior posee una etiqueta adherida donde se observa la inscripción “Empresa Minera de Aysen LTDA con el código 38 253-1.'
                },
                {
                    id: 'PC0193',
                    description: 'Conjunto de objetos destinados a entintar papel, con una descripción o propiedad específica a modo de impresión manual. Está compuesto por un objeto cilíndrico de madera que en uno de sus extremos contiene una superficie de impresión de goma de forma circular o sello de tinta. El otro elemento es una almohadilla de algodón cubierta de seda y saturada en tinta ubicada en una caja con tapa, de forma rectangular, metálica y pintada de amarillo, posee una inscripción impresa "Fulton´s". El tampón tiene la inscripción "SERVICIO SALUD AYSÉN. POSTA PUERTO CRISTAL".'
                },
                {
                    id: 'PC0194',
                    description: 'Instrumento utilizado para la medición de presión. Consistente en un cuerpo cilíndrico con una salida de conexión por el borde inferior y en el centro se puede observar una manilla que mide variaciones entre los 0 a los 600 Lbs/pulg2. Fue elaborado en metal troquelado y papel blanco ensamblado. Tiene la inscripción de la marca GILO.'
                },
                {
                    id: 'PC0195',
                    description: 'Objeto de entorno cotidiano, utilizado para servir o presentar alimentos. El recipiente se compone de una sola pieza de metal, tiene una base rectangular poco profunda y un borde de poca altura. La parte superior del recipiente es de color café esmaltado y posee la impresión de una imagen publicitaria con ilustraciones a color mientras que la parte posterior es esmaltada de color gris.'
                },
                {
                    id: 'PC0196',
                    description: 'Conjunto de objetos destinados a entintar papel, con una descripción o propiedad específica a modo de impresión manual. Está compuesto por un objeto cilíndrico de madera que en uno de sus extremos contiene una superficie de impresión de goma de forma circular o sello de tinta. El otro elemento es una almohadilla de algodón cubierta de seda y saturada en tinta ubicada en una caja con tapa, de forma rectangular, metálica y pintada de amarillo, posee una inscripción impresa "Fulton´s". El tampón tiene la inscripción "SERVICIO NACIONAL DE SALUD. POSTA PTO CRISTAL".'
                },
                {
                    id: 'PC0197',
                    description: 'Objeto utilizado para enmarcar fotografías y/o cuadros. Es de forma rectangular con moldura de madera ensamblada, de composición horizontal, color café y una estructura de vidrio rectangular más pequeña en la superficie frontal. En su cara posterior se encuentra el pie de apoyo hexagonal adherido por una pieza de acero a modo de bisagra. En dicha cara se observa la inscripción manuscrita con plumón negro: “Para, Cristóbal Alvarado. Recuerdo de la familia Gutiérrez - Escalona de Caleta Olivia - Santa Cruz, Argentina. 07/03 2009". '
                },
                {
                    id: 'PC0198',
                    description: 'Conjunto de herramientas utilizadas para sujetar y/o fijar uniones de elementos desmontables. La pieza se compone por once objetos de hierro con forma hexagonal y con un centro de forma circular ahuecada, en la que se pueden apreciar los hilos para enroscar. Están unidas de manera secuenciada por un alambre forrado unido en sus extremos.'
                },
                {
                    id: 'PC0199',
                    description: 'Objeto utilizado para enmarcar fotografías y/o cuadros. Es de forma rectangular con moldura de madera ensamblada, de composición horizontal, color negro y una estructura de vidrio rectangular más pequeña en la superficie frontal. En su cara posterior, se encuentra el pie de apoyo y la pieza de madera está sujeto por pletinas y piezas metálicas. El portarretratos exhibe una fotografía a color de Cristóbal Alvarado junto a una mujer en el muelle de Puerto Cristal.'
                },
                {
                    id: 'PC0200',
                    description: 'Objeto de entorno cotidiano, utilizado para servir o presentar alimentos. Tiene una base rectangular profunda y un borde extendido con dos asas en sus laterales. Fue elaborado en metal troquelado y moldeado por compresión. Posee una inscripción grabada “Aluminio Fantuzzi Fab. Chilena" y abajo el número 80.'
                }
            ]

            let arr5 = [{
                    id: 'PC0201',
                    description: 'Literatura de tipo religiosa utilizada para enseñar y practicar la fe cristiana. Debido a su reducido tamaño se asocia a un tipo de biblia de bolsillo. Es de tamaño rectangular con tapa plástica de color negro y el interior contiene 384 páginas de color crudo. Los textos fueron impresos en negro. Contiene el OREMUS Manual bíblico- litúrgico de piedad del Episcopado de Chile, impreso en IMPRENTA GENDARMERÍA de Santiago de Chile en 1990.'
                },
                {
                    id: 'PC0202',
                    description: 'Prenda de vestir utilizada para proteger la ropa por delante en tareas profesionales. Se compone de una sola pieza que tiene una forma poligonal de seis lados. Contiene tres amarras, una a cada extremo de la cintura y una en la parte superior en la zona del cuello. Está confeccionada en cuero color gris.'
                },
                {
                    id: 'PC0203',
                    description: 'Prenda de vestir utilizada para proteger canillas y pies en labores de soldadura y trabajos con plasma. Su forma es tubular en la parte superior con acabado abierto semicircular para cubrir el zapato. Posee un sistema de amarre con guincha y hebillas en tres puntos de la zona lateral y un sistema de agarre con guincha en la zona inferior. Fue confeccionada en cuero de color café e hilos verdes. '
                },
                {
                    id: 'PC0204',
                    description: 'Objeto utilizado para suspensión de paquetes de resorte para buses y camiones Mercedes Benz. Es de forma semi curva batida con un etiquetado adherido por medio de hilo. Fue elaborado en hierro por medio de fundición y moldeado. El objeto está etiquetado bajo la inscripción de la Empresa Minera Aysén como: ARTÍCULO: abrazadera paq. Resorte & M. Benz, CODIGO 42.006-6'
                },
                {
                    id: 'PC0205',
                    description: 'Objeto utilizado para impermeabilizar y repintar cierto tipo de calzado. Está compuesto por tres piezas: un cuerpo cilíndrico de base plana, una superficie a modo de tapa también circular y plana y un sistema de seguridad de cierre y apertura en su borde superior derecho. Fue elaborado completamente en metal moldeado con diferentes impresiones. La parte inferior es color dorado y en la superior predomina el color blanco. Posee diferentes inscripciones café y rojo donde se observan las palabras “NUGGET" “CAFÉ" tanto en la superficie superior como en los laterales.'
                },
                {
                    id: 'PC0206',
                    description: 'Objeto utilizado como protección de ralladuras o daños producto del uso de tornillos o tuercas de montaje. Tiene forma redonda con un orificio en el centro. Fue completamente elaborado en acero troquelado.'
                },
                {
                    id: 'PC0207',
                    description: 'Objeto utilizado para impermeabilizar y repintar cierto tipo de calzado. Está compuesto por tres piezas: un cuerpo cilíndrico de base plana, una superficie a modo de tapa también circular y plana y un sistema de seguridad de cierre y apertura en su borde superior derecho. Fue elaborado completamente en metal moldeado con diferentes impresiones. La parte inferior es color dorado, y en la superior predomina el color café y el blanco. Posee diferentes inscripciones blancas y rojo donde se observan las palabras “VIRGINIA" “Betún" “CAFÉ" tanto en la superficie superior como en los laterales.'
                },
                {
                    id: 'PC0208',
                    description: 'Herramienta usada en los caballos para proteger sus cascos y pezuñas de desgastes y roturas. La pieza es plana con forma de U y presenta cuatro orificios rectangulares puestos de manera simétrica a cada lado. Fue fabricada completamente en hierro.'
                },
                {
                    id: 'PC0209',
                    description: 'Herramienta manual utilizada para apretar o bien aflojar tornillos, como particularidad está llave poseía la posibilidad de ajustarse al tamaño deseado. Está compuesta por un mango plano, unida a una mordaza fija. Fue elaborada completamente en hierro mediante moldeado y vaciado. En su cara superior tiene inscrita la marca "EMGO". La pieza se encuentra incompleta.'
                },
                {
                    id: 'PC0210',
                    description: 'Objeto utilizado para contener adhesivo líquido. Se compone por un cuerpo cilíndrico metálico y una tapa metálica a presión. Su exterior se encuentra forrado con una etiqueta adhesiva de color azul y diseños y tipografía de color blanco y rojo. Fue elaborado en metal ensamblado. Posee una inscripción que alude a su contenido original: pegatodo cemento especial de la marca AGOREX 60.'
                },
                {
                    id: 'PC0211',
                    description: 'Objeto utilizado para medir y detectar la presión física de líquidos y gases. Está compuesto por un cuerpo cilíndrico En la cara superior se puede observar la manilla y los números que varían desde el 0 al 200, y del 0 al 14, medidas en kg/cm2 y lb/in2 respectivamente. Presenta tres tornillos que no ejercen presión en su cara superior y tres tornillos pequeños en la parte posterior. El objeto fue elaborado casi completamente en metal troquelado, exceptuando el interior, donde se observan las variaciones de medición, que está confeccionado con papel blanco. En el centro se aprecia la inscripción AIR PRESSURE.'
                },
                {
                    id: 'PC0212',
                    description: 'Envase utilizado para conservar y mantener en correcto estado su contenido interior. El objeto con forma cilíndrica y base plana, está compuesto de un cuerpo base y una tapa metálica en cuya cara superior se encuentra impresa la marca MENTALOL en letras blancas. En la parte inferior se puede divisar detalles de su fabricación y especificaciones del contenido. El objeto tiene una tonalidad oxidada.'
                },
                {
                    id: 'PC0213',
                    description: 'Herramienta utilizada como parte de un conjunto de elementos que controlan la entrada de combustible en diferentes tipos de motores. Tiene forma de disco circular en cuya cara superior se encuentran seis canales de ventilación y dos orificios en los extremos. Por el reverso se encuentran dos placas de acero ensambladas en los laterales izquierdo y derecho. Fue elaborada en hierro y acero mediante moldeado, ensamblado y troquelado.'
                },
                {
                    id: 'PC0214',
                    description: 'Prenda de vestir usada tanto para identificar labores u oficios de quién lo vestía como para proteger la ropa del diario vivir de posibles manchas o desgastes de uso. El textil es verde oliva en casi su totalidad, de mangas largas, con un cuello sin encajes de color blanco y puntos decorativos café. En la parte delantera, situado al centro, posee un cierre y dos bolsillos laterales. El uniforme fue confeccionado por el Centro de Madres "Los Cristales" para recibir en acto conmemorativo a Lucia Hiriart, presidenta de los CEMA Chile.'
                },
                {
                    id: 'PC0215',
                    description: 'Pieza utilizada frecuentemente para fabricar cables eléctricos. Su forma es de hilo alargado y estirado, enrollado en sí mismo a modo de ovillo. Las tonalidades de la pieza son propias del cobre, aun cuando su interior se observa más claro. El largo total no se encuentra dimensionado. '
                },
                {
                    id: 'PC0216',
                    description: 'Objeto utilizado para sujetar, agarrar o colgar distintos objetos de su extremo. Tiene forma simétrica a modo de “S". Tanto la parte superior como la inferior terminan en punta. Fue elaborado en hierro mediante fundición. '
                },
                {
                    id: 'PC0217',
                    description: 'Objeto utilizado para cubrir la boca y evitar respirar gases tóxicos u otro tipo de partículas. El objeto se compone por un cuerpo cóncavo irregular, dos objetos plásticos cilíndricos a cada lado, que funcionan como válvulas de inhalación, y dos objetos cilíndricos de menor medida: uno en el centro y el otro en la parte trasera a modo de válvulas de exhalación. La base del cuerpo es negra y los objetos plásticos adheridos son de color gris. Posee una inscripción en la parte delante que se lee como “NORSEG". Al respirador le faltan los filtros.'
                },
                {
                    id: 'PC0218',
                    description: 'Instrumento utilizado para prender, agarrar o colgar objetos. Está compuesto por una forma plana y curva que termina en punta y una pieza rectangular adherida al final, mediante un cilindro macizo que atraviesa ambas partes. La pieza conectora fue elaborada para unir el gancho a otra maquinaria para su transporte. El gancho posee una inscripción grabada en relieve que dice “5 TON"'
                },
                {
                    id: 'PC0219',
                    description: 'Herramienta de uso manual, utilizado para aflojar o apretar otra pieza mediante el uso de la fuerza. Está compuesta por un mango plano, unido a una pieza ajustable a diferentes medidas de pernos o tuercas y que cumple la función de mordaza. Fue fabricada en hierro. La pieza se encuentra visiblemente oxidada y modificada.'
                },
                {
                    id: 'PC0220',
                    description: 'Pieza utilizada para trasmitir potencias de un componente a otro de manera manual. Tiene forma cónica dentada ensanchándose al máximo en la zona de la corona y volviéndose más pequeña en el cubo. En la zona del eje posee un orificio circular que atraviesa la pieza. La superficie es ligeramente cóncava. Fue elaborada en metal esmerilado con técnica de fresado.'
                },
                {
                    id: 'PC0221',
                    description: 'Objeto utilizado en torres o postes, cuyo objetivo se designa tanto a la aislación eléctrica como a la transmisión y distribución de un conductor de línea. Está compuesto por dos piezas específicas: una cadena de dos aisladores circulares de vidrio color celeste, ensamblados a unos herrajes. En la base del aislador se encuentra una inscripción moldeada en el vidrio. '
                },
                {
                    id: 'PC0222',
                    description: 'Pieza utilizada para trasmitir potencias de un componente a otro de manera manual. Es una rueda cilíndrica de metal fundido, que mediante la técnica de fresado está rodeada por dieciséis dientes paralelos entre sí. La superficie es ligeramente cóncava. En la zona del eje posee un orificio circular que atraviesa la pieza. La pieza fue elaborada en metal fundido y esmerilado. Se encuentran en el depósito distintos ejemplares que varían en tamaños y en cantidad de dientes.'
                },
                {
                    id: 'PC0223',
                    description: 'Pieza utilizada para trasmitir potencias de un componente a otro de manera manual. Es una rueda cilíndrica de metal fundido, que mediante la técnica de fresado está rodeada por dieciséis dientes paralelos entre sí. En la zona del eje posee un orificio circular que atraviesa la pieza. La pieza fue elaborada en metal fundido y esmerilado. '
                },
                {
                    id: 'PC0224',
                    description: 'Pieza utilizada para trasmitir potencias de un componente a otro de manera manual. Tiene forma cónica dentada ensanchándose al máximo en la zona de la corona y volviéndose más pequeña en el cubo. En la zona del eje posee un orificio circular que atraviesa la pieza. La superficie es ligeramente cóncava. Fue elaborada en metal esmerilado con técnica de fresado.'
                },
                {
                    id: 'PC0225',
                    description: 'Objeto empleado en líneas eléctricas de transmisión y distribución eléctrica. El objeto, en conjunto con los herrajes asociados, tiene la misión de soportar la línea eléctrica de las torres o postes de tensión, proporcionando al mismo tiempo la aislación eléctrica requerida. Está compuesto por tres piezas de aspecto cilíndricas cóncavas de diferentes tamaños, puestas una al interior de la otra y adheridas mediante una tuerca. Fue elaborado en porcelana y acero ensamblado y moldeado. Predomina el color café de la pieza, exceptuando la estructura interior color blanca. El objeto presenta un etiquetado que perdió su adhesión y que se encuentra fragmentado. '
                },
                {
                    id: 'PC0226',
                    description: 'Pieza fija de un motor utilizado, en conjunto a un rotor, para transmitir potencia o corriente eléctrica en una máquina. Su forma es circular, troquelada en su interior, formando uniones en las ranuras para alojar las bobinas. Fue fabricado con láminas de acero al silicio.'
                },
                {
                    id: 'PC0227',
                    description: 'Parte de la estructura del cabezal de perforación, utilizada específicamente para la unión de la barra de perforación con la corona de diamantina. Es de forma cilíndrica y ahuecada en el centro. Uno de sus extremos posee unión rosca y la otra unión lisa (también conocida como unión macho/hembra). Fue fabricado en metal por extrusión. El objeto posee un pliego de cartón con la impresión TRUCO, impreso en color azul y adherido por medio de cinta adhesiva café. '
                },
                {
                    id: 'PC0228',
                    description: 'Herramienta utilizada para aflojar o ajustar tanto tornillos como tuercas. Consiste en un objeto de confección manual compuesta por una tuerca ensamblada a un dado y soldada a un mango de tornillo. Fue elaborada de metal por medio de soldadura.'
                },
                {
                    id: 'PC0229',
                    description: 'Mecanismo utilizado para almacenar energía que se libera bruscamente al soltarse. La pieza está compuesta por un cuerpo de acero enrollado de forma helicoidal, adherido por un alambre, a una hoja o etiqueta de papel. Tiene inscrito en el papel “ARTICULO Resortes (...) Herschel dif medidas, LETRA RA, FOLIO 11, 41110-8".'
                },
                {
                    id: 'PC0230',
                    description: 'Objeto de seguridad utilizado para trabar una tuerca de fijación evitando la soltura. Es de forma circular con dieciocho dientes de acero troquelado. Posee faltante de uno de los dientes. '
                },
                {
                    id: 'PC0231',
                    description: 'Objeto de seguridad utilizado para trabar una tuerca de fijación evitando la soltura. Es de forma circular con dieciocho dientes de acero troquelado. Posee faltante de dos de los dientes y uno está completamente doblado al interior.'
                },
                {
                    id: 'PC0232',
                    description: 'Conjunto de elementos utilizados en las juntas de las cajas de transmisiones (y genéricamente en cualquier elemento hidráulico y/o neumático), para sellar y evitar fuga de lubricante en la unión de las caras mecanizadas de los elementos de cierre. El conjunto está compuesto por doce piezas planas de diferentes diseños y materiales, con orificios en los lados para facilitar el agarre. Entre los materiales que se destacan como parte de la elaboración de las distintas piezas, se observan fieltro, fibra de vidrio y metal blando, en distintos colores y tonos. '
                },
                {
                    id: 'PC0233',
                    description: 'Objeto utilizado para facilitar el cierre hermético de algunas superficies. Está formado por una pieza con forma de disco cóncavo con catorce dientes a su alrededor, dispuestos simétricamente. Posee un agujero circular en su centro. Fue elaborado en acero troquelado. '
                },
                {
                    id: 'PC0234',
                    description: 'Recipiente utilizado frecuentemente para el lavado del cuerpo en espacios domésticos. Es de forma circular, fondo plano y lados cóncavos. Fue elaborado en metal esmaltado de porcelana blanca con bordes negros. '
                },
                {
                    id: 'PC0235',
                    description: 'Parte del casco del Barco Don Edmundo, construido en Puerto Cristal con el nombre del primer administrador de la empresa minera. Se quemó en la bahía de Puerto Cristal y quedó solamente esa parte.'
                },
                {
                    id: 'PC0236',
                    description: 'Prenda utilizada para cubrir o tapar superficies u objetos. La pieza está compuesta por dos ejemplares rectangulares con un patrón simétrico impreso de flores moradas y líneas punteadas. El color base de ambos, tanto en su anverso como en su reverso, es de color crudo. '
                },
                {
                    id: 'PC0237',
                    description: 'Objeto utilizado para impedir el paso a través de un conducto u orificio. El objeto es cilíndrico ahuecado, con formas de hilo en el interior fabricado en hierro torneado y moldeado.'
                },
                {
                    id: 'PC0238',
                    description: 'Prenda de vestir usada principalmente como indumentaria deportiva. Es de manga larga y llega hasta la cintura. Su confección fue en base a tela sintética y en técnica de punto. Es de color blanco, con cuello negro, tres franjas negras en cada manga y puños del mismo color. En la parte posterior tiene estampado el número 7. Debido a sus características se asocia a un uso frecuente como camiseta de fútbol.'
                },
                {
                    id: 'PC0239',
                    description: 'Recipiente utilizado para contener y servir líquidos. Es de forma globular, seguido por un cuello corto y de menor diámetro, con un bisel que termina en un pico pronunciado con forma redondeada para facilitar verter el líquido interior. En el lateral opuesto posee un asa remachada que nace a un cuarto de su altura. La base es circular y plana. Fue completamente elaborado en metal enlozado blanco, exceptuando el cuello y el asa color azul. '
                },
                {
                    id: 'PC0240',
                    description: 'Recipiente utilizado para contener edemas en el hospital. Es de forma tubular, con una cara frontal plana. En su parte frontal superior posee una placa en forma de argolla sostenedora para enganche. En la parte posterior se encuentra el asa de color negro unida en sus extremos por remaches circulares. En la zona inferior tiene una salida de conexión. Fue completamente elaborado en metal enlozado blanco, exceptuando el cuello y el asa color azul. '
                },
                {
                    id: 'PC0241',
                    description: 'Recipiente utilizado para recoger heces y orina. Compuesto por una base ovalada con un extremo más angosto que el extremo opuesto. Su pared es cóncava y en sus bordes presenta una curvatura hacia el exterior. El extremo más delgado del óvalo tiene también menor profundidad que el más ancho. En su cara posterior tiene adherida un asa. El objeto fue elaborado en hierro soldado y fundido, con un esmaltado color blanco, exceptuando los bordes y el asa color negro. La mitad del objeto se encuentra cubierto por una placa de hierro enlozado de color blanco.'
                },
                {
                    id: 'PC0242',
                    description: 'Objeto utilizado para regular la entrada de aire de una válvula. Tiene forma circular con ranuras de forma ovoide. En el centro posee una ranura circular. Fue elaborado en acero troquelado. '
                },
                {
                    id: 'PC0243',
                    description: 'Recipiente utilizado para contener sustancias o elementos. Tiene forma cónica truncada, de base plana. Cercano a la base, el objeto presenta un anillo con curva hacia el exterior, y cercano a la zona superior, un anillo con curva hacia el interior. Tiene dos ganchos en la zona superior, probablemente con la finalidad de adjuntar un asa a ellos. El balde fue elaborado con hierro enlozado blanco, con bordes negros. '
                },
                {
                    id: 'PC0244',
                    description: 'Herramienta utilizada para sostener, mover o sujetar los crisoles en el laboratorio. Se clasifica como una pinza con forma de tenaza, fabricada con técnica de moldeado y compuesta en su mayoría por acero. El mango es de plástico, con tonos amarillentos, característica que permite facilitar el agarre.'
                },
                {
                    id: 'PC0245',
                    description: 'Recipiente utilizado para contener diferentes sustancias líquidas o sólidas. Es de forma cilíndrica y boca ancha con hilos de rosca. Fabricado en vidrio de color verde. En la parte externa del cuerpo se observan restos de papel rasgado adherido al envase.'
                },
                {
                    id: 'PC0246',
                    description: 'Bastón utilizado como apoyo para las personas con dificultades para sostenerse o caminar. Está compuesto por dos palos cilíndricos que forman una estructura triangular y que se unen en la parte inferior por una pieza de acero. La parte superior posee una pieza horizontal para apoyar el peso del cuerpo y entre los dos palos verticales existe una unión también horizontal para estabilizar el bastón. La pieza fue tallada en madera de coligüe. Posee desgaste por uso. '
                },
                {
                    id: 'PC0247',
                    description: 'Conjunto de elementos utilizados en las juntas de las cajas de transmisiones (y genéricamente en cualquier elemento hidráulico y/o neumático), para sellar y evitar fuga de lubricante en la unión de las caras mecanizadas de los elementos de cierre. El conjunto está compuesto por ocho piezas planas de diferentes diseños y materiales, con orificios en los lados para facilitar el agarre. Entre los materiales que se destacan como parte de la elaboración de las distintas piezas, se observan fieltro, fibra de vidrio y metal blando, en distintos colores y tonos. '
                },
                {
                    id: 'PC0248',
                    description: 'Recipiente utilizado generalmente en la posta para retener, contener o recoger excrementos u orinas de pacientes. La forma de su base es ovalada con un extremo más angosto que el extremo opuesto. El extremo más delgado del ovalo tiene menor profundidad que el más ancho. Su pared es cóncava y en sus bordes presenta una curvatura hacia el exterior. La bacinica fue elaborada de hierro enlozado, y casi en su totalidad de color blanco, exceptuando un asa de color azul adherida en una de sus caras posteriores mediante soldadura. '
                },
                {
                    id: 'PC0249',
                    description: 'Recipiente utilizado para la cocción de alimentos o para hervir agua. Posee un cuerpo cilíndrico y base plana y tiene un asa metálica superior. Fue elaborado en metal ensamblado color azul con bordes negros.'
                },
                {
                    id: 'PC0250',
                    description: 'Bolsa utilizada en la espalda para el transporte de objetos o provisiones. Es de forma rectangular ahuecada y tapa ajustable, con sistema de amarre frontal por correas de tela. En las esquinas de la base se observan refuerzos de cuero de forma rectangular y en el reverso dos correas de ajuste para la espalda. Fue confeccionado en cuero grueso de color café.'
                }
            ]
            let arr6 = [{
                    id: 'PC0251',
                    description: 'Pieza de cubertería utilizada como parte del servicio de mesa. Tiene forma ovalada en un extremo y un mango plano. El mango se curva en la unión con el óvalo y se ensancha hacia el otro extremo a modo de ornamento. Fue elaborada completamente en metal forjado y bordes fileteados. En el depósito se encuentran dos ejemplares que difieren en el tipo de diseño del mango. '
                },
                {
                    id: 'PC0252',
                    description: 'Herramienta manual de carpintería, utilizada para cepillar la madera. Se constituye por un cuerpo paralelepípedo en cuyo centro posee una abertura donde se coloca el hierro y la cuña. La abertura es de boca ancha en su parte superior y termina en una ranura más angosta, la que se encuentra inclinada a 45º en diagonal. En el costado de la pieza se encuentra el mango de madera que sobresale del cuerpo principal hacia arriba. La pieza fue tallada de manera manual. El objeto está incompleto, faltando el hierro y el asa de la pieza.'
                },
                {
                    id: 'PC0253',
                    description: 'Objeto utilizado para llevar un registro documentado de anotaciones. Está compuesto por tres partes: una pieza rectangular plana con bordes redondeados, una pinza trapezoide con bucle en los extremos a modo de ornamento que finaliza en forma circular y un conjunto de hojas (tres en total) posiblemente de cuaderno espiral rayado A4. La superficie más rígida fue elaborada en madera, la pinza en metal y las hojas en papel. Además, se encontró, como parte del conjunto, un alambre envuelto en plástico blanco con tonalidad amarillenta y una pieza de cartón de menor tamaño que las hojas, con dos orificios al mismo lado, y con escritos en lápiz pasta, posiblemente anotaciones de cantidades (Se alcanza a leer 13 Kgs X MIN = 10 TONH TURNO=). '
                },
                {
                    id: 'PC0254',
                    description: 'Objeto utilizado para esquilar ovejas, compuesto por dos hojas metálicas triangulares enfrentadas en sus filos. Ambas hojas se adhieren a un brazo metálico. Ambos brazos son rematados en una curva y se unen entre ellos mediante remaches, formando dos lóbulos. En la cara superior de la hoja izquierda se puede observar la inscripción "cast steel ward sheffield england" y en la hoja derecha la punta quebrada. En la zona de los brazos se observa un cordel blanco utilizado como amarre.'
                },
                {
                    id: 'PC0255',
                    description: 'Herramienta manual de carpintería utilizada para cepillar la madera. Tiene forma de paralelepípedo en cuyo centro posee una abertura donde se coloca el hierro y la cuña mediante un tornillo mariposa y golillas. La abertura es de boca ancha en su parte superior y termina en una ranura más angosta, la que se encuentra inclinada a 45º en diagonal. En sus extremos laterales posee mangos de agarre que sobresalen del cuerpo principal hacia arriba. El objeto fue elaborado principalmente en metal troquelado, con ensambles de madera y plástico negro para los mangos.'
                },
                {
                    id: 'PC0256',
                    description: 'Prenda de vestir para la cabeza utilizada como protección contra la lluvia. Consiste en una copa cóncava y un ala o visera que rodea el borde, siendo la misma más prominente en su zona posterior. Posee un cordel de amarre que va de un lateral al otro. Fue confeccionado en plástico con tonalidades grises. El sombrero se encuentra completamente rígido por lo que probablemente podría haber estado en situaciones ambientales adversas.'
                },
                {
                    id: 'PC0257',
                    description: 'Elemento esférico utilizado dentro del molino de bolas durante el proceso de molienda, donde se producía la reducción de tamaño del mineral mediante el principio de impacto y fricción hasta llegar al tamaño adecuado para poder efectuar el proceso de flotación. Se constituye por un juego de esferas de acero. Se encuentran múltiples ejemplares, tanto en el depósito como en la planta de procesos variando en pesos y diámetros. '
                },
                {
                    id: 'PC0258',
                    description: 'Herramienta utilizada para generar cortes. Es de forma rectangular. Su parte inferior tiene una reducción del grosor hasta quedar con un filo de corte, y la parte opuesta culmina con el mismo grosor pero que finaliza de manera más trapezoide con un orificio en el centro. Elaborado en metal fundido y troquelado. El objeto por sí solo no tiene mayor uso, sin embargo, es parte del conjunto del cepillo de carpintería manual, siendo éste el encargado de cortar y dar forma a la madera.'
                },
                {
                    id: 'PC0259',
                    description: 'Recipientes utilizados para contener o guardar distintos elementos. La pieza está compuesta por dos elementos independientes entre sí, pero con características similares. Ambos elementos están conformados por un cuerpo cilíndrico, forrado artesanalmente en todo su lateral con papel blanco y con etiqueta adhesiva color marrón dónde indica nombre de un medicamento. No poseen tapa. Fue elaborado en metal ensamblado. Las inscripciones de las etiquetas son: 1. Tarros grandes: HIDROCLOROTIAZIDA, FENITOINA SÓDICA 100 mg, AMINOFILINA 100 mg TAB, PARACETAMOL, FURAZOLIDONA 100mg, CO-TRIMOXAZOL, CLOROPROPAMIDA, AMPICILINA CM, FUROSEMITA TAB, PROPANOLOL 40 mg TAB, ALUMINIO HIDROXIDO TAB. 2. Tarros chicos: ACIDO ASCORBICO, TOLBUTAMIDA 500 mg, METHERGIN, PIRIDOXINA, AMOXICILINA 250 mg CPS, ASPIRINA, ACETAZOLAMIDA 250 mg, TIAMIDA, LIOTIRONINNA 5mg, INDOMETACINA, ADRENALINA, METILDOPA 250 mg. 3. Etiqueta suelta: PAPAVERINA.'
                },
                {
                    id: 'PC0260',
                    description: 'Herramienta utilizada para asegurar la unión y transmitir fuerza entre la biela y el pistón. El objeto está compuesto por una forma cilíndrica con un acabado de rosca o hilo, y una pieza rectangular afirmada por otra hexagonal adherida en la parte superior. La herramienta no presenta tuerca de apriete. El objeto fue fabricado en acero. '
                },
                {
                    id: 'PC0261',
                    description: 'Herramienta utilizada como parte de un conjunto de corte para el roscado manual de pernos y tornillo. El objeto es cilíndrico de borde biselado. En la cara externa presenta cuatro incisiones circulares equidistantes y una ranura. La parte central de su cara superior se encuentra atravesada por una figura pentagonal de puntas circulares. Fue elaborado en acero, con una inscripción grabada "5/8 11 BSW".'
                },
                {
                    id: 'PC0262',
                    description: 'Conjunto de herramientas manuales utilizado para apretar elementos atornillados mediante tuercas. El conjunto se compone de una superficie rectangular de madera que funciona como soporte y que contiene ocho orificios, cuatro a cada lado, donde se insertan las tuercas de diferentes formas y diámetros. Posee en el centro la herramienta metálica de acoplamiento, con el mango hacia abajo. De las ocho tuercas se conservan solo siete. El objeto no cuenta con tapa. '
                },
                {
                    id: 'PC0263',
                    description: 'Pieza utilizada para contener o guardar objetos. Su forma es rectangular, compuesta por cinco tablas unidas en sus bordes por medio de clavos. Fue confeccionado de manera manual en madera. Su cara frontal tiene escrito con tinta negra “remaches 1/4 x 2/4 " y con plumón rojo se alcanza a divisar en la misma cara “C-221 Clavos". En el interior contiene gran cantidad de remaches de distintos tamaños.'
                },
                {
                    id: 'PC0264',
                    description: 'Pieza utilizada para contener o guardar objetos. Su forma es rectangular, compuesta cuatro comportamientos: dos de ellos tienen las mismas medidas y se encuentran en cada esquina, y los que se encuentran ubicados en el medio son de menor tamaño, iguales entre sí y ubicados uno sobre otro. En dos de sus bordes opuestos se encuentran adheridas piezas de metal plana, plegadas en sí misma, posiblemente para sostener la caja o guardarla con mayor facilidad. No posee tapa. Fue confeccionado en metal y madera ensamblado. En el interior se puede observar que contiene gran cantidad de remaches de distintos tamaños. En los compartimientos se encuentran diversas herramientas y repuestos tales como golillas, remaches, tornillos, tuercas resortes, entre otros.'
                },
                {
                    id: 'PC0265',
                    description: 'Objeto utilizado para contener o transportar diferentes materiales. El recipiente es cilíndrico y alargado de acero, en su parte superior tiene una huincha metálica unida con pernos utilizada como asa. Debido a la composición del metal se observan manchas producidas por el óxido. El borde superior presenta irregularidades en su circunferencia posiblemente ocasionadas por golpes del uso cotidiano.'
                },
                {
                    id: 'PC0266',
                    description: 'Objeto utilizado para contener diferentes materiales. La pieza es rectangular y fue elaborado en madera tallada y ensamblada. En su interior se observan artículos de menor tamaño: dos limas con mango de madera de confección manual, tres clavos industriales y un sacabocados (herramienta de talabartería). El objeto tiene una inscripción en una cara lateral: AVISAR: FONO 22700 y en la otra cara lateral: Puerto-Aysén.'
                },
                {
                    id: 'PC0267',
                    description: 'Recipiente utilizado para contener diferentes sustancias líquidas o solidas. Tiene forma cilíndrica y boca ancha con hilos de rosca, fabricado en vidrio de color café. En la base se encuentra una inscripción de fábrica que dice 150cc 28c90.'
                },
                {
                    id: 'PC0268',
                    description: 'Objeto utilizado para aumentar y difundir las ondas sonoras, especialmente de la voz, para transmitir un mensaje a mayor distancia. Tiene forma de cono con bordes irregulares y se encuentra abierto por la parte superior y por la parte inferior con aberturas de diferentes diámetros. Fue confeccionado de forma manual en base a acero moldeado por compresión y unido mediante soldadura. Perteneciente a Carabineros de Chile, se identifica la pintura color verde característica de la institución.'
                },
                {
                    id: 'PC0269',
                    description: 'Objeto utilizado para evitar la entrada de materiales o químicos en los ojos. Se compone de una pieza frontal que cubre los ojos, ensamblada a los protectores laterales o patillas que son ajustables. Fue elaborado completamente en plástico, moldeado por compresión. La pieza es transparente. '
                },
                {
                    id: 'PC0270',
                    description: 'Recipiente utilizado para contener cenizas y recoger colillas de cigarros. El objeto fue compuesto de una sola pieza con forma octagonal, cóncavo en el centro, con cuatro ranuras cilíndricas en sus esquinas. La base es plana y presenta manchas color rojo. En el centro pose la forma de un círculo desgastado con manchas amarillas, en su mayoría circulares u ovaladas. Está elaborado a partir de una piedra pulida. '
                },
                {
                    id: 'PC0271',
                    description: 'Objeto de seguridad usado para sustentar linieros cómodamente mientras trabajan en postes de luz de gran altura y proporciona aditamentos para guardar herramientas necesarias. Está compuesto por dos anillos tipo D con círculo de acero forjado para colocarse en las correas para postes, también tiene hebillas forjadas tipo mordaza, más 4 presillas para herramientas soporte de cinta fibrosa, anillos para guantes, cierre tipo navaja y arnés de soporte con anillos tipo D; construcción dieléctrica .correas y hebillas unidas mediante mosquetones y remaches. El objeto está elaborado en cuero marrón con hebillas metálicas.'
                },
                {
                    id: 'PC0272',
                    description: 'Objeto utilizado para encerrar o transportar animales, específicamente pájaros. Está compuesto por ocho varillas. En la parte superior posee un botón soldado, que a su vez sujetaría un gancho, posiblemente para colgar el objeto a algún tipo de superficie sobre el suelo. Para mantener la forma cilíndrica presenta tres varillas adicionales dispuestas horizontalmente en forma circular, la primera en la base, y las otras dos de menor tamaño inscriben y le dan la forma circular al objeto. La segunda varilla horizontal está quebrada y despegada de uno de sus lados, en el otro presenta un alambreado en su esquina. La última varilla horizontal, que podría considerarse como la base del objeto no es circular completa si no que termina con cada esquina modo de gancho vuelto hacia abajo. Fue elaborado completamente en acero ensamblado con terminaciones soldadas.'
                },
                {
                    id: 'PC0273',
                    description: 'Accesorio utilizado en los pies, a modo de seguridad, para evitar accidentes laborales en lo que respecta a cableados de postes de luz. Se compone de dos garfios metálicos que se sujetan a los pies mediante una plantilla metálica y una correa de tela con hebillas, que puede ser ajustada a distintos tamaños de extremidades. La pieza completa se percibe en tonos marrón.'
                },
                {
                    id: 'PC0274',
                    description: 'Utensilio de cocina utilizado para freír, cocer o saltear diferentes alimentos. Posee una base curva cóncava y un mango compuesto por una plancha doblada en sí misma, generando un borde más bien cilíndrico y ahuecado en el centro. El final del mango pose un orificio oval. El objeto fue completamente elaborado en metal vaciado. Posiblemente su forma actual fue adoptada por desgastes por uso. '
                },
                {
                    id: 'PC0275',
                    description: 'Conjunto de elementos destinados a grabar y reproducir sonidos en cinta magnética. Compuesto por siete cintas magnetofónicas de forma circular, guardadas en sus respectivas cajas. Las cajas son mayoritariamente blancas, con franjas, laterales y detalles rojos y negros. En la parte superior se encuentra una etiqueta de papel adherida por medio de cinta adhesiva. Las cajas de cuatro de las unidades, de iguales características, presentan la siguiente información: Audiotape Formula 10 All purpose Standard Recording 1200 Ft. OF 1 1/2 Mil Acetate (todo propósito 1200ft de 1 1/2 mil cinta de grabación de acetato). Las cajas de tres de las unidades de iguales características presentan la siguiente información: VOICE OF AMERICA, United States of America, this recording is the property of the United States of America. Dos de las unidades de VOICE OF AMERICA presentan etiqueta pegada con las siguientes inscripciones: 1. RADIO: M DIVINA PROVIDENCIA, CIUDAD: PUERTO CRISTAL, PROGRAMA: ENFOQUE MUNDIAL, SALIDA: SEP 26 1975. 2. RADIO: M DIVINA P, CIUDAD: PUERTO CRISTAL, PROGRAMA: DISCOTECA 50, SALIDA: 28 NOV 1975.'
                },
                {
                    id: 'PC0276',
                    description: 'Utensilio utilizado frecuentemente para calentar, freír o asar alimentos. Es de forma circular cóncava poco profunda, con dos asas metálicas adheridas a cada costado y unidas por clavillos. El objeto fue fabricado en metal forjado. Aunque desgastada, se mantiene parte del color negro de las asas.'
                },
                {
                    id: 'PC0277',
                    description: 'Recipiente para contener y/o almacenar distintos materiales. Tiene forma circular, poco profundo, con fondo plano y lados cóncavos elaborado en metal forjado, sin pintar ni esmaltar. Se asocia a un plato sopero debido a que sus lados presentan una altura mayor a la regular.'
                },
                {
                    id: 'PC0278',
                    description: 'Recipiente o fuente para contener o almacenar distintos objetos o líquidos. Tiene forma circular, fondo plano y lados cóncavos. De materialidad metálica con esmaltado de porcelana verde con el borde azul.'
                },
                {
                    id: 'PC0279',
                    description: 'Objeto facilitador de comunicación a distancia, de uso cotidiano. Está compuesto por dos piezas principales conectadas a través de un cable en forma de espiral. El elemento inferior es de base rectangular y contiene un marcador numérico circular. El elemento superior, o auricular, es una pieza alargada que contiene el receptor de sonido y el micrófono. El cable de alimentación a la corriente está enrollado y amarrado con cinta aislante negra. El objeto fue elaborado de plástico ensamblado de color gris claro. El aparato es de la marca Siemens.'
                },
                {
                    id: 'PC0280',
                    description: 'Objeto utilizado para impermeabilizar cierto tipo de calzados. Está compuesto por un cuerpo cilíndrico de base plana, una superficie a modo de tapa también circular y plana con un sistema de seguridad de cierre y apertura en su borde superior derecho. Fue elaborado completamente en metal moldeado con diferentes impresiones. La parte inferior es color plateado y en la superior predomina el color negro. Posee diferentes inscripciones blancas y rojas donde se observan las palabras “VIRGINIA", “UNA PASADITA Y BASTA" y “NEGRO" tanto en la superficie superior como en los laterales.'
                },
                {
                    id: 'PC0281',
                    description: 'Prenda de vestir de infante utilizada para cubrir los pies. Está compuesta por dos objetos independientes entre sí, pero unidos mediante un hilo plástico. El primer zapato posee en la parte alta tres agujeros de acero para pasar un cordón y costura de felpa en la recubierta superior. El otro zapato es semiabierto con cierre de velcro, un lazo en su parte delantera y agujeros en la punta. Ambos fueron confeccionados en plástico cocido color blanco y corresponden a ejemplares derechos. El zapato con ojal presenta perdida de la punta.'
                },
                {
                    id: 'PC0282',
                    description: 'Objeto utilizado para iluminar mediante una proyección de luz. Es de forma circular, con una estructura transparente de vidrio en la cara frontal y protegida en todo el borde. En la parte posterior se observa una estructura rectangular metálica adherida por una tuerca y que funcionaría como una rendija para poder sujetar la linterna a un casco minero o una batería. Presenta, Además, tres orificios de distintos tamaños, uno en la parte superior y dos a cada uno de sus lados. No tiene ampolleta. El objeto fue elaborado en plástico ensamblado, predomina el color negro excepto por los bordes azules del aro.'
                },
                {
                    id: 'PC0283',
                    description: 'Envase de uso primario para productos farmacéuticos. Es de forma cilíndrica con cuello estrecho, que se encuentra cerrado herméticamente. Está elaborado en vidrio soplado, con una impresión del nombre específico del medicamento que contiene. Es de color ocre oscuro. '
                },
                {
                    id: 'PC0284',
                    description: 'Estructura utilizada para arreglar, organizar, regular u ordenar diferentes objetos. Es de forma rectangular compuesto por 9 compartimientos, distribuidos simétricamente en filas de tres. Fue elaborado en madera ensamblado sin pintar. Posee trozos pequeños de cinta adhesiva en distintos sectores y, en cuatro de los compartimientos (primero de la primera fila y en los tres del centro), se observa un etiquetado adherido con cinta adhesiva que, si bien no se observa completamente a qué hace alusión, se alcanza a ver que poseen un código distinto cada uno de ellos.'
                },
                {
                    id: 'PC0285',
                    description: 'Prenda de vestir de infante utilizada para cubrir los pies. El objeto se compone por una puntera, un empeine, una lengüeta con velcro y una suela. Posee un parche cosido en la lengüeta con un dibujo infantil de dos conejos y una zanahoria. El objeto fue confeccionado en plástico, principalmente rojo y la suela posee un diseño antideslizante blanco. En la zona interior de la plantilla se encuentra la inscripción de la marca Ficcus. En el depósito solo se encuentra la unidad derecha.'
                },
                {
                    id: 'PC0286',
                    description: 'Objeto utilizado como parte del servicio de mesa para servir alimentos. Es de forma circular ligeramente cóncava y un pie de apoyo también circular. Fue elaborado en cerámica por medio de moldeado en barbotina y esmaltado. Su color predominante es el amarillo.'
                },
                {
                    id: 'PC0287',
                    description: 'Recipiente utilizado para guardar o contener objetos. Tiene forma circular, ligeramente cóncavo con bordes tallados. Fue elaborado en madera por medio de torneado y, en su parte superior, lleva escrito manualmente "Temuco". En la superficie interna se observa un diseño de copihues y otras plantas. '
                },
                {
                    id: 'PC0288',
                    description: 'Recipiente utilizado para contener diferentes sustancias líquidas o solidas. Es de forma cilíndrica y boca ancha con hilos de rosca. En la superficie tiene una tapa blanca de lata. Fue elaborado en vidrio transparente moldeado por compresión. La mitad inferior del cuerpo de vidrio se encuentra teñido de color oscuro, posiblemente debido al contenido que tuvo.'
                },
                {
                    id: 'PC0289',
                    description: 'Objeto utilizado como medio de acreditar o registrar. Es de forma rectangular, con líneas horizontales. Fue elaborado en papel y posee en la parte superior una impresión "Ministerio de salud, servicio de salud" y, en la misma posición en el centro, "Historia y Evolución Clínica". Bajo éstos se observa un listado escrito de manera manual con los nombres de los medicamentos y cantidad. En el reverso se puede ver escrito manualmente "Medicamento a Cristal". '
                },
                {
                    id: 'PC0290',
                    description: 'Recipiente utilizado en la cocina para la preparación de alimentos. Tiene forma cilíndrica ahuecada con base plana. Presenta dos asas a los lados, paralelas entre sí y unidas por clavillos. Fue elaborado en metal forjado. '
                },
                {
                    id: 'PC0291',
                    description: 'Objeto utilizado para direccionar la luz de una lámpara. Tiene forma de cono truncado. En la parte superior posee una forma de cilindro con un agujero a cada lado. Fue elaborado en hierro esmaltado, la parte exterior es negra y la interior blanca.'
                },
                {
                    id: 'PC0292',
                    description: 'Recipiente utilizado para contener diferentes sustancias líquidas o solidas. Tiene forma cilíndrica y boca ancha con hilos de rosca. En la parte superior tiene una tapa dentada. Fue elaborado en plástico moldeado y es completamente blanco. '
                },
                {
                    id: 'PC0293',
                    description: 'Utensilio utilizado para alisar arrugas o pliegues de la ropa mediante el uso de calor. El objeto tiene forma volumétrica de base triangular con una perilla circular y móvil en la parte superior. Además, posee un asa en la cara posterior. Fue elaborado en acero moldeado por compresión y plástico negro. En su cara posterior posee una inscripción no legible.'
                },
                {
                    id: 'PC0294',
                    description: 'Objeto utilizado para contener o transportar diferentes materiales. Es de forma cilíndrica y alargada que se va ensanchando en la superficie. En su parte superior tiene una huincha metálica que termina en forma de espiral, unida con pernos utilizada como asa. Fue elaborada completamente en acero moldeado por compresión y fundición. El asa, para evitar desprendimientos, mantiene terminaciones circulares.'
                },
                {
                    id: 'PC0295',
                    description: 'Objeto utilizado para contener o transportar diferentes materiales. Es de forma cilíndrica y alargada. En la parte superior del objeto se observan tres orificios, y de aquellos que están en posición contraria se ata un asa metálica con sus esquinas retorcidas para evitar que se salgan y faciliten, de este modo, el traslado del objeto. Fue elaborada en confección artesanal completamente en acero moldeado por compresión y fundición.'
                },
                {
                    id: 'PC0296',
                    description: 'Concreciones minerales utilizada como muestra de material. Se compone por tres fragmentos de forma irregular, posiblemente pirita, y que difieren en tamaños. '
                },
                {
                    id: 'PC0297',
                    description: 'Material utilizado para curar o vendar heridas. El objeto es plano de forma rectangular conformado por dos partes adheridas en sus bordes para permitir el correcto sellado; contiene una gasa. La pieza es de papel plastificado y principalmente de color blanco. En la cara superior se observa la información impresa en color verde, destacando la marca KENDALL.'
                },
                {
                    id: 'PC0298',
                    description: 'Recipiente utilizado para contener o guardar elementos. Tiene forma de cubo, sin tapa. Posee dos tornillos por lado para el ensamblaje, ubicado en cada esquina y dispuesto de manera vertical el uno del otro. Fue elaborado de manera artesanal en madera ensamblada. En dos de sus caras opuestas se encuentra a lápiz escrito el número 792,2. En su interior se observan dos repuestos de filtro en sus respectivas bolsas con la impresión de “NorseG" En otra de sus caras se puede observar derrame de pintura celeste.'
                },
                {
                    id: 'PC0299',
                    description: 'Recipiente utilizado para contener o guardar elementos. Tiene forma rectangular, compuesto por una base en cuya cara frontal tiene un asa y dos enganches a cada lado. La cara superior es una tapa móvil que se encuentra unida por bisagras a la base. Fue elaborado en metal moldeado por compresión y pintado completamente en color verde. '
                },
                {
                    id: 'PC0300',
                    description: 'Objeto utilizado para la separación y limpieza de diferentes minerales mediante movimientos circulares y decantación, con forma cónica y terminación en punta. Fue elaborada en metal laminado con terminaciones soldadas. '
                }
            ]

            let arr7 = [{
                    id: 'PC0301',
                    description: 'Objetos utilizados como medio de cambio en transacciones económicas. El conjunto está elaborado en metal y corresponde a una colección de veinte monedas de Chile de distintos tañamos, valores e inscripciones y una arandela metálica. El conjunto fue encontrado en la casa de Cristóbal Alvarado. Todas las monedas se encuentran actualmente fuera de circulación.'
                },
                {
                    id: 'PC0302',
                    description: 'Recipiente utilizado para contener objetos. Está compuesto por una sola lámina doblada por los bordes y sin tapa. Las uniones se realizaron mediante pestañas de la misma placa, al igual que el doblez de los bordes con el fin de proteger de los filos. Presenta dos asas, en cada uno de sus lados de menor tamaño adheridas por soldadura. El objeto fue elaborado en metal laminado. '
                },
                {
                    id: 'PC0303',
                    description: 'Contrapeso utilizado para ser colgado en una balanza por medio de una ranura. Es de forma circular fabricada en acero mediante moldeado. En la cara superior contiene una inscripción: 60K; en la cara inferior tiene catorce incisiones circulares.'
                },
                {
                    id: 'PC0304',
                    description: 'Objeto proveniente de restos de aluminio. Diminutas hojas delgadas, posiblemente provenientes de herramientas de labrar, con forma de espiral. Se encuentra embalado con plástico burbuja. '
                },
                {
                    id: 'PC0305',
                    description: 'Objeto utilizado para apagar o disminuir fuego o incendios de pequeña magnitud. Es de forma cilíndrica, de base levemente plana, en cuya parte superior sobresale un volumen. Fue elaborado en vidrio soplado y plástico. En su interior contiene líquido y en su cara exterior una etiqueta adherida con la inscripción "Ruda M.R. Soc. Extinguid. de Incendio Ruda LTDA. Copiapó 766 - Fono 36455 CAS. 13274 - STGO".'
                },
                {
                    id: 'PC0306',
                    description: 'Objeto mecánico utilizado para resolver y/o efectuar diversos cálculos del área de la aritmética. Es completamente automática con un teclado multiplicador auxiliar de 10 teclas. El carro, móvil a izquierda y derecha, consta de dos filas donde se muestran dígitos. La fila superior (acumulador) puede acumular resultados de hasta veinte dígitos y tiene sobre cada dígito diales para ajustar manualmente los valores en operaciones de redondeo. La fila inferior (contador) es el registro de los elementos utilizados cuando se realizaban las operaciones (sumas, restas, multiplicaciones y divisiones) teniendo capacidad para once dígitos, bajo esta fila se encuentran los botones para insertar decimales. A la derecha del carro se encuentran los deslizadores, controles que permiten borrar los registros del acumulador y del contador. Los dígitos de las cifras para el cálculo se ingresaban de forma paralela en las distintas columnas del teclado principal, el cero se ingresaba pulsando la tecla NO de cada columna. El teclado multiplicador con capacidad para cifras de diez dígitos se utilizaba para las operaciones de multiplicación. Su construcción fue basada en el ensamble de piezas tanto plásticas como metálicas, con tonos y detalles grises blancos y cafés. El objeto es de grandes dimensiones y bastante pesado.'
                },
                {
                    id: 'PC0307',
                    description: 'Mueble utilizado frecuentemente en trabajos u oficinas con el fin de leer, escribir o utilizar cómodamente una computadora sobre él. Tiene forma rectangular, con una tabla de base en la superficie para facilitar el apoyo y, bajo esta, se despliegan dos cajones, de los cuales solo uno presenta manilla. Fue elaborado en madera ensamblada y esmaltada en tonos rojos. '
                },
                {
                    id: 'PC0308',
                    description: 'Objeto utilizado para trasladar a heridos desde la Mina hasta la posta. Predomina la forma rectangular, con cuatro fierros puestos de forma vertical, y tres en horizontal que dan la forma y firmeza. Posee, además, en toda la estructura una malla de alambre que proporcionaba mayor estabilidad para transportar. Fue elaborado en acero y hierro.'
                },
                {
                    id: 'PC0309',
                    description: 'Mueble utilizado como estante de exhibición y suministro de revistas, libros y documentos. Predomina la forma rectangular, con siete compartimientos en sentido vertical hacia la parte superior de la mesa y uno extendido en su horizontal hacia la parte inferior del mueble. Fue elaborado completamente en madera tallada y ensamblada. El esmaltado es de color celeste. '
                },
                {
                    id: 'PC0310',
                    description: 'Mueble utilizado para realizar breves descansos. Predomina la forma rectangular y se conforma por dos tablas con forma trapezoidal que tapan la parte superior de las patas del objeto. El objeto fue confeccionado de manera manual con madera ensamblada y es de color azul. Las patas traseras tienen un sacado en forma de semicírculo en la parte central inferior.'
                },
                {
                    id: 'PC0311',
                    description: 'Recipientes utilizados para almacenar líquidos. Poseen cuello angosto, pero cuerpo abultado, que permiten servir los líquidos interiores de manera cómoda y segura. Fueron elaboradas en vidrio soplado de color verde y café y difieren en los diseños ornamentales.'
                },
                {
                    id: 'PC0312',
                    description: 'Objeto utilizado para subir o bajar distintos niveles de una superficie. Está compuesta por tres peldaños irregulares entre sí, al igual que los cantos. Los peldaños están conectados a los montantes mediante ensamblado con clavos metálicos. Fue elaborado manualmente con madera tallada y ensamblada.'
                },
                {
                    id: 'PC0313',
                    description: 'Sistema de iluminación individual para mineros de metales y del carbón. Está compuesto por dos piezas fundamentales. Por un lado, una linterna de enfoque ajustable cuya forma es principalmente circular, con una estructura transparente de vidrio en la cara frontal y protegida en todo el borde, y por otra parte la batería de forma rectangular con un refuerzo en la base y una especie de tapa en la parte superior, desde la cual se observa un cableado que une ambas piezas. Posee una inscripción de color rojo, donde se divisa una serie numérica “1403003057". El objeto fue elaborado plástico de policarbonato moldeado y predomina el color negro.'
                },
                {
                    id: 'PC0314',
                    description: 'Recipiente utilizado para contener líquidos. Está compuesto por un cuerpo de vidrio verde. El cuello es estrecho y presenta un pliegue generado por el mismo material hacia afuera, que le proporciona un borde propio que lo diferencia de otras botellas. La base presenta una cavidad convexa. No posee tapa. Fue elaborado en vidrio soplado. En uno de sus laterales presenta una mancha, al parecer desde dentro del objeto de color amarillento.'
                },
                {
                    id: 'PC0315',
                    description: 'Objeto utilizado para aumentar y difundir las ondas sonoras, especialmente de la voz, para transmitir un mensaje a mayor distancia. Tiene forma de cono con bordes irregulares y se encuentra abierto por la parte superior y por la parte inferior con aberturas de diferentes diámetros. Posee un asa rectangular adherida a uno de sus laterales. Fue confeccionado en base a acero moldeado por compresión y unido mediante soldadura. Predomina el color blanco con craquelados de pintura. '
                },
                {
                    id: 'PC0316',
                    description: 'Objeto utilizado para prolongar o conectar líneas de transmisión de distintos ejes y, de esta forma, transmitir correctamente energía. Es de forma circular, a modo de anillo. En su cara superior presenta un volumen más prominente rodeando el orificio central y contiene doce orificios en su cara superior, al igual que en el borde exterior. En la parte posterior se observa el negativo de la parte superior. Fue elaborado en plástico vaciado color negro. '
                },
                {
                    id: 'PC0317',
                    description: 'Instrumento utilizado para generar peso en una balanza antigua tipo romana. Está compuesto por tres elementos: una varilla cilíndrica que culmina con un garfio, facilitando la posibilidad de ser colgado, una pieza de base plana y bordes circulares clavada en la superficie y un contrapeso, móvil, hexagonal, con una inscripción no legible. Fue elaborada en acero moldeado. A diferencia de los contrapesos que existen en el catálogo, este no posee rendija para extraerlo.'
                },
                {
                    id: 'PC0318',
                    description: 'Objeto utilizado en oficinas para mantener, organizar y planificar espacios, papeles o proyectos. El objeto es rectangular, tres de sus caras están fabricadas con tablas de madera de una pieza y, la parte frontal, también de madera, presenta formas de rejilla cuadrada, posiblemente para colocar planos enrollados. Cada una de las celdas de la rejilla presenta una etiqueta de papel adherida ya sea por corchetes o cinta aislante café. Los textos de las etiquetas están escritos a mano y dan la indicación del plano que contenían.'
                },
                {
                    id: 'PC0319',
                    description: 'Objeto utilizado para aumentar y difundir las ondas sonoras, especialmente de la voz, para transmitir un mensaje a mayor distancia. Tiene forma de cono abierto en uno de sus extremos, el otro extremo, de menor tamaño, tiene un pliegue hacia adentro, también en forma de cono, que culmina con una especie de tapón. En la cara posterior se observa una pieza metálica rígida, a modo de soporte y adjunta por medio de dos tuercas, una a cada lado, generando un equilibro incluso para que el objeto se mantenga de pie. Fue elaborado en metal moldeado por compresión, predomina el color gris, excepto el tapón interior color negro, con una inscripción en letras blancas donde se observa la palabra “LEEA". '
                },
                {
                    id: 'PC0320',
                    description: 'Objeto utilizado para contener o transportar objetos, en este caso tres granadas contra incendios. Es de forma cilíndrica compuesto por dos mitades unidas por bisagras en su borde lateral izquierdo y en su lado derecho por un sistema de ajuste por contacto. En el interior posee tres franjas curvas divisoras. Fue elaborado en acero moldeado, color gris por fuera y violeta en el interior. Se observa la inscripción de "Ruda Soc. Extinguid. De Incendio Ruda LTDA Copiapó 766 - Fono 36455 CAS. 13274 - STGO". Se conservan las tres granadas en el depósito.'
                },
                {
                    id: 'PC0321',
                    description: 'Objeto utilizado para triturar minerales y rocas. Es de tipo Blake, de base rectangular. Presenta una placa fija recta y otra placa móvil cóncava, ambas placas son rugosas. En su parte superior posee un soporte que sostiene un eje al cual se acoplan dos volantes, uno de los cuales presenta ranuras para conectar las correas. En la parte posterior al centro de la base presenta un resorte y tuercas para ajustar el tamaño del "paso" del equipo. Fue elaborado en acero ensamblado. En ambos volantes tiene una inscripción que dice DECO 10x20.'
                },
                {
                    id: 'PC0322',
                    description: 'Objeto de uso cotidiano, utilizado para facilitar el descanso. Posee un asiento plano en forma de trapecio y un respaldo en marco horizontal, compuesto por un retablo plano unido por cuatro clavijas, dos por lado, puesto en forma horizontal y opuestas entre sí. Fue elaborado en madera ensamblada. Una de las caras laterales posee inscrito con plumón negro “SALA 2", la misma inscripción, pero en mayor tamaño, se repite en la parte posterior del respaldo. La base del asiento posee adheridos restos de cera de vela blanca.'
                },
                {
                    id: 'PC0323',
                    description: 'Objeto de uso cotidiano, utilizado para facilitar el descanso. Posee un asiento plano en forma de trapecio unido por cuatro clavijas puestas una en cada esquina, sujetándose a las patas en las partes superiores. El respaldo está compuesto por dos tablas en forma vertical, opuestas entre sí, que tanto en el medio como en la parte de la superficie se mantienen ensambladas con otra pieza de madera rectangular en forma horizontal, permitiendo un espacio vacío entre cada una. Fue elaborado en madera ensamblada. En el respaldo, se alcanza a observar una inscripción del número siete, posiblemente con pintura negra y, al lado en plumón negro, “sala n°3". Pose manchas o pérdida de color en la madera, particularmente visible en el asiento.'
                },
                {
                    id: 'PC0324',
                    description: 'Equipo utilizado para generar energía eléctrica. Es de forma cilíndrica con base rectangular. Posee un cuerpo cilíndrico ranurado en cuya parte superior tiene una argolla de levante para mover el equipo. En ambos extremos tiene tapas ovaladas con pernos de sujeción. La tapa posterior tiene cuatro pernos de sujeción pudiéndose observar los componentes internos a través de una rejilla. La tapa frontal del equipo tiene cinco pernos de sujeción y sobresale un eje al cual se sujeta una polea con siete ranuras. En la parte posterior del cuerpo se observa una abertura rectangular desde donde salen cables eléctricos. Fue elaborado en cobre y acero ensamblado. En la tapa posterior presenta la marca “Siemens",  Además, tiene una inscripción “cod2049" en color negro. En la parte posterior se observa que tuvo una placa con las especificaciones técnicas del equipo, sin embargo, estas fueron retiradas.'
                },
                {
                    id: 'PC0325',
                    description: 'Herramienta pediátrica usada para medir la altura de bebés. La pieza fue tallada en madera con forma rectangular, con una superficie cortada en chaflán, proporcionando una silueta trapezoide. La parte inferior posee otra pieza con un corte similar pero más pequeña, posibilitando la opción de ser utilizada de manera vertical u horizontal. En la mitad de la tabla se observa una rendija con una pieza móvil que servía para medir a los niños con mayor exactitud. A cada lado de la rendija se encuentran dos huinchas negras que van desde los 30 cm. hasta los 100 cm. En la parte superior pose un adhesivo amarillo con la inscripción “PRECISON HISPANA".'
                },
                {
                    id: 'PC0326',
                    description: 'Equipo utilizado como un sistema de trasporte continuo para el traslado de mercadería. La estructura se encuentra inclinada y conformada por perfiles de acero tipo C que se encuentran unidos entre sí por pernos y tuercas. Esta estructura rectangular es la que da soporte al equipo y a ella se acoplan diez polines y cuatro rodillos quienes soportan una cinta continúa de caucho que se mantiene tensa gracias a la acción de dos tambores ubicados en los extremos de la estructura, uno de estos actúa como tambor motriz y se encuentra en el extremo superior del equipo, mientras que el otro actúa de tambor tensor para mantener la cinta en su lugar. Por acción de los polines la cinta tiene una curvatura en la parte superior para transportar un mayor volumen de material. Fue elaborado en caucho y acero ensamblado. En cuanto a su ubicación actual, su extremo inferior se encuentra bajo el chancador de mandíbula primario mientras que su extremo superior está sobre la tolva de alimentación secundaria.'
                },
                {
                    id: 'PC0327',
                    description: 'Conjunto de objetos utilizados como muestras, realizadas por medio de un barrero durante el proceso de perforación. Tienen formas cilíndricas de aspecto irregular y se encuentran dispuestos en tres compartimientos dentro de una caja rectangular con tapa deslizable. Los testigos son de roca caliza y la caja fue elaborada en madera ensamblada con la inscripción “Pozo SR-12: caja 10, caja 12, caja 13"'
                },
                {
                    id: 'PC0328',
                    description: 'Objeto utilizado para anunciar o indicar una información específica. Es de forma rectangular y se encuentra adosado un soporte de cartón, envuelto en un plástico transparente afirmado por cinta adhesiva en su cara posterior. Fue elaborado en una lámina de papel impreso. Posee ilustraciones en distintos colores y lleva escrito en letras verdes “El huerto casero mejora nuestra alimentación ¿usted construyó el suyo?", en la esquina inferior izquierda se observa la inscripción del Ministerio de Salud.'
                },
                {
                    id: 'PC0329',
                    description: 'Estructura usada en un entorno doméstico para que un infante se pudiese mecer o dormir. La cuna es de aspecto rectangular con cuatro patas, la unión entre las piezas es con clavos metálicos y cinta de mimbre como decoración. La baranda del respaldo es de mayor altura que el resto de las barandas. Fue confeccionada de forma manual con madera de colihue al natural.'
                },
                {
                    id: 'PC0330',
                    description: 'Objeto utilizado para anunciar o indicar una información específica. Es de forma rectangular y se encuentra adosado un soporte de cartón, envuelto en un plástico transparente afirmado por cinta adhesiva en su cara posterior. Fue elaborado en una lámina de papel impreso. Posee ilustraciones en distintos colores y lleva escrito en letras rojas "Calendario de siembra" y en verdes “El huerto casero", en la esquina inferior derecha se observa la inscripción del Ministerio de Salud.'
                },
                {
                    id: 'PC0331',
                    description: 'Objeto utilizado para fundir metal a altas temperaturas. Tiene forma cilíndrica, con una boquilla en uno de sus lados que facilitaba el verter los elementos dispuestos en él. Posee en uno de sus extremos un mango de aspecto plano, que finaliza en una forma cilíndrica. Fue elaborado en metal forjado, con indicios de manufactura artesanal pues se observan piezas rectangulares añadidas al mango por medio de soldadura. '
                },
                {
                    id: 'PC0332',
                    description: 'Objeto utilizado para anunciar o indicar una información específica. Es de forma rectangular y se encuentra adosado un soporte de cartón, envuelto en un plástico transparente afirmado por cinta adhesiva en su cara posterior. Fue elaborado en una lámina de papel impreso. Posee ilustraciones en distintos colores y lleva escrito en letras azules "Pauta breve de desarrollo psicomotor 0 a 3 años" y en la parte central inferior se observa la inscripción del Ministerio de Salud.'
                },
                {
                    id: 'PC0333',
                    description: 'Equipo utilizado para generar energía eléctrica. Predomina la forma ovalada, con un cuerpo cilíndrico de base rectangular, a cada extremo del cuerpo se acoplan tapas ovaladas que se encuentran sujetas a éste por pernos. Desde una de las tapas sobresale un eje que se conecta a otro equipo mediante pernos. Fue elaborado en acero y cobre ensamblado. En la parte superior se observa una placa metálica con la marca del equipo: General Eléctrica. En la parte inferior de la placa se observa una inscripción que dice "INDUCTION MOTOR", y su modelo: 5K254D1679, tiene una potencia de 3 HP, voltaje de 380 V, amperaje de 5.44 AMP y frecuencia de 50Hz, también hace referencia a patentes de Estados Unidos. La pintura se encuentra desteñida y descascarada en algunas partes dónde se observa un color rojizo producto del óxido. Su color original puede haber sido celeste o gris.'
                },
                {
                    id: 'PC0334',
                    description: 'Objeto utilizado para anunciar o indicar una información específica. Es de forma rectangular, protegida con algún tipo de acetato, que se encuentra adherida a un soporte de mayor tamaño. Posee adheridas dos franjas de papel en la parte superior e inferior. Fue elaborado en una lámina gruesa de papel impreso con el retrato de Bernardo O´Higgins. El soporte es de Cholguán pintado en color celeste. '
                },
                {
                    id: 'PC0335',
                    description: 'Objeto utilizado en la herrería para manipular y mover piezas de metal con mayor facilidad. Está compuesto por dos piezas idénticas de forma rectangular alargada, que finaliza en forma semicircular con una parte plana y ensanchada. Ambas piezas están unidas por un clavo o tornillo de tal manera que generen presión. El mango no presenta ningún diseño y la terminación es completamente plana y cuadrada. Fue elaborado en metal forjado.'
                },
                {
                    id: 'PC0336',
                    description: 'Herramienta de uso manual, utilizado para aflojar o apretar otra pieza mediante el uso de la fuerza. Está compuesta por un mango plano, unido a una pieza ajustable a diferentes medidas de pernos o tuercas, que cumple la función de mordaza. Fue fabricada en hierro. El mango es de color rojo y presenta una inscripción en la cara superior “SUPER-EGO, INDUSTRIAL QUALITY, 102-48", en la cara posterior se observa “SUPER-EGO, HEAVY DUTT" y, en la mordaza, se divisa una última inscripción “1200mm-48".'
                },
                {
                    id: 'PC0337',
                    description: 'Objeto utilizado para trasladar a heridos hasta la posta. Predomina la forma rectangular y está compuesta por una estructura de dos tubos que soportan una tela. Posee patas plegables a ambos lados. Fue elaborada en acero y tela color naranja. '
                },
                {
                    id: 'PC0338',
                    description: 'Pieza fija de un motor utilizado, en conjunto a un rotor, para transmitir potencia o corriente eléctrica en una máquina. Su forma es cilíndrica con eje horizontal que presenta una base en su parte inferior donde se observan cuatro agujeros para anclar el equipo en su lugar. Por toda la parte exterior del cuerpo presenta agujeros ovalados. En la parte superior tiene dos argollas de levante para mover o transportar el equipo. En la parte interior del cuerpo presenta ranuras en dónde se ubicaba el embobinado de cobre. En ambos costados del cuerpo tiene agujeros rectangulares desde donde se sacaba el cableado eléctrico. Fue elaborado en acero y cobre por fundición. El color del equipo pudo ser azul o azul marino. No presenta signos de corrosión. Se puede apreciar que las placas con la información del equipo fueron removidas'
                },
                {
                    id: 'PC0339',
                    description: 'Herramienta de mano utilizada para golpear o percutir. Está compuesto por dos piezas soldadas: La primera, a modo de mango, es cilíndrica completa, sin detalles ni ornamentos y, en la parte superior, se ancla mediante un agujero soldado a una segunda pieza cónica, horizontal al mango, que posee un lado plano y el otro con un leve filo vertical. Fue elaborado en metal forjado. Este tipo de mazo también es conocido como macho de herrero.'
                },
                {
                    id: 'PC0340',
                    description: 'Objeto utilizado como representación gráfica de elementos estructurales. Su forma es rectangular y presenta diferentes dobleces sobre soporte papel. En base a tinta color rojiza está dibujada la estructura (corte longitudinal) de la Planta de Concentrado.'
                },
                {
                    id: 'PC0341',
                    description: 'Objeto utilizado para indicar o señalar un lugar o número. Su forma es rectangular, plana. Presenta dos agujeros en las esquinas y dos en el centro. Fue elaborado en hierro forjado y esmaltado en negro, con letras amarillas con la inscripción "SOLIDATED PNEUMATIC" (CONSOLIDATED PNEUMATIC)". En su cara posterior se observa una capa de pintura negra adicional.'
                },
                {
                    id: 'PC0342',
                    description: 'Mueble utilizado en un ambiente escolar para escribir y leer de manera cómoda. Tiene forma rectangular, con una tabla de base en la superficie para facilitar el apoyo y, bajo esta, se observa un compartimiento sin cubierta a modo de cajón. La mesa tiene dos patas anchas laterales que finalizan en una pieza horizontal para dar mayor estabilidad. Fue elaborado en fibropanel (trupán) y pintado en color celeste. '
                },
                {
                    id: 'PC0343',
                    description: 'Equipo electrónico destinado a la transmisión y recepción de imágenes y sonidos, utilizado frecuentemente para la entretención en un ambiente doméstico. Tiene forma rectangular con una pantalla convexa dónde se proyectan las imágenes. En la cara frontal posee un mando o control manual que consiste en una perilla giratoria con números. En la parte posterior tiene una antena, un interruptor INT-EXT y un cable de alimentación. Fue elaborado plástico, metal y vidrio. La carcasa del televisor es color gris y la superficie frontal del objeto es color negro. Tiene la inscripción “HECHO EN CHILE" y se observa el modelo “12 P6 2C".'
                },
                {
                    id: 'PC0344',
                    description: 'Herramienta manual utilizada para generar cortes, sobre todo en superficies u objetos de madera. Su forma predominante es trapezoidal con un extremo dentado tipo norteamericano, con un patrón de tres dientes rectos y terminados de manera más cóncava. El lado opuesto tiene un orificio circular en la esquina. El extremo más ancho va unido a un mango de madera a modo de tirador amoldado a la anatomía de la mano, conocido también como “de carpintero". La pieza principal fue elaborada en metal y el mango en madera. '
                },
                {
                    id: 'PC0345',
                    description: 'Herramienta manual utilizada para generar cortes, sobre todo en superficies u objetos de madera. Su forma predominante es trapezoidal con un extremo dentado tipo norteamericano, con un patrón de tres dientes rectos y terminados de manera más cóncava. Posee a modo de mango y a cada extremo una pieza circular, unida por dos clavijas a la pieza principal, evitando así que se suelte o salga. La pieza principal fue elaborada en metal y madera. Esta pieza presenta una circunferencia vacía, dispuesta para sostener la presión de una pieza de madera cilíndrica, que funcionaría como mango y, de esta forma, este tipo de serrucho es pensado para un trabajo de más de una persona. En el ejemplar solo se presenta una de las piezas de madera antes descritas.'
                },
                {
                    id: 'PC0346',
                    description: 'Objeto utilizado para anunciar o indicar una información específica. Es de forma rectangular y se encuentra adosado a un soporte de madera cuya cara posterior es sostenida por un marco de cuatro listones ensamblados con clavos, cuyas esquinas están calzadas en 45° y sus bordes laterales están pintados en color negro. Fue elaborado en una lámina de papel impreso. Posee ilustraciones en distintos colores y lleva escrito " Uso de Antídotos". En la parte inferior izquierda contiene un texto escrito a mano alzada que dice “Cristal" y, en la zona inferior derecha, se encuentra el logo de “Asociación Chilena de Seguridad, Líder en prevención de riesgos, ACHS".'
                },
                {
                    id: 'PC0347',
                    description: 'Objeto utilizado para anunciar o indicar una información específica. Es de forma rectangular y se encuentra adosado a un soporte de madera cuya cara posterior es sostenida por un marco de cuatro listones ensamblados con clavos cuyas esquinas están calzadas en 45° y sus bordes laterales están pintados en color negro. Fue elaborado en una lámina de papel impreso. Posee ilustraciones en distintos colores y lleva escrito “Ahí no le sirven a nadie…", en el centro una ilustración de accesorios de seguridad con el texto “¡Úselos!" y, en la zona inferior derecha, se encuentra el logo de “Asociación Chilena de Seguridad, Prevención de riesgos, ACHS".'
                },
                {
                    id: 'PC0348',
                    description: 'Objeto utilizado para anunciar o indicar una información específica. Es de forma rectangular y se encuentra sellado por ambos lados por un plástico protector a modo de lengüeta en su parte superior. Fue elaborado en una lámina de papel impreso. Contiene el texto “Plan de Prevención en Salud Bucal", en el centro se detalla e ilustra contenido informativo, en la parte inferior izquierda la inscripción “Extensión Facultad Odontología Universidad de Chile" y la zona inferior derecha el logo “Mentadent, cuidado dental preventivo"'
                },
                {
                    id: 'PC0349',
                    description: 'Herramienta manual utilizada para cortar cadenas, alambrados y varillas, entre otros. Tiene forma de tijera y filo por un solo lado. Está compuesto por dos mangos largos y dos hojas cortas, unidas por un conjunto de bisagras que logran un efecto palanca que es transmitido a la fuerza de corte. Fue elaborado en metal moldeado y forjado. Posee distintas inscripciones, destacando la posición de izquierda y derecha “RICHT" “LEFT", su procedencia de fabricación “MADE IN ENGLAND", el modelo específico, repetido en diferentes locaciones “N°601" y destacan, además, las palabras “RECORD" y “CUTTER" en distintos lugares. Esta herramienta también es conocida como "Napoleón"'
                },
                {
                    id: 'PC0350',
                    description: 'Objeto utilizado para anunciar o indicar una información específica. Es de forma rectangular y se encuentra adosado a un soporte de cartón, envuelto en un plástico transparente afirmado por cinta adhesiva en su cara posterior. Fue elaborado en una lámina de papel impreso. En la zona superior contiene un texto que dice “LA PÍLDORA" y, en el centro, una secuencia de números que contienen información sobre el tema, enmarcado por una franja de color negro. En el depósito se encuentran dos ejemplares de similar información.'
                }
            ]

            let arr8 = [{
                    id: 'PC0351',
                    description: 'Objeto utilizado para anunciar o indicar una información específica. Es de forma rectangular y se encuentra adosado a un soporte de cartón envuelto en un plástico transparente afirmado por cinta adhesiva en su cara posterior. Fue elaborado en una lámina de papel impreso con letras elaboradas por plantillas. En la zona superior contiene un texto que dice “TODA EMBARAZADA DEBE:" en letras negras, seguido de una secuencia de guiones con información en letras grises, y que culmina con una oración en rojo “SOLO ASÍ LOGRARA UN NIÑO SANO Y FELIZ"'
                },
                {
                    id: 'PC0352',
                    description: 'Objeto utilizado para anunciar o indicar una información específica. Es de forma rectangular y se encuentra adosado a un soporte de cartón envuelto en un plástico transparente afirmado por cinta adhesiva en su cara posterior. Fue elaborado en una lámina de papel impreso con letras elaboradas por plantillas. En la zona superior contiene un texto que dice “ESCAVIOSIS O SARNA:" en letras negras, seguido con información en letras grises.'
                },
                {
                    id: 'PC0353',
                    description: 'Objeto utilizado para anunciar o indicar una información específica. Es de forma rectangular y se encuentra adosado a un soporte de cartón envuelto en un plástico transparente afirmado por cinta adhesiva en su cara posterior. Fue elaborado en una lámina de papel impreso. En la zona superior contiene un texto que dice “Señora - Señorita" seguido de un texto informativo enmarcado en una franja negra'
                },
                {
                    id: 'PC0354',
                    description: 'Objeto utilizado para anunciar o indicar una información específica. Es de forma rectangular y se encuentra adosado a un soporte de cartón envuelto en un plástico transparente afirmado por cinta adhesiva en su cara posterior. Fue elaborado en una lámina de papel impreso con letras elaboradas por plantillas. En la zona superior contiene un texto que dice “Sra Mamá" seguido de un texto informativo.'
                },
                {
                    id: 'PC0355',
                    description: 'Objeto utilizado para pesar objetos mediante la comparación de pesos conocidos. Tiene forma de cono truncado alargado y hueco en su parte interior. Se compone por tres piezas fundamentales que se encuentran enlazadas entre sí. En el medio posee una estructura vertical terminada en una circunferencia con un agujero redondo, cubierto por vidrio, con una aguja blanca parecida a las de reloj que marcaría o identificaría elementos según el peso. En uno de los laterales del objeto se observa una placa de metal, doblada en sí misma para sostener objetos, unida mediante remaches. En el lateral opuesto una bandeja de metal móvil. En la parte frontal se observa un medidor de pesos que va del 0 y asciende consecutivamente hasta llegar a 500. Fue elaborado en metal ensamblado y esmaltado en color verde. Posee un adhesivo con la marca de origen “PRECISION HISPANA" y, en la parte posterior, escrito con plumón negro, se observa la inscripción “COD 2105".'
                },
                {
                    id: 'PC0356',
                    description: 'Objeto utilizado para anunciar o indicar una información específica. Es de forma rectangular y se encuentra adosado a un soporte de cartón envuelto en un plástico transparente afirmado por cinta adhesiva en su cara posterior. Fue elaborado en una lámina de papel impreso con letras negras elaboradas por plantillas. En la zona superior contiene un texto que dice “Autoridades" seguido de un texto informativo.'
                },
                {
                    id: 'PC0357',
                    description: 'Objeto utilizado para anunciar o indicar una información específica. Es de forma rectangular dispuesto de manera vertical y se encuentra adosado a un soporte de madera por medio de remaches. Fue elaborado en una lámina de papel impreso con letras negras. Presenta en su superficie una secuencia de formas en color negro de distintos tamaños y distancias, relacionada con el examen oftalmológico. Se encuentran en el depósito dos ejemplares, uno de ellos con la información impresa en color naranjo “OPTICAS RICARDO HENZI".'
                },
                {
                    id: 'PC0358',
                    description: 'Objeto utilizado para pesar objetos. Se compone por dos piezas fundamentales: La primera es de forma circular, con un vidrio también circular en el medio que se encuentra quebrado y, más arriba, terminaría con una forma de abanico. La segunda es una superficie con una vitrina de vidrio que marcaría la medición de kilogramos del 1 al 5. En la parte posterior del objeto se encuentra una bandeja metálica, que puede ser extraída sin mayor dificultar. Fue elaborado en metal fundido y moldeado, y vidrio. Es de color blanco. Posee en la parte del soporte una placa metálica en la que se lee “BALANZA MOD. 85, N°1412 FABRICADA EN CHILE BAJO LICENCIA DE THE HOBART MANUFACTURING CO., TRQY, OHIO, U.S.A. AGENTES Y DISTRIBUIDORES MARSOL. CATEDRAL 1159 SANTIAGO FONO 62172"'
                },
                {
                    id: 'PC0359',
                    description: 'Objeto utilizado para cubrir o tapar superficies. Es de forma rectangular. Fue elaborado en tela, de origen industrial y tanto anverso como reverso son de color crudo. No posee ningún tipo de ornamento ni diseño.'
                },
                {
                    id: 'PC0360',
                    description: 'Objeto utilizado para anunciar o indicar una información específica. Es de forma rectangular. Fue elaborado en una lámina de papel brillante impreso. En la zona superior contiene un texto que dice “Instrucciones de mantenimiento de lámparas de casco" escrito en tres idiomas. Desplegado en el centro se detalla contenido informativo a modo de texto, respaldado y secuenciado con ilustraciones gráficas.'
                },
                {
                    id: 'PC0361',
                    description: 'Herramienta utilizada para verificar niveles de suelos y paredes y como guía para la construcción de muros. Está conformada por dos piezas de madera rectangulares, de dos metros de largo, unidas en sus extremos por bisagras que permiten abatir el objeto hasta su largo máximo de cuatro metros. La parte interior fue fabricada en madera y pintada en color blanco, presenta inscripciones en color negro y rojo, con forma de E y numeración del 1 al 40. La parte exterior presenta en un lateral dos asas metálicas recubiertas con plástico. La parte exterior de una de las piezas de madera tiene una inscripción en blanco que dice "Óptica Rodolfo Hammersley Agustinas 1090".'
                },
                {
                    id: 'PC0362',
                    description: 'Objeto utilizado para cubrir o tapar superficies. Es de forma rectangular. Fue elaborado en tela, de origen industrial color beige. Posee un patrón de diseño horizontal y simétrico con franjas rectas. En dos de sus bordes opuestos entre si se observa un acabado en el tejido y los otros dos parecieran haber sido cortados.'
                },
                {
                    id: 'PC0363',
                    description: 'Objetos utilizados para dar soporte a una cinta transportadora. Es de base rectangular y sobresalen cuatro soportes equidistantes entre los cuales se ubican tres rodillos sujetados desde sus ejes. Los rodillos laterales se encuentran inclinados mientras que el rodillo central se encuentra horizontal y paralelo a la base. La base tiene un perfil tipo L y desde sus extremos salen dos pestañas, cada una con un agujero para fijar los polines en su posición con pernos. Fue elaborado en acero ensamblado.'
                },
                {
                    id: 'PC0364',
                    description: 'Objeto utilizado para ayudar a los infantes con sus primeros pasos. La estructura está compuesta por dos fierros cilíndricos simétricos entre sí, que mediante tornillos se adhieren el uno con el otro generando una estructura con cuatro patas (cada una con una rueda que permite el arrastre) y hacia la parte más alta termina de forma más bien cuadrada. La parte superior es lo suficientemente estrecha como para introducir a un infante y propinarle un apoyo. La base es más ancha para facilitar el movimiento de los pies en conjunto con las ruedas. Además, posee un saco para el apoyo del niño afirmado por pitillas en los extremos superiores e inferiores a modo de asiento. Fue elaborado en metal ensamblado y tela. En la parte de abajo se logra divisar la inscripción en rojo de una marca “SOMOS SOCIEDAD MOLINERA DE OSORNO" pudiendo entonces agregar que en parte es un andador de confección manual.'
                },
                {
                    id: 'PC0365',
                    description: 'Prenda de vestir usada para abrigar. Es de manga larga y llega hasta la cintura. Posee 3 botones de unión hasta el comienzo del pecho. Su confección fue en base a tela sintética, es de color negro exceptuando el cuello color gris. En la zona frontal derecha del cuerpo se observa un estampado "Ski Alpine" y bajo éste un bolsillo color rosa.'
                },
                {
                    id: 'PC0366',
                    description: 'Objeto utilizado para ayudar a los infantes con sus primeros pasos. La estructura está compuesta por dos fierros cilíndricos simétricos entre sí, que mediante tornillos se adhieren el uno con el otro generando una estructura con cuatro patas (tres de las cuatro patas poseen una rueda que permite el arrastre) y hacia la parte más alta termina de forma más bien cuadrada. En el interior presenta un textil, posiblemente como parte original del conjunto para facilitar el apoyo del infante. Fue elaborado en metal y tela azul ensamblada. La estructura, por faltantes de acero, no puede mantenerse en pie por sí sola. '
                },
                {
                    id: 'PC0367',
                    description: 'Instrumento de percusión utilizado para generar determinados sonidos. Es de forma de copa invertida colgada por su asa a un gancho con una cuerda tipo soga amarrada a un aro soldado de la que se tira para martillear la pieza y emitir el sonido. Fue elaborado en hierro por medio de fundición.'
                },
                {
                    id: 'PC0368',
                    description: 'Prenda de vestir utilizada para evitar el frío. Es de manga larga y llega hasta la cintura. Posee un forro interno y una capucha. Se encuentra abierta en su parte frontal por lo que dispone de un sistema de cierre con botones y amarras de ajuste. Tiene dos bolsillos en el pecho y dos en los laterales. Fue elaborada en tela, de confección industrial y predomina el color negro.'
                },
                {
                    id: 'PC0369',
                    description: 'Objeto utilizado para poder escribir o dibujar en una superficie reutilizable. Es de forma rectangular. Se compone de tres planchas de madera encoladas que soportan dos láminas de fibropanel (trupan) también encoladas. En la parte inferior tiene un receptáculo, en toda su longitud, para sostener los utensilios de escritura. Fue pintado con chalk paint, tono oscuro y mate para poder escribir con tiza'
                },
                {
                    id: 'PC0370',
                    description: 'Objeto utilizado para indicar o señalar un lugar o número. Su forma es rectangular y plana. Fue elaborado fibropanel (trupán) y pintado en negro por una de sus caras. Posee letras en mayúscula y pintura blanca donde se observa la inscripción “PTO. SANCHEZ"'
                },
                {
                    id: 'PC0371',
                    description: 'Objeto utilizado para poder escribir o dibujar en una superficie reutilizable. Es de forma rectangular. Se compone de tres planchas de madera encoladas que soportan dos láminas de fibropanel (trupán) también encoladas. En la parte delantera tiene un marco de madera. En la parte inferior tiene un receptáculo, en toda su longitud, para sostener los utensilios de escritura. Fue pintado con chalk paint, tono oscuro y mate para poder escribir con tiza'
                },
                {
                    id: 'PC0372',
                    description: 'Objeto utilizado para anunciar o indicar una información específica. Es de forma rectangular. Fue elaborado en una lámina de papel con letras elaboradas por plantillas y se distingue la palabra “DIRECCIÓN". Se encuentra adherido a la pared por medio de corchetes en sus bordes laterales.'
                },
                {
                    id: 'PC0373',
                    description: 'Objeto utilizado para anunciar o indicar una información específica. Tiene forma rectangular. Fue elaborado en tela y decorado con pintura de diferentes colores. El lienzo pertenece al Ministerio de Educación y el estampado dice: MINISTERIO DE EDUCACION PUBLICA, DIRECCION GENERAL DE EDUCACION, CANAL ESCOLAR, DEPARTAMENTO DE EDUCACION EXTRAESCOLAR, SISTEMA NACIONAL DE DEPORTES Y RECREACION, DIGEDER. El texto escrito a mano dice: JUEGOS-LOCALES-DEPORTIVOS-ESCOLARES, PTO CRISTAL OCTUBRE 1983.'
                },
                {
                    id: 'PC0374',
                    description: 'Objeto utilizado para anunciar o indicar una información específica. Es de forma rectangular. Se encuentra adherido a la pared de manera vertical mediante dieciséis remaches, uno de ellos pose un trozo rectangular de papel suspendido. También se observan corchetes en distintos sectores. Fue elaborado en papel blanco. Se logran distinguir las siguientes inscripciones “AUTORIDADES. PDTE DE LA REPUBLICA. MINISTRO DE EDUCACIÓN. INTENDENTE REGIONAL. SECRET. MINIS. REG. EDUC. SRA. ROSA SANHUEZA M. GOBERNADOR PROVINCIAL. SR. JUAN MERCEGU A. ALCALDE COMUNAL. DIRECTOR ESCUELA. RAMON BELMAR QUIJADA"'
                },
                {
                    id: 'PC0375',
                    description: 'Objeto utilizado para anunciar o indicar una información específica. Es de forma rectangular. Fue elaborado en una lámina de papel con letras rojas elaboradas por plantillas y se distingue la palabra “SALA 2". Se encuentra adherido a la puerta.'
                },
                {
                    id: 'PC0376',
                    description: 'Mueble utilizado frecuentemente en trabajos u oficinas con el fin de leer, escribir o utilizar cómodamente una computadora sobre él. Tiene forma rectangular, con una tabla de base en la superficie para facilitar el apoyo y, bajo esta, se despliegan cuatro cajones y un compartimento con puerta, de los cuales solo uno presenta manilla. Fue elaborado en madera ensamblada. La puerta del compartimento está pintada de color verde pastel.'
                },
                {
                    id: 'PC0377',
                    description: 'Vehículo utilizado al interior de los túneles mineros para el transporte de carga y mineral. El carro era desplazado sobre rieles que se encontraban al interior de la mina. Está compuesto por un chasis con dos perfiles tipo C en paralelo y en la parte inferior de cada extremo se encuentra apernado un eje desde cuyos extremos salen dos ruedas metálicas con cuatro agujeros circulares. Sobre el chasis posee una placa sobre la que descansa un recipiente para almacenar material, en su parte frontal las uniones presentan ángulos rectos con leves curvaturas en las esquinas, mientras que la parte posterior es ovalada. Fue elaborado en metal ensamblado.'
                },
                {
                    id: 'PC0378',
                    description: 'Objeto utilizado para anunciar o indicar una información específica. Es de forma rectangular. Fue elaborado en una lámina de papel con letras rojas elaboradas por plantillas y se distingue la palabra “BIBLIOTECA". Se encuentra adherido a la pared por medio de corchetes en sus esquinas. En la parte frontal inferior se observa una mancha de pintura de color celeste sobre parte del texto'
                },
                {
                    id: 'PC0379',
                    description: 'Placa distintiva utilizada para identificar un establecimiento o espacio de institución policial. El objeto es de metal con forma de escudo, en su anverso y reverso contiene una imagen pintada a mano compuesta por dos carabinas cruzadas entre sí formando una cruz. En sus esquinas superiores y en el borde central de la parte inferior se observan placas metálicas que sobresalen y se encuentran unidas por remaches al soporte. El color predominante es el verde y los detalles identificadores fueron hechos en pintura blanca permitiendo que resalten.'
                },
                {
                    id: 'PC0380',
                    description: 'Placa distintiva utilizada para identificar un establecimiento o espacio de institución policial. El objeto es de metal rectangular y plano. En los laterales del borde superior se observan dos placas metálicas de forma plana en su base que se encuentran unidas por remaches al soporte y de forma cilíndrica en su parte superior. El color predominante es el verde y se identifica el texto "RETEN PUERTO CRISTAL"'
                },
                {
                    id: 'PC0381',
                    description: 'Objeto de uso cotidiano, utilizado para facilitar el descanso. Posee un asiento plano y de forma circular y un respaldo en marco horizontal. Posee un travesaño en la parte inferior. En cada una de las superficies inferiores pose un tope de plástico negro que rodea la forma cilíndrica. Fue elaborado en metal moldeado y ensamblado.'
                },
                {
                    id: 'PC0382',
                    description: 'Objeto utilizado para anunciar o indicar una información específica. Es de forma rectangular y se encuentra adosado a un soporte de madera por clavos cuya cara posterior es sostenida por un marco de cuatro listones ensamblados con clavos cuyas esquinas están calzadas en 45°. Fue elaborado en una lámina color blanco en fibropanel impreso. Posee una inscripción en letras negras donde se observa la palabra “MAESTRANZA" rodeado por una franja del mismo color.'
                },
                {
                    id: 'PC0383',
                    description: 'Objeto de combustión lenta utilizada para calentar. Está compuesto por una base plana, con un compartimiento cóncavo conocido como cenicero (compartimiento para limpiar la ceniza) adosado a cuatro patas curvas. Sobre esta superficie tiene una estructura de mayor tamaño, con aspectos cuadrado y una compuerta con un clavo a modo de manilla. En la parte superior, con forma cilíndrica, se divisa el caño para evacuar gases y humo de combustión. Fue elaborada en metal ensamblado con uniones de soldadura. A modo de diseño se observan, tanto en la puerta como en laterales, bordes fileteados. Posee una inscripción de fabricación en la compuerta y, en el centro, un escudo con el número 20. En la cara lateral derecha posee una inscripción no legible, posiblemente de la marca o modelo.'
                },
                {
                    id: 'PC0384',
                    description: 'Objeto utilizado como representación gráfica de la tierra. Representa el país de norte a sur, incluida la Antártica chilena. Se encuentra dividido en tres columnas, que incluye una imagen representando algún evento histórico del país. Fue elaborado en papel tela y detalles en metal. Pertenece al Instituto Geográfico Militar de Chile, producción nacional, num. Inscripción 18128, edición 1973, escala 1:1.000.000.'
                },
                {
                    id: 'PC0385',
                    description: 'Mueble camilla utilizada para diferentes tipos de examen médico. Tiene forma de cama somier con una cabecera compuesta de una estructura de madera con una base y cuatro patas. Sobre la misma se apoya una plancha de espuma de relleno envuelta en una tela de cuero sintético color verde que se encuentra adherida a los laterales de la base por remaches de metal.'
                },
                {
                    id: 'PC0386',
                    description: 'Mueble utilizado para generar cortes en la carnicería. Tiene forma rectangular y está compuesto por ocho tablas puestas en posición horizontal una al lado de la otra, permitiendo un espacio entre cada una de ellas, facilitando el corte de las carnes. Las primeras seis tablas, cuentan a su lado izquierdo con un trozo rectangular del mismo grosos, pero mucho menor ancho de madera, dispuesto como bloque y anclado mediante dos clavos cada uno, puestos en esquinas contrarias. Las últimas tres cuentan con la misma superficie, pero a lado derecho (la tabla seis, cuenta con los bloques a ambos lados). Posee cuatro patas con cuatro travesaños cruzados en la parte inferior, de manera horizontal, y en las vistas de las caras posteriores y traseros tiene dos travesaños más en forma de cruz. Fue elaborada completamente en madera ensamblada.'
                },
                {
                    id: 'PC0387',
                    description: 'Vehículo utilizado para el transporte de carga y mineral. En el tramo "Filita" - Planta de Concentrados se movía sobre rieles en un plano inclinado. Tiene base rectangular construida con perfiles tipo I y en la parte inferior de la base se encuentran apernados dos ejes que sostienen las ruedas metálicas. En la parte superior de la base se encuentra un recipiente de forma irregular, cuyo fondo se encuentra inclinado y es soportado por cuatro perfiles tipo L, en la parte frontal presenta una tapa móvil curvada con un gancho en la parte central y dos rodillos a los costados y en la parte superior tiene una abertura. Fue elaborado en metal ensamblado, color rojizo producto del oxido. Presenta marcas de soldadura y remaches'
                },
                {
                    id: 'PC0388',
                    description: 'Mueble utilizado para dar apoyo en distintas labores u oficios. Es de forma rectangular. Está compuesto por una superficie de tres tablones de madera sobre los que se coloca una formica con despiece, imitación de baldosas. En un lateral, sobre el mesón, se observa una jaula de madera abierta por uno de sus lados donde se ubicaba la caja registradora. La cara delantera y la cara lateral visible del mostrador están compuestas por tablones de madera pintados de color verde pastel. La cara lateral es abatible. El mueble es de confección manual. Posee adherido a diferentes superficies trozos de fibropanel y linóleo, entre otros.'
                },
                {
                    id: 'PC0389',
                    description: 'Mueble camilla utilizada para diferentes tipos de exámenes ginecológicos. Está compuesto por tres segmentos; respaldo, asiento y soporte para las piernas. Sobre esta superficie se observa una base de madera y una plancha acolchada de espuma recubierta en tela de cuero sintético color café. Apoyada en la estructura base de la parte frontal se encuentra un escalón cubierto por un tipo de goma antideslizante y, en los laterales de la parte frontal del asiento, sobresalen dos estructuras metálicas móviles terminadas en una talonera de apoyo.'
                },
                {
                    id: 'PC0390',
                    description: 'Mueble camilla utilizada para diferentes tipos de examen médico. Tiene forma de cama somier con una cabecera compuesta de una estructura de madera con una base y cuatro patas con terminaciones plásticas a modo de tope- Sobre la misma se apoya una plancha de espuma de relleno envuelta en una tela de cuero sintético color negro que se encuentra adherida a los laterales de la base por remaches de metal. Posee, además, una placa metálica blanca y azul con la inscripción “METALURGICA. BLANCO Y BLANCO LTDA. MUEBLES CLINICOS. DIREC: 3 AV 1220 FONO 5210920". Se utilizó en la posta como camilla para la atención de los pacientes, razón por la cual la cabecera cuenta con la opción de poder subirse o bajarse, dependiendo de las necesidades de cada paciente.'
                },
                {
                    id: 'PC0391',
                    description: 'Estructura utilizada como parte de una maquinaria eléctrica o turbina. Posee un eje metálico horizontal cuyo diámetro varía cada ciertos tramos. En la parte central sobresale un cilindro al cual se encuentran acoplados seis soportes rectangulares, en cuya parte exterior tienen placas planas de área mayor. Estas placas presentan dos hileras de nueve círculos ranurados similares a tornillos. Fue elaborado en acero ensamblado. Esta pieza forma parte del generador de la planta hidroeléctrica de Puerto Cristal.'
                },
                {
                    id: 'PC0392',
                    description: 'Conjunto de elementos utilizados en el área odontológica. Está compuesto por un sillón con respaldo, cabezal de doble almohadilla, asiento y reposapiés tapizado en color negro, una bandeja o brazo porta instrumentos plásticos con forma redonda y partida por la mitad, un pedal o reóstat, un equipo hídrico compuesto por la escupidera, vertedero (de plástico blanco) y grifo de metal. El sistema de aspiración tiene boquillas y tubos, una lámpara y un ventilador sobre la misma y un compresor cilíndrico con manómetro y llave conectado a la pieza principal. Tanto el cuerpo de la unidad como la lámpara son de metal enlozado en color verde agua. Tiene una placa metálica con la inscripción TRADE Y MARK. DENTL UNIT "ROSA" DENTAL INDUSTRY CO., LTD. VOLT 220 CYCLE 50 AMP. 10A SERIAL NO. 162 DATE 34.12 '
                },
                {
                    id: 'PC0393',
                    description: 'Instrumento utilizado para medir grandes pesos. Tiene forma rectangular. En su borde superior central en dirección perpendicular contiene un tubo en cuyo interior tiene una barra de medición de altura removible de metal rígido, terminada en una lengüeta móvil de plástico color negro. Tras la cinta métrica y sostenida por el tubo metálico se encuentra la báscula con un espectro de calibración de peso hasta los 150 Kg. También se observan dos controles deslizables independientes, el superior está configurado en incrementos de 10 kg y el inferior representa porciones de kilogramo a gramo. Fue elaborado en metal y plástico ensamblado y esmaltado en color blanco. Posee en la base una inscripción escrita a mano "Cristal 89". En la cara superior se observa una placa con la inscripción de la marca "SECA".'
                },
                {
                    id: 'PC0394',
                    description: 'Placa distintiva utilizada para identificar un establecimiento o espacio de institución policial. El objeto es de metal con forma de escudo, en su anverso y reverso contiene una imagen pintada a mano compuesta por dos carabinas cruzadas entre sí formando una cruz. En sus esquinas superiores y en el borde central de la parte inferior se observan placas metálicas que sobresalen y se encuentran unidas por remaches al soporte. Desde los remaches superiores se observa un alambrado horizontal que posibilita el poder ser colgado en alguna superficie plana. El color predominante es el verde y los detalles identificadores fueron hechos en pintura blanca permitiendo que resalten.'
                },
                {
                    id: 'PC0395',
                    description: 'Objeto utilizado para la devoción religiosa. Su forma es antropomorfa y representa a una mujer, específicamente la Virgen María de Lourdes. Su rostro y manos son de un color crema, su túnica es blanca con bordes dorados y una cinta alrededor de la cintura de color celeste. Se encuentra sobre una base rectangular de color negro. La representación está de pie con señal de adoración. Fue elaborada en granito con decorado de pintura. '
                },
                {
                    id: 'PC0396',
                    description: 'Herramienta con motor eléctrico utilizada para cortar materiales o estructuras duras. Está conformado por una base de forma rectangular que se encuentra sostenida por dos bloques anchos terminados en dos puntos de apoyo. Sobre la base se encuentra una estructura mecánica conformada por un sistema de poleas adherido por medio de tornillos a un arco de sierra y, en la cara frontal, se observa una prensa compuesta de dos plataformas rígidas de acción mecánica. Está elaborado de manera industrial en hierro moldeado por comprensión, torneado y fundición.'
                },
                {
                    id: 'PC0397',
                    description: 'Herramienta utilizada para calentar metales y de esta forma poder forjarlos o trabajarlos con mayor facilidad. Su forma es de cubo, sostenida por cuatro patas laterales. La cara posterior se encuentra conectada por medio de un tubo a un cubo que, a su vez, va conectado a un tubo de salida al exterior. Fue elaborado en metal troquelado, ensamblado y con detalles soldados. En el interior del cuerpo principal se observa carbón. '
                },
                {
                    id: 'PC0398',
                    description: 'Objeto utilizado para anunciar o indicar una información específica. Tiene forma rectangular. En el extremo superior posee un listón de madera de perfil circular desde donde sobresale una cinta de tela que en su extremo tiene una argolla para colgar la pieza. Fue elaborado en tela, con confección artesanal. Sobre el lienzo se observan algunas inscripciones como “SANTIAGO" en color rojo y algunas líneas onduladas con lápiz grafito. Sobre el textil se hayan siete imágenes impresas en papel, copias de pinturas con información del autor de la obra y el museo en dónde se encuentra.'
                },
                {
                    id: 'PC0399',
                    description: 'Equipo utilizado para generar energía eléctrica. Se encuentra conectado a un sistema de poleas para trabajos en la zona del torno del taller mecánico de la Maestranza. Fue elaborado en metal ensamblado.'
                },
                {
                    id: 'PC0400',
                    description: 'Objeto utilizado para anunciar o indicar una información específica. El objeto es rectangular. Fue elaborado en fibropanel (trupán). Está pintado de color negro y contiene una inscripción en letras blancas “PTO. CRISTAL"'
                }
            ]

            let arr9 = [{
                    id: 'PC0401',
                    description: '_'
                },
                {
                    id: 'PC0402',
                    description: 'Mueble utilizado como apoyo para distintas actividades. Está compuesto por una estructura circular sostenido por tres patas de apoyo y otra estructura circular que ayuda a mantener y contener el armazón del objeto. La estructura principal fue elaborada en madera y posee dos orificios, el resto de la estructura fue elaborado en metal y soldadura. Predomina el color azul con letras amarillas donde se puede observan la palabra “COCESA" y paralela a esta inscripción la palabra “CHILE".'
                },
                {
                    id: 'PC0403',
                    description: 'Objeto utilizado para proteger objetos o documentos de gran valor. Corresponde a una pieza rectangular, con una compuerta delantera provista de un sistema de cierre de gran dificultad, evitando de esta forma que cualquier individuo pueda acceder a su interior. Posee una manilla vertical de acero. Fue elaborada completamente en metal y pintada de color negro. '
                },
                {
                    id: 'PC0404',
                    description: 'Objeto utilizado para asegurar la protección de vidas humanas en el mar. Tiene forma toroide, con cuatro adhesivos dispuestos de forma equidistante entre sí y que son reflectantes.  Además, posee cuatro estructuras que sobresalen del plano y por donde pasa una cuerda. Por sus cualidades correspondería a una especie de flotador de goma, que es llenado mediante el flujo de aire interior. Predomina el color anaranjado fluorescente. '
                },
                {
                    id: 'PC0405',
                    description: 'Objeto utilizado para la molienda de materiales excepcionalmente duros y/o abrasivos. Está compuesto por dos piezas cilíndricas de gran tamaño ahuecadas dispuestas una frente a la otra. La pieza de mayor tamaño posee orificios de distintos tamaños en ambas caras y en el cuerpo longitudinal se observan protuberancias circulares ordenadas de manera simétrica, con carácter adiamantado. El objeto de menor tamaño posee una superficie lisa, con una base circular dentada. El objeto es de dimensiones y pesos considerables, por lo que se mantendrá in situ a pesar de su estado actual de deterioro por oxidación.'
                },
                {
                    id: 'PC0406',
                    description: 'Conjunto de objetos posiblemente utilizados para identificar o enumerar materiales. En su mayoría predominan las formas rectangulares, hexagonales o circulares. Poseen una inscripción metálica donde se observan diferentes números, y todas las fichas poseen un orificio en la parte superior, probablemente para colgar o enganchar. Algunas de las piezas se encuentran agrupadas por alambres recubiertos de plásticos blancos o rojos.'
                },
                {
                    id: 'PC0407',
                    description: 'Objeto utilizado para la devoción religiosa. Su forma es antropomorfa y representa a una mujer, específicamente la Virgen María de Lourdes. Su rostro y manos son de un color crema, su túnica es blanca con bordes dorados y una cinta alrededor de la cintura de color celeste. Posee una base color celeste con detalles café. La representación está de pie con señal de adoración. '
                },
                {
                    id: 'PC0408',
                    description: 'Objeto utilizado para generar determinados sonidos. Es de forma de copa invertida y ahuecada. En el interior posee una estructura cilíndrica móvil, la cual genera sonidos mediante el golpe con los bordes interiores. La estructura posee en la superficie un sistema de anclaje metálico con forma semicircular adherida por tornillos.  Además, cuenta con una soga color blanco asegurada por uno de sus bordes. Posee una inscripción circular en la superficie frontal. Es principalmente de color esmeralda y presenta perdidas de pigmento, posiblemente por exposición al sol o por desgaste de uso.'
                },
                {
                    id: 'PC0409',
                    description: 'Objeto utilizado en ceremonias de carácter religiosos para recibir ofrendas de distintos tipos. Fue elaborado en dos piezas, una metálica que da forma circular al objeto, y que termina con una manilla para sostener, y una tela sostenida alrededor del área metálica que sirve como saco para la recepción de las ofrendas. El telar posee un patrón cuadrado tipo escocés, de color celeste y blanco.'
                },
                {
                    id: 'PC0410',
                    description: 'Objeto utilizado para fundir metal a altas temperaturas. Tiene forma cilíndrica con un agujero en la parte superior por donde posiblemente se vertían los elementos dispuestos en él. Posee en uno de sus extremos un mango generado por una tuerca y adherido al cuerpo cilíndrico por un perno. La base posee relieves que podrían deberse a la forma en que fue soldado. El objeto es de metal, y posee desgaste por uso'
                },
                {
                    id: 'PC0411',
                    description: 'Objeto utilizado para cubrir la boca y evitar respirar gases tóxicos u otro tipo de partículas. El objeto se compone por un cuerpo cóncavo irregular, dos objetos plásticos cilíndricos a cada lado, que funcionan como válvulas de inhalación, y dos objetos cilíndricos de menor medida: uno en el centro y el otro en la parte superior a modo de válvulas de exhalación. La base del cuerpo es negra y los objetos plásticos adheridos son de color rojo. Posee una inscripción en los bordes de los cilindros laterales donde se aprecia la marca “NORSEG". En la válvula frontal y superior se observa un diseño tipo cruz o estrella de cuatro puntas.'
                },
                {
                    id: 'PC0412',
                    description: 'Recipiente utilizado para contener y servir líquidos. Es de forma globular, seguido por un cuello corto y de menor diámetro, con un bisel que termina en un pico pronunciado con forma redondeada para facilitar verter el líquido interior. En el lateral opuesto posee un asa remachada que nace a un cuarto de su altura. La base es circular y plana. Fue completamente elaborado en metal enlozado blanco, exceptuando el cuello y el asa color azul. Se observa abrasión, perdida de pigmento y orificios en la base.'
                },
                {
                    id: 'PC0413',
                    description: 'Objeto destinado a entintar o marcar papel con una descripción o propiedad específica a modo de impresión manual. Está compuesto por un objeto cilíndrico de madera que en uno de sus extremos contiene una superficie de impresión de goma de forma circular o sello de tinta. El tampón tiene la inscripción “CENTRO DE MADRES. LOS CRISTALES FUNDADO -10-71"'
                },
                {
                    id: 'PC0414',
                    description: 'Objeto utilizado como parte de la vestimenta de seguridad enfocada en el rostro, se utilizaba como protección a posibles exposiciones accidentales de salpicaduras de sustancias peligrosas y/o infecciosas. Está compuesto por dos piezas plásticas: una de color naranjo que funcionaría como soporte y sujeción a la cabeza, y un escudo de material ligero y transparente que no dificultaría la visión. Su estado de conservación es regular pues se encuentra quebrado en uno de sus lados,  Además, de observarse bastante deterioro posiblemente debido al uso. '
                },
                {
                    id: 'PC0415',
                    description: 'Objeto utilizado para abrir y cerrar una cerradura. Se componen de una espiga con forma plana con un agujero en la parte superior y culmina en una superficie plana dentada por ambos lados. Fue elaborada en metal. Posee amarrado un alambre envuelto en plástico azul que funciona como llavero.'
                },
                {
                    id: 'PC0416',
                    description: 'Objeto utilizado para medir y detectar la presión física de líquidos y gases. Está compuesto por un cuerpo cilíndrico. En el borde inferior se sostiene sobre una estructura de metal cilíndrica con una manija en la base. En la cara superior se puede observar la manilla y los números que varían desde el 0 hasta el 60 destacando en el medio el número 30 en color rojo. El objeto fue elaborado casi completamente en metal troquelado, exceptuando el interior que está confeccionado con papel blanco. '
                },
                {
                    id: 'PC0417',
                    description: 'Objeto de uso doméstico utilizado para medir con mayor precisión la hora del día. Está compuesto por tres piezas fundamentales: la primordial consiste en un armazón cilíndrico, con una cara de vidrio transparente donde se observan las manijas que asignan el horario y la numeración del 1 al 12. La segunda pieza la conforman dos pilares, adheridos a cada lado de la estructura, y finalmente una base donde se adhieren los pilares para dar un apoyo sólido para poder sostenerse. La base y los laterales de la estructura cilíndrica son color negro, y los pilares color plata. En la superficie cilíndrica se observa la inscripción “MADE IN CHINA" y un ave sobre la misma. '
                },
                {
                    id: 'PC0418',
                    description: 'Objeto utilizado como parte del servicio de mesa para servir y beber líquidos calientes. Es un contenedor de forma cilíndrica con mayor diámetro en el extremo superior. Las paredes son rectas y convergen en la superficie produciendo una elevación sobre una breve moldura abultada, la base es plana. Posee un asa adherida, del mismo material, en su lateral. Es de metal enlozado color verde agua casi en tu totalidad, exceptuando una zona negra en la parte superior del asa. Posee pérdida de color, posiblemente por desgaste por uso.'
                },
                {
                    id: 'PC0419',
                    description: 'Objeto utilizado para retener el testigo, evitando que éste se devuelva. Está compuesto por una pieza cilíndrica, con una franja en uno de sus laterales que la traspasa completamente de forma vertical. En el interior posee una estructura dentada. '
                },
                {
                    id: 'PC0420',
                    description: 'Objeto utilizado para indicar o señalar un lugar, un número u otros. Su forma es rectangular, de poco grosor y se encuentra dispuesto de manera vertical. Se observan orificios por donde se anclaba el objeto, a una superficie plana, mediante tornillos. Fue elaborado en metal. Predomina el color celeste con una inscripción roja donde se alcanza a leer “GENERAL MOTORS" con un lettering distintivo. Posee desgaste por uso. '
                },
                {
                    id: 'PC0421',
                    description: 'Objeto utilizado como parte interior de la estructura de un Jack Leg. Esta pieza se ubica entre el frontal y el pistón. Está compuesto por tres componentes: el mandril es de forma cilíndrica ahuecada, con una pieza más abultada en la zona superior; la bocina se encuentra en el interior, también es de forma cilíndrica y sobresale en la zona superior; el último componente es una tuerca de forma hexagonal ubicada en el extremo opuesto. Fue elaborada en metal y posee una hebra café anudada en el extremo que genera una diferencia de grosor. Se observa oxidación. '
                },
                {
                    id: 'PC0422',
                    description: 'Objeto utilizado como parte interior de la estructura de un Jack Leg. Esta pieza se ubica entre frontal y el pistón. Está compuesto por una pieza cilíndrica ahuecada. La superficie es lisa y pose orificios circulares en distintos sectores. Fue elaborada en metal. Se observa oxidación.'
                },
                {
                    id: 'PC0423',
                    description: 'Objeto utilizado como parte interior de la estructura de un Jack Leg. Esta pieza se ubica al interior del mandril. Existen dos ejemplares en bodega, el primero de aspecto cilíndrico con un mayor diámetro en uno de sus extremos y que en el interior tiene una forma de tuerca hexagonal. El otro ejemplar también es cilíndrico, pero posee una estructura menos lisa en la parte superior, se observa una forma hexagonal tanto en el interior como en el exterior. Ambas poseen orificios en los latearles. '
                },
                {
                    id: 'PC0424',
                    description: 'Objeto utilizado, en conjunto con otros elementos, como parte de las transmisiones de potencia de maquinaria eléctrica. Si bien es utilizado en máquinas giratorias, el estator es una pieza que se mantiene fija. Es de forma circular, con dos piezas metálicas superiores y un orificio central dentado para permitir anclar el rotor y traspasar la energía. La pieza posee, en uno de sus lados, una placa metálica adherida por medio de cuatro tornillos donde se divisan detalles como el modelo, el voltaje, entre otros, y destaca la marca de fabricación y distribución STEWART STEVENSON.'
                },
                {
                    id: 'PC0425',
                    description: 'Objeto utilizado como sujeción del mandril, en el interior de la estructura de un Jack Leg. Está compuesto por una pieza con rosca exterior y el interior posee forma de tuerca cabeza de Phillips o estrella. Fue elaborado completamente de metal. '
                },
                {
                    id: 'PC0426',
                    description: 'Objeto utilizado como soporte para poder anotar o escribir con mayor comodidad. Está compuesto por dos piezas: por un lado, una estructura de madera plana trapezoide, con un orifico en la parte superior, por otro lado, una hoja de papel con claros indicios de haber sido adherida a la madera mediante corchetes. Se observan nombres numerados a lo largo de toda la hoja y pérdidas del papel en la parte inferior derecha. '
                },
                {
                    id: 'PC0427',
                    description: 'Recipiente utilizado en la cocina para la preparación de alimentos. Tiene forma cilíndrica ahuecada con base plana. Está compuesto por una base, dos asas dispuestas de manera simétrica y la tapa, que a su vez posee un asa rectangular en la parte superior. Fue elaborado en metal esmaltado con técnica de vaciado. La pieza es verde tanto en el exterior como en el interior. Tanto las asas, el borde y la tapa son de color negro. Posee orificios en la base, posiblemente por desgaste por uso. '
                },
                {
                    id: 'PC0428',
                    description: 'Objeto utilizado como parte interior de la estructura de un Jack Leg. Esta pieza se ubica entre el mandril y el cilindro. Está compuesto por un solo componente de forma circular ahuecado con una pieza alargada conocida como brazo de biela, que posee forma helicoidal, terminando en forma de cruz. Fue elaborado completamente en metal y se observa oxidación.'
                },
                {
                    id: 'PC0429',
                    description: 'Objeto utilizado como instrumento de medición. Se le conoce coloquialmente como huincha de medir. Fue elaborado en tela. Predomina el color blanco, amarilleado por el paso del tiempo. Posee detalles azules oscuros y rojos. El objeto se encuentra enredado y en estado frágil, por lo que se mantiene en depósito con su forma enredada y no es posible afirmar su largo total. '
                },
                {
                    id: 'PC0430',
                    description: 'Objeto utilizado como parte interior de la estructura de un Jack Leg. Esta pieza se ubica dentro del cilindro y ayuda en el movimiento de rotación de la barra perforadora. Está compuesto por una pieza cilíndrica helicoidal que termina en una cruz helicoidal. Fue elaborado completamente en metal y se observa oxidación.'
                },
                {
                    id: 'PC0431',
                    description: 'Objeto originalmente utilizado en embarcaciones para trabar un barco o evitar derribes. Está compuesto por una sola pieza con forma de arpón o anzuelo, con puntas rematadas en forma de flecha. En la parte superior se observa una estructura redondeada que la cruza paralelamente y que termina con una esfera a cada lado. Fue elaborado completamente en hierro. Predomina el color celeste, sin embargo, posee varias pérdidas de color por oxidación. Se encuentra in situ. '
                },
                {
                    id: 'PC0432',
                    description: 'Objeto utilizado para la perforación de la roca, es un componente del Jack Leg. Se conforma por una pieza cilíndrica ahuecada con hilo de acople en ambos extremos de la barra, el inferior en HE y el superior en HI, permitiendo la realización el mecanismo de rotación. '
                },
                {
                    id: 'PC0433',
                    description: 'Objeto utilizado como parte del servicio de mesa para servir y beber líquidos calientes. Es un contenedor de forma cilíndrica. Las paredes son rectas y convergen en la superficie produciendo una elevación sobre una moldura levemente abultada; la base posee un apoyo de pie circular. Fue elaborado en cerámica, con moldeo en barbotina y esmaltado. Predomina el color blanco, exceptuando un estampado vegetal color rojo, a lo largo de la superficie. Se observa que la pieza tuvo un asa, pero está quebrada.'
                },
                {
                    id: 'PC0434',
                    description: 'Su función es mantener alineado al tubo interior donde se aloja la muestra mientras se perfora y darle la estabilidad necesaria con la vibración de la máquina. Objeto utilizado para mantener alineado al tubo interior donde se aloja la muestra mineral, mientras se perfora, y darle la estabilidad necesaria con la vibración de la máquina. Está conformado por un solo componente circular ahuecado con rosca interior para asegurar la sujeción. La superficie se encuentra oxidada, pero el interior se conserva en buen estado.'
                },
                {
                    id: 'PC0435',
                    description: 'Objeto utilizado para transformar la energía mecánica mediante el empuje, la potencia y la velocidad con que gire. Está compuesto por cuatro aspas ladeadas unidas a un eje central circular. Posee en el centro una estructura rectangular que asegura el agarre o acople a otra pieza. Predomina el color rojizo de óxido. Posee desgaste por uso en distintos sectores de las aspas. '
                },
                {
                    id: 'PC0436',
                    description: 'Objeto utilizado para perforar la roca. Tiene forma cilíndrica ahuecada, de mayor grosor en uno de sus lados. La pieza es lisa, exceptuando el lado más grueso donde se observa una superficie con relieve y con pequeños diamantes incrustados. Se observa corrosión en toda la superficie. '
                },
                {
                    id: 'PC0437',
                    description: 'Objeto utilizado como parte interior de la estructura de un Jack Leg. Esta pieza intermedia se ubica entre el pistón y el mandril. Está compuesta por dos piezas de similares características, coincidiendo en que ambas son cilíndricas y poseen un extremo dentado de tal forma que pueden machihembrase la una con la otra. Ambas tienen superficie lisa, pero una de ellas pose una pérdida de la circunferencia en el extremo contrario, terminando en una pieza tipo tuerca hexagonal. La pieza completa fue elaborada en metal. Se observa oxidación. '
                },
                {
                    id: 'PC0438',
                    description: 'Objeto utilizado como soporte para poder anotar o escribir con mayor comodidad. Está compuesto por dos piezas: por un lado, una estructura de madera plana rectangular, con un orifico en la parte superior, por otro lado, un conjunto de hojas de papel con claros indicios de haber sido adheridas a la madera mediante corchetes. Se encuentra en un mal estado de conservación: se observan la madera roída y el papel oxidado. Se logran divisar ciertos nombres numerados, escritos con tinta azul.'
                },
                {
                    id: 'PC0439',
                    description: 'Herramienta manual utilizada para apretar o soltar tuercas y tornillos. Está compuesta por una sola pieza alargada y plana. Uno de sus extremos termina en una estructura de corona de seis puntas.'
                },
                {
                    id: 'PC0440',
                    description: 'Prenda de vestir utilizada como complemento de la camisa, principalmente masculina que se considera indispensable en ciertos ambientes formales. Está elaborada con una tela alargada, enlazada en sí misma para dar la circunferencia necesaria para colgar del cuello, dejando caer sus extremos. Posee un diseño principalmente azul simétrico con formas cuadradas y unas líneas transversales en color crema. En la parte posterior posee un etiquetado donde se alcanza a leer “TREVIDA".'
                },

                {
                    id: 'PC0442',
                    description: 'Herramienta manual utilizada para apretar o soltar tuercas y tornillos. Está compuesta por una sola pieza alargada y plana que termina en una pieza esférica de mayor grosor, la cual termina en una estructura de corona de seis puntas.'
                },

                {
                    id: 'PC0445',
                    description: 'Parte de la estructura del cabezal de perforación, utilizada específicamente para la unión de la barra de perforación con la corona de diamantina. Es de forma cilíndrica y ahuecada en el centro. Uno de sus extremos posee unión rosca y la otra unión lisa (también conocida como unión macho/hembra). Fue fabricado en metal por extrusión. Existen cuatro ejemplares de similares características en bodega. Una de ellas poseía en el interior un trozo de papel doblado.'
                },
                {
                    id: 'PC0446',
                    description: 'Herramienta manual utilizada para apretar o soltar tuercas y tornillos. Está compuesta por una pieza alargada y plana que termina en dos puntas abiertas fijas que permiten una correcta sujeción. Se observa la inscripción SKANDIA en el mango y una numeración en la parte superior. '
                },
                {
                    id: 'PC0447',
                    description: 'Herramienta manual utilizada para apretar o soltar tuercas y tornillos. Está compuesta por una pieza alargada y plana que terminan en dos puntas abiertas fijas que permiten una correcta sujeción. Posee un orificio en la parte inferior del mango.'
                },
                {
                    id: 'PC0448',
                    description: 'Objeto utilizado para la extracción de aceites, agua, polvos y otras partículas. El objeto es de forma cilíndrica con dos piezas circulares situadas a cada extremo. En el interior se observan telas dispuestas de manera vertical y una externa, que protege el interior, dispuesta de manera horizontal. Esta última destaca por la variedad simétrica de orificios. Existen dos ejemplares dispuestos en la bodega que difieren en su tamaño. El más grande posee una impresión oscura donde se lee “FILTRO ORIGINAL PARA MOTOR CUMMINS DIESEL" y en el lateral de la misma pieza se observa “N°139.097. FF 108", “Industria argentina" El ejemplar más pequeño posee en la misma tela la inscripción “REFILA".'
                },
                {
                    id: 'PC0449',
                    description: 'Parte de la estructura del cabezal de perforación, utilizada específicamente para la unión de la barra de perforación con la corona de diamantina. Es de forma cilíndrica y ahuecada en el centro. Uno de sus extremos posee unión rosca y la otra unión lisa (también conocida como unión macho/hembra). Fue fabricado en metal por extrusión. Existen cuatro ejemplares de similares características en bodega que difieren principalmente en le colores y largos.'
                },
                {
                    id: 'PC0450',
                    description: 'Objeto utilizado como parte del cabezal de perforación del Jack Leg, que se ubica entre el candado y el resorte del cabezal de perforación de diamantina. Esta pieza se encarga de transmitir y soportar tanto fuerzas axiales como de torsión. Está compuesto por una pieza cilíndrica, que tiene un corte vertical en uno de sus laterales (para tener diferentes juegos de presión cuando pasa el testigo) y un orificio ubicado en uno de sus laterales. En bodega se encuentran dos ejemplares que varían en su tamaño.'
                },
                {
                    id: 'PC0451',
                    description: 'Objeto utilizado como parte de la estructura de una motosierra que, en conjunto de una rueda dentada, empuja la biela haciendo girar un cigüeñal. Está compuesto por sesenta y ocho escoplos y cada uno asociado a un eslabón motriz. Tanto los eslabones cortantes como los talones de profundidad se encuentran en mal estado.'
                }
            ]

            let arr10 = [{
                    id: 'PC0451',
                    description: 'Objeto utilizado como parte de la estructura de una motosierra que, en conjunto de una rueda dentada, empuja la biela haciendo girar un cigüeñal. Está compuesto por sesenta y ocho escoplos y cada uno asociado a un eslabón motriz. Tanto los eslabones cortantes como los talones de profundidad se encuentran en mal estado.'
                },
                {
                    id: 'PC0452',
                    description: 'Conjunto de objetos posiblemente utilizados para identificar o enumerar materiales. En su mayoría predominan las formas rectangulares. El conjunto está compuesto por más de veinte piezas metálicas adheridas entre sí por medio de un alambre que traspasa todas las fichas gracias a un orificio en la parte superior de cada una de ellas. Se observa en las fichas una numeración en color rojo.'
                },
                {
                    id: 'PC0453',
                    description: 'Parte de la estructura del cabezal de perforación de diamantina de un Jack Leg, que se ubica en el tubo de perforación interior y que es utilizado específicamente para evitar que el testigo retroceda mediante dos diámetros diferentes en su forma circular.'
                },
                {
                    id: 'PC0454',
                    description: 'Objeto utilizado para la extracción de aceites, agua, polvos y otras partículas. El objeto es de forma cilíndrica con un orificio en el medio que traspasa la estructura. La parte exterior fue elaborada en metal y en la base se observan agujeros dispuestos de manera simétrica que dejan ver el material interior fabricado en filtro de papel. El exterior es blanco con dos franjas donde se observan diferentes inscripciones. La primera es de color negro y se observan las palabras FILTER. CAV. ELEMENT. Bajo esta franja se observa una de color celeste donde se observan las inscripciones PATENT NO. 693495. MADE IN ENGLAND. PART NO. 7111/44.'
                },
                {
                    id: 'PC0455',
                    description: 'Accesorio de tuberías utilizado para la unión de dos piezas, generando una curvatura de 90 grados entre ambas. Tiene forma tubular, de acero roscado tipo HI por ambos lados. Es de color plomo. Pose una inscripción en su latera con forma de F, inscrito en el centro de un triángulo. El lateral opuesto también posee una inscripción donde se observa el número 2. '
                },
                {
                    id: 'PC0456',
                    description: 'Parte de la estructura de una perforada Jack Leg. El lubricador es de estilo Joy Football y su objetivo principal es el de prevenir el desgaste prematuro de las piezas móviles. Está conformado por una pieza ovalada ahuecada. Uno de sus extremos termina en una superficie de tuerca hexagonal, mientras la otra presenta dos tuercas de similares características. Uno de sus latearles presenta un orificio circular que sobresale del plano. Posee una inscripción con relieve ilegible. '
                },
                {
                    id: 'PC0457',
                    description: 'Objeto utilizado para adherir o unir dos barras para transmitir mayor fuerza mecánica, en un jack Leg. Su forma es cilíndrica y uno de sus extremos termina con hilo externo en forma de rosca. Su extremo contrario presenta hilo interno. Por fuera es circular y liso.'
                },
                {
                    id: 'PC0458',
                    description: 'Parte de la estructura de una perforada Jack Leg. Su objetivo principal es el de prevenir el desgaste prematuro de las piezas móviles. Está conformado por una pieza cilíndrica ahuecada. Uno de sus extremos termina en una superficie de tuerca hexagonal, mientras la otra presenta una tuerca de similares características, pero con una pieza cilíndrica adherida a un costado. Uno de sus laterales presenta un orificio circular que sobresale del plano. '
                },
                {
                    id: 'PC0459',
                    description: 'Parte de la estructura de una perforada Jack Leg. Su objetivo principal es el de prevenir el desgaste prematuro de las piezas móviles. Está conformado por una pieza ovalada con un acople cilíndrico similar a una barra que traspasa todo el interior. La superficie es lisa y la barra posee hilo externo en forma de rosca en uno de sus lados, mientras el otro presenta terminación circular con agarre. En la superficie del cuerpo oval se observa un orificio céntrico y al lado de éste una especie de tapón o llave de paso. En la parte superior se observa una flecha grabada. '
                },
                {
                    id: 'PC0460',
                    description: 'Recipiente para guardar o transportar líquidos. Es de forma cilíndrica y posee un cierre hermético que evita el derrame. En la parte superior se observa una boquilla cilíndrica que sobresale del plano, utilizada tanto para llenar el recipiente como para extraer los líquidos internos. Al lado de esta pieza se observa un asa metálica adherida por un remache a cada lado que permitía el traslado. El objeto es de manufactura casera.'
                },
                {
                    id: 'PC0461',
                    description: 'Objeto utilizado para el disfrute y entretención de niños. Tiene forma antropomorfa y representa la cabeza de un infante, observándose facciones completas. En el área del cabello se observan pérdidas importantes, sobre todo en la parte posterior. La base del objeto presenta un agujero donde se adhería el cuerpo. Fue elaborado principalmente en plástico y el cabello de algún material de fibra sintética no identificado. Predomina el color rosado pálido en el rostro y castaño oscuro para el cabello. El objeto se encuentra incompleto y en mal estado de conservación. '
                },
                {
                    id: 'PC0462',
                    description: 'Parte de la estructura del cabezal de perforación, utilizada específicamente para la unión de la barra de perforación con la corona de diamantina. Es de forma cilíndrica y ahuecada en el centro. Uno de sus extremos posee unión rosca y el otro unión lisa (también conocida como unión macho/hembra). Fue fabricado en metal por extrusión. Se observa que esta pieza aún conserva la corona en uno de sus extremos. Posee un etiquetado circular color amarillo rojo y negro donde se alcanza a leer BOART S.A. BELGIUM.'
                },
                {
                    id: 'PC0463',
                    description: 'Objeto utilizado para mantener y expulsar el agua del inodoro accionando de este modo la cisterna y generando la eliminación de los residuos de la taza. Está compuesto principalmente por tres piezas, la de mayor tamaño y que da la estructura al objeto tiene forma de campana ahuecada, con un orificio en la parte superior donde se observa una pieza cilíndrica atravesándola de lado a lado. A esta pieza horizontal se le adhiere otra pieza del mismo material con forma de cruz que posee, en uno de sus extremos, un alambre. '
                },
                {
                    id: 'PC0464',
                    description: 'Objeto utilizado para cuidar los oídos de los ruidos fuertes y constantes de herramientas o maquinarias de construcción. Presentan una sujeción del tipo diadema que permite amoldarse a la curvatura de la cabeza. Fue elaborado en metal, plástico y espuma. Predomina el color azul, con detalles negros. El objeto, si bien cuenta con ambos auriculares, no es posible adherirlos entre sí ya que la sujeción del tipo diadema está rota. Las almohadillas que evitan la entrada del ruido también están rotas.'
                },
                {
                    id: 'PC0465',
                    description: 'Objeto utilizado para evitar la entrada de materiales o químicos en los ojos. Se compone de una pieza frontal que cubre los ojos, con una protección en los lados con orificios simétricos. Posee un elástico ensamblado en los laterales, que es ajustable a la cabeza del usuario. Fue elaborado principalmente en plástico transparente y blanco, moldeado por compresión. '
                },
                {
                    id: 'PC0466',
                    description: 'Objeto utilizado en el uso cotidiano para calentar o preparar bebestibles como té o café. Está compuesto por dos partes, el cuerpo o vasija de lata con forma cilíndrica y un asa de metal trenzada para dar mayor estabilidad, adherida por sus laterales. Se observa adherido a la lata un etiquetado de color amarillo y donde se aprecia la marca NESQUIK. Es de confección artesanal. La base se encuentra quemada debido al uso constante.  '
                },
                {
                    id: 'PC0467',
                    description: 'Objeto utilizado en redes de agua o gas domiciliaras para unir dos secciones de tubería metalizadas. Su forma es cilíndrica con hilo de rosca en todo su interior. Por fuera es circular y liso exceptuando cuatro relieves verticales que sobresalen del plano y están ubicados de manera equidistante los unos de los otros. Fue elaborado en bronce. '
                },
                {
                    id: 'PC0468',
                    description: 'Objeto utilizado en redes de agua o gas para montar o desmontar artefactos que se encuentren fijos en espacios domiciliarios. El objeto es cilíndrico en su interior y por fuera se observa una pared octagonal, con una tuerca de mayor tamaño en el medio con la misma cantidad de lados. Tiene terminales de hilo interno (HI) por un lado y por el otro tiene una unión tipo hembra y lisa (SO). '
                },
                {
                    id: 'PC0469',
                    description: 'Objeto utilizado para dividir o combinar tuberías, logrando de este modo ajustar o cambiar el flujo de dirección. Tiene forma cilíndrica y posee tres bocas o ramales, uno a cada extremo y otro en el centro dispuesto de manera transversal, todos con HI. Al ser una tee reducida se observa que el diámetro del ramal es menor que el de la línea principal. Se observa en su lateral una inscripción con las iniciales A.F. En el sector contrario es posible identificar números como 3, 2 y 5.'
                },
                {
                    id: 'PC0470',
                    description: 'Objeto utilizado para la fijación de metales, generalmente en la industria automotriz. Conocida también como tuerca castillo, posee una estructura hexagonal ranurada y dentada, con hilo interior de rosca. Existen dos ejemplares en el depósito que se diferencian en el tamaño.'
                },
                {
                    id: 'PC0471',
                    description: 'Objeto utilizado en redes de agua o gas domiciliaras para unir dos secciones de tubería metalizadas. Su forma es cilíndrica con hilo de rosca en todo su interior. Por fuera es circular y liso exceptuando cuatro relieves verticales que sobresalen del plano y están ubicados de manera equidistante los unos de los otros. Fue elaborado posiblemente en acero galvanizado.'
                },
                {
                    id: 'PC0472',
                    description: 'Objeto utilizado para dividir o combinar tuberías, logrando de este modo ajustar o cambiar el flujo de dirección. Tiene forma cilíndrica y posee tres bocas o ramales, uno a cada extremo y otro en el centro dispuesto de manera transversal, todos con HI. Todos los diámetros de los ramales son idénticos a la línea principal. En su lado posterior se observa una inscripción de un rombo con las iniciales FF en su interior.'
                },
                {
                    id: 'PC0473',
                    description: 'Objeto utilizado en redes de agua o gas domiciliaras para unir dos secciones de tubería metalizadas. Su forma es cilíndrica con hilo de rosca en todo su interior. Por fuera es circular y liso exceptuando cuatro relieves verticales que sobresalen del plano y están ubicados de manera equidistante los unos de los otros. Fue elaborado posiblemente en acero galvanizado. Se observan números inscritos en el plano liso, tales como 1 y 8.'
                },
                {
                    id: 'PC0474',
                    description: 'Objeto utilizado para dividir o combinar tuberías, logrando de este modo ajustar o cambiar el flujo de dirección. Tiene forma cilíndrica y posee tres bocas o ramales, uno a cada extremo y otro en el centro dispuesto de manera transversal, todos con HI. Todos los diámetros de los ramales son idénticos a la línea principal. En su lado posterior se observa una inscripción con forma poligonal. '
                },
                {
                    id: 'PC0475',
                    description: 'Objeto de plomería utilizado como acople rápido para conectar y/o alargar mangueras. Está conformado por una sola pieza ahuecada, con un cabezal con doble agarre en un extremo y una superficie cilíndrica con relieve circular de distinto grosor. '
                },
                {
                    id: 'PC0476',
                    description: 'Objeto utilizado como parte del servicio de mesa para servir alimentos. Tiene forma circular ligeramente cóncava y un pie de apoyo también circular. Fue elaborado en cerámica con moldeo en barbotina y esmaltado. El color predominante es el blanco, posee un diseño floreado color azul ubicado en forma concéntrica. Posee una inscripción en la base donde se observa la palabra “LOZA PENCO. S.A. CHILE".'
                },
                {
                    id: 'PC0477',
                    description: 'Objeto utilizado, como parte sistémica fundamental, para hacer funcionar las maquinarias mediante el movimiento y la transformación de energía. Se caracteriza por necesitar poco combustible. El proceso consiste en aprovechar el aire caliente que posee la maquinaria en su parte interna, para luego inyectar el combustible, acción que permite una eficiencia del 50%. Su forma es irregular y está fijado al suelo mediante vigas de acero. Fabricado completamente en metal sin pintar. Posee en distintos lados de sus laterales la inscripción manual en plumón negro de COD 2070.'
                },
                {
                    id: 'PC0478',
                    description: 'Objeto de instalación fija utilizado como radiorreceptor en los vehículos. Está compuesto por un cuerpo metálico rectangular. En la parte frontal posee dos tuercas que funcionan como perillas. En el centro se observa una pantalla negra numerada que termina en KHZ/ MHz y bajo esta zona cinco botones. La superficie superior posee una etiqueta blanca adherida. '
                },
                {
                    id: 'PC0479',
                    description: 'Conjunto de objetos utilizados para perforar la roca. Tienen forma cilíndrica ahuecada, de mayor grosor en uno de sus lados. Las piezas son lisas en un extremo y en el otro (el lado más grueso) se observa una superficie con relieve y con pequeños diamantes incrustados. La mitad del cuerpo cilíndrico está formado por hilo externo tipo rosca. Se observa corrosión en toda la superficie. Existen cuatro ejemplares de distinto tamaño y color en el depósito. Uno de ellos posee en su interior un papel o material que funciona como tapón.'
                },
                {
                    id: 'PC0480',
                    description: 'Objeto de plomería utilizado para distribuir líquidos o gases. Está compuesto por una sola pieza cilíndrica lisa y ahuecada, con bordes irregulares. Es de color plomo.'
                },
                {
                    id: 'PC0481',
                    description: 'Estructura utilizada para arreglar, organizar, regular u ordenar diferentes objetos. Es de forma rectangular compuesto por 12 compartimientos, distribuidos simétricamente en filas de 3x4. Fue elaborado en madera ensamblada sin pintar. En su lateral frontal se observan cuatro etiquetados blancos adheridos con cinta adhesiva. En el primero se puede leer “DETALLE. ¾ Hilo externo. CODIGO: 08057-8", en el segundo no se alcanza a leer nada, el tercero está incompleto, pero se observa el código “08013-6" y en el último se puede leer “DETALLE: Complas Cobre ½ CODIGO 08028-4".'
                },
                {
                    id: 'PC0482',
                    description: 'Objeto utilizado como parte de la estructura de la perforadora jack leg. Se ubica específicamente entre el frontal y el cilindro. El objeto completo está compuesto por tres piezas que se acoplan entre sí (mandril, pistón y tuerca). La primera es una pieza cilíndrica que termina en una especie de tuerca castillo que permite la conexión con la segunda pieza, que es cilíndrica pero más achatada. La última pieza es de color negro, con un diámetro mínimo mucho más pequeño que las otras dos, pero que se ensancha al llegar al final. En bodega se encuentran todos los componentes. '
                },
                {
                    id: 'PC0483',
                    description: 'Objeto utilizado en el área médica para poder escuchar los sonidos cardiacos de un feto. Está conformado por una sola pieza de dos conos unidos entre sí, el más pequeño es el que recibe los sonidos ampliándolos hacia el más grande. Es ahuecado, completamente elaborado en metal. La pieza completa es color negro. '
                },
                {
                    id: 'PC0484',
                    description: 'Objeto utilizado para reproducir música de manera portátil, que, además, integra un aparato para la reproducción de una cinta de casete. El objeto es rectangular, en la parte posterior presenta diferentes inscripciones relacionadas con el cuidado y el uso,  Además, del modelo. En la parte frontal se observan diferentes orificios donde probablemente iban perillas para poder mover y ajustar tanto el sonido como la transmisión. El objeto está incompleto ya que se observa la falta de la tapa del casete y, además, los botones de reproducción se encuentran trabados. Predomina el color metálico y posee franjas horizontales color celeste.'
                },
                {
                    id: 'PC0485',
                    description: 'Objeto utilizado como insignia o símbolo de un lugar. El objeto tiene forma de pentágono irregular. Posee flequillos de hilo en todos sus lados exceptuando el superior. Fue elaborado en tela. Predomina el color blanco, exceptuando el flequillo que es de color dorad, y el diseño de las letras de la parte frontal que son celestes y rojas. Se observan las palabras “CENTRO DE MADRES. FUND. 10-10 AÑO 1971 LOS – CRISTALELES. PTO. CRISTAL". La parte posterior tiene bastante suciedad adherida. '
                },
                {
                    id: 'PC0486',
                    description: 'Objeto utilizado para llevar un registro de los días y meses del año en curso. Está compuesto principalmente de un rectángulo impreso de cartón y un conjunto de papeles también impresos. El cartón tiene un alambre en la parte superior, dispuesto para poder ser colgado. Se observa la imagen de una niña en una granja alimentando a unas gallinas. Las hojas son blancas, con impresión azul y detalles rojos. Se observan dos meses por hoja. El objeto se encuentra en mal estado de observación, arrugado y con suciedad adherida.'
                },
                {
                    id: 'PC0487',
                    description: 'Contenedor utilizado para contener licor de piña colada. Es de forma cilíndrica en cuello y cuerpo, con hombros redondeados. La superficie tiene relieves en todo el cuerpo. Está elaborado en vidrio soplado y moldeado con tapa de aluminio. Mantiene una etiqueta de papel adherida. Se observa la marca “MAI TAI".'
                },
                {
                    id: 'PC0488',
                    description: 'Prenda de vestir de infante utilizada para cubrir los pies. Está compuesto por diferentes correas sujetas a una suela que dan la forma del calzado o sandalia. Fue confeccionado mayoritariamente en cuero, a excepción de la hebilla de hierro. Es mayoritariamente de color blanco. Se observa en la planta la inscripción de la marca nacional CALIPSO. En el depósito solo se encuentra el ejemplar izquierdo.'
                },
                {
                    id: 'PC0489',
                    description: 'Objeto utilizado para contener líquidos. Su forma es cilíndrica con cuello estrecho que finaliza en rosca. Posee un apoyo de botella con relieve. Fue elaborado en plástico transparente no retornable, al igual que el etiquetado que es color blanco y rojo. Se observan diferentes inscripciones destacando la marca “FREE" y la cantidad de líquido que poseía “1 ½ lts". El objeto aún conserva la tapa.'
                },
                {
                    id: 'PC0490',
                    description: 'Objeto utilizado para evitar golpes fuertes de la puerta. Su forma es parecida a la de un bolo, con una superficie casi esférica, que es de menor tamaño que la base que lo sostiene. Posee facciones antropomorfas destacando el rostro y vestuario: se observa como si vistiesen de terno y corbata. Fueron elaborados de manera artesanal, destacando el color blanco con detalles negros, azules y rojos. Su conservación es regular, presentan lugares deshilachados y suciedad adherida. En el depósito se observan dos ejemplares de similares características, pero uno de ellos, además, posee un trozo de tela a modo de bufanda. '
                },
                {
                    id: 'PC0491',
                    description: 'Objeto utilizado como tiesto o vasija decorativa para poder plantar diferentes flores. Fue elaborado con dos piezas, la principal tiene forma de semiesfera invertida y ahuecada para poder utilizar el espacio con tierra y plantaciones. En la parte superior posee una especie de marco de metal para poder sostener objetos o funcionar como asiento. Predomina el color celeste. Producto de manufactura casera. Debido a sus grandes dimensiones se encuentra in situ. '
                },
                {
                    id: 'PC0492',
                    description: 'Objeto utilizado para anunciar o indicar una información específica. Es de forma rectangular. Fue fabricado con dos componentes, una madera de trupán como apoyo y un adhesivo del porte total de la superficie, pero con las esquinas redondeadas. Predomina el color blanco y verde. Se observa la ilustración de unas manos cerca de una especie de rodillo y se distinguen las palabras “CUIDADO" y las siglas “ACHS". '
                },
                {
                    id: 'PC0493',
                    description: 'Objeto utilizado para anunciar o indicar una información específica. Es de forma rectangular con los bordes redondeados. Fue elaborado en una sola pieza plástica con impresión. Predomina el color blanco y azul. Se observa una ilustración circular y en el centro una especie de engranaje. Se distinguen las palabras “MANTENGA PROTECCIÓN" y las siglas “ACHS" en color verde. '
                },
                {
                    id: 'PC0494',
                    description: 'Objeto utilizado para anunciar o indicar una información específica. Es de forma rectangular. Fue fabricado con dos componentes, una madera de trupán como apoyo y un adhesivo del porte total de la superficie. Predomina el color blanco con detalles rojos, negros y amarillos. Se observa la ilustración de una persona cruzando la calle y se distinguen las palabras “AL CRUZAR USE LAS VIAS PEATONALES" y las siglas “ACHS". En la parte posterior se observan manchas que podrían ser producidas por humedad o por hongos. '
                },
                {
                    id: 'PC0495',
                    description: 'Objeto de uso industrial utilizado para pesar objetos. Está conformado por una base rectangular de metal que sostiene una estructura también rectangular pero dispuesta de modo vertical, como un poste. En la parte superior y puesta de forma perpendicular dispone de una masa que se desplaza sobre una escala de graduación negra, logrando la estabilidad una vez encontrado el peso exacto del objeto en cuestión. En el borde de esta escala se proyecta un objeto donde van anclados contrapesos. El objeto se encuentra in situ debido a su tamaño.'
                },
                {
                    id: 'PC0496',
                    description: 'Objeto de uso industrial utilizado para pesar objetos y se conoce también como balanza romana. Está conformado por una base rectangular que sostiene una estructura también rectangular, pero dispuesta de modo vertical, como un poste. En la parte superior y puesta de forma perpendicular dispone de una estructura que se sostiene por medio de un gancho y que termina con contrapesos al otro extremo, desde donde se podían anclar otros contrapesos. Para facilitar la movilidad, posee cuatro ruedas. El objeto está elaborado en su mayoría en madera, con refuerzos metálicos. El objeto se encuentra in situ debido a su tamaño.'
                },
                {
                    id: 'PC0497',
                    description: 'Documento utilizado para orientar y establecer pautas para desarrollar correctamente actividades o procedimientos específicos. El objeto está conformado por una tapa y contratapa de mayor grosor y durabilidad. Dentro de éste se observa una encuadernación tipo archivero, donde se unen las hojas mediante tres ojales dispuestos de manera equidistante los unos de los otros, en la misma línea vertical ubicada en el lateral izquierdo. El objeto cuenta con cuatro tipos de materialidades diferentes: plástico para la portada, metal para el soporte y organización interno, papel para las hojas y cartón para distribuir secciones laterales. Se observa a modo de titulo la impresión en la portada y el lomo de “MSA Guía de Equipo de Seguridad" y en el interior se observa información del mismo tema. Predomina el color blanco en la portada,  Además, de un diseño ilustrativo en variedad de colores. '
                },
                {
                    id: 'PC0498',
                    description: 'Objeto utilizado para direccionar la luz de una lámpara. Tiene forma de cono truncado. La parte superior cuenta con una forma de cilindro con un agujero. En el mismo sector tiene amarrado un alambre grueso y revestido de plástico. Fue elaborado en hierro esmaltado, la parte exterior es verde y la interior blanca.'
                },
                {
                    id: 'PC0499',
                    description: 'Concreciones minerales utilizadas como muestras de material. Tienen forma irregular y se componen por tres piezas de diferentes tamaños y colores. Se reconocen los minerales de plomo y zinc gracias a la observación de sus características físicas como color, porosidad, entre otros. '
                },
                {
                    id: 'PC0500',
                    description: 'Concreciones minerales utilizadas como muestras de material. Tienen forma irregular y se componen por dos piezas de diferentes tamaños. Se reconocen los minerales de roca o granito gracias a la observación de sus características físicas como color, porosidad, entre otros.'
                }
            ]

            let arr11 = [{
                    id: 'PC0501',
                    description: 'Objeto de carácter industrial utilizado para generar cortes. Está compuesto por una base rectangular de grandes dimensiones utilizada para concentrar la fuerza mecánica necesaria para la realización de los cortes. Sobre esta estructura se encuentra una de menor tamaño, adherida en la parte frontal de la base, de forma irregular que presenta una cuchilla rectangular y que mediante un mango alargado lograría ejercer la presión necesaria. El objeto se encuentra in situ.'
                },
                {
                    id: 'PC0502',
                    description: 'Concreciones minerales utilizadas como muestras de material. Tienen forma irregular y se componen por dos piezas de diferentes tamaños. Posiblemente se trata de los minerales de roca caliza identificadas gracias a la observación de sus características físicas como color, porosidad, entre otros. '
                },
                {
                    id: 'PC0503',
                    description: 'Objeto utilizado para almacenar pintura. Tiene forma cilíndrica con una tapa redonda acoplada a presión y dispuesta de manera tal que queda sellado al vacío. Posee un asa adherida a dos de sus laterales fabricadas por alambre grueso para facilitar el transporte. Destacan los colores: blanco, rojo y mostaza. Se observa la inscripción impresa de fabrica “PINTURAS CORDILLERA" e inscripciones manuales realizadas en tinta azul “LATEX VINILICO. BLANCO". Fue elaborado completamente en hojalata. No es posible comprobar si posee el producto en el interior. '
                },
                {
                    id: 'PC0504',
                    description: 'Concreciones minerales utilizadas como muestras de material. Tienen forma irregular y se componen por dos piezas de diferentes tamaños. Se reconocen los minerales de cristales de calcita gracias a la observación de sus características físicas como color, porosidad, entre otros.'
                },
                {
                    id: 'PC0505',
                    description: 'Representación tangible y visual, que permite comunicar, orientar, guiar u organizar a una persona, en relación a la ubicación del teléfono dentro del campamento. El objeto está compuesto por una barra metálica con una placa de metal apernada en su parte superior. Es una pieza compuesta, la cual tiene un fierro fabricado en metal y de color negro, con una placa elaborada en metal y pintada de color blanco desgastado por el tiempo, con una señalética que dice “Teléfono" y acompañando este texto, tiene un dibujo de un teléfono antiguo y una flecha indicando hacia arriba.'
                },
                {
                    id: 'PC0506',
                    description: 'Objeto utilizado para mantener la temperatura de los líquidos de su interior. Tiene forma principalmente cilíndrica con tapa y base selladas de manera hermética. Posee dos tubos metálicos adheridos en la parte superior para poder generar las conexiones industriales. En la parte posterior se observan dos agarres horizontales adheridos de manera paralelos el uno con el otro, para permitir fijarlos a distintas estructuras. La estructura es blanca y posee una inscripción azul con forma de escudo donde se observa la marca TROTTER, con el diseño de un ave y en la parte superior se pueden leer las advertencias de uso. '
                },
                {
                    id: 'PC0507',
                    description: 'Estructura utilizada como parte de una maquinaria eléctrica o turbina. A gran escala consiste en un eje giratorio que soporta un juego de bobinas enrolladas sobre piezas polares estáticas. Posee un eje metálico horizontal cuyo diámetro varía cada ciertos tramos. En la parte central sobresale un cilindro al cual se encuentran acoplados seis soportes rectangulares, en cuya parte exterior tienen placas planas de área mayor. Fue elaborado en acero ensamblado. Esta pieza forma parte del generador de la planta hidroeléctrica de Puerto Cristal.'
                },
                {
                    id: 'PC0508',
                    description: 'Objeto utilizado, como parte sistémica fundamental, para hacer funcionar las maquinarias mediante el movimiento y la transformación de energía. Es completamente de metal, de forma cilíndrica, con toda la superficie dentada. En la parte superior pose una argolla metálica y en la parte inferior se observa una pieza trapezoide con cuatro agujeros para adherir la estructura desde la base al suelo o a otra estructura. Esta atravesada en el centro por un tubo metálico, donde se observa por un lado cableado y por otro una terminación en rosca con hilo externo. Posee una placa adherida por cuatro topes, donde se observa la palabra ASEA y descripciones específicas del motor como “MRC 26. 45 KW. 380 V 2.410V. MOT 3 50. 975 R/M. PR. 20 V 249A."  Además, se alcanza a observar que la localidad de fabricación en Suecia. '
                },
                {
                    id: 'PC0509',
                    description: 'Objeto utilizado para reducir el volumen de un líquido o gas mediante la presión del mismo. El objeto está compuesto por distintas piezas, no todas en su lugar funcional. Posee una estructura base de forma rectangular parecida a un cajón y que en uno de sus laterales posee una superficie de gran altura con una rejilla y una placa. Paralelo a este plano, y al otro lado, se observa una superficie no tan alta con un orificio circular. Dentro de esta estructura se observan piezas de distintos tamaños y formas. La pieza es principalmente de color amarillo y café con desgastes de uso en toda la superficie. '
                },
                {
                    id: 'PC0510',
                    description: 'Objeto utilizado para llevar a cabo el procedo de molienda, donde se producía la reducción del mineral mediante el principio de impacto y fricción. Tiene forma de cono octaedro, con una ventanilla rectangular en uno de sus laterales. Es completamente hueco en el interior. El objeto, debido a su gran tamaño y peso, se encuentra in situ. '
                },
                {
                    id: 'PC0511',
                    description: 'Objeto utilizado para generar movimiento mediante la presión de fluido de distintas especies. Está compuesto por un tambor con forma de semi círculo de acero, adherido al suelo mediante tornillos de gran tamaño. Esta pieza es atravesada por una estructura circular de gran grosor que por un lado termina en un eje cilíndrico con un tope y por el otro está adosado a una estructura de mayor tamaño circular. La pieza posee una base de concreto en la cual está sostenida y que, al ser inamovible, se mantiene in situ. '
                },
                {
                    id: 'PC0512',
                    description: 'Objeto utilizado para resguardar y proteger equipos eléctricos. Es de forma rectangular y se encuentra ensamblado en la pared. Posee una compuerta en la parte superior de la estructura, donde, además, se observan dos estructuras que sobresalen del plano a modo de ventanillas situadas una al lado de la otra, ambas cuadradas y que permiten ver la medición y variación de las cargas eléctricas, observándose en la izquierda el voltaje con medición (hasta 600) y en la derecha el amperaje con medición (hasta 400). En la mitad inferior, y dispuesta de manera vertical, se observa una especie de manilla que al parecer lograba cerrar mediante presión la compuerta. La pieza es principalmente color negro. En la parte superior se observa una inscripción posiblemente artesanal, con plumón rojo, 02071.'
                },
                {
                    id: 'PC0513',
                    description: 'Conjunto de objetos utilizados como medio de almacenamiento analógico de señales sonoras, utilizados junto a un tocadiscos para producir música. Son de forma circular y plana y están fabricados con resina plastificada de color negro. Tienen una etiqueta adherida en el centro describiendo el contenido de cada uno. Poseen diferentes inscripciones manuales con lápiz pasta color azul. El conjunto se encontró en una bolsa cuadrada, del porte de los objetos, con las inscripciones “IRT. DISCOS. INDUSTRIA DE RAIO Y TELEVISION S.A. – CHILE". En el depósito se encuentran doce ejemplares que varían en contenido y color de la etiqueta.'
                },
                {
                    id: 'PC0514',
                    description: 'Objeto utilizado para proyectar luz mediante la combustión de gas. El objeto está compuesto por dos piezas fundamentales: la primera es un recipiente, con forma de casi esfera, hecho de vidrio y que protege a la llama contra el viento y, además, colabora con la proyección de luz de manera segura; y la segunda es una tapa con orificios para la salida de los gases quemados, que está adherida a una manguera de gas que ayuda a generar la combustión. El recipiente de vidrio posee una inscripción blanca ilegible. El objeto se encuentra incompleto.'
                },
                {
                    id: 'PC0515',
                    description: 'Herramienta manual utilizada para apretar o soltar tuercas y tornillos. Está compuesta por una sola pieza alargada y plana. Uno de sus extremos termina en dos puntas abiertas fijas que permiten una correcta sujeción. En el extremo opuesto se observa un orificio. La herramienta pose una inscripción de fabricación en el mango.'
                },
                {
                    id: 'PC0516',
                    description: 'Herramienta manual utilizada para apretar o soltar tuercas y tornillos. Está compuesta por una pieza rectangular alargada que termina, por un lado, en dos puntas abiertas fijas que permiten una correcta sujeción y, por el otro, en otra pieza rectangular colocada de manera perpendicular a la primera, a modo de agarre de la herramienta. Un extremo del agarre termina en una curvatura. La herramienta es de manufactura casera.'
                },
                {
                    id: 'PC0517',
                    description: 'Concreciones minerales utilizadas como muestras de material. Tienen forma irregular y se compone por una pieza de color rojo con distintas tonalidades en la superficie. Posiblemente se trata de minerales de Jaspe Rojo identificado gracias a la observación de sus características físicas como color, porosidad, entre otros.'
                },
                {
                    id: 'PC0518',
                    description: 'Objeto utilizado para direccionar la luz de una lámpara. Tiene forma de cono truncado con una especie de sombrilla en uno de sus extremos. En general, la pieza posee un diseño artesanal compuesto por esterillas verticales a modo de urdimbre y en algunos sectores se observa una trama que le da un diseño y confección particular. Fue elaborado de manera manual, completamente de mimbre tejido sin pintar.'
                },
                {
                    id: 'PC0519',
                    description: 'Objeto utilizado para indicar o señalar un lugar o número. Está compuesto por dos placas rectangulares de bordes redondeados y unidos en el centro. Su forma general es rectangular, plana. Presenta dos agujeros en cada esquina y dos en el centro. Fue elaborado en hierro forjado y esmaltado en negro, con letras amarillas con la inscripción "CONSOLIDATED PNEUMATIC". Posee desgaste por oxidación. '
                },
                {
                    id: 'PC0520',
                    description: 'Aparato eléctrico utilizado para reproducir sonidos grabados en discos específicos. La pieza está compuesta principalmente de cuatro elementos. Una base rectangular para sostener las otras piezas. Una estructura circular con un tope en el centro para sostener los discos. Un elemento redondo, donde posiblemente iba el contrapeso, y un cartucho para sostener la aguja. En la parte inferior del tocadiscos se observa un cableado. Posee una inscripción en una de sus esquinas donde se alcanza a leer en letra cursiva y manuscrita “Alta Fidelidad. INDUSTRIA ARGENTINA" '
                },
                {
                    id: 'PC0521',
                    description: 'Estantería utilizada para colocar las lámparas de minería y recargarlas de energía. Está compuesta por tres repisas y unas latas metálicas horizontales, con una distribución de topes y orificios simétricos en su mayoría cargados con electricidad. Las repisas son de color celeste, con detalles anaranjados, donde se observa la inscripción manual escrita con plumón “COD. 2075". Cada orificio pose un cartón numerado color rojo y pegado con cinta adhesiva transparente. Debido a sus grandes dimensiones el objeto se encuentra in situ. '
                },
                {
                    id: 'PC0522',
                    description: 'Objeto utilizado para distribuir información de diferente índole o materia determinada. Corresponde a una encuadernación manual de cubierta flexible y ligera. En la portada se observa la materia general a tratar en sus páginas posteriores “NOTICIARIO DE EQUIPOS INDUSTRIALES. FUNDADA EN 1997". Predomina el color verde agua. En su interior se observan ilustraciones de diferentes máquinas y sus usos. '
                },
                {
                    id: 'PC0523',
                    description: 'Objeto utilizado para anunciar o indicar una información específica. Es de forma rectangular y se encuentra adherido a la pared mediante corchetes y pegamento. Fue elaborado en una lámina de papel impreso. Posee una ilustración religiosa, representando a Jesús con las manos alzadas dejando ver sus estigmas. Se puede leer en letras impresas color negro “ALÉGRATE. DIOS TE VIENE A LIBERAR" y bajo esta inscripción, de carácter artesanal y escrito con lápiz pasta azul, se lee “Y A AYUDAR EN TUS ANGUSTIAS" Predominan los colores: blanco, naranjo y negro.'
                },
                {
                    id: 'PC0524',
                    description: 'Objeto utilizado para distribuir información, según criterios y listados ordenados de objetos específicos, en este caso zapatos. La encuadernación es industrial y la portada posee las inscripciones de “BATA INDUSTRTIALS. PARA MAXIMA SEGURIDAD Y COMODIDAD" predominando los colores marrones. La contraportada posee un rectángulo blanco, donde se observan datos de contacto de la empresa. En el interior se observan ilustraciones y descripciones específicas de zapatos y botines a la venta.'
                },
                {
                    id: 'PC0525',
                    description: 'Objeto utilizado para distribuir información según criterios y listados ordenados de objetos. La encuadernación es industrial y la portada posee las inscripciones en francés “LES NIVEAUX A LUNETTE G. MORIN" predominando los colores blancos y letras negras. En la contraportada se observan datos de contacto de la empresa. En el interior se observan ilustraciones, descripciones y valores de los objetos.'
                },
                {
                    id: 'PC0526',
                    description: 'Objeto utilizado para anunciar o indicar una información específica. Es de forma rectangular y se encuentra adherido a la pared mediante corchetes y pegamento. Fue elaborado en una lámina de papel impreso. Posee una ilustración de un búho parado sobre unas gafas en tonalidades frías y se observa la inscripción “OJO CON SUS OJOS" en negro. En la parte inferior, se lee en letras de menor tamaño “PROTEJALOS CONTRA LA PROTECCIÓN DE PARTICULAS" Y el logo de la ACHS .'
                },
                {
                    id: 'PC0527',
                    description: 'Objeto utilizado como parte del vestuario de trabajo para sujetar a la cintura un elemento, en este caso, la batería para la lámpara del casco y las herramientas mineras. Está compuesto por una pieza rectangular alargada, acabada con una pieza rectangular a modo de hebilla de marco. Al extremo contrario se observan cinco ojales que permiten regular el largo.  Además, se observa en la mitad de la pieza, otra hebilla que permite controlar la longitud del objeto. Posee una banda de amarre con tres orificios. Fue elaborado en tela, cuero y metal. Predomina el color negro, excepto dos líneas paralelas entre sí color rojo.'
                },
                {
                    id: 'PC0528',
                    description: 'Objeto utilizado para la sujeción en la instalación de postes o para amarrarse a las cuerdas existentes en los túneles para no caerse a otros niveles. Se compone de una pieza rectangular alargada, con una hebilla en el medio que regula el largo. Posee un mosquetón industrial en cada uno de sus extremos. Posee una inscripción grabada en uno de sus mosquetones donde se observa “3040 FC85". Fue elaborado en tela y metal. Predomina el color café. '
                },
                {
                    id: 'PC0529',
                    description: 'Objeto utilizado para desenredar y arreglar el cabello. Es conocido también como peine de corte. Fue elaborado por una pieza horizontal gruesa y dentada. Cada una de estas estructuras se conoce también como púas, en este caso, de aspecto fino. Las púas se dividen en dos partes, la primera mitad junta y la segunda mucho más separada. Fue elaborado completamente en plástico negro. '
                },
                {
                    id: 'PC0530',
                    description: 'Representación tangible y visual, informativa sobre la proximidad de una zona específica con centros educativos. La parte superior tiene forma de rombo cuadrilátero, apernado por una de sus esquinas a una barra rectangular. La pieza superior es amarilla por delante y destacan dos figuras humanas a modo de sombra. La barra que lo sostiene es negra. EL objeto fue elaborado en metal. Se encuentra in situ.'
                },
                {
                    id: 'PC0531',
                    description: 'Objeto utilizado para anunciar o indicar una información específica. Es de forma rectangular y está formado por dos partes: un soporte o bastidor de fibras de madera, tipo cholguán, y una lámina gruesa de papel impreso con el retrato de Bernardo O´Higgins. En la parte superior se observa un clavo con un a alambre flexible que permite colgarlo a un muro. Se observan rastros de humedad.'
                },
                {
                    id: 'PC0532',
                    description: 'Objeto utilizado para anunciar o indicar una información específica. Es de forma rectangular. Fue elaborado en una lámina de papel impreso. Posee ilustraciones en blanco y negro que aluden a accidentes laborales, dispuestos en un fondo naranja con franjas amarillas. Lleva escrito en letras negras, naranjas y verdes “CUIDADO", “EVITE CAIDAS. MUCHAS SON FATALES" y “RESPETE LAS NORMAS DE SEGURIDAD" en la parte inferior se observa el logo de la ACHS. Posee manchas, posiblemente de humedad.'
                },
                {
                    id: 'PC0533',
                    description: 'Objeto utilizado para la entretención al aire libre. También es conocido como resbalín. Tiene forma de rampla deslizante, y por el lado posterior se observa una escalera para poder llegar hasta el punto más alto. Fue elaborado en metal. Se encuentra oxidado. '
                },
                {
                    id: 'PC0534',
                    description: 'Objeto utilizado para generar calor en una habitación. Está compuesto por una pieza rectangular que en el interior contiene dos barras eléctricas colocadas de manera horizontal y paralelas entre sí, con una rejilla para la protección. Posee una base de alambre para elevarse de la altura del piso. Posee un alambre grueso en la parte superior para poder facilitar el agarre. Desde la parte posterior sobresale un cableado. La rejilla frontal termina en cada extremo con una placa metálica: en la derecha se puede leer “220 V. 1000 W FAB. CHILENA" “PARDO" “CERTIFICADO N° 149-06RM"; en la izquierda posee dos aberturas rectangulares. En la parte posterior se observan características como el modelo y la certificación. Los bordes y la parte posterior son amarillo pálido. El objeto se encuentra incompleto porque le falta el enchufe. '
                },
                {
                    id: 'PC0535',
                    description: 'Objeto utilizado como instructivo para el uso de motores diesel marinos. Está compuesto por una tapa, contratapa y las hojas del interior. Posee el título del libro grabado en la portada. Tanto la tapa como la contratapa son color café y las hojas son blancas con tinta negra. Se encuentra en mal estado de conservación.'
                },
                {
                    id: 'PC0536',
                    description: 'Objeto utilizado para generar fuentes de calor para cocción, mediante energía eléctrica. Su forma predominante es rectangular y está compuesto por una encimera sostenida por cuatro puntos de apoyo en formas de patas. Posee, además, una placa circular empotrada. En la zona posterior derecha se observa el cable de conexión. El soporte rectangular fue elaborado en metal ensamblado y forjado y las placas en cerámica. El cable se encuentra cortado por lo que carece de enchufe. '
                },
                {
                    id: 'PC0537',
                    description: 'Objeto utilizado para la entretención al aire libre. Está compuesto por una estructura o barra horizontal sostenida por un doble par de patas inclinadas con el mismo diámetro. El objeto se encuentra incompleto, pues carece de cadenas y de asientos. Fue elaborado en fierro. Se encuentra oxidado.'
                },
                {
                    id: 'PC0538',
                    description: 'Recipiente utilizado frecuentemente para el lavado del cuerpo en espacios domésticos. Es de forma circular, fondo plano y lados cóncavos. Fue elaborado en metal esmaltado de porcelana blanca con bordes negros. '
                },
                {
                    id: 'PC0539',
                    description: 'Objeto utilizado para asegurar u apretar dos elementos. Está compuesto por una banda de apriete circular (de dos piezas), una caja o soporte y un tubo metálico a modo de tornillo de presión. La banda de apriete posee un tornillo en uno de sus extremos y en el otro solo se observa un orificio. Posee pintura blanca en distintos sectores. '
                },
                {
                    id: 'PC0540',
                    description: 'Objeto utilizado, en conjunto con una balanza, para generar contrapeso. Su forma es esférica, con un asa en la parte superior que permite tener un agarre en la balanza. En la parte inferior se observa una tuerca, que sostiene una pieza con forma de tronco de cono invertido. La pieza está fabricada en acero. '
                },
                {
                    id: 'PC0541',
                    description: 'Objeto utilizado para la entretención al aire libre. La estructura posee dos barras circulares, una sobre la otra, unidas por medio de una barra en el centro que funcionaría como eje giratorio. De esta estructura sobresalen cuatro barras, dispuestas de manera equidistante entre sí. En cada extremo se sostiene un asiento de madera pintado de blanco con respaldo metálico. Fue elaborado en fierro. Se encuentra oxidado.'
                },
                {
                    id: 'PC0542',
                    description: 'Objeto utilizado como parte del vestuario de seguridad, cuya función es contener el cuerpo de una posible caída. Está compuesto por distintas tiras de seguridad adaptadas al cuerpo por medio de anillos o argollas tipos D, que permiten el enganche con otros conectores. Posee un punto de ajuste. Posee un agarre a modo de cinturón, con cinco ojales. Es de color café. '
                },
                {
                    id: 'PC0543',
                    description: 'Objeto utilizado para anunciar o indicar una información específica. Consiste en una lámina de papel impreso adosada adosado a un soporte de cartón por medio de huincha aislante y cinta de embalaje, todo ello en formato rectangular. En la parte superior se observa un alambre revestido de plástico blanco que facilita la sujeción al muro. Posee ilustraciones en distintos colores y lleva escrito en letras blancas “Bendice nuestra familia" con una ilustración católica de la sagrada familia.'
                },
                {
                    id: 'PC0544',
                    description: 'Objeto utilizado para la entretención al aire libre. También conocido como trepador rectangular. El objeto está elaborado por dos estructuras paralelas entre sí de forma rectangular, unidos por barras horizontales a modo de escalera. El objeto pose pérdida de capas de pinturas y se observa con oxidación. '
                },
                {
                    id: 'PC0545',
                    description: 'Objeto utilizado para anunciar o indicar una información específica. Consiste en una lámina metálica, de forma rectangular, adosada a un soporte parecido a un bastidor, rectangular y de madera. En la parte posterior se observan dos alambres que facilitan la sujeción al muro. Se observan dos inscripciones pintadas donde se lee “COMITÉ DE DEPORTE" y “PUERTO CRISTAL". '
                },
                {
                    id: 'PC0546',
                    description: 'Concreción mineral utilizada como muestra de material. Tiene forma irregular y se compone por una pieza de tonalidades cobre. Posiblemente se trate de un mineral de Rosa del Desierto identificado gracias a la observación de sus características físicas como color, porosidad, entre otros.'
                },
                {
                    id: 'PC0547',
                    description: 'Objeto utilizado en la cocina como herramienta de rallado o desmenuzado de alimentos. Tiene forma cónica y está compuesto por una lámina metálica con orificios simétricos y con un filo regular. Posee un asa rectangular con un orificio en el centro de la zona superior. Fue elaborado completamente en metal. Se observa oxidación en toda la pieza.'
                },
                {
                    id: 'PC0548',
                    description: 'Objeto utilizado en la oratoria para sostener libros o papeles de manera más cómoda. Está compuesto por tres partes, un soporte con inclinación y tope, que evita el desplazamiento de los objetos sobre él, una columna rectangular y una base cuadrada. Fue elaborado completamente en madera.'
                },
                {
                    id: 'PC0549',
                    description: 'Objeto utilizado en el juego de mesa ping pong. Está compuesto por un mango cilíndrico y una pieza plana de aspecto circular. Fue elaborado en madera, con una pieza de goma antideslizante por uno de sus lados. El mango es de color negro, una cara de la paleta es de color rojo y la otra cara es de color negro. El objeto está desgastado y con pérdida de color. '
                },
                {
                    id: 'PC0550',
                    description: 'Objeto utilizado para la devoción religiosa. Su forma es antropomorfa y representa a un hombre crucificado, específicamente Jesucristo. La representación está enfocada en el momento preciso de la crucifixión. La pieza fue elaborada en madera policromada y destacan los colores rosados para la piel, blanco para la túnica y café para la madera.'
                }
            ]

            let arr12 = [{
                    id: 'PC0551',
                    description: 'Objeto utilizado para guardar cigarrillos. Está compuesto por una caja con tapa solapada. Se observa la inscripción “Belmont" en todas sus superficies. Predomina el color blanco, con detalles celestes y letras negras. '
                },
                {
                    id: 'PC0552',
                    description: 'Objeto utilizado para el lavado e higiene dental. Está compuesto por un tubo cilíndrico que termina en forma plana y una tapa con solapa y textura. Se observa la marca “PEPSODENT" por ambos lados. El envase fue elaborado en plástico. Predomina el color blanco, sin embargo, la mayoría de los detalles impresos son de color azul. '
                },
                {
                    id: 'PC0553',
                    description: 'Objeto utilizado como fuente de calor industrial. Tiene forma cilíndrica ahuecada. Por un lado, posee una base plana con tres orificios y, por el otro, un orificio de gran diámetro, sobre el que se ubican, formando tres filas colocadas de manera semicircular, múltiples orificios de diámetro inferior. Fue elaborado en láminas de acero, adheridas por remaches y tornillos. Debido a su gran tamaño el objeto se encuentra in situ. '
                },
                {
                    id: 'PC0554',
                    description: 'Objeto utilizado en la cocina para sacar, rebañar o dar vuelta a los alimentos fritos o cocidos del interior de la sartén o cacerola. El objeto está compuesto por dos piezas: la primera tiene forma de paleta, plana y con rejillas. Sus bordes superiores son curvos y la parte inferior termina de forma redondeada, dicha superficie está adherida a la otra pieza: un mango redondo con curvatura del mismo material que termina en una pieza rectangular con un orificio a modo de mango. Fue elaborada principalmente en metal forjado, a excepción del mango que es plástico y de color café'
                },
                {
                    id: 'PC0555',
                    description: 'Objeto utilizado para la clasificación de sólidos mediante diferencias de tamaños. Está compuesto por una pieza cónica hueca y una base cilíndrica, separadas por una pieza transversal de forma rectangular.  Además, posee cuatro piezas circulares puestas de forma horizontal, una en la base, una en la zona superior y otras dos dispuestas equidistantemente entre sí. Fue elaborado en acero. Se encuentra oxidado. Debido a sus grandes dimensiones, la pieza se encuentra in situ.'
                },
                {
                    id: 'PC0556',
                    description: 'Objeto utilizado para anunciar o indicar una información específica. Es de forma rectangular. Fue elaborado en una lámina de papel impreso a color. Se observa una fotografía de un equipo de futbol. Se encuentra adherido a un soporte por medio de seis scotchs, tres en la parte superior y tres en la inferior. Lleva escrito en letras blancas “ESTUDIANTES DE LA PLATA. CAMPEON NACIONAL ‘B’ 1994/95"" en la parte inferior se observa el logo del Banco de Crédito Provincial. '
                },
                {
                    id: 'PC0557',
                    description: 'Objeto utilizado para la oración católica. Está compuesto por cinco series de cuencas idénticas ensartadas y dividas por una cuenca del mismo tamaño, pero dispuesta a una distancia levemente mayor. Termina con forma oval con la figura de la Virgen María y el niño Jesús. De esta estructura sobresalen cinco cuentas más terminadas en una cruz. Fue elaborado en plástico blanco.'
                },
                {
                    id: 'PC0558',
                    description: 'Objeto utilizado para la oración católica. Está compuesto por una cadena con cinco series de piedras revestida de acero y dividas por otra piedra del mismo tamaño, pero dispuesta a una distancia levemente mayor. Termina con una pieza de plata con forma de escudo que contiene una figura sagrada. De esta estructura sobresalen cinco piedras de similares características terminadas en cruz, como símbolo de la crucifixión. Las piedras tienen tonalidades azules. Fue elaborado en plata.'
                },
                {
                    id: 'PC0559',
                    description: 'Objeto utilizado para anunciar o indicar una información específica. Es de forma rectangular. Está compuesto por dos placas metálica lisas de distintos colores y un borde también metálico que le otorga mayor superficie. En la parte superior se observan dos ganchos que servirían para poder colgarlo. Predomina el color blanco y azul. En la parte frontal se observa en letras blancas la palabra “TELEFONO". '
                },
                {
                    id: 'PC0560',
                    description: 'Objeto utilizado como soporte de vela. Está compuesto por una pieza cilíndrica ahuecada con corte en cruz a cada extremo, de modo tal que termina con cuatro bandas de menor dimensión. Pose una base circular y, al extremo contrario, se observa un recipiente también circular donde depositar la vela. Fue elaborado en metal, pintado de blanco. Existen dos ejemplares idénticos en la bodega. '
                },
                {
                    id: 'PC0561',
                    description: 'Objeto utilizado para alumbrar en ocasiones religiosas especiales. Está compuesto por un cilindro sólido, envuelto en papel con inscripciones dibujadas a mano. Fue elaborado principalmente en cera, a excepción del papel exterior. Es en su mayor parte color crema. Posee desgaste por uso.'
                },
                {
                    id: 'PC0562',
                    description: 'Recipiente utilizado en la cocina para la preparación de alimentos. Tiene forma cilíndrica ahuecada con base plana. Está compuesto por la base y dos asas dispuestas de manera paralela entre sí. Fue elaborado en metal esmaltado con técnica de vaciado. La pieza es azul tanto en su exterior como interior y, tanto las asas como el borde, son color negro. Además, posee una hilo o cuerda amarrado a una de sus asas. La parte inferior de la olla posee un diseño simétrico blanco que da vuelta a todo el plano. Posee desgaste por uso.'
                },
                {
                    id: 'PC0563',
                    description: 'Objeto utilizado para acomodar flores. La forma es cilíndrica de cuello alargado y estrecho hacía la boca o corona, con un diseño en la superficie en relieve, simétrico. La base tiene forma hexagonal. Fue elaborado en loza esmaltada blanca. En bodega se encuentran dos ejemplares idénticos con un juego de flores plásticas anaranjadas para cada uno.'
                },
                {
                    id: 'PC0564',
                    description: 'Objeto utilizado como parte del servicio de mesa para servir y beber líquidos calientes. Es un contenedor de forma cilíndrica con mayor diámetro en el extremo superior. Las paredes son rectas y planas, la base también es plana. Posee un asa adherida, del mismo material, en su lateral. Fue elaborada en cerámica enlozada color blanco casi en tu totalidad, exceptuando un diseño lineal verde en la parte superior. Posee suciedad adherida y desgaste por uso.'
                },
                {
                    id: 'PC0565',
                    description: 'Objeto utilizado en ceremonias de carácter religiosos para recibir ofrendas de distintos tipos. Fue elaborado en dos piezas, una metálica revestida en plástico que da forma circular al objeto terminada con una manilla para sostener, y una tela sujeta alrededor del área metálica que sirve como saco para la recepción de las ofrendas. El telar posee un patrón cuadrado tipo escocés de color celeste y blanco.'
                },
                {
                    id: 'PC0566',
                    description: 'Objeto utilizado para registrar del paso de los días y meses del año en curso. Fue elaborado en una lámina de papel impreso a color con una franja metálica en la parte superior, que le permitía ser colgada a una superficie plana. En el lateral izquierdo se observa la bandera y el escudo de la República Argentina y a su lado una imagen religiosa de Jesús orando. En la parte inferior se observa una caricatura con la insignia “PANADERÍA “SAN MARTIN" de Humberto Tolaba" “ALAMEDA Y PICADERO (9041) LOS ANTIGUOS (SANTA CRUZ)". El objeto se encuentra incompleto, pues faltaría el papel inferior impreso con los días y meses del año.'
                },
                {
                    id: 'PC0567',
                    description: 'Objeto utilizado para realizar trabajos de perforación en ángulos horizontales e inclinados. Generalmente se utiliza para construcción de galerías, subniveles y ramplas. El objeto tiene dos componentes: la perforadora y el cilindro de pistón unidos por medio de un dispositivo de acoplamiento de pie de avance, ambos elaborados en hierro. El objeto se encuentra completo, pero presenta desgaste de uso. '
                },
                {
                    id: 'PC0568',
                    description: 'Objeto utilizado para anunciar o indicar una información específica. Es de forma rectangular con un amarre en la zona superior que permite colgarlo en la pared. Fue elaborado en madera y pintado a mano. Es principalmente color marrón con fondo y letras crema. Se observa un diseño de cruz y la palabra “Iglesia". En la parte posterior se observa una inscripción manual en lápiz de tinta que dice “Escuela “G" N° 42. Puerto Cristal".'
                },
                {
                    id: 'PC0569',
                    description: 'Objeto utilizado para realizar perforaciones en la construcción de chimeneas y perforaciones verticales. Está compuesto por un equipo perforador y una barra de avance que permite la correcta realización de las perforaciones y presenta una barra integrada. El objeto fue elaborado en hierro. No posee la manguera para el aire y presenta desgaste de uso. '
                },
                {
                    id: 'PC0570',
                    description: 'Objeto utilizado para realizar piques y perforaciones verticales o inclinadas hacia abajo. Se trabaja con el avance por el propio peso del equipo. El objeto tiene principalmente forma cilíndrica con una barra horizontal en la parte superior para permitir el agarre y fue elaborado en hierro. El objeto tiene desgaste por uso.'
                },
                {
                    id: 'PC0571',
                    description: 'Objeto católico sagrado utilizado para guardar el copón y las hostias consagradas. Se compone por una caja rectangular con una compuerta que se abre por medio de una manilla redonda. Posee una cruz en la parte superior. Fue elaborado completamente en madera ensamblada. '
                },
                {
                    id: 'PC0572',
                    description: 'Recipiente utilizado para hervir agua. Tiene forma cilíndrica ahuecada, en la parte superior se observa un mango (adherido por medio de clavillos) y en su cara frontal sobresale una boquilla curva para la salida del agua. Posee una tapa móvil redonda y cóncava con una pieza redonda decorativa en la parte superior. Fue elaborada completamente en hierro, con técnicas de soldadura, fundición y troquelado.  Además, posee un esmaltado en la superficie de la pieza de color celeste. El asa fue esmaltada en color negro al igual que la tapa. El objeto presenta pequeños orificios en su base, corrosión y pérdida de pintura.'
                },
                {
                    id: 'PC0573',
                    description: 'Objeto utilizado para la entretención al aire libre, también conocido como arco. El objetivo de su uso era que la pelota entrara en esta portería para poder anotar un punto o gol en un juego de equipo. Está compuesto por dos postes paralelos y uno superior (travesaño o larguero) unidos por un codo negro y hueco, desde el cual,  Además, sobresale una barra curva hacia la parte posterior. El objeto pose pintura blanca en distintos lugares de su superficie. No posee malla. '
                },
                {
                    id: 'PC0574',
                    description: 'Objeto utilizado para la entretención al aire libre, también conocido como arco. El objetivo de su uso era que la pelota entrara en esta portería para poder anotar un punto o gol en un juego de equipo. Está compuesto por dos postes paralelos y uno superior (travesaño o larguero) unidos por un codo negro y hueco, desde el cual,  Además, sobresale una barra curva hacia la parte posterior. El objeto pose pintura blanca en distintos lugares de su superficie. No posee malla. '
                },
                {
                    id: 'PC0575',
                    description: 'Objeto utilizado para la entretención al aire libre, también conocido como aro. El objetivo de su uso era que el balón atravesara el aro para poder conseguir puntos en un juego de equipo. Está compuesto por tres objetos: una barra rectangular metálica de gran altura que termina de forma curva, en la cima de ésta un tablero de madera compuesto por ocho tablas, una al lado de la otra, y de esta sobresale un aro metálico. El objeto no cuenta con malla y el aro se encuentra fijo, pero no centrado respecto a las maderas, puesto que, al parecer, no están todas. '
                },
                {
                    id: 'PC0576',
                    description: 'Objeto utilizado como representación del nacimiento de Jesucristo para la religión católica. Está compuesto por una estructura de madera hexagonal, a modo de establo, cubierto por paja, de la que cuelga una estrella de guirnalda. En la base se observa más paja y guirnalda. Posee distintas figuras de yeso, algunas antropomorfas y otras en representación de animales. En total se compone por nueve piezas. '
                },
                {
                    id: 'PC0577',
                    description: 'Objeto utilizado para afilar diferentes materiales o herramientas. Se encuentra adherido a un banco de trabajo y se compone principalmente de una pieza cilíndrica de gran tamaño y, al otro extremo, casi en el borde de la banca una pieza de menor tamaño que posee una barra horizontal adherida por dos acoples a la superficie. Ambas partes se encuentran unidas por una cinta metálica insertada en las muelas de los componentes, la cual permite el movimiento constante. El objeto es de uso mecánico a diferencia de los esmeriles eléctricos actuales.'
                },
                {
                    id: 'PC0578',
                    description: 'Mueble utilizado para realizar breves descansos. Predomina la forma rectangular y se conforma por un conjunto de tablas de diferentes grosores y largos. Está compuesto principalmente por cuatro patas en cada extremo y sobre ellas una base superior compuesta por dos listones rectangular alargados horizontales y un respaldo en su eje vertical. El objeto fue confeccionado de manera manual con madera ensamblada.'
                },
                {
                    id: 'PC0579',
                    description: 'Objeto utilizado por dos individuos para trasladar elementos sagrados en un peregrinaje con connotaciones religiosas. Tiene forma rectangular, con una base de tres tablas horizontales y paralelas entre sí, ensambladas alrededor de otras cuatro tablas, destacando la derecha y la izquierda que son de mayor tamaño para facilitar el transporte. Es de color café, con celeste en algunas de las tablas interiores. '
                },
                {
                    id: 'PC0580',
                    description: 'Objeto utilizado para calentar un recinto. Está compuesto por una pieza cilíndrica de gran tamaño, que en la parte inferior pose una gaveta con manillar. En la parte superior se observa una superficie plana con una barra que sigue la forma circular alrededor del objeto. Finalmente se observa de manera vertical un tubo desde el cual se extrae el humo. El objeto se encuentra sobre una estructura metálica de cuatro patas que le da altura y estabilidad. Fue elaborado completamente en metal. '
                },
                {
                    id: 'PC0581',
                    description: 'Objeto utilizado en ceremonias religiosas para realizar actividades o dejar elementos sobre ella. Esta elaborado por un tablero horizontal sostenido por cuatro patas dispuestas de manera diagonal y afirmadas en una base ensamblada que le da estabilidad y altura, todos de madera. Posee un textil rectangular blanco con una cruz bordada en el medio para cubrir la base de la mesa.'
                },
                {
                    id: 'PC0582',
                    description: 'Recipiente para contener y/o almacenar distintos materiales o alimentos. La pieza está compuesta por un objeto circular, poco profundo, con fondo plano, lados cóncavos, de metal forjado, sin pintar ni esmaltar. El objeto posee desgaste por uso.'
                },
                {
                    id: 'PC0583',
                    description: 'Objeto utilizado para sentarse o anclarse de rodillas durante una ceremonia religiosa. Posee una madera horizontal con travesaño para conformar el asiento, y un respaldo también elaborado por una madera horizontal. En la parte posterior se observa un reclinatorio, característica particular de este tipo de estructuras. Fue elaborado en madera ensamblada'
                },
                {
                    id: 'PC0584',
                    description: 'Objeto utilizado para contener o servir alimentos. Es de forma circular, fondo plano y lados cóncavos. Fue elaborado en metal esmaltado de porcelana celeste con bordes negros. Se encuentra con un desgaste en la base, donde se observa pérdida de pintura y oxidación. '
                },
                {
                    id: 'PC0585',
                    description: 'Objeto utilizado en la cocina para cerrar o cubrir ollas. Tiene forma circular con un ángulo levemente cóncavo. Pose un asa en la parte superior, adherida por un remache en cada extremo. Los bordes tienen mayor grosor el centro para permitir adherirse con mayor facilidad al cuerpo de la olla o recipiente que se desee tapar. Fue elaborado completamente en metal. Posee una mancha amarilla en uno de sus lados.'
                },
                {
                    id: 'PC0586',
                    description: 'Parte estructural de una cama, utilizado para sostener el colchón. La estructura es rectangular y tiene cuatro patas. Tanto la parte frontal como posterior poseen dos largueros dispuestos de forma horizontal y paralelos entre sí, dándoles una altura mayor. Los otros dos laterales poseen solo un larguero, de mayor largo pero dispuesto a una altura menor. El objeto fue elaborado en madera ensamblada.'
                },
                {
                    id: 'PC0587',
                    description: 'Objeto de uso cotidiano, utilizado para facilitar el descanso. Posee un asiento plano en forma de trapecio y conformado por la consecución de tablas paralelas. Posee un respaldo en marco horizontal elaborado con cinco piezas cilíndricas de madera. Las piezas de madera tienen biseles en las esquinas. '
                },
                {
                    id: 'PC0588',
                    description: 'Objeto decorativo para adornar la cabeza. De forma redonda ajustable a la cabeza con un alambre tipo cintillo, adornado con siete flores de diferentes colores, elaboradas en papel. Las flores se encuentran adheridas al alambre por medio de un hilo blanco.'
                },
                {
                    id: 'PC0589',
                    description: 'Mueble utilizado como apoyo para distintas actividades. El objeto está compuesto por ocho tablas horizontales y lisas (dos de ellas se encuentra partidas), dispuestas una al lado de la otra y sostenidas por una estructura rectangular de menor tamaño que a su vez posee cuatro patas. Fue elaborado completamente en madera. El tablero mantiene el color original de la madera y la estructura que lo sostiene está pintada de verde. La superficie pose una inscripción realizada de manera artesanal donde se observa un corazón con las iníciales FSH en el centro'
                },
                {
                    id: 'PC0590',
                    description: 'Objeto utilizado en ceremonias o espacios religiosos. Está compuesto por una pieza de tres fierros cilíndricos, paralelos y verticales, y dos piezas similares, de menor tamaño, dispuestos de manera horizontal, de modo tal que forman una cruz. Posee una base de metal rectangular que permite anclarla a otras estructuras. Fue elaborado en metal ensamblado, con pintura blanca. '
                },
                {
                    id: 'PC0591',
                    description: 'Objeto utilizado en el uso cotidiano para calentar o preparar bebestibles como té o café. Está compuesto por dos partes, el cuerpo o vasija de lata con forma cilíndrica y un asa de metal trenzada para dar mayor estabilidad, adherida por sus laterales. Se observa adherido a la lata un etiquetado de color verde y detalles rojos donde se aprecia la marca MILO. Es de confección artesanal. La base se encuentra quemada debido al uso constante.  '
                },
                {
                    id: 'PC0592',
                    description: 'Objeto utilizado para regular la entrada de aire por el tubo de salida de humo de una estufa. Fue elaborado en una sola pieza metálica lisa, que por un lado termina de forma rectangular con dos dobleces que permiten una manipulación mucho más fácil, y por el contrario pose forma circular plana. La base del objeto se encuentra quemado debido al uso. '
                },
                {
                    id: 'PC0593',
                    description: 'Objeto utilizado como soporte de vela. Está elaborado con tres componentes: una superficie semi circular con cuatro estructuras cilíndricas para poder sostener las velas de manera independientes, una estructura alargada que lo sujeta y una base rectangular con una leve curvatura. La estructura fue fabricada en metal y pintada en blanco. Posee una guirnalda amarilla a modo de decoración. '
                },
                {
                    id: 'PC0594',
                    description: 'Mueble utilizado para realizar breves descansos. Predomina la forma rectangular en la base para sentarse, con una tabla de forma de trapecio invertido en los laterales. Posee, además, dos patas, elaboradas en una madera heptagonal, con un acabado triangular en la parte inferior. El objeto fue elaborado completamente en madera.'
                },
                {
                    id: 'PC0595',
                    description: 'Mueble utilizado para realizar breves descansos. Predomina la forma rectangular en la base para sentarse, con una tabla hexagonal irregular en los laterales. Posee, además, dos patas heptagonales, con un acabado triangular parte inferior y un travesaño entre las patas. El objeto fue elaborado completamente en madera.'
                },
                {
                    id: 'PC0596',
                    description: 'Objeto utilizado para contener y servir líquidos. El recipiente tiene un cuerpo de forma globular que termina en un pico pronunciado con forma redondeada para facilitar la acción de verter el líquido interior. En el lateral opuesto posee un asa que nace a desde el borde de la pieza. Fue completamente elaborada en plástico anaranjado. El recipiente posee desgaste por uso, suciedad adherida y el pico para verter está quebrado.'
                },
                {
                    id: 'PC0597',
                    description: 'Objeto utilizado como revestimiento de fachada y techos, también puede llevar el nombre de pizarrilla. Está compuesto por una sola pieza de madera rectangular y plana. En la parte superior se observan dos orificios por donde seguramente se clavaban. Uno de sus extremos se observa mucho más desgastado, con indicios de quiebres y curvaturas del plano.'
                },
                {
                    id: 'PC0598',
                    description: 'Objeto utilizado para indicar o señalar un lugar o número. Su forma es rectangular y plana con esquinas redondeadas. Fue elaborado en hierro forjado y esmaltado en blanco, con letras negras con la inscripción "1° BRIGADA DE COMBEROS PUERTO CRISTAL". '
                },
                {
                    id: 'PC0599',
                    description: 'Objeto utilizado para enseñar o representar eventos bíblicos. Se compone por un conjunto de 13 cuadros con imágenes elaborados en papel impreso a color y enmarcados en madera. Son representativos del Arte Sacro, que representan la crucifixión. Cada uno de ellos posee en la parte inferior un número romano que indicaría el momento o numeración del suceso, el título y una breve descripción de lo que se observa.  Además, tiene impresa la marca registrada NB. Entre las diferencias de los objetos está el hecho de que no todas las maderas presentan un barniz final y algunas de ellas tienen en la parte superior un soporte o gancho que le permite ser colgado con mayor facilidad.'
                },
                {
                    id: 'PC0600',
                    description: 'Objeto utilizado como parte de un mecanismo giratorio. El objeto tiene forma cilíndrica y en su eje presenta una barra o tuerca que lo atraviesa de un lado a otro. Fue elaborado completamente en metal y se observa oxidación.'
                }
            ]

            console.log(arr1, arr2, arr3, arr4, arr5, arr6, arr7, arr8, arr9, arr10, arr11, arr12)
            arr11.forEach(async item => {
                await updateDoc(doc(db, 'ListaObjetos', item.id), {
                    description: item.description
                })
                console.log(item.id + " actualizado")
            })

        },
        async getAndSetCurrentItem({
            commit,
            getters,
            dispatch
        }, payload) {
            let element = getters.allItems.find(el => el.id === payload)
            if (!element) {
                element = await dispatch('getSingleItemByQuery', doc(db, 'ListaObjetos', payload))
            }
            commit('SET_CURRENT_ITEM', payload)
            return element
        },
        async setList({getters}) {
            let arr = [{
                    id: 'PC0001',
                    name: 'Tenaza de Herrero',
                    published: false,
                    inventory: 1
                },
                {
                    id: 'PC0002',
                    name: 'Llave de Punta-Punta',
                    published: false,
                    inventory: 2
                },
                {
                    id: 'PC0003',
                    name: 'Contrapeso balanza antigua',
                    published: false,
                    inventory: 3
                },
                {
                    id: 'PC0004',
                    name: 'Gradilla',
                    published: false,
                    inventory: 4
                },
                {
                    id: 'PC0005',
                    name: 'Jarra',
                    published: false,
                    inventory: 5
                },
                {
                    id: 'PC0006',
                    name: 'Tapa de caja para Empalme',
                    published: false,
                    inventory: 6
                },
                {
                    id: 'PC0007',
                    name: 'Batería',
                    published: false,
                    inventory: 7
                },
                {
                    id: 'PC0008',
                    name: 'Amperímetro',
                    published: false,
                    inventory: 8
                },
                {
                    id: 'PC0009',
                    name: 'Amperímetro',
                    published: false,
                    inventory: 9
                },
                {
                    id: 'PC0010',
                    name: 'Muestra de Mineral',
                    published: false,
                    inventory: 10
                },
                {
                    id: 'PC0011',
                    name: 'Aislador loza blanca para cable eléctrico',
                    published: false,
                    inventory: 11
                },
                {
                    id: 'PC0012',
                    name: 'Gradilla',
                    published: false,
                    inventory: 12
                },
                {
                    id: 'PC0013',
                    name: 'Aislador de Alta Tensión',
                    published: false,
                    inventory: 13
                },
                {
                    id: 'PC0014',
                    name: 'Recipiente con asas para muestras',
                    published: true,
                    inventory: 14
                },
                {
                    id: 'PC0015',
                    name: 'Casco de Bomberos',
                    published: true,
                    inventory: 15
                },
                {
                    id: 'PC0016',
                    name: 'Casco de Minero',
                    published: true,
                    inventory: 16
                },
                {
                    id: 'PC0017',
                    name: 'Pinza para bureta doble',
                    published: false,
                    inventory: 17
                },
                {
                    id: 'PC0018',
                    name: 'Envase Crema Nivea',
                    published: false,
                    inventory: 18
                },
                {
                    id: 'PC0019',
                    name: 'Repuesto copa reflector',
                    published: true,
                    inventory: 19
                },
                {
                    id: 'PC0020',
                    name: 'Balón de Fútbol',
                    published: true,
                    inventory: 20
                },
                {
                    id: 'PC0021',
                    name: 'Casco de Minero',
                    published: true,
                    inventory: 21
                },
                {
                    id: 'PC0022',
                    name: 'Clavo rielero',
                    published: true,
                    inventory: 22
                },
                {
                    id: 'PC0023',
                    name: 'Zapato Infante',
                    published: true,
                    inventory: 23
                },
                {
                    id: 'PC0024',
                    name: 'Discos de vinilo',
                    published: true,
                    inventory: 24
                },
                {
                    id: 'PC0025',
                    name: 'Zapato Infante',
                    published: true,
                    inventory: 25
                },
                {
                    id: 'PC0026',
                    name: 'Zapatilla de deporte',
                    published: true,
                    inventory: 26
                },
                {
                    id: 'PC0027',
                    name: 'Casete',
                    published: true,
                    inventory: 27
                },
                {
                    id: 'PC0028',
                    name: 'Silla',
                    published: true,
                    inventory: 28
                },
                {
                    id: 'PC0029',
                    name: 'Hacha de tala',
                    published: true,
                    inventory: 29
                },
                {
                    id: 'PC0030',
                    name: 'Taladro Berbiquí antiguo',
                    published: true,
                    inventory: 30
                },
                {
                    id: 'PC0031',
                    name: 'Zapato',
                    published: true,
                    inventory: 31
                },
                {
                    id: 'PC0032',
                    name: 'Cinta magnética',
                    published: true,
                    inventory: 32
                },
                {
                    id: 'PC0033',
                    name: 'Bota',
                    published: true,
                    inventory: 33
                },
                {
                    id: 'PC0034',
                    name: 'Calculadora con impresora eléctrica',
                    published: true,
                    inventory: 34
                },
                {
                    id: 'PC0035',
                    name: 'Cinta adhesiva',
                    published: true,
                    inventory: 35
                },
                {
                    id: 'PC0036',
                    name: 'Paila',
                    published: true,
                    inventory: 36
                },
                {
                    id: 'PC0037',
                    name: 'Infiernillo eléctrico doble',
                    published: true,
                    inventory: 37
                },
                {
                    id: 'PC0038',
                    name: 'Quemador de fierro',
                    published: true,
                    inventory: 38
                },
                {
                    id: 'PC0039',
                    name: 'Zapato Infante',
                    published: true,
                    inventory: 39
                },
                {
                    id: 'PC0040',
                    name: 'Portabaterías',
                    published: true,
                    inventory: 40
                },
                {
                    id: 'PC0041',
                    name: 'Enchufe (Caja de salida)',
                    published: true,
                    inventory: 41
                },
                {
                    id: 'PC0042',
                    name: 'Repuesto para linterna',
                    published: true,
                    inventory: 42
                },
                {
                    id: 'PC0043',
                    name: 'Zapatilla de deporte',
                    published: true,
                    inventory: 43
                },
                {
                    id: 'PC0044',
                    name: 'Zapato Infante',
                    published: true,
                    inventory: 44
                },
                {
                    id: 'PC0045',
                    name: 'Zapato Infante',
                    published: true,
                    inventory: 45
                },
                {
                    id: 'PC0046',
                    name: 'Zapatilla de deporte',
                    published: true,
                    inventory: 46
                },
                {
                    id: 'PC0047',
                    name: 'Zapato Infante',
                    published: true,
                    inventory: 47
                },
                {
                    id: 'PC0048',
                    name: 'Recipiente cobre con objetos',
                    published: true,
                    inventory: 48
                },
                {
                    id: 'PC0049',
                    name: 'Fusibles Tipo Botella',
                    published: true,
                    inventory: 49
                },
                {
                    id: 'PC0050',
                    name: 'Zapatilla de deporte',
                    published: true,
                    inventory: 50
                },
                {
                    id: 'PC0051',
                    name: 'Zapato',
                    published: true,
                    inventory: 51
                },
                {
                    id: 'PC0052',
                    name: 'Juguete',
                    published: true,
                    inventory: 52
                },
                {
                    id: 'PC0053',
                    name: 'Tetera',
                    published: true,
                    inventory: 53
                },
                {
                    id: 'PC0054',
                    name: 'Pala',
                    published: true,
                    inventory: 54
                },
                {
                    id: 'PC0055',
                    name: 'Cajón',
                    published: true,
                    inventory: 55
                },
                {
                    id: 'PC0056',
                    name: 'Cuchara',
                    published: true,
                    inventory: 56
                },
                {
                    id: 'PC0057',
                    name: 'Damajuana',
                    published: true,
                    inventory: 57
                },
                {
                    id: 'PC0058',
                    name: 'Tetera',
                    published: true,
                    inventory: 58
                },
                {
                    id: 'PC0059',
                    name: 'Bota',
                    published: true,
                    inventory: 59
                },
                {
                    id: 'PC0060',
                    name: 'Balanza pediátrica',
                    published: true,
                    inventory: 60
                },
                {
                    id: 'PC0061',
                    name: 'Cañería inyector',
                    published: true,
                    inventory: 61
                },
                {
                    id: 'PC0062',
                    name: 'Platos',
                    published: true,
                    inventory: 62
                },
                {
                    id: 'PC0063',
                    name: 'Envase Polvos Royal',
                    published: true,
                    inventory: 63
                },
                {
                    id: 'PC0064',
                    name: 'Olla',
                    published: true,
                    inventory: 64
                },
                {
                    id: 'PC0065',
                    name: 'Envase Choritos',
                    published: true,
                    inventory: 65
                },
                {
                    id: 'PC0066',
                    name: 'Dominó',
                    published: true,
                    inventory: 66
                },
                {
                    id: 'PC0067',
                    name: 'Betún para calzado',
                    published: true,
                    inventory: 67
                },
                {
                    id: 'PC0068',
                    name: 'Delantal',
                    published: true,
                    inventory: 68
                },
                {
                    id: 'PC0069',
                    name: 'Envase de té',
                    published: true,
                    inventory: 69
                },
                {
                    id: 'PC0070',
                    name: 'Jarro con Asa',
                    published: true,
                    inventory: 70
                },
                {
                    id: 'PC0071',
                    name: 'Embudo',
                    published: true,
                    inventory: 71
                },
                {
                    id: 'PC0072',
                    name: 'Suéter corto',
                    published: true,
                    inventory: 72
                },
                {
                    id: 'PC0073',
                    name: 'Gradilla',
                    published: true,
                    inventory: 73
                },
                {
                    id: 'PC0074',
                    name: 'Botella',
                    published: true,
                    inventory: 74
                },
                {
                    id: 'PC0075',
                    name: 'Peluche',
                    published: true,
                    inventory: 75
                },
                {
                    id: 'PC0076',
                    name: 'Plato metálico',
                    published: true,
                    inventory: 76
                },
                {
                    id: 'PC0077',
                    name: 'Botella',
                    published: true,
                    inventory: 77
                },
                {
                    id: 'PC0078',
                    name: 'Jarra',
                    published: true,
                    inventory: 78
                },
                {
                    id: 'PC0079',
                    name: 'Desecador',
                    published: true,
                    inventory: 79
                },
                {
                    id: 'PC0080',
                    name: 'Botella',
                    published: true,
                    inventory: 80
                },
                {
                    id: 'PC0081',
                    name: 'Tarjetero',
                    published: true,
                    inventory: 81
                },
                {
                    id: 'PC0082',
                    name: 'Juego de Cubiertos',
                    published: true,
                    inventory: 82
                },
                {
                    id: 'PC0083',
                    name: 'Saco',
                    published: true,
                    inventory: 83
                },
                {
                    id: 'PC0084',
                    name: 'Guantes de protección',
                    published: true,
                    inventory: 84
                },
                {
                    id: 'PC0085',
                    name: 'Tablero de ajedrez',
                    published: true,
                    inventory: 85
                },
                {
                    id: 'PC0086',
                    name: 'Sombrero de lluvia',
                    published: true,
                    inventory: 86
                },
                {
                    id: 'PC0087',
                    name: 'Caja',
                    published: true,
                    inventory: 87
                },
                {
                    id: 'PC0088',
                    name: 'Pala',
                    published: true,
                    inventory: 88
                },
                {
                    id: 'PC0089',
                    name: 'Recipiente metálico',
                    published: true,
                    inventory: 89
                },
                {
                    id: 'PC0090',
                    name: 'Caja',
                    published: true,
                    inventory: 90
                },
                {
                    id: 'PC0091',
                    name: 'Teléfono',
                    published: true,
                    inventory: 91
                },
                {
                    id: 'PC0092',
                    name: 'Caja',
                    published: true,
                    inventory: 92
                },
                {
                    id: 'PC0093',
                    name: 'Plantilla zapato',
                    published: true,
                    inventory: 93
                },
                {
                    id: 'PC0094',
                    name: 'Tetera',
                    published: true,
                    inventory: 94
                },
                {
                    id: 'PC0095',
                    name: 'Casco de Bomberos',
                    published: true,
                    inventory: 95
                },
                {
                    id: 'PC0096',
                    name: 'Teléfono',
                    published: true,
                    inventory: 96
                },
                {
                    id: 'PC0097',
                    name: 'Huso',
                    published: true,
                    inventory: 97
                },
                {
                    id: 'PC0098',
                    name: 'Tetera',
                    published: false,
                    inventory: 98
                },
                {
                    id: 'PC0099',
                    name: 'Teléfono',
                    published: true,
                    inventory: 99
                },
                {
                    id: 'PC0100',
                    name: 'Trituradora',
                    published: true,
                    inventory: 100
                },
                {
                    id: 'PC0101',
                    name: 'Taburete de madera',
                    published: true,
                    inventory: 101
                },
                {
                    id: 'PC0102',
                    name: 'Base de mortero',
                    published: true,
                    inventory: 102
                },
                {
                    id: 'PC0103',
                    name: 'Molde de muestras',
                    published: true,
                    inventory: 103
                },
                {
                    id: 'PC0104',
                    name: 'Sobre',
                    published: true,
                    inventory: 104
                },
                {
                    id: 'PC0105',
                    name: 'Mortero',
                    published: true,
                    inventory: 105
                },
                {
                    id: 'PC0106',
                    name: 'Base de mortero',
                    published: true,
                    inventory: 106
                },
                {
                    id: 'PC0107',
                    name: 'Bandeja',
                    published: true,
                    inventory: 107
                },
                {
                    id: 'PC0108',
                    name: 'Casco de Minero',
                    published: true,
                    inventory: 108
                },
                {
                    id: 'PC0109',
                    name: 'Mortero',
                    published: true,
                    inventory: 109
                },
                {
                    id: 'PC0110',
                    name: 'Horno de secado',
                    published: true,
                    inventory: 110
                },
                {
                    id: 'PC0111',
                    name: 'Mortero Grande',
                    published: true,
                    inventory: 111
                },
                {
                    id: 'PC0112',
                    name: 'Cuaderno',
                    published: true,
                    inventory: 112
                },
                {
                    id: 'PC0113',
                    name: 'Cartel',
                    published: true,
                    inventory: 113
                },
                {
                    id: 'PC0114',
                    name: 'Separador de Muestras',
                    published: true,
                    inventory: 114
                },
                {
                    id: 'PC0115',
                    name: 'Horno',
                    published: true,
                    inventory: 115
                },
                {
                    id: 'PC0116',
                    name: 'Cartel',
                    published: true,
                    inventory: 116
                },
                {
                    id: 'PC0117',
                    name: 'Tamiz',
                    published: true,
                    inventory: 117
                },
                {
                    id: 'PC0118',
                    name: 'Balde',
                    published: true,
                    inventory: 118
                },
                {
                    id: 'PC0119',
                    name: 'Clasificador',
                    published: true,
                    inventory: 119
                },
                {
                    id: 'PC0120',
                    name: 'Traje impermeable',
                    published: true,
                    inventory: 120
                },
                {
                    id: 'PC0121',
                    name: 'Recipiente',
                    published: true,
                    inventory: 121
                },
                {
                    id: 'PC0122',
                    name: 'Chaqueta impermeable',
                    published: true,
                    inventory: 122
                },
                {
                    id: 'PC0123',
                    name: 'Matraz Aforado',
                    published: true,
                    inventory: 123
                },
                {
                    id: 'PC0124',
                    name: 'Taburete de madera',
                    published: true,
                    inventory: 124
                },
                {
                    id: 'PC0125',
                    name: 'Gradilla',
                    published: true,
                    inventory: 125
                },
                {
                    id: 'PC0126',
                    name: 'Panel de distribución',
                    published: true,
                    inventory: 126
                },
                {
                    id: 'PC0127',
                    name: 'Destilador de Agua',
                    published: true,
                    inventory: 127
                },
                {
                    id: 'PC0128',
                    name: 'Salamandra',
                    published: true,
                    inventory: 128
                },
                {
                    id: 'PC0129',
                    name: 'Bidón',
                    published: true,
                    inventory: 129
                },
                {
                    id: 'PC0130',
                    name: 'Capilla de extracción de gases',
                    published: true,
                    inventory: 130
                },
                {
                    id: 'PC0131',
                    name: 'Armario',
                    published: true,
                    inventory: 131
                },
                {
                    id: 'PC0132',
                    name: 'Organizador',
                    published: true,
                    inventory: 132
                },
                {
                    id: 'PC0133',
                    name: 'Botella',
                    published: true,
                    inventory: 133
                },
                {
                    id: 'PC0134',
                    name: 'Depresor lingual',
                    published: true,
                    inventory: 134
                },
                {
                    id: 'PC0135',
                    name: 'Botella',
                    published: true,
                    inventory: 135
                },
                {
                    id: 'PC0136',
                    name: 'Abrazadera',
                    published: true,
                    inventory: 136
                },
                {
                    id: 'PC0137',
                    name: 'Agitador de vidrio (química)',
                    published: true,
                    inventory: 137
                },
                {
                    id: 'PC0138',
                    name: 'Repuesto para linterna',
                    published: true,
                    inventory: 138
                },
                {
                    id: 'PC0139',
                    name: 'Embudo',
                    published: true,
                    inventory: 139
                },
                {
                    id: 'PC0140',
                    name: 'Pipeta (fragmento)',
                    published: true,
                    inventory: 140
                },
                {
                    id: 'PC0141',
                    name: 'Vaso de Precipitado',
                    published: true,
                    inventory: 141
                },
                {
                    id: 'PC0142',
                    name: 'Matraz',
                    published: true,
                    inventory: 142
                },
                {
                    id: 'PC0143',
                    name: 'Vaso de Precipitado',
                    published: true,
                    inventory: 143
                },
                {
                    id: 'PC0144',
                    name: 'Embudo',
                    published: true,
                    inventory: 144
                },
                {
                    id: 'PC0145',
                    name: 'Vaso de Precipitado',
                    published: true,
                    inventory: 145
                },
                {
                    id: 'PC0146',
                    name: 'Placa de porcelana - Portamuestras (laboratorio)',
                    published: true,
                    inventory: 146
                },
                {
                    id: 'PC0147',
                    name: 'Placa de porcelana - Portamuestras (laboratorio)',
                    published: true,
                    inventory: 147
                },
                {
                    id: 'PC0148',
                    name: 'Placa de porcelana - Portamuestras (laboratorio)',
                    published: true,
                    inventory: 148
                },
                {
                    id: 'PC0149',
                    name: 'Probeta',
                    published: true,
                    inventory: 149
                },
                {
                    id: 'PC0150',
                    name: 'Matraz de Erlenmeyer',
                    published: true,
                    inventory: 150
                },
                {
                    id: 'PC0151',
                    name: 'Vidrio de reloj',
                    published: true,
                    inventory: 151
                },
                {
                    id: 'PC0152',
                    name: 'Matraz Balón de fondo plano',
                    published: true,
                    inventory: 152
                },
                {
                    id: 'PC0153',
                    name: 'Matraz de Erlenmeyer',
                    published: true,
                    inventory: 153
                },
                {
                    id: 'PC0154',
                    name: 'Vidrio de reloj',
                    published: true,
                    inventory: 154
                },
                {
                    id: 'PC0155',
                    name: 'Matraz Balón de fondo plano',
                    published: true,
                    inventory: 155
                },
                {
                    id: 'PC0156',
                    name: 'Matraz Balón de fondo plano',
                    published: true,
                    inventory: 156
                },
                {
                    id: 'PC0157',
                    name: 'Matraz de Erlenmeyer',
                    published: true,
                    inventory: 157
                },
                {
                    id: 'PC0158',
                    name: 'Vidrio de reloj',
                    published: true,
                    inventory: 158
                },
                {
                    id: 'PC0159',
                    name: 'Probeta',
                    published: true,
                    inventory: 159
                },
                {
                    id: 'PC0160',
                    name: 'Embalaje',
                    published: true,
                    inventory: 160
                },
                {
                    id: 'PC0161',
                    name: 'Matraz de Erlenmeyer',
                    published: false,
                    inventory: 161
                },
                {
                    id: 'PC0162',
                    name: 'Probeta',
                    published: false,
                    inventory: 162
                },
                {
                    id: 'PC0163',
                    name: 'Talonario',
                    published: false,
                    inventory: 163
                },
                {
                    id: 'PC0164',
                    name: 'Papel de filtro',
                    published: false,
                    inventory: 164
                },
                {
                    id: 'PC0165',
                    name: 'Probeta',
                    published: false,
                    inventory: 165
                },
                {
                    id: 'PC0166',
                    name: 'Vidrio de reloj',
                    published: false,
                    inventory: 166
                },
                {
                    id: 'PC0167',
                    name: 'Vidrio de reloj',
                    published: false,
                    inventory: 167
                },
                {
                    id: 'PC0168',
                    name: 'Tejido',
                    published: false,
                    inventory: 168
                },
                {
                    id: 'PC0169',
                    name: 'Textil',
                    published: false,
                    inventory: 169
                },
                {
                    id: 'PC0170',
                    name: 'Vidrio de reloj',
                    published: false,
                    inventory: 170
                },
                {
                    id: 'PC0171',
                    name: 'Vidrio de reloj',
                    published: false,
                    inventory: 171
                },
                {
                    id: 'PC0172',
                    name: 'Vidrio de reloj',
                    published: false,
                    inventory: 172
                },
                {
                    id: 'PC0173',
                    name: 'Vidrio de reloj',
                    published: false,
                    inventory: 173
                },
                {
                    id: 'PC0174',
                    name: 'Vidrio de reloj',
                    published: false,
                    inventory: 174
                },
                {
                    id: 'PC0175',
                    name: 'Desecador',
                    published: false,
                    inventory: 175
                },
                {
                    id: 'PC0176',
                    name: 'Vidrio de reloj',
                    published: false,
                    inventory: 176
                },
                {
                    id: 'PC0177',
                    name: 'Bureta',
                    published: true,
                    inventory: 177
                },
                {
                    id: 'PC0178',
                    name: 'Muestra de Mineral',
                    published: false,
                    inventory: 178
                },
                {
                    id: 'PC0179',
                    name: 'Placa',
                    published: false,
                    inventory: 179
                },
                {
                    id: 'PC0180',
                    name: 'Muestra de Mineral',
                    published: false,
                    inventory: 180
                },
                {
                    id: 'PC0181',
                    name: 'Bureta',
                    published: false,
                    inventory: 181
                },
                {
                    id: 'PC0182',
                    name: 'Bureta',
                    published: false,
                    inventory: 182
                },
                {
                    id: 'PC0183',
                    name: 'Llave de Bureta',
                    published: true,
                    inventory: 183
                },
                {
                    id: 'PC0184',
                    name: 'Envase Sal',
                    published: false,
                    inventory: 184
                },
                {
                    id: 'PC0185',
                    name: 'Contrapeso balanza antigua',
                    published: false,
                    inventory: 185
                },
                {
                    id: 'PC0186',
                    name: 'Repuesto goma para linterna',
                    published: false,
                    inventory: 186
                },
                {
                    id: 'PC0187',
                    name: 'Envase te',
                    published: false,
                    inventory: 187
                },
                {
                    id: 'PC0188',
                    name: 'Sello de aceite',
                    published: false,
                    inventory: 188
                },
                {
                    id: 'PC0189',
                    name: 'Tapón',
                    published: false,
                    inventory: 189
                },
                {
                    id: 'PC0190',
                    name: 'O-Ring',
                    published: false,
                    inventory: 190
                },
                {
                    id: 'PC0191',
                    name: 'Cédula de indentdad',
                    published: false,
                    inventory: 191
                },
                {
                    id: 'PC0192',
                    name: 'Tope',
                    published: true,
                    inventory: 192
                },
                {
                    id: 'PC0193',
                    name: 'Tampón entintador',
                    published: false,
                    inventory: 193
                },
                {
                    id: 'PC0194',
                    name: 'Manómetro',
                    published: false,
                    inventory: 194
                },
                {
                    id: 'PC0195',
                    name: 'Bandeja',
                    published: false,
                    inventory: 195
                },
                {
                    id: 'PC0196',
                    name: 'Tampón entintador',
                    published: false,
                    inventory: 196
                },
                {
                    id: 'PC0197',
                    name: 'Portarretrato',
                    published: false,
                    inventory: 197
                },
                {
                    id: 'PC0198',
                    name: 'Tuerca',
                    published: false,
                    inventory: 198
                },
                {
                    id: 'PC0199',
                    name: 'Portarretrato',
                    published: false,
                    inventory: 199
                },
                {
                    id: 'PC0200',
                    name: 'Bandeja',
                    published: false,
                    inventory: 200
                },
                {
                    id: 'PC0201',
                    name: 'Biblia',
                    published: false,
                    inventory: 201
                },
                {
                    id: 'PC0202',
                    name: 'Delantal',
                    published: false,
                    inventory: 202
                },
                {
                    id: 'PC0203',
                    name: 'Polaina Descarne',
                    published: false,
                    inventory: 203
                },
                {
                    id: 'PC0204',
                    name: 'Abrazadera',
                    published: false,
                    inventory: 204
                },
                {
                    id: 'PC0205',
                    name: 'Betún para calzado',
                    published: false,
                    inventory: 205
                },
                {
                    id: 'PC0206',
                    name: 'Golilla',
                    published: true,
                    inventory: 206
                },
                {
                    id: 'PC0207',
                    name: 'Betún para calzado',
                    published: false,
                    inventory: 207
                },
                {
                    id: 'PC0208',
                    name: 'Herradura',
                    published: false,
                    inventory: 208
                },
                {
                    id: 'PC0209',
                    name: 'Llave Regulable',
                    published: false,
                    inventory: 209
                },
                {
                    id: 'PC0210',
                    name: 'Envase de agorex',
                    published: false,
                    inventory: 210
                },
                {
                    id: 'PC0211',
                    name: 'Manómetro',
                    published: false,
                    inventory: 211
                },
                {
                    id: 'PC0212',
                    name: 'Envase Mentalol',
                    published: false,
                    inventory: 212
                },
                {
                    id: 'PC0213',
                    name: 'Válvula de Admisión',
                    published: false,
                    inventory: 213
                },
                {
                    id: 'PC0214',
                    name: 'Delantal',
                    published: false,
                    inventory: 214
                },
                {
                    id: 'PC0215',
                    name: 'Alambre de cobre',
                    published: true,
                    inventory: 215
                },
                {
                    id: 'PC0216',
                    name: 'Gancho',
                    published: true,
                    inventory: 216
                },
                {
                    id: 'PC0217',
                    name: 'Mascarilla',
                    published: false,
                    inventory: 217
                },
                {
                    id: 'PC0218',
                    name: 'Gancho',
                    published: false,
                    inventory: 218
                },
                {
                    id: 'PC0219',
                    name: 'Llave Inglesa',
                    published: false,
                    inventory: 219
                },
                {
                    id: 'PC0220',
                    name: 'Engranaje',
                    published: false,
                    inventory: 220
                },
                {
                    id: 'PC0221',
                    name: 'Aislador de disco',
                    published: false,
                    inventory: 221
                },
                {
                    id: 'PC0222',
                    name: 'Engranaje',
                    published: true,
                    inventory: 222
                },
                {
                    id: 'PC0223',
                    name: 'Engranaje',
                    published: false,
                    inventory: 223
                },
                {
                    id: 'PC0224',
                    name: 'Engranaje',
                    published: false,
                    inventory: 224
                },
                {
                    id: 'PC0225',
                    name: 'Aislador de disco',
                    published: false,
                    inventory: 225
                },
                {
                    id: 'PC0226',
                    name: 'Estátor',
                    published: false,
                    inventory: 226
                },
                {
                    id: 'PC0227',
                    name: 'Tubería',
                    published: false,
                    inventory: 227
                },
                {
                    id: 'PC0228',
                    name: 'Llave de Corona',
                    published: false,
                    inventory: 228
                },
                {
                    id: 'PC0229',
                    name: 'Resorte',
                    published: false,
                    inventory: 229
                },
                {
                    id: 'PC0230',
                    name: 'Arandela',
                    published: false,
                    inventory: 230
                },
                {
                    id: 'PC0231',
                    name: 'Arandela',
                    published: false,
                    inventory: 231
                },
                {
                    id: 'PC0232',
                    name: 'Empaquetadura',
                    published: false,
                    inventory: 232
                },
                {
                    id: 'PC0233',
                    name: 'Chapa',
                    published: false,
                    inventory: 233
                },
                {
                    id: 'PC0234',
                    name: 'Palangana',
                    published: false,
                    inventory: 234
                },
                {
                    id: 'PC0235',
                    name: 'Lingote',
                    published: false,
                    inventory: 235
                },
                {
                    id: 'PC0236',
                    name: 'Textil',
                    published: false,
                    inventory: 236
                },
                {
                    id: 'PC0237',
                    name: 'Tapón',
                    published: false,
                    inventory: 237
                },
                {
                    id: 'PC0238',
                    name: 'Camiseta tipo polo',
                    published: false,
                    inventory: 238
                },
                {
                    id: 'PC0239',
                    name: 'Jarra',
                    published: false,
                    inventory: 239
                },
                {
                    id: 'PC0240',
                    name: 'Depósito para enemas',
                    published: false,
                    inventory: 240
                },
                {
                    id: 'PC0241',
                    name: 'Bacinica',
                    published: false,
                    inventory: 241
                },
                {
                    id: 'PC0242',
                    name: 'Repuesto placa para válvula',
                    published: false,
                    inventory: 242
                },
                {
                    id: 'PC0243',
                    name: 'Balde',
                    published: false,
                    inventory: 243
                },
                {
                    id: 'PC0244',
                    name: 'Pinza para crisol',
                    published: false,
                    inventory: 244
                },
                {
                    id: 'PC0245',
                    name: 'Frasco',
                    published: false,
                    inventory: 245
                },
                {
                    id: 'PC0246',
                    name: 'Muleta',
                    published: false,
                    inventory: 246
                },
                {
                    id: 'PC0247',
                    name: 'Empaquetadura',
                    published: false,
                    inventory: 247
                },
                {
                    id: 'PC0248',
                    name: 'Bacinica',
                    published: false,
                    inventory: 248
                },
                {
                    id: 'PC0249',
                    name: 'Olla',
                    published: false,
                    inventory: 249
                },
                {
                    id: 'PC0250',
                    name: 'Mochila',
                    published: false,
                    inventory: 250
                },
                {
                    id: 'PC0251',
                    name: 'Cuchara',
                    published: false,
                    inventory: 251
                },
                {
                    id: 'PC0252',
                    name: 'Cepillo Carpintero',
                    published: false,
                    inventory: 252
                },
                {
                    id: 'PC0253',
                    name: 'Anotador con sujeta papeles',
                    published: false,
                    inventory: 253
                },
                {
                    id: 'PC0254',
                    name: 'Tijera',
                    published: false,
                    inventory: 254
                },
                {
                    id: 'PC0255',
                    name: 'Cepillo Carpintero',
                    published: false,
                    inventory: 255
                },
                {
                    id: 'PC0256',
                    name: 'Sombrero de lluvia',
                    published: false,
                    inventory: 256
                },
                {
                    id: 'PC0257',
                    name: 'Bolas de molienda',
                    published: false,
                    inventory: 257
                },
                {
                    id: 'PC0258',
                    name: 'Cuchilla',
                    published: false,
                    inventory: 258
                },
                {
                    id: 'PC0259',
                    name: 'Envase de medicinas',
                    published: false,
                    inventory: 259
                },
                {
                    id: 'PC0260',
                    name: 'Bulón',
                    published: false,
                    inventory: 260
                },
                {
                    id: 'PC0261',
                    name: 'Dado para Terraja de Roscar',
                    published: false,
                    inventory: 261
                },
                {
                    id: 'PC0262',
                    name: 'Caja de herramientas',
                    published: false,
                    inventory: 262
                },
                {
                    id: 'PC0263',
                    name: 'Caja de herramientas',
                    published: false,
                    inventory: 263
                },
                {
                    id: 'PC0264',
                    name: 'Caja de herramientas',
                    published: false,
                    inventory: 264
                },
                {
                    id: 'PC0265',
                    name: 'Balde',
                    published: false,
                    inventory: 265
                },
                {
                    id: 'PC0266',
                    name: 'Cajón',
                    published: false,
                    inventory: 266
                },
                {
                    id: 'PC0267',
                    name: 'Frasco',
                    published: false,
                    inventory: 267
                },
                {
                    id: 'PC0268',
                    name: 'Megáfono',
                    published: false,
                    inventory: 268
                },
                {
                    id: 'PC0269',
                    name: 'Gafa de Protección',
                    published: false,
                    inventory: 269
                },
                {
                    id: 'PC0270',
                    name: 'Cenicero',
                    published: false,
                    inventory: 270
                },
                {
                    id: 'PC0271',
                    name: 'Arnés',
                    published: false,
                    inventory: 271
                },
                {
                    id: 'PC0272',
                    name: 'Jaula',
                    published: false,
                    inventory: 272
                },
                {
                    id: 'PC0273',
                    name: 'Trepadora',
                    published: false,
                    inventory: 273
                },
                {
                    id: 'PC0274',
                    name: 'Sartén',
                    published: false,
                    inventory: 274
                },
                {
                    id: 'PC0275',
                    name: 'Cinta magnética',
                    published: false,
                    inventory: 275
                },
                {
                    id: 'PC0276',
                    name: 'Paila',
                    published: false,
                    inventory: 276
                },
                {
                    id: 'PC0277',
                    name: 'Plato',
                    published: false,
                    inventory: 277
                },
                {
                    id: 'PC0278',
                    name: 'Palangana',
                    published: false,
                    inventory: 278
                },
                {
                    id: 'PC0279',
                    name: 'Teléfono',
                    published: false,
                    inventory: 279
                },
                {
                    id: 'PC0280',
                    name: 'Betún para calzado',
                    published: false,
                    inventory: 280
                },
                {
                    id: 'PC0281',
                    name: 'Zapato Infante',
                    published: false,
                    inventory: 281
                },
                {
                    id: 'PC0282',
                    name: 'Linterna',
                    published: false,
                    inventory: 282
                },
                {
                    id: 'PC0283',
                    name: 'Ampolla',
                    published: false,
                    inventory: 283
                },
                {
                    id: 'PC0284',
                    name: 'Organizador',
                    published: false,
                    inventory: 284
                },
                {
                    id: 'PC0285',
                    name: 'Zapato Infante',
                    published: false,
                    inventory: 285
                },
                {
                    id: 'PC0286',
                    name: 'Plato',
                    published: false,
                    inventory: 286
                },
                {
                    id: 'PC0287',
                    name: 'Recipiente',
                    published: false,
                    inventory: 287
                },
                {
                    id: 'PC0288',
                    name: 'Frasco',
                    published: false,
                    inventory: 288
                },
                {
                    id: 'PC0289',
                    name: 'Documento',
                    published: false,
                    inventory: 289
                },
                {
                    id: 'PC0290',
                    name: 'Olla',
                    published: false,
                    inventory: 290
                },
                {
                    id: 'PC0291',
                    name: 'Campana de lámpara',
                    published: false,
                    inventory: 291
                },
                {
                    id: 'PC0292',
                    name: 'Frasco',
                    published: false,
                    inventory: 292
                },
                {
                    id: 'PC0293',
                    name: 'Plancha',
                    published: false,
                    inventory: 293
                },
                {
                    id: 'PC0294',
                    name: 'Balde',
                    published: false,
                    inventory: 294
                },
                {
                    id: 'PC0295',
                    name: 'Balde',
                    published: false,
                    inventory: 295
                },
                {
                    id: 'PC0296',
                    name: 'Muestra de Mineral',
                    published: false,
                    inventory: 296
                },
                {
                    id: 'PC0297',
                    name: 'Gasa',
                    published: false,
                    inventory: 297
                },
                {
                    id: 'PC0298',
                    name: 'Caja',
                    published: false,
                    inventory: 298
                },
                {
                    id: 'PC0299',
                    name: 'Caja de herramientas',
                    published: false,
                    inventory: 299
                },
                {
                    id: 'PC0300',
                    name: 'Placa torneada',
                    published: false,
                    inventory: 300
                },
                {
                    id: 'PC0301',
                    name: 'moneda',
                    published: false,
                    inventory: 301
                },
                {
                    id: 'PC0302',
                    name: 'Bandeja',
                    published: false,
                    inventory: 302
                },
                {
                    id: 'PC0303',
                    name: 'Contrapeso balanza antigua',
                    published: false,
                    inventory: 303
                },
                {
                    id: 'PC0304',
                    name: 'Viruta',
                    published: false,
                    inventory: 304
                },
                {
                    id: 'PC0305',
                    name: 'Granada contra incendios',
                    published: false,
                    inventory: 305
                },
                {
                    id: 'PC0306',
                    name: 'Calculadora',
                    published: false,
                    inventory: 306
                },
                {
                    id: 'PC0307',
                    name: 'Escritorio',
                    published: false,
                    inventory: 307
                },
                {
                    id: 'PC0308',
                    name: 'Camilla',
                    published: false,
                    inventory: 308
                },
                {
                    id: 'PC0309',
                    name: 'Librería',
                    published: false,
                    inventory: 309
                },
                {
                    id: 'PC0310',
                    name: 'Banca',
                    published: false,
                    inventory: 310
                },
                {
                    id: 'PC0311',
                    name: 'Botella',
                    published: false,
                    inventory: 311
                },
                {
                    id: 'PC0312',
                    name: 'Escala',
                    published: false,
                    inventory: 312
                },
                {
                    id: 'PC0313',
                    name: 'Batería',
                    published: true,
                    inventory: 313
                },
                {
                    id: 'PC0314',
                    name: 'Botella',
                    published: false,
                    inventory: 314
                },
                {
                    id: 'PC0315',
                    name: 'Megáfono',
                    published: false,
                    inventory: 315
                },
                {
                    id: 'PC0316',
                    name: 'Manchon de Acople',
                    published: false,
                    inventory: 316
                },
                {
                    id: 'PC0317',
                    name: 'Soporte para contrapesos de balanza antigua',
                    published: false,
                    inventory: 317
                },
                {
                    id: 'PC0318',
                    name: 'Archivador',
                    published: true,
                    inventory: 318
                },
                {
                    id: 'PC0319',
                    name: 'Megáfono',
                    published: false,
                    inventory: 319
                },
                {
                    id: 'PC0320',
                    name: 'Caja',
                    published: false,
                    inventory: 320
                },
                {
                    id: 'PC0321',
                    name: 'Chancador de Mandíbula',
                    published: true,
                    inventory: 321
                },
                {
                    id: 'PC0322',
                    name: 'Silla',
                    published: false,
                    inventory: 322
                },
                {
                    id: 'PC0323',
                    name: 'Silla',
                    published: false,
                    inventory: 323
                },
                {
                    id: 'PC0324',
                    name: 'Motor Eléctrico',
                    published: false,
                    inventory: 324
                },
                {
                    id: 'PC0325',
                    name: 'Infantómetro',
                    published: false,
                    inventory: 325
                },
                {
                    id: 'PC0326',
                    name: 'Cinta Transportadora',
                    published: false,
                    inventory: 326
                },
                {
                    id: 'PC0327',
                    name: 'Testigo de perforación',
                    published: false,
                    inventory: 327
                },
                {
                    id: 'PC0328',
                    name: 'Cartel',
                    published: false,
                    inventory: 328
                },
                {
                    id: 'PC0329',
                    name: 'Cuna',
                    published: false,
                    inventory: 329
                },
                {
                    id: 'PC0330',
                    name: 'Cartel',
                    published: false,
                    inventory: 330
                },
                {
                    id: 'PC0331',
                    name: 'Crisol',
                    published: false,
                    inventory: 331
                },
                {
                    id: 'PC0332',
                    name: 'Cartel',
                    published: false,
                    inventory: 332
                },
                {
                    id: 'PC0333',
                    name: 'Motor Eléctrico',
                    published: false,
                    inventory: 333
                },
                {
                    id: 'PC0334',
                    name: 'Póster',
                    published: false,
                    inventory: 334
                },
                {
                    id: 'PC0335',
                    name: 'Pinza',
                    published: false,
                    inventory: 335
                },
                {
                    id: 'PC0336',
                    name: 'Llave Inglesa',
                    published: false,
                    inventory: 336
                },
                {
                    id: 'PC0337',
                    name: 'Camilla',
                    published: false,
                    inventory: 337
                },
                {
                    id: 'PC0338',
                    name: 'Estátor',
                    published: false,
                    inventory: 338
                },
                {
                    id: 'PC0339',
                    name: 'Mazo',
                    published: false,
                    inventory: 339
                },
                {
                    id: 'PC0340',
                    name: 'PLano de estructura',
                    published: false,
                    inventory: 340
                },
                {
                    id: 'PC0341',
                    name: 'Placa',
                    published: false,
                    inventory: 341
                },
                {
                    id: 'PC0342',
                    name: 'Pupitre',
                    published: false,
                    inventory: 342
                },
                {
                    id: 'PC0343',
                    name: 'Televisor',
                    published: false,
                    inventory: 343
                },
                {
                    id: 'PC0344',
                    name: 'Serrucho',
                    published: false,
                    inventory: 344
                },
                {
                    id: 'PC0345',
                    name: 'Serrucho',
                    published: false,
                    inventory: 345
                },
                {
                    id: 'PC0346',
                    name: 'Cartel',
                    published: false,
                    inventory: 346
                },
                {
                    id: 'PC0347',
                    name: 'Cartel',
                    published: false,
                    inventory: 347
                },
                {
                    id: 'PC0348',
                    name: 'Cartel',
                    published: false,
                    inventory: 348
                },
                {
                    id: 'PC0349',
                    name: 'Cortaperno',
                    published: false,
                    inventory: 349
                },
                {
                    id: 'PC0350',
                    name: 'Cartel',
                    published: false,
                    inventory: 350
                },
                {
                    id: 'PC0351',
                    name: 'Cartel',
                    published: false,
                    inventory: 351
                },
                {
                    id: 'PC0352',
                    name: 'Cartel',
                    published: false,
                    inventory: 352
                },
                {
                    id: 'PC0353',
                    name: 'Cartel',
                    published: false,
                    inventory: 353
                },
                {
                    id: 'PC0354',
                    name: 'Cartel',
                    published: false,
                    inventory: 354
                },
                {
                    id: 'PC0355',
                    name: 'Balanza',
                    published: false,
                    inventory: 355
                },
                {
                    id: 'PC0356',
                    name: 'Cartel',
                    published: false,
                    inventory: 356
                },
                {
                    id: 'PC0357',
                    name: 'Cartel',
                    published: false,
                    inventory: 357
                },
                {
                    id: 'PC0358',
                    name: 'Balanza',
                    published: false,
                    inventory: 358
                },
                {
                    id: 'PC0359',
                    name: 'Textil',
                    published: false,
                    inventory: 359
                },
                {
                    id: 'PC0360',
                    name: 'Cartel',
                    published: false,
                    inventory: 360
                },
                {
                    id: 'PC0361',
                    name: 'Mira topográfica',
                    published: false,
                    inventory: 361
                },
                {
                    id: 'PC0362',
                    name: 'Textil',
                    published: false,
                    inventory: 362
                },
                {
                    id: 'PC0363',
                    name: 'Polines',
                    published: false,
                    inventory: 363
                },
                {
                    id: 'PC0364',
                    name: 'Andador',
                    published: false,
                    inventory: 364
                },
                {
                    id: 'PC0365',
                    name: 'Camiseta tipo polo',
                    published: false,
                    inventory: 365
                },
                {
                    id: 'PC0366',
                    name: 'Andador',
                    published: false,
                    inventory: 366
                },
                {
                    id: 'PC0367',
                    name: 'Campana',
                    published: false,
                    inventory: 367
                },
                {
                    id: 'PC0368',
                    name: 'Chaqueta',
                    published: false,
                    inventory: 368
                },
                {
                    id: 'PC0369',
                    name: 'Pizarra',
                    published: false,
                    inventory: 369
                },
                {
                    id: 'PC0370',
                    name: 'Cartel',
                    published: false,
                    inventory: 370
                },
                {
                    id: 'PC0371',
                    name: 'Pizarra',
                    published: false,
                    inventory: 371
                },
                {
                    id: 'PC0372',
                    name: 'Señalética',
                    published: false,
                    inventory: 372
                },
                {
                    id: 'PC0373',
                    name: 'Lienzo',
                    published: false,
                    inventory: 373
                },
                {
                    id: 'PC0374',
                    name: 'Cartel',
                    published: false,
                    inventory: 374
                },
                {
                    id: 'PC0375',
                    name: 'Señalética',
                    published: false,
                    inventory: 375
                },
                {
                    id: 'PC0376',
                    name: 'Escritorio',
                    published: false,
                    inventory: 376
                },
                {
                    id: 'PC0377',
                    name: 'Carro Minero',
                    published: false,
                    inventory: 377
                },
                {
                    id: 'PC0378',
                    name: 'Señalética',
                    published: false,
                    inventory: 378
                },
                {
                    id: 'PC0379',
                    name: 'Placa',
                    published: false,
                    inventory: 379
                },
                {
                    id: 'PC0380',
                    name: 'Placa',
                    published: false,
                    inventory: 380
                },
                {
                    id: 'PC0381',
                    name: 'Silla',
                    published: false,
                    inventory: 381
                },
                {
                    id: 'PC0382',
                    name: 'Señalética',
                    published: false,
                    inventory: 382
                },
                {
                    id: 'PC0383',
                    name: 'Salamandra',
                    published: false,
                    inventory: 383
                },
                {
                    id: 'PC0384',
                    name: 'Mapa de Chile',
                    published: false,
                    inventory: 384
                },
                {
                    id: 'PC0385',
                    name: 'Cama de Exploración',
                    published: false,
                    inventory: 385
                },
                {
                    id: 'PC0386',
                    name: 'Mesa',
                    published: false,
                    inventory: 386
                },
                {
                    id: 'PC0387',
                    name: 'Carro Minero',
                    published: false,
                    inventory: 387
                },
                {
                    id: 'PC0388',
                    name: 'Mostrador',
                    published: false,
                    inventory: 388
                },
                {
                    id: 'PC0389',
                    name: 'Cama de Exploración',
                    published: false,
                    inventory: 389
                },
                {
                    id: 'PC0390',
                    name: 'Cama de Exploración',
                    published: false,
                    inventory: 390
                },
                {
                    id: 'PC0391',
                    name: 'Rotor',
                    published: false,
                    inventory: 391
                },
                {
                    id: 'PC0392',
                    name: 'Unidad dental',
                    published: false,
                    inventory: 392
                },
                {
                    id: 'PC0393',
                    name: 'Báscula',
                    published: false,
                    inventory: 393
                },
                {
                    id: 'PC0394',
                    name: 'Placa',
                    published: false,
                    inventory: 394
                },
                {
                    id: 'PC0395',
                    name: 'Imagen de Culto',
                    published: false,
                    inventory: 395
                },
                {
                    id: 'PC0396',
                    name: 'Sierra Eléctrica',
                    published: false,
                    inventory: 396
                },
                {
                    id: 'PC0397',
                    name: 'Fragua',
                    published: false,
                    inventory: 397
                },
                {
                    id: 'PC0398',
                    name: 'Lienzo',
                    published: false,
                    inventory: 398
                },
                {
                    id: 'PC0399',
                    name: 'Motor Eléctrico',
                    published: false,
                    inventory: 399
                },
                {
                    id: 'PC0400',
                    name: 'Cartel',
                    published: false,
                    inventory: 400
                },
                {
                    id: 'PC0401',
                    name: 'Cuchillo',
                    published: false,
                    inventory: 401
                },
                {
                    id: 'PC0402',
                    name: 'Mesa',
                    published: false,
                    inventory: 402
                },
                {
                    id: 'PC0403',
                    name: 'Caja fuerte',
                    published: false,
                    inventory: 403
                },
                {
                    id: 'PC0404',
                    name: 'Aro Salvavidas',
                    published: false,
                    inventory: 404
                },
                {
                    id: 'PC0405',
                    name: 'Molino de Barras',
                    published: false,
                    inventory: 405
                },
                {
                    id: 'PC0406',
                    name: 'Fichas Numeradas',
                    published: false,
                    inventory: 406
                },
                {
                    id: 'PC0407',
                    name: 'Estatuilla Religiosa',
                    published: true,
                    inventory: 407
                },
                {
                    id: 'PC0408',
                    name: 'Campana',
                    published: false,
                    inventory: 408
                },
                {
                    id: 'PC0409',
                    name: 'Ofrendero',
                    published: true,
                    inventory: 409
                },
                {
                    id: 'PC0410',
                    name: 'Crisol',
                    published: false,
                    inventory: 410
                },
                {
                    id: 'PC0411',
                    name: 'Mascarilla',
                    published: false,
                    inventory: 411
                },
                {
                    id: 'PC0412',
                    name: 'Jarra',
                    published: false,
                    inventory: 412
                },
                {
                    id: 'PC0413',
                    name: 'Timbre',
                    published: false,
                    inventory: 413
                },
                {
                    id: 'PC0414',
                    name: 'Escudo facial',
                    published: false,
                    inventory: 414
                },
                {
                    id: 'PC0415',
                    name: 'Llave',
                    published: false,
                    inventory: 415
                },
                {
                    id: 'PC0416',
                    name: 'Manómetro',
                    published: false,
                    inventory: 416
                },
                {
                    id: 'PC0417',
                    name: 'Reloj',
                    published: false,
                    inventory: 417
                },
                {
                    id: 'PC0418',
                    name: 'Taza',
                    published: false,
                    inventory: 418
                },
                {
                    id: 'PC0419',
                    name: 'Resorte',
                    published: false,
                    inventory: 419
                },
                {
                    id: 'PC0420',
                    name: 'Placa',
                    published: false,
                    inventory: 420
                },
                {
                    id: 'PC0421',
                    name: 'Mandril',
                    published: false,
                    inventory: 421
                },
                {
                    id: 'PC0422',
                    name: 'Mandril',
                    published: false,
                    inventory: 422
                },
                {
                    id: 'PC0423',
                    name: 'Bocina',
                    published: false,
                    inventory: 423
                },
                {
                    id: 'PC0424',
                    name: 'Estator',
                    published: false,
                    inventory: 424
                },
                {
                    id: 'PC0425',
                    name: 'Tuerca',
                    published: false,
                    inventory: 425
                },
                {
                    id: 'PC0426',
                    name: 'Anotador',
                    published: false,
                    inventory: 426
                },
                {
                    id: 'PC0427',
                    name: 'Olla',
                    published: false,
                    inventory: 427
                },
                {
                    id: 'PC0428',
                    name: 'Pistón',
                    published: false,
                    inventory: 428
                },
                {
                    id: 'PC0429',
                    name: 'Cinta métrica',
                    published: false,
                    inventory: 429
                },
                {
                    id: 'PC0430',
                    name: 'Pistón',
                    published: false,
                    inventory: 430
                },
                {
                    id: 'PC0431',
                    name: 'Ancla',
                    published: false,
                    inventory: 431
                },
                {
                    id: 'PC0432',
                    name: 'Barra perforadora',
                    published: false,
                    inventory: 432
                },
                {
                    id: 'PC0433',
                    name: 'Taza',
                    published: false,
                    inventory: 433
                },
                {
                    id: 'PC0434',
                    name: 'estabilizador',
                    published: false,
                    inventory: 434
                },
                {
                    id: 'PC0435',
                    name: 'Hélice',
                    published: false,
                    inventory: 435
                },
                {
                    id: 'PC0436',
                    name: 'Corona perforación',
                    published: false,
                    inventory: 436
                },
                {
                    id: 'PC0437',
                    name: 'Mandril',
                    published: false,
                    inventory: 437
                },
                {
                    id: 'PC0438',
                    name: 'Anotador',
                    published: false,
                    inventory: 438
                },
                {
                    id: 'PC0439',
                    name: 'Llave de Corona',
                    published: false,
                    inventory: 439
                },
                {
                    id: 'PC0440',
                    name: 'Corbata',
                    published: false,
                    inventory: 440
                },
                {
                    id: 'PC0441',
                    name: 'Testigo de perforación',
                    published: false,
                    inventory: 441
                },
                {
                    id: 'PC0442',
                    name: 'Llave de Corona',
                    published: false,
                    inventory: 442
                },
                {
                    id: 'PC0443',
                    name: 'Testigo de perforación',
                    published: false,
                    inventory: 443
                },
                {
                    id: 'PC0444',
                    name: 'Testigo de perforación',
                    published: false,
                    inventory: 444
                },
                {
                    id: 'PC0445',
                    name: 'Set Escariadores',
                    published: false,
                    inventory: 445
                },
                {
                    id: 'PC0446',
                    name: 'Llave de Punta',
                    published: false,
                    inventory: 446
                },
                {
                    id: 'PC0447',
                    name: 'Llave de Punta',
                    published: false,
                    inventory: 447
                },
                {
                    id: 'PC0448',
                    name: 'Filtros de aire',
                    published: false,
                    inventory: 448
                },
                {
                    id: 'PC0449',
                    name: 'Set de Escariadores',
                    published: false,
                    inventory: 449
                },
                {
                    id: 'PC0450',
                    name: 'Porta resorte',
                    published: false,
                    inventory: 450
                },
                {
                    id: 'PC0451',
                    name: 'Cadena Motosierra',
                    published: false,
                    inventory: 451
                },
                {
                    id: 'PC0452',
                    name: 'Fichas Numeradas',
                    published: false,
                    inventory: 452
                },
                {
                    id: 'PC0453',
                    name: 'Anillo de Aterrizaje',
                    published: false,
                    inventory: 453
                },
                {
                    id: 'PC0454',
                    name: 'Filtros de aire',
                    published: false,
                    inventory: 454
                },
                {
                    id: 'PC0455',
                    name: 'Codo',
                    published: false,
                    inventory: 455
                },
                {
                    id: 'PC0456',
                    name: 'Lubricador',
                    published: false,
                    inventory: 456
                },
                {
                    id: 'PC0457',
                    name: 'Acople',
                    published: false,
                    inventory: 457
                },
                {
                    id: 'PC0458',
                    name: 'Lubricador',
                    published: false,
                    inventory: 458
                },
                {
                    id: 'PC0459',
                    name: 'Lubricador',
                    published: false,
                    inventory: 459
                },
                {
                    id: 'PC0460',
                    name: 'Bidón',
                    published: false,
                    inventory: 460
                },
                {
                    id: 'PC0461',
                    name: 'Cabeza de Muñeca',
                    published: false,
                    inventory: 461
                },
                {
                    id: 'PC0462',
                    name: 'Escariador',
                    published: false,
                    inventory: 462
                },
                {
                    id: 'PC0463',
                    name: 'Campana para depósito de inodoro',
                    published: false,
                    inventory: 463
                },
                {
                    id: 'PC0464',
                    name: 'tapaorejas',
                    published: false,
                    inventory: 464
                },
                {
                    id: 'PC0465',
                    name: 'Gafa de Protección',
                    published: false,
                    inventory: 465
                },
                {
                    id: 'PC0466',
                    name: 'Choquero',
                    published: false,
                    inventory: 466
                },
                {
                    id: 'PC0467',
                    name: 'Acople',
                    published: false,
                    inventory: 467
                },
                {
                    id: 'PC0468',
                    name: 'Acople',
                    published: false,
                    inventory: 468
                },
                {
                    id: 'PC0469',
                    name: 'Tee',
                    published: false,
                    inventory: 469
                },
                {
                    id: 'PC0470',
                    name: 'Tuerca',
                    published: false,
                    inventory: 470
                },
                {
                    id: 'PC0471',
                    name: 'Acople',
                    published: false,
                    inventory: 471
                },
                {
                    id: 'PC0472',
                    name: 'Tee',
                    published: false,
                    inventory: 472
                },
                {
                    id: 'PC0473',
                    name: 'Acople',
                    published: false,
                    inventory: 473
                },
                {
                    id: 'PC0474',
                    name: 'Tee',
                    published: false,
                    inventory: 474
                },
                {
                    id: 'PC0475',
                    name: 'Conector de manguera',
                    published: false,
                    inventory: 475
                },
                {
                    id: 'PC0476',
                    name: 'Platos',
                    published: false,
                    inventory: 476
                },
                {
                    id: 'PC0477',
                    name: 'Motor Diesel',
                    published: false,
                    inventory: 477
                },
                {
                    id: 'PC0478',
                    name: 'Radio',
                    published: false,
                    inventory: 478
                },
                {
                    id: 'PC0479',
                    name: 'Set de coronas',
                    published: false,
                    inventory: 479
                },
                {
                    id: 'PC0480',
                    name: 'Tubería',
                    published: false,
                    inventory: 480
                },
                {
                    id: 'PC0481',
                    name: 'Organizador',
                    published: false,
                    inventory: 481
                },
                {
                    id: 'PC0482',
                    name: 'Pistón completo',
                    published: false,
                    inventory: 482
                },
                {
                    id: 'PC0483',
                    name: 'Estetoscopio de pinar',
                    published: false,
                    inventory: 483
                },
                {
                    id: 'PC0484',
                    name: 'Radiocasete',
                    published: false,
                    inventory: 484
                },
                {
                    id: 'PC0485',
                    name: 'Estandarte',
                    published: false,
                    inventory: 485
                },
                {
                    id: 'PC0486',
                    name: 'Calendario',
                    published: false,
                    inventory: 486
                },
                {
                    id: 'PC0487',
                    name: 'Botella',
                    published: false,
                    inventory: 487
                },
                {
                    id: 'PC0488',
                    name: 'Zapato Infante',
                    published: false,
                    inventory: 488
                },
                {
                    id: 'PC0489',
                    name: 'Botella',
                    published: false,
                    inventory: 489
                },
                {
                    id: 'PC0490',
                    name: 'Tope de Puerta',
                    published: false,
                    inventory: 490
                },
                {
                    id: 'PC0491',
                    name: 'Maceta',
                    published: false,
                    inventory: 491
                },
                {
                    id: 'PC0492',
                    name: 'Señalética',
                    published: false,
                    inventory: 492
                },
                {
                    id: 'PC0493',
                    name: 'Señalética',
                    published: false,
                    inventory: 493
                },
                {
                    id: 'PC0494',
                    name: 'Señalética',
                    published: false,
                    inventory: 494
                },
                {
                    id: 'PC0495',
                    name: 'Balanza',
                    published: false,
                    inventory: 495
                },
                {
                    id: 'PC0496',
                    name: 'Balanza',
                    published: false,
                    inventory: 496
                },
                {
                    id: 'PC0497',
                    name: 'Guía',
                    published: false,
                    inventory: 497
                },
                {
                    id: 'PC0498',
                    name: 'Campana de lámpara',
                    published: false,
                    inventory: 498
                },
                {
                    id: 'PC0499',
                    name: 'Muestra de Mineral',
                    published: false,
                    inventory: 499
                },
                {
                    id: 'PC0500',
                    name: 'Muestra de Mineral',
                    published: false,
                    inventory: 500
                },
                {
                    id: 'PC0501',
                    name: 'Guillotina',
                    published: false,
                    inventory: 501
                },
                {
                    id: 'PC0502',
                    name: 'Muestra de Mineral',
                    published: false,
                    inventory: 502
                },
                {
                    id: 'PC0503',
                    name: 'Envase de Pintura',
                    published: false,
                    inventory: 503
                },
                {
                    id: 'PC0504',
                    name: 'Muestra de Mineral',
                    published: false,
                    inventory: 504
                },
                {
                    id: 'PC0505',
                    name: 'Señalética',
                    published: false,
                    inventory: 505
                },
                {
                    id: 'PC0506',
                    name: 'Termo Eléctrico',
                    published: false,
                    inventory: 506
                },
                {
                    id: 'PC0507',
                    name: 'Rotor',
                    published: false,
                    inventory: 507
                },
                {
                    id: 'PC0508',
                    name: 'Motor Eléctrico',
                    published: false,
                    inventory: 508
                },
                {
                    id: 'PC0509',
                    name: 'Compresor',
                    published: false,
                    inventory: 509
                },
                {
                    id: 'PC0510',
                    name: 'Molino de Bolas',
                    published: false,
                    inventory: 510
                },
                {
                    id: 'PC0511',
                    name: 'Turbina',
                    published: false,
                    inventory: 511
                },
                {
                    id: 'PC0512',
                    name: 'Tablero eléctrico',
                    published: false,
                    inventory: 512
                },
                {
                    id: 'PC0513',
                    name: 'Discos de vinilo',
                    published: false,
                    inventory: 513
                },
                {
                    id: 'PC0514',
                    name: 'Lámpara de gas',
                    published: false,
                    inventory: 514
                },
                {
                    id: 'PC0515',
                    name: 'Llave de Corona',
                    published: false,
                    inventory: 515
                },
                {
                    id: 'PC0516',
                    name: 'Llave de Corona',
                    published: false,
                    inventory: 516
                },
                {
                    id: 'PC0517',
                    name: 'Muestra de Mineral',
                    published: false,
                    inventory: 517
                },
                {
                    id: 'PC0518',
                    name: 'Campana de lámpara',
                    published: false,
                    inventory: 518
                },
                {
                    id: 'PC0519',
                    name: 'Señalética',
                    published: false,
                    inventory: 519
                },
                {
                    id: 'PC0520',
                    name: 'Tocadiscos',
                    published: false,
                    inventory: 520
                },
                {
                    id: 'PC0521',
                    name: 'Sistema Modular de Carga MiniSpot',
                    published: false,
                    inventory: 521
                },
                {
                    id: 'PC0522',
                    name: 'Revista',
                    published: false,
                    inventory: 522
                },
                {
                    id: 'PC0523',
                    name: 'Cartel',
                    published: false,
                    inventory: 523
                },
                {
                    id: 'PC0524',
                    name: 'Catálogo',
                    published: false,
                    inventory: 524
                },
                {
                    id: 'PC0525',
                    name: 'Catálogo',
                    published: false,
                    inventory: 525
                },
                {
                    id: 'PC0526',
                    name: 'Cartel',
                    published: false,
                    inventory: 526
                },
                {
                    id: 'PC0527',
                    name: 'Cinturón',
                    published: false,
                    inventory: 527
                },
                {
                    id: 'PC0528',
                    name: 'Correa para poste',
                    published: false,
                    inventory: 528
                },
                {
                    id: 'PC0529',
                    name: 'Peineta',
                    published: false,
                    inventory: 529
                },
                {
                    id: 'PC0530',
                    name: 'Señalética',
                    published: false,
                    inventory: 530
                },
                {
                    id: 'PC0531',
                    name: 'Póster',
                    published: false,
                    inventory: 531
                },
                {
                    id: 'PC0532',
                    name: 'Cartel',
                    published: false,
                    inventory: 532
                },
                {
                    id: 'PC0533',
                    name: 'Tobogán',
                    published: false,
                    inventory: 533
                },
                {
                    id: 'PC0534',
                    name: 'Estufa',
                    published: false,
                    inventory: 534
                },
                {
                    id: 'PC0535',
                    name: 'Manual',
                    published: false,
                    inventory: 535
                },
                {
                    id: 'PC0536',
                    name: 'Infiernillo eléctrico',
                    published: false,
                    inventory: 536
                },
                {
                    id: 'PC0537',
                    name: 'Columpio',
                    published: false,
                    inventory: 537
                },
                {
                    id: 'PC0538',
                    name: 'Palangana',
                    published: false,
                    inventory: 538
                },
                {
                    id: 'PC0539',
                    name: 'Abrazadera',
                    published: false,
                    inventory: 539
                },
                {
                    id: 'PC0540',
                    name: 'Contrapeso balanza',
                    published: false,
                    inventory: 540
                },
                {
                    id: 'PC0541',
                    name: 'Carrusel',
                    published: false,
                    inventory: 541
                },
                {
                    id: 'PC0542',
                    name: 'Arnés',
                    published: false,
                    inventory: 542
                },
                {
                    id: 'PC0543',
                    name: 'Cartel',
                    published: false,
                    inventory: 543
                },
                {
                    id: 'PC0544',
                    name: 'Escalera Gimnasia',
                    published: false,
                    inventory: 544
                },
                {
                    id: 'PC0545',
                    name: 'Cartel',
                    published: false,
                    inventory: 545
                },
                {
                    id: 'PC0546',
                    name: 'Muestra de Mineral',
                    published: false,
                    inventory: 546
                },
                {
                    id: 'PC0547',
                    name: 'Rallador',
                    published: false,
                    inventory: 547
                },
                {
                    id: 'PC0548',
                    name: 'Atril',
                    published: false,
                    inventory: 548
                },
                {
                    id: 'PC0549',
                    name: 'Paleta ping pong',
                    published: false,
                    inventory: 549
                },
                {
                    id: 'PC0550',
                    name: 'Imagen de Culto',
                    published: false,
                    inventory: 550
                },
                {
                    id: 'PC0551',
                    name: 'Cajetilla de cigarrillos',
                    published: false,
                    inventory: 551
                },
                {
                    id: 'PC0552',
                    name: 'Pasta de dientes',
                    published: false,
                    inventory: 552
                },
                {
                    id: 'PC0553',
                    name: 'Caldera',
                    published: false,
                    inventory: 553
                },
                {
                    id: 'PC0554',
                    name: 'Espátula',
                    published: false,
                    inventory: 554
                },
                {
                    id: 'PC0555',
                    name: 'Ciclón',
                    published: false,
                    inventory: 555
                },
                {
                    id: 'PC0556',
                    name: 'Póster',
                    published: false,
                    inventory: 556
                },
                {
                    id: 'PC0557',
                    name: 'Rosario',
                    published: false,
                    inventory: 557
                },
                {
                    id: 'PC0558',
                    name: 'Rosario',
                    published: false,
                    inventory: 558
                },
                {
                    id: 'PC0559',
                    name: 'Señalética',
                    published: false,
                    inventory: 559
                },
                {
                    id: 'PC0560',
                    name: 'Candelabro',
                    published: false,
                    inventory: 560
                },
                {
                    id: 'PC0561',
                    name: 'Cirio',
                    published: false,
                    inventory: 561
                },
                {
                    id: 'PC0562',
                    name: 'Olla',
                    published: false,
                    inventory: 562
                },
                {
                    id: 'PC0563',
                    name: 'Florero',
                    published: false,
                    inventory: 563
                },
                {
                    id: 'PC0564',
                    name: 'Taza',
                    published: false,
                    inventory: 564
                },
                {
                    id: 'PC0565',
                    name: 'Ofrendero',
                    published: false,
                    inventory: 565
                },
                {
                    id: 'PC0566',
                    name: 'Calendario',
                    published: false,
                    inventory: 566
                },
                {
                    id: 'PC0567',
                    name: 'Jack Leg',
                    published: false,
                    inventory: 567
                },
                {
                    id: 'PC0568',
                    name: 'Letrero',
                    published: false,
                    inventory: 568
                },
                {
                    id: 'PC0569',
                    name: 'Stoper',
                    published: false,
                    inventory: 569
                },
                {
                    id: 'PC0570',
                    name: 'Jack Hammer',
                    published: false,
                    inventory: 570
                },
                {
                    id: 'PC0571',
                    name: 'Sagrario',
                    published: false,
                    inventory: 571
                },
                {
                    id: 'PC0572',
                    name: 'Tetera',
                    published: false,
                    inventory: 572
                },
                {
                    id: 'PC0573',
                    name: 'Portería',
                    published: false,
                    inventory: 573
                },
                {
                    id: 'PC0574',
                    name: 'Portería',
                    published: false,
                    inventory: 574
                },
                {
                    id: 'PC0575',
                    name: 'Canasta basketball',
                    published: false,
                    inventory: 575
                },
                {
                    id: 'PC0576',
                    name: 'Pesebre',
                    published: false,
                    inventory: 576
                },
                {
                    id: 'PC0577',
                    name: 'Esmeril',
                    published: false,
                    inventory: 577
                },
                {
                    id: 'PC0578',
                    name: 'Banca',
                    published: false,
                    inventory: 578
                },
                {
                    id: 'PC0579',
                    name: 'Altar',
                    published: false,
                    inventory: 579
                },
                {
                    id: 'PC0580',
                    name: 'Estufa',
                    published: false,
                    inventory: 580
                },
                {
                    id: 'PC0581',
                    name: 'Altar',
                    published: false,
                    inventory: 581
                },
                {
                    id: 'PC0582',
                    name: 'Plato metálico',
                    published: false,
                    inventory: 582
                },
                {
                    id: 'PC0583',
                    name: 'Banca',
                    published: false,
                    inventory: 583
                },
                {
                    id: 'PC0584',
                    name: 'Fuente',
                    published: false,
                    inventory: 584
                },
                {
                    id: 'PC0585',
                    name: 'tapa de olla',
                    published: false,
                    inventory: 585
                },
                {
                    id: 'PC0586',
                    name: 'Armazón de cama',
                    published: false,
                    inventory: 586
                },
                {
                    id: 'PC0587',
                    name: 'Silla',
                    published: false,
                    inventory: 587
                },
                {
                    id: 'PC0588',
                    name: 'Corona',
                    published: false,
                    inventory: 588
                },
                {
                    id: 'PC0589',
                    name: 'Mesa',
                    published: false,
                    inventory: 589
                },
                {
                    id: 'PC0590',
                    name: 'Cruz',
                    published: false,
                    inventory: 590
                },
                {
                    id: 'PC0591',
                    name: 'Choquero',
                    published: false,
                    inventory: 591
                },
                {
                    id: 'PC0592',
                    name: 'Tiraje',
                    published: false,
                    inventory: 592
                },
                {
                    id: 'PC0593',
                    name: 'Candelabro',
                    published: false,
                    inventory: 593
                },
                {
                    id: 'PC0594',
                    name: 'Banca',
                    published: false,
                    inventory: 594
                },
                {
                    id: 'PC0595',
                    name: 'Banca',
                    published: false,
                    inventory: 595
                },
                {
                    id: 'PC0596',
                    name: 'Jarra',
                    published: false,
                    inventory: 596
                },
                {
                    id: 'PC0597',
                    name: 'Tejuela',
                    published: false,
                    inventory: 597
                },
                {
                    id: 'PC0598',
                    name: 'Señalética',
                    published: false,
                    inventory: 598
                },
                {
                    id: 'PC0599',
                    name: 'Cuadro',
                    published: false,
                    inventory: 599
                },
                {
                    id: 'PC0600',
                    name: 'Rodillo',
                    published: false,
                    inventory: 600
                }
            ]
            arr.forEach(elem=>{
                let doc=getters.getDocumentById(elem.id)
                let val=''
                doc.found.forEach(el=>{
                    if(el.label==='sitio'){
                        val=el.value
                    }
                })
                elem.origin=val
                elem.published=doc.published
            })
            
            await updateDoc(doc(db, 'ListaObjetos', 'indexList'), {
                list: arr
            })
        }
    },
    getters: {
        order: state => {
            return state.order
        },
        queryParams: state => {
            return state.queryParams
        },
        currentItemList: state => {
            return state.currentItemList
        },
        indexedItems: state => {
            return state.indexedItems
        },
        allItems: state => {
            return state.items
        },
        getMissingObjects: state => {
            return state.missingRange
        },
        itemsByPublishedStatus: state => {
            return state.items.filter(item => item.published === state.filter)
        },
        itemsByCompoundStatus: state => {
            return state.items.filter(item => item.compuesto === state.filter)
        },

        getNextBatchByIndex: (state, stateRoot) => {
            let arr = []
            state.indexedItems.forEach(docRef => {
                let isAdded = false
                for (let i = 0; i < arr.length; i++) {
                    if (arr[i].id.localeCompare(docRef.id) === 1) {
                        arr.splice(i, 0, docRef)
                        isAdded = true
                        i = arr.length
                    }
                }
                if (!isAdded) {
                    arr.push(docRef)
                }
            })

            if (stateRoot.user) {
                let start = state.itemsPerPage * (state.currentPage - 1);
                let end = start + state.itemsPerPage;
                return arr.slice(start, end);
            } else {
                arr = arr.filter(item => item.published)
                let start = state.itemsPerPage * (state.currentPage - 1);
                let end = start + state.itemsPerPage;
                return arr.slice(start, end);
            }
        },
        getNextBatchByName: (state, stateRoot) => {
            let arr = []
            state.indexedItems.forEach(docRef => {
                let isAdded = false
                for (let i = 0; i < arr.length; i++) {
                    if (arr[i].name.localeCompare(docRef.name) === 1) {
                        arr.splice(i, 0, docRef)
                        isAdded = true
                        i = arr.length
                    }
                }
                if (!isAdded) {
                    arr.push(docRef)
                }
            })

            if (stateRoot.user) {
                let start = state.itemsPerPage * (state.currentPage - 1);
                let end = start + state.itemsPerPage;
                return arr.slice(start, end);
            } else {
                arr = arr.filter(item => item.published)
                let start = state.itemsPerPage * (state.currentPage - 1);
                let end = start + state.itemsPerPage;
                return arr.slice(start, end);
            }
        },
        filteredList: state => {
            return state.filteredList
        },
        collections: state => {
            return state.collections
        },
        idsList: state => {
            let list = []
            state.indexedItems.forEach(item => {
                list.push({
                    id: item.id,
                    name: item.name
                })
            })
            return list
        },
        itemsPerPage: state => {
            return state.itemsPerPage
        },
        currentPage: state => {
            return state.currentPage
        },
        listSize: (state, rootGetters) => {
            if (state.showFilters) {
                return state.filteredList.length
            } else {
                if (rootGetters.user) {
                    return state.indexedItems.length
                } else {
                    return state.indexedItems.filter(item => item.published).length
                }
            }
        },
        lastFiveItems: state => {
            let mayor = 0
            let i = 0
            let currentMayor = 100000
            let list = []
            while (i < 5) {
                mayor = 0
                state.items.forEach(element => {
                    if (element.inventory > mayor && element.inventory < currentMayor) {
                        mayor = element.inventory
                    }
                });
                currentMayor = mayor
                list.push(state.items.find(docRef => docRef.inventory === currentMayor))
                i++
            }
            return list
        },
        hasFilters: state => {
            return state.showFilters
        },
        getDocumentById: state => payload => {
            return state.items.find(item => item.id === payload)
        },
        currentItem: state => {
            return state.currentItem
        },
        hasFilterBeenLoaded: state => payload => {
            if (state.loadedFilters.find(item => item === payload)) {
                return true
            }
            return false
        },
        getConjuntoObjetos: state => payload => {
            return state.composedItems.filter(item => item.parent === payload)
        },
        getItem: state => payload => {
            let el = state.items.find(elem => elem.id === payload)
            if (el) {
                return el;
            } else {
                return null;
            }

        },
        checkLoadingStatus(state) {
            let status = true
            let item = state.currentItem
            if (item) {
                if (item.relatedObjects.length > 0) {
                    item.relatedObjects.forEach(element => {
                        let ref = state.items.find(el => el.id === element.id)
                        if (ref) {
                            console.log(element.id, "loaded")
                        } else {
                            status = false
                        }
                    });
                }
                if (item.conjuntoObjetos.length > 0) {
                    item.conjuntoObjetos.forEach(element => {
                        let ref = state.composedItems.find(el => el.id === element.id)
                        if (ref) {
                            console.log(element.id, "loaded")
                        } else {
                            status = false
                        }
                    });
                }
            } else {
                status = false
            }
            return status
        },
        listIncomplitObjects(state) {
            let print = {}
            let status = false
            let array = []
            state.items.forEach(item => {
                print.id = item.id
                print.name = item.name
                print.images = ""
                print.referencia = ""
                status = false
                if (item.referenceImage === undefined) {
                    print.referencia = "Referencia: incompleto"
                    status = true
                }
                if (item.images.length === 0) {
                    print.images = "Galeria: incompleto"
                    status = true
                }
                if (status) {
                    array.push(print.id, print.referencia, print.images)
                }
            })
            return array
        },
        getBatch: (state, rootGetters) => payload => {
            let arr = []
            if (rootGetters.user) {
                state.indexedItems.forEach((el, index) => {
                    if (index >= payload.start && index <= payload.end) {
                        arr.push(el)
                    }
                })
            } else {
                let filtro = state.indexedItems.filter(item => item.published === true)
                filtro.forEach((el, index) => {
                    if (index >= payload.start && index <= payload.end) {
                        arr.push(el)
                    }
                })
            }
            return arr
        }

    },
    mutations: {
        ADD_COLLECTION(state,payload){
            if (!state.collections.find(item => item.id === payload.id)) {
                    state.collections.push({
                        ...payload
                    })
                }
            },
        SET_FILTER_VALUE(state, payload) {
            state.filter = payload
        },

        ORDER_INDEXED_ITEMS_BY(state, payload) {

            try {
                let arr = []
                let position = 0;
                switch (payload) {
                    case "id":
                        state.indexedItems.forEach(el => {
                            position = 0;
                            arr.forEach(element => {
                                if (element.inventory < el.inventory) {
                                    position++;
                                }
                            });
                            arr.splice(position, 0, el);
                        })
                        state.indexedItems = arr

                        break;
                    case "name":
                        state.indexedItems.forEach(el => {
                            position = 0;
                            arr.forEach(element => {
                                let name = element.name
                                if (name.localeCompare(el.name) < 0) {
                                    position++;
                                }
                            });
                            arr.splice(position, 0, el);
                        })
                        state.indexedItems = arr
                        break;
                    default:
                        break;
                }
            } catch (e) {
                console.log(e)
            }
        },
        ORDER_ITEMS_BY(state, payload) {

            let arr = []
            let position = 0;
            switch (payload) {
                case "id":
                    state.items.forEach(el => {
                        position = 0;
                        arr.forEach((element) => {
                            if (element.inventory < el.inventory) {
                                position++;
                            }
                        });
                        arr.splice(position, 0, el);
                    })
                    state.items = arr

                    break;
                case "name":
                    state.items.forEach(el => {
                        position = 0;
                        arr.forEach(element => {
                            if (element.name.localeCompare(el.name) < 0) {
                                position++;
                            }
                        });
                        arr.splice(position, 0, el);
                    })
                    state.items = arr
                    break;
                default:
                    break;
            }
        },
        SET_MISSING_OBJECTS(state) {

            let arr = []
            let position = 0;
            state.items.forEach(el => {
                position = 0;
                arr.forEach((element) => {
                    if (element.inventory < el.inventory) {
                        position++;
                    }
                });
                arr.splice(position, 0, el);
            })
            let range = []
            let min = 0
            let max = 0
            let lastStatus
            if (arr[0].inventory === 1) {
                lastStatus = true
            } else {
                lastStatus = false
            }
            let exists
            position = 1
            while (position < state.nextValue) {
                if (state.items.find(elem => elem.inventory === position)) {
                    exists = true
                } else {
                    exists = false
                }
                if (!exists && !lastStatus) {
                    max = position
                    lastStatus = exists
                } else if (exists && !lastStatus) {
                    max = position
                    range.push({
                        min: min,
                        max: max
                    })
                    min = position - 1
                    lastStatus = exists
                } else if (exists && lastStatus) {
                    max = position
                    min = position
                    lastStatus = exists
                } else {
                    max = position
                    min = position - 1
                    lastStatus = exists
                }
                position++
            }
            state.missingRange = range

        },
        SET_NEXT_VALUE(state, payload) {
            state.nextValue = payload
        },
        ACTUALIZAR_CARACTERISTICAS_OBJETO_CONJUNTO(state, payload) {
            let item = state.composedItems.find(el => el.id === payload.id)
            if (item) {
                item.data.description = payload.data.description,
                    item.data.name = payload.data.name,
                    item.data.materials = payload.data.materials,
                    item.data.techniques = payload.data.techniques
            }
        },
        ACTUALIZAR_DIMENSIONES_OBJETO_CONJUNTO(state, payload) {
            let item = state.composedItems.find(el => el.id === payload.id)
            if (item) {
                item.data.dimensions = payload.data
            }
        },
        ACTUALIZAR_IMAGEN_PRINCIPAL_OBJETO_CONJUNTO(state, payload) {
            let item = state.composedItems.find(el => el.id === payload.id)
            if (item) {
                item.data.mainImage = payload.data
            }
        },
        ACTUALIZAR_LISTA_IMAGENES_OBJETO_CONJUNTO(state, payload) {
            let item = state.composedItems.find(el => el.id === payload.id)
            if (item) {
                item.data.photos = payload.data
            }
        },
        ELIMINAR_IMAGEN_PRINCIPAL_OBJETO(state, payload) {
            let item = state.items.find(el => el.id === payload)
            if (item) {
                item.referenceImage = {}
            }
        },
        ELIMINAR_FOTO_DE_LISTA(state, payload) {
            let item = state.items.find(el => el.id === payload.id)
            if (item) {
                item.images = payload.list
            }
        },
        ACTUALIZAR_CARACTERISTICAS(state, payload) {
            let item = state.items.find(el => el.id === payload.id)
            if (item) {
                item.description = payload.data.descripcion
                item.keyWords = payload.data.palabras
                item.materials = payload.data.materiales
                item.techniques = payload.data.tecnicas
            }
        },
        ACTUALIZAR_DIMENSIONES(state, payload) {
            let item = state.items.find(el => el.id === payload.id)
            if (item) {
                item.measurements = payload.data
            }
        },
        ACTUALIZAR_ORIGEN(state, payload) {
            let item = state.items.find(el => el.id === payload.id)
            if (item) {
                item.donant = payload.donante
                item.origin = payload.origen
                item.found = payload.encontrado
            }
        },
        ACTUALIZAR_ESTADO_CONSERVACION(state, payload) {
            let item = state.items.find(el => el.id === payload.id)
            if (item) {
                item.conservation = payload.conservacion
                item.pieceStatus = payload.estado
                item.restoration = payload.restauracion
                item.identifier = payload.rotulado
            }
        },
        ACTUALIZAR_UBICACION(state, payload) {
            let item = state.items.find(el => el.id === payload.id)
            if (item) {
                item.exhibition = payload.exhibicion
                item.location = payload.ubicacion
                item.locationComments = payload.comentarios
                item.others = payload.notas
            }
        },
        ACTUALIZAR_LISTA_OBJETOS_RELACIONADOS(state, payload) {
            let item = state.items.find(el => el.id === payload.id)
            if (item) {
                item.relatedObjects = payload.list
            }
        },
        ADD_COMPOSED_ITEM(state, payload) {
            let item = state.composedItems.find(el => el.id === payload.id)
            if (item) {
                item.data = payload.data
            } else {
                state.composedItems.push(payload)
            }
        },
        UPDATE_LISTA_CONJUNTO(state, payload) {
            let docRef = state.items.find(el => el.id === payload.id)
            if (docRef) {
                docRef.conjuntoObjetos = payload.lista
            }
        },
        UPDATE_COMPUESTO_FROM_OBJECT(state, payload) {
            let docRef = state.items.find(item => item.id = payload.id)
            if (docRef) {
                docRef.compuesto = payload.value
            }
        },
        UPDATE_COLLECTION(state, payload) {
            let el = state.collections.find(docRef => docRef.id === payload.id)
            if (el) {
                el = payload.data
                el.id = payload.id
            }
        },
        SET_INDEXED_ITEMS(state, payload) {
            state.indexedItems = payload
        },
        ADD_PUBLISHED_ITEMS(state, payload) {
            state.indexedItems.forEach(item => {
                if (payload.find(elem => elem === item.id)) {
                    item.published = true
                } else {
                    item.published = false
                }
            })
        },
        SET_ITEM_STATUS(state, payload) {
            let item = state.items.find(item => item.id === payload.id)
            item.published = payload.value
            state.indexedItems.forEach(item => {
                if (item.id === payload.id) {
                    item.published = payload.value
                }
            })
        },
        SET_FILTER_DISPLAYED(state, payload) {
            state.showFilters = payload
        },
        ADD_ITEM(state, payload) {
            if (!payload.keyWords) {
                payload.keyWords = []
            }
            if (!payload.materials) {
                payload.materials = []
            }
            if (!payload.techniques) {
                payload.techniques = []
            }
            if (!payload.conservation) {
                payload.conservation = {
                    comments: "",
                    value: ""
                }
            }
            if (!payload.pieceStatus) {
                payload.pieceStatus = {
                    comments: "",
                    value: ""
                }
            }
            if (!payload.restoration) {
                payload.restoration = ""
            }
            if (!payload.locationComments) {
                payload.locationComments = ""
            }
            if (!payload.location) {
                payload.location = ""
            }

            if (!payload.published) {
                payload.published = false
            }
            if (!payload.relatedObjects) {
                payload.relatedObjects = []
            }
            if (!payload.measurements) {
                payload.measurements = []
            }
            if (!payload.referenceImage) {
                payload.referenceImage = ""
            }


            if (!state.items.find(item => item.id === payload.id)) {
                if (payload.compuesto) {
                    state.items.push(payload)
                } else {
                    state.items.push({
                        ...payload,
                        compuesto: false
                    })
                }
            }
        },
        SET_ORDER(state, payload) {
            state.order = payload
        },
        SET_CURRENT_LIST(state, payload) {
            state.currentItemList = payload
        },
        ADD_ITEM_TO_CURRENT_LIST(state, payload) {
            state.currentItemList.push(payload)
        },
        UPDATE_ITEM(state, payload) {
            let el = state.items.find(docRef => docRef.id === payload.id)
            if (el) {
                el = payload.data
                el.id = payload.id
            }
        },
        SET_FILTERED_LIST(state, payload) {
            state.filteredList = payload
        },
        CLEAR_CURRENT_LIST(state) {
            state.currentItemList = []
        },
        SET_QUERY_PARAM(state, payload) {
            switch (payload.param) {
                case 'limit':
                    state.queryParams.limit = payload.value
                    break;
                case 'order':
                    state.queryParams.order = payload.value
                    break;
                case 'materials':
                    state.queryParams.materials = payload.value
                    break;
                case 'techniques':
                    state.queryParams.techniques = payload.value
                    break;
                case 'typology':
                    state.queryParams.typology = payload.value
                    break;
                case 'category':
                    state.queryParams.category = payload.value
                    break;
            }
        },
        SET_QUERY(state) {
            state.query = db.collection('ListaObjetos')
            if (state.queryParams.category !== '') {
                state.query = state.query.where
            }

        },
        SET_ITEMS_PER_PAGE(state, payload) {
            state.itemsPerPage = payload
            state.currentPage = 1
        },
        SET_CURRENT_PAGE(state, payload) {
            state.currentPage = payload
        },
        SET_CURRENT_ITEM(state, payload) {
            let obj = state.items.find(elem => elem.id === payload)
            if (obj) {
                state.currentItem = obj
            } else {
                state.currentItem = null
            }
        },
        ADD_LOADED_FILTER(state, payload) {
            if (!state.loadedFilters.find(item => item === payload)) {
                state.loadedFilters.push(payload)
            }
        }
    }
}