<template>
  <div class="admin-container" :class="{'collapsed':adminBarCollapsed}">
    <div class="section" v-for="(item,index) in sections" :key="index">
      <h5 v-html="item.name" v-show="!adminBarCollapsed"></h5>
      <ul>
        <li v-for="link in item.links" :key="link.link" @click="$router.push(link.link)">
          <i class="material-icons">{{link.icon}}</i>
          <p v-html="link.text"></p>
        </li>
      </ul>
    </div>
    <button class="collapse" @click="toggleCollapse">
      <i class="material-icons" v-if="adminBarCollapsed">chevron_right</i>
      <i class="material-icons" v-else>chevron_left</i>
    </button>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "Admin-Menu",

  data: function() {
    return {};
  },
  computed: {
    ...mapGetters(["adminBarCollapsed"]),
    sections() {
      return [
        {
          name: "Ajustes de Sistema",
          links: [
            {
              icon:'category',
              text: "Categor&iacute;as",
              link: "/categories"
            },
            {
              icon:'book',
              text: "Tipolog&iacute;as",
              link: "/settings/typologies"
            },
            {
              icon:'color_lens',
              text: "Materiales",
              link: "/settings/materials"
            },
            
            {
              icon:'brush',
              text: "T&eacute;cnicas",
              link: "/settings/techniques"
            },
            
            {
              icon:'place',
              text: "Sitios",
              link: "/sites"
            },
            {
              icon:'history_edu',
              text: "Definiciones",
              link: "/settings/definitions"
            },
          ]
        },
        {
          name:'Colecciones',
          links:[
            {
              icon:'description',
              text:'Registro de Objetos',
              link:"/insert"
            },
            {
              icon:'photo_library',
              text:'Archivo Visual',
              link:"/AVDA_Dashboard"
            },
            {
              icon:'dashboard',
              text:'Dep&oacute;sito',
              link:"/deposit"
            }
            ,
            {
              icon:'pie_chart',
              text:'Estad&iacute;sticas',
              link:"/statistics"
            },
            {
              icon:'ios_share',
              text:'Exportar Datos',
              link:'/export'
            }
          ]
        }
      ];
    }
  },
  methods: {
    ...mapMutations(["SET_PROFILE_DISPLAYED", "SET_ADMIN_BAR_COLLAPSE"]),
    toggleCollapse() {
      this.SET_ADMIN_BAR_COLLAPSE(!this.adminBarCollapsed);
    },
    showUser() {
      this.SET_PROFILE_DISPLAYED(true);
    },
    hideMenu() {
      this.SET_MENU_DISPLAYED(false);
    },
    setMenu() {
      this.SET_MENU_DISPLAYED(!this.menuDisplayed);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "./../assets/sass/variables";
.admin-container {
  background-color: $theme-d3;
  width: 100%;
  height: 100vh;
  position: sticky;
  top: 0;
  button.collapse {
    background-color: $theme-d3;
    padding: 5px;
    border: solid 2px transparent;
    border-radius: 25px;
    color: white;
    cursor: pointer;
    i.material-icons {
      vertical-align: middle;
      width: 100%;
      font-size: 28px;
      text-align: center;
    }
    position: absolute;
    margin-bottom: 10px;
    margin-top: auto;
    bottom: 0;
    margin-left: auto;
    margin-right: 16px;
    right: 0;
    
      &:hover{
        background-color: $theme-d2;
      }
  }

  &.collapsed {
    display: none;
    .section{
      overflow: visible;
      ul{
        li{
          &:hover p{
            display: inline;
          }
          p{
            margin-left: 25px;
            display: none;
            padding:8px;
            width: 100%;
            border-radius: 5px;
            background-color: $theme-d2;
            white-space: nowrap;
          }
        }
      }
    }
  }
  .section{
    padding: 5px 0px;
    border-bottom: solid 1px rgba($color: white, $alpha: 0.5);
    h5{
      text-transform: uppercase;
      color: white;
      padding-left: 15px;
    }
    ul{
      margin-left: 0px;
      padding: 0;
      li{
        padding-left: 25px;
        list-style: none;
        padding-top: 5px;        
        height: 36px;
        margin: 0;
        cursor: pointer;
        i,p{
          display: inline;
          color: white;
        }
        i.material-icons{
          margin-right: 10px;
          vertical-align: middle;
        }
        p{
          line-height: 24px;
          vertical-align: middle;
        }
        &:hover{
          background-color: $theme-d2;
        }
      }
    }
    &:first-child{
      border-top: solid 1px rgba($color: white, $alpha: 0.5);
      margin-top: 5px;
    }
  }
}
@media (min-width: 920px) {
  .admin-container {
    &.collapsed {
      display: block;
    }
    button.collapse {
      display: block !important;
      &:hover{
        background-color: $theme-d2;
      }
    }
  }
}
a.router-link-exact-active {
  background-color: $theme-d1;
}
</style>