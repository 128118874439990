<template>
  <div class="settings-container">
    <div class="search">
      <h2 v-html="title"></h2>
      <div>
        <div class="autocomplete-container">
          <div>
            <Autocomplete
              :list="allItems"
              :alternativeText="'Buscar'"
              v-on:get-result="getId"
              :border="false"
              height="50px"
              margin="25px"
              class="radius-left"
            />
            <button
              class="radius-right"
              :disabled="searchedId === ''"
              @click="search"
            >
              <i class="material-icons">search</i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="index-container">
      <p v-for="(item, index) in indexList" :key="index">
        <button
          class="index"
          :class="{ selected: item === currentIndex }"
          @click="currentIndex = item"
        >
          {{ item }}
        </button>
        <span v-if="index < indexList.length - 1">-</span>
      </p>
    </div>

    <div class="title-add">
      <h1 @click="update">{{ currentIndex }}</h1>
      <div class="right">
        <button
          class="icon-and-label-btn grey"
          style="float: right; margin-right: 1em"
          @click="addModule = true;"
        >
          <i class="icon material-icons">add</i>
          <span class="text" style="white-space: nowrap">Agregar Nuevo</span>
        </button>
      </div>
    </div>
    <div class="page-container">
      <div class="definition-card" v-for="item in list" :key="item.id">
        <h4>{{ item.name }}</h4>
        <h6>{{ item.id }}</h6>
        <p>
          {{ item.shortDefinition }}
          <span v-if="item.isLong">...</span>
        </p>
        <p class="right" v-if="item.source">Fuente: {{ item.source }}</p>
        <p class="btns">
          <button
            class="btn-container"
            v-if="item.isLong"
            @click="displayedDefinition = item.id"
          >
            <div class="btn">
              <p v-html="'Ver m&aacute;s'"></p>
              <i class="material-icons">visibility</i>
            </div>
          </button>
          <button
            class="btn-container"
            @click="
              editDisplayed = true;
              itemId = item.id;
            "
          >
            <div class="btn">
              <p>Editar</p>
              <i class="material-icons">edit</i>
            </div>
          </button>
        </p>
        <SimpleBar
          class="full-content"
          :class="{ locked: displayedDefinition === item.id }"
          v-if="item.isLong"
          data-SimpleBar-auto-hide="false"
        >
          <button class="close" @click="displayedDefinition = ''">
            <i class="material-icons">close</i>
          </button>
          <div>
            <h4 v-html="'Definici&oacute;n'"></h4>
            <p>{{ item.definition }}</p>
          </div>
        </SimpleBar>
      </div>
    </div>
    <div class="modal" v-show="addModule">
      <div class="top" @click="addModule = false"></div>
      <div class="left" @click="addModule = false"></div>
      <div class="rigth" @click="addModule = false"></div>
      <div class="bottom" @click="addModule = false"></div>
      <div class="center">
        <SimpleBar class="box align">
        <button class="close" @click="addModule = false">
          <i class="material-icons">close</i>
        </button>
        <h4 v-html="'Agregar Elemento'"></h4>
        <label v-html="'Nombre:'"></label>
        <input type="text" v-model="addModuleName" />
        <label v-html="'Definici&oacute;n:'"></label>
        <textarea
          name
          id
          cols="30"
          rows="10"
          v-model="addModuleDescription"
        ></textarea>
        <label v-html="'Fuente:'"></label>
        <input type="text" v-model="addModuleSource" />
        <p class="btn-center">
          <button class="ok-btn" @click="addNewItem">Guardar</button>
          <button class="cancel-btn" @click="addModule = false">
            Cancelar
          </button>
        </p>
      </SimpleBar>
      </div>
      
    </div>
    <div class="modal" v-show="editDisplayed">
      <div class="top" @click="editDisplayed = false"></div>
      <div class="left" @click="editDisplayed = false"></div>
      <div class="right" @click="editDisplayed = false"></div>
      <div class="bottom" @click="editDisplayed = false"></div>
      <div class="center"><SimpleBar class="box align">
        <div class="content padding">
          <p class="icon">
          <i class="material-icons" @click="editDisplayed = false">close</i>
        </p>
        <h4 v-html="'Editar Elemento'"></h4>
        <label v-html="'Nombre:'"></label>
        <input type="text" v-model="currentItem.name" />
        <label v-html="'Definici&oacute;n:'"></label>
        <textarea
          name
          id
          cols="30"
          rows="10"
          v-model="currentItem.definition"
        ></textarea>
        <label v-html="'Fuente:'"></label>
        <input type="text" v-model="currentItem.source" />
        <p class="btn-center">
          <button class="ok-btn" @click="addNewItem">Guardar</button>
          <button class="cancel-btn" @click="editDisplayed = false">
            Cancelar
          </button>
        </p>
        </div>
        
      </SimpleBar></div>
      
    </div>
    <Loading text="cargando" />
    <Message />
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";

import Autocomplete from "@/components/Autocomplete.vue";
//import EditDefinition from "@/components/EditDefinition.vue"
import {SimpleBar} from "simplebar-vue3";
import Message from "@/components/Message.vue";
import Loading from "@/components/Loading.vue";
export default {
  name: "Settings-section",
  components: { Autocomplete, SimpleBar, Message, Loading },
  data() {
    return {
      list: [],
      currentIndex: "A",
      currentId: "",
      itemId: "",
      currentItem: { name: "", definition: "", source: "" },
      indexList: [],
      title: "",
      allItems: [],
      searchedId: "",
      displayedDefinition: "",
      editDisplayed: false,
      addModule: false,
      addModuleName: "",
      addModuleDescription: "",
      addModuleSource: "",
    };
  },
  computed: {
    ...mapGetters("materials", {
      materials: "items",
      indexMaterials: "abcItems",
      materialsByIndex: "itemsByIndex",
    }),
    ...mapGetters("techniques", {
      techniques: "items",
      indexTechniques: "abcItems",
      techniquesByIndex: "itemsByIndex",
    }),
    ...mapGetters("typologies", {
      typologies: "items",
      indexTypologies: "abcItems",
      typologiesByIndex: "itemsByIndex",
    }),
    ...mapGetters("glossary", {
      glossary: "items",
      indexGlossary: "abcItems",
      glossaryByIndex: "itemsByIndex",
    }),
  },
  mounted() {
    this.currentId = this.$route.params.id;
    this.setContent();
  },
  watch: {
    getMessageAction(payload) {
      if (payload.component === "addSuccess") {
        console.log("entra");
        this.addModule = false;
        this.SET_MESSAGE_PARAMETERS({});
      }
    },
    currentIndex(val) {
      switch (this.$route.params.id) {
        case "materials":
          this.list = this.materialsByIndex(val);
          break;
        case "techniques":
          this.list = this.techniquesByIndex(val);
          break;
        case "typologies":
          this.list = this.typologiesByIndex(val);
          break;
        case "definitions":
          this.list = this.glossaryByIndex(val);
          break;
      }
    },
    "$route.params.id": function (id) {
      this.currentId = id;
      this.setContent();
    },
    itemId(val) {
      this.currentItem = this.allItems.find((item) => item.id === val);
    },
  },
  methods: {
    ...mapActions("materials", {
      addMaterial: "addItem",
      setMaterials:"setMateriales"
    }),
    ...mapActions("keywords", {
      setPalabras: "setPalabras",
    }),
    ...mapActions("techniques", {
      addTechnic: "addItem",
      setTecnicas:"setTecnicas",
      updateOrigin:"updateOrigin"
    }),
    ...mapActions("typologies", {
      addTypology: "addItem",
      setTipologias:"setTipologias"
    }),
    ...mapActions("glossary", {
      addDefinicion: "addItem",
    }),
    ...mapMutations(["SET_MESSAGE_PARAMETERS", "SET_LOADING"]),
    setContent() {
      if (this.currentId === "materials") {
        if (this.indexMaterials && this.indexMaterials.length > 0) {
          this.currentIndex = this.indexMaterials[0];
          this.indexList = this.indexMaterials;
        }
        this.title = "Administrar Materiales";
        this.list = this.materialsByIndex(this.currentIndex);
        this.allItems = this.materials;
      } else if (this.currentId === "techniques") {
        if (this.indexTechniques && this.indexTechniques.length > 0) {
          this.currentIndex = this.indexTechniques[0];
          this.indexList = this.indexTechniques;
        }
        this.title = "Administrar T&eacute;cnicas";
        this.list = this.techniquesByIndex(this.currentIndex);
        this.allItems = this.techniques;
      } else if (this.currentId === "typologies") {
        if (this.indexTypologies && this.indexTypologies.length > 0) {
          this.currentIndex = this.indexTypologies[0];
          this.indexList = this.indexTypologies;
        }
        this.title = "Administrar Tipolog&iacute;as";
        this.list = this.typologiesByIndex(this.currentIndex);
        this.allItems = this.typologies;
      } else if (this.currentId === "definitions") {
        if (this.indexGlossary && this.indexGlossary.length > 0) {
          this.currentIndex = this.indexGlossary[0];
          this.indexList = this.indexGlossary;
        }
        this.title = "Administrar Definiciones";
        this.list = this.glossaryByIndex(this.currentIndex);
        this.allItems = this.glossary;
      }
    },
    getId(payload) {
      console.log(payload);
    },
    addNewItem() {
      let response;
      if (this.currentId === "materials") {
        this.SET_LOADING(true);
        response = this.addMaterial({
          name: this.addModuleName,
          definition: this.addModuleDescription,
          source: this.addModuleSource,
        });
        if (response) {
          this.SET_LOADING(false);
          this.addModule = false;
          this.SET_MESSAGE_PARAMETERS({
            isVisible: true,
            type: "success",
            icon: "done",
            title: "Definici&oacute;n Agregada",
            message: "Se ha agregado correctamente la Definici&oacute;n",
            buttonAction: "",
            button: "Aceptar",
            respondTo: "addSuccess",
            action: 0,
          });
        } else {
          console.log(response);
        }
      } else if (this.currentId === "techniques") {
        this.SET_LOADING(true);
        response = this.addTechnic({
          name: this.addModuleName,
          definition: this.addModuleDescription,
          source: this.addModuleSource,
        });
        if (response) {
          this.SET_LOADING(false);
          this.addModule = false;
          this.SET_MESSAGE_PARAMETERS({
            isVisible: true,
            type: "success",
            icon: "done",
            title: "Definici&oacute;n Agregada",
            message: "Se ha agregado correctamente la Definici&oacute;n",
            buttonAction: "",
            button: "Aceptar",
            respondTo: "addSuccess",
            action: 0,
          });
        } else {
          console.log(response);
        }
      } else if (this.currentId === "typologies") {
        this.SET_LOADING(true);
        response = this.addTypology({
          name: this.addModuleName,
          definition: this.addModuleDescription,
          source: this.addModuleSource,
        });
        if (response) {
          this.SET_LOADING(false);
          this.addModule = false;
          this.SET_MESSAGE_PARAMETERS({
            isVisible: true,
            type: "success",
            icon: "done",
            title: "Definici&oacute;n Agregada",
            message: "Se ha agregado correctamente la Definici&oacute;n",
            buttonAction: "",
            button: "Aceptar",
            respondTo: "addSuccess",
            action: 0,
          });
        } else {
          console.log(response);
        }
      } else if (this.currentId === "definitions") {
        this.SET_LOADING(true);
        response = this.addDefinicion({
          name: this.addModuleName,
          definition: this.addModuleDescription,
          source: this.addModuleSource,
        });
        if (response) {
          this.SET_LOADING(false);
          this.addModule = false;
          this.SET_MESSAGE_PARAMETERS({
            isVisible: true,
            type: "success",
            icon: "done",
            title: "Definici&oacute;n Agregada",
            message: "Se ha agregado correctamente la Definici&oacute;n",
            buttonAction: "",
            button: "Aceptar",
            respondTo: "addSuccess",
            action: 0,
          });
        } else {
          console.log(response);
        }
      }
    },
    search() {},
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/sass/variables";
@import "./../assets/sass/buttons";
@import "./../assets/sass/modal";
.settings-container {
  div.autocomplete-container {
    padding: 25px 5% 25px 10%;
    display: block !important;
    div {
      display: grid;
      grid-template-columns: auto 60px;
      grid-template-rows: 50px;
      border-radius: 50px;
      background-color: white;
      padding: 0px;
      overflow: visible;
      .radius-left {
        border-bottom-left-radius: 50px;
        border-top-left-radius: 50px;
      }
      .radius-right {
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
      }

      button {
        margin-block-end: 0px;
        margin-block-start: 0px;
        background-color: $theme;
        border: none;
        cursor: pointer;
        i.material-icons {
          vertical-align: middle;
          font-size: 35px;
          color: white;
        }
      }
      button:disabled {
        opacity: 0.5;
        cursor: no-drop;
      }
    }
  }
  .index-container {
    text-align: center;
    p {
      display: inline-block;
      color: $text-dark;
      vertical-align: middle;
      button {
        background-color: transparent;
        border: none;
        cursor: pointer;
        font-size: 1em;
        &:hover,
        &.selected {
          font-size: 1.5em;
          color: $theme-d1;
          font-weight: 600;
        }
      }
    }
  }
  .title-add {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }
  h1 {
    font-size: 3.5em;
    margin-block-start: 0px;
    color: $theme-d2;
    margin-left: 1em;
  }
  .search {
    background-image: linear-gradient(
        to bottom,
        transparent,
        rgba(0, 0, 0, 0.75)
      ),
      url("../assets/media/MAESTRANZA.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: grid;
    grid-template-rows: auto auto 15px;
    h1,
    h2 {
      position: relative;
      text-align: center;
      color: $theme-l4;
      padding-left: 10%;
      padding-right: 10%;
      margin-block-end: 0px;
      margin-block-start: 0px;
      padding-top: 15%;
    }
    div {
      display: grid;
      padding-left: 10%;
      padding-right: 10%;
      grid-template-columns: 1.5fr 0.25fr;
      div.options {
        .icon-container {
          padding-top: 25px;
          padding-bottom: 25px;
          button {
            background-color: $theme;
            color: white;
            border-radius: 50px;
          }
        }
      }
    }
  }
  .page-container {
    padding: 0.7em 2em;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 10px;
    margin-bottom: 10px;
    .definition-card {
      background-color: white;
      padding: 1.5em 1em;
      position: relative;
      h4,
      h6 {
        color: $text-dark;
        margin: 0;
      }
      h4 {
        font-weight: 600;
      }
      h6 {
        text-decoration: underline;
      }
      p {
        margin-top: 1em;
        span {
          font-weight: 600;
        }
      }
      p.btns {
        margin-top: auto;
        bottom: 0;
        margin-bottom: 0px;
        position: relative;
        width: 100%;
        text-align: right;

        .btn-container {
          width: auto;
          height: 36px;
          border: none;
          background-color: white;

          .btn {
            color: $text;
            background-color: transparent;
            border-radius: 35px;
            width: fit-content;
            position: relative;
            padding: 5px;
            border: solid 1px $text;
            clip-path: circle(18px at calc(100% - 18px) 18px);
            transition: all 0.3s ease-in-out;
            cursor: pointer;

            p,
            span {
              margin: 0px 5px 0px 8px;
              vertical-align: middle;
              display: none;
            }

            i {
              display: inline;
            }

            i.material-icons {
              vertical-align: middle;
            }
          }

          &:hover .btn p,
          span {
            display: inline;
            color: $theme-d1;
          }

          &:hover .btn {
            clip-path: circle(75%);
            border-color: $theme-d1;
            color: $theme-d1;
          }
        }
      }
      .full-content {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: $theme-d1;
        padding: 2em 2em;
        top: 0;
        left: 0;
        clip-path: circle(0% at 50% 80%);
        transition: all 0.3s ease-in-out;
        h4,
        p {
          color: white;
        }
        &.locked {
          clip-path: circle(72%);
        }
        button.close {
          border: solid 1px white;
          border-radius: 20px;
          padding: 4px;
          background-color: transparent;
          margin: 0;
          position: absolute;
          margin-left: auto;
          margin-right: 1em;
          right: 0;
          top: 0;
          margin-top: 1em;
          cursor: pointer;
          i.material-icons {
            color: white;
            vertical-align: middle;
          }
          &:hover {
            background-color: white;
            i.material-icons {
              color: $theme-d1;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}
</style>