<template>
  <div class="container">
    <h4 v-html="'Importar Fotograf&iacute;as'"></h4>

    <div>
      <p
        class="full-length"
        v-html="
          'Seleccione el archivo Excel con la informaci&oacute;n de las fotograf&iacute;s a ingresar'
        "
      ></p>
      <div>
        <label class="label" v-html="'Seleccionar archivo'"></label>
        <div class="add-element">
          <input type="text" disabled v-model="excelFile" />
          <input
            class="file"
            type="file"
            id="excelFile"
            ref="fileInput"
            @change="onFilePicked"
          />
          <button
            class="icon-btn"
            @click="onPickFile"
          >
            <i class="material-icons">attach_file</i>
          </button>
        </div>
      </div>
      <p
        class="full-length"
        v-if="importado.length > 0"
        v-html="'Se han cargado ' + importado.length + ' &aacute;lbumes.'"
      ></p>
      <button :disabled="btnDisabled" class="text-btn" @click="importar">Importar</button>
    </div>

    <!--<div v-else>
      <div class="image-upload">
        <img :src="filePreview" />
        <div class="input">
          <label>Imagen de Referencia</label>
          <input type="text" disabled v-model="mainImage" />
          <input class="file" type="file" ref="fileInput" accept="image/*" @change="onFilePicked" />
        </div>
        <button @click="onPickFile">
          <i class="material-icons">cloud_upload</i>
        </button>
      </div>
      <button class="add-btn" @click="create" :disabled="btnDisabled">Agregar</button>
    </div>-->
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

import readXlsxFile from "read-excel-file";
export default {
  name: "AVDA_import_photos",
  data: function () {
    return {
      excelFile: "",
      messageData: {},
      columnDefs: [
        {
          field: "id",
        },
        {
          field: "AVDA_id",
        },
        {
          field: "titulo",
        },
        {
          field: "descripcion",
        },
        {
          field: "temas",
        },
        {
          field: "fecha",
        },
        {
          field: "propietario",
        },
        {
          field: "autor",
        },
        {
          field: "dimensiones",
        },
        {
          field: "resolucion",
        },
        {
          field: "fechadeRegistro",
        },
        {
          field: "registrador",
        },
        {
          field: "sectorOInmueble",
        },
        {
          field: "relacionados",
        },
        {
          field: "foto",
        },
        {
          field: "reverso",
        },
      ],
      columns: [
        {
          label: "Id",
          field: "id",
        },
        {
          label: "Id AVDA",
          field: "AVDA_id",
        },
        {
          label: "Título",
          field: "titulo",
        },
        {
          label: "Descripción",
          field: "descripcion",
        },
        {
          label: "Temas o Contenidos",
          field: "temas",
        },
        {
          label: "Fecha",
          field: "fecha",
        },
        {
          label: "Propietario",
          field: "propietario",
        },
        {
          label: "Autor",
          field: "autor",
        },
        {
          label: "Dimensiones",
          field: "dimensiones",
        },
        {
          label: "Resolución",
          field: "resolucion",
        },
        {
          label: "Fecha de Registro",
          field: "fechadeRegistro",
        },
        {
          label: "Registrador",
          field: "registrador",
        },
        {
          label: "Sector o Inmueble",
          field: "sectorOInmueble",
        },
        {
          label: "Relacionados",
          field: "relacionados",
        },
        {
          label: "Foto",
          field: "foto",
        },
        {
          label: "Reverso",
          field: "reverso",
        },
      ],
      importado: [],
      largo:0
    };
  },
  computed: {
    ...mapGetters(["categories", "collections", "user", "getMessageAction"]),

    btnDisabled() {
      if (this.importado.length > 0) {
        return false;
      } else {
        return true;
      }
    },
  },
  watch: {
    getMessageAction(payload) {
      if (payload.component === "add") {
        if (payload.action === 1) {
          this.$route.push("item/" + this.newId + "/edit");
        }
        this.SET_MESSAGE_PARAMETERS({});
      }
    },
  },
  methods: {
    ...mapActions("avda_config", {
      importPhotos: "importPhotos",
    }),
    ...mapActions(["createNewObject"]),
    ...mapMutations([
      "SET_LOADING",
      "SET_LOADING_OPACITY",
      "SET_MESSAGE_PARAMETERS",
      "SET_CURRENT_OBJECT_ID",
      "SET_LOADING_TEXT",
    ]),
    onPickFile: function () {
      this.$refs.fileInput.click();
    },
    onFilePicked: function () {
      const input = document.getElementById("excelFile");

      readXlsxFile(input.files[0]).then((rows) => {
        rows.forEach((data) => {
          let rev = "NO";
          if (data[15] === "SI") {
            rev = "";
          }
          let arr = data[4].split("/");
          let photo = {
            id: data[0],
            foto: "",
            reverso: rev,
            album: data[7],
            data: [
              {
                position: 1,
                field: "Título",
                value: data[2],
              },
              {
                position: 2,
                field: "Descripción",
                value: data[3],
              },
              {
                position: 3,
                field: "Temas o Contenidos",
                value: arr,
              },
              {
                position: 4,
                field: "Lugar dónde fue tomada",
                value: data[5],
              },
              {
                position: 5,
                field: "Fecha",
                value: data[6],
              },
              {
                position: 6,
                field: "Autor",
                value: data[8],
              },
              {
                position: 7,
                field: "Dimensiones",
                value: data[9],
              },
              {
                position: 8,
                field: "Resolución",
                value: data[10],
              },
              {
                position: 9,
                field: "Fecha de Registro",
                value: data[11],
              },
              {
                position: 10,
                field: "Registrador",
                value: data[12],
              },
              {
                position: 11,
                field: "Edificación o Sector",
                value: data[13],
              },
              {
                position: 12,
                field: "Id AVDA",
                value: data[1],
              },
            ],
          };
          let album = this.importado.find((item) => item.id === data[7]);
          if (album) {
            album.fotos.push(photo);
          } else {
            let newAlbum = { id: data[7], fotos: [] };
            newAlbum.fotos.push(photo);
            this.importado.push(newAlbum);
          }
        });
      });
    
    },
    async importar() {
      try {
        this.SET_LOADING(true);
        let i = 1;
        this.largo = this.importado.length;
        this.SET_LOADING_TEXT("Cargando " + i + " de "+this.largo+" álbumes.");
        this.importado.forEach(async (item) => {
          let result = await this.importPhotos(item);
          if(result){
            i++
            this.SET_LOADING_TEXT("Cargando " + i + " de "+this.largo+" álbumes.");
          }
          if(i>this.largo){
            
        
        this.SET_LOADING(false);
          }
          this.$emit("get-result",this.largo)
        });
      } catch (error) {
        this.SET_MESSAGE_PARAMETERS({
          isVisible: true,
          type: "error",
          icon: "error",
          title: "Ocurri&oacute; un error al cargar la informaci&oacute;n",
          message:
            "Ocurri&oacute; un error al cargar los &aacute;lbumes, por favor intentelo m&aacute;s tarde.",
          buttonAction: "",
          button: "Aceptar",
          respondTo: "error",
          action: 0,
        });
        this.SET_LOADING(false);
        console.log(error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/sass/variables";
div.container {
  width: 100%;
  display: grid;
  grid-gap: 5px;
  grid-template-columns: auto;
  h2,
  h3,
  h4,
  div {
    label,
    input,
    select {
      width: 100%;
      font-weight: bold;
    }
    .autocomplete-container {
      overflow: visible;
      height: 30px;
    }
  }
  .add-element {
    width: 100%;
    display: grid;
    height: 40px;
    grid-template-columns: auto 40px;
    grid-gap: 5px;
    button {
      margin: 0px;
    }
  }
  button.text-btn {
    background-color: $theme;
    color: whitesmoke;
    border: none;
    font-weight: 400;
    cursor: pointer;
    border-radius: 10px;
    border: none;
    font-size: 18px;
    line-height: 32px;
    vertical-align: middle;
    i {
      font-size: 32px;
      vertical-align: middle;
    }
    &:disabled{
    opacity: 0.5;
    cursor: not-allowed;

    }
  }
  h4 {
    text-align: center;
  }
  .note {
    font-size: 14px;
    font-style: italic;
  }
  .visible {
    display: grid !important;
  }
  .options {
    display: none;
    height: 0px;
    overflow: visible;
    margin-top: 0px;
    ul {
      background-color: white;
      border: solid 1px $text;
      margin-block-start: 0px;
      margin-block-end: 0px;
      z-index: 5;
      padding-inline-start: 0px;
      li {
        padding-left: 10px;
        line-height: 27px;
        color: $text;
        cursor: pointer;
        list-style: none;
      }
      li.selected,
      li:hover {
        background-color: $theme;
        color: white;
        font-weight: 500;
      }
    }
  }
  .file {
    display: none;
  }
  div.image-upload {
    display: grid;
    grid-template-columns: 80% 20%;
    grid-template-rows: auto;
    grid-gap: 10px;
    grid-template-areas:
      "imagen imagen"
      "input button";
    img {
      width: 100%;
      height: auto;
      grid-area: imagen;
    }
    div.input {
      grid-area: input;
      .file {
        display: none;
      }
    }
    button {
      grid-area: button;
      background-color: $theme;
      color: whitesmoke;
      border: none;
      font-weight: 400;
      cursor: pointer;
      border-radius: 10px;
      border: none;
      font-size: 18px;
      line-height: 32px;
      vertical-align: middle;
      i {
        font-size: 32px;
        vertical-align: middle;
      }
    }
  }
  .next-btn {
    background-color: transparent;
    border: none;
    font-weight: 200;
    cursor: pointer;
    font-size: 18px;
    width: auto;
    float: right;
    margin: 8px 16px;
    right: 0%;
    p {
      margin-block-end: 0px;
      margin-block-start: 0px;
      text-decoration: underline;
      vertical-align: middle;
      color: $theme;
      display: inline-block;
    }
    div {
      border-radius: 50px;
      background-color: $theme;
      color: white;
      padding: 5px;
      margin-left: 8px;
      i.material-icons {
        vertical-align: middle;
      }
      display: inline-block;
    }
  }
  .add-btn {
    margin-top: 5px;
    background-color: $theme;
    color: whitesmoke;
    border: none;
    font-weight: 400;
    cursor: pointer;
    border-radius: 10px;
    font-size: 18px;
    line-height: 32px;
    padding: 8px 25px;
    vertical-align: middle;
  }
  button.add-btn:disabled,
  .next-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
</style>
