<template>
  <div class="section">
    <div class="nav">
      <div class="nav-items main-nav">
        <div
          class="items"
          v-for="i in 8"
          :key="i"
          :class="{
            selected:
              i === parseInt($route.params.step) ||
              ($route.params.step === undefined && i === 1),
            checked: i < parseInt($route.params.step),
          }"
          @click="$router.push('/item/' + $route.params.id + '/edit/' + i)"
        >
          <h4>{{ i }}.</h4>
        </div>
        <div class="item-title"><h4 v-html="currentNavLabel"></h4></div>
      </div>
    </div>
    <div
      class="form-section"
      v-if="
        ($route.params.step === undefined || $route.params.step === '1') &&
        user !== null
      "
    >
      <label class="label" v-html="'Descripci&oacute;n:'"></label>
      <textarea
        name=""
        id=""
        rows="5"
        v-model="currentItem.description"
      ></textarea>
      <label class="label" v-html="'Palabras clave:'"></label>
      <div class="split-field">
        <div class="add-element">
          <div class="autocomplete-container">
            <Autocomplete
              :list="keywords"
              alternativeText="Ingresar la palabra clave"
              v-on:get-result="setResult"
              :border="true"
              height="30px"
              margin="0px"
              :showInfo="false"
            />
          </div>
          <button
            class="icon-btn"
            @click="addNewKeyWord"
            title="Agregar"
            :disabled="newKeyWord.name === '' && newKeyWord.name.length < 4"
          >
            <i class="material-icons">add</i>
          </button>
        </div>
        <div>
          <div
            class="tag"
            v-for="(item, index) in currentItem.keyWords"
            :key="index"
          >
            <p>
              {{ keywordName(item) }}
              <i class="material-icons" @click="keyWords.splice(index, 1)"
                >close</i
              >
            </p>
          </div>
        </div>
      </div>
      <label class="label" v-html="'T&eacute;nicas:'"></label>
      <div class="split-field">
        <div class="add-element">
          <div class="autocomplete-container">
            <Autocomplete
              :list="techniques"
              alternativeText="Ingresar la palabra clave"
              v-on:get-result="setTechniques"
              :border="true"
              height="30px"
              margin="0px"
              :showInfo="true"
            />
          </div>
          <button
            class="icon-btn"
            @click="addNewTechnique"
            title="Agregar"
            :disabled="newTechnique.name === '' && newTechnique.name.length < 4"
          >
            <i class="material-icons">add</i>
          </button>
        </div>
        <div>
          <div
            class="tag"
            v-for="(item, index) in currentItem.techniques"
            :key="index"
          >
            <p>
              {{ techniqueName(item) }}
              <i
                class="material-icons"
                @click="currentItem.techniques.splice(index, 1)"
                >close</i
              >
            </p>
          </div>
        </div>
      </div>
      <label class="label" v-html="'Materiales'"></label>
      <div class="split-field">
        <div class="add-element">
          <div class="autocomplete-container">
            <Autocomplete
              :list="materials"
              alternativeText="Ingresar la palabra clave"
              v-on:get-result="setMaterials"
              :border="true"
              height="30px"
              margin="0px"
              :showInfo="true"
            />
          </div>
          <button
            class="icon-btn"
            @click="addNewMaterial"
            title="Agregar"
            :disabled="newMaterial.name === '' && newMaterial.name.length < 4"
          >
            <i class="material-icons">add</i>
          </button>
        </div>
        <div>
          <div
            class="tag"
            v-for="(item, index) in currentItem.materials"
            :key="index"
          >
            <p>
              {{ materialName(item) }}
              <i
                class="material-icons"
                @click="currentItem.materials.splice(index, 1)"
                >close</i
              >
            </p>
          </div>
        </div>
      </div>
      <div class="nav-buttons">
        <div class="left"></div>
        <div class="right">
          <p>
            <button
              class="nav-btn right"
              @click="$router.push('/item/' + $route.params.id + '/edit/2')"
            >
              <p>Continuar</p>
              <div class="grey">
                <i class="material-icons">arrow_forward_ios</i>
              </div>
            </button>
            <button
              class="ok-btn right"
              style="margin-right: 1em"
              @click="updateSection1"
            >
              Guardar
            </button>
          </p>
        </div>
      </div>
    </div>
    <div
      class="form-section"
      v-if="$route.params.step === '2' && user !== null"
    >
      <p
        v-html="
          'Ingrese al menos una dimensi&oacute;n y medida del objeto, Los valores se ir&aacute;n desplegando autom&aacute;ticamente a medida que se ingresen nuevas medidas'
        "
      ></p>
      <div class="split-60-40 fullsize">
        <div>
          <label v-html="'Dimensi&oacute;n'"></label>
          <select name="dimension" id="dimension" v-model="dimension">
            <option
              value="none"
              v-html="'Seleccione una dimensi&oacute;n'"
            ></option>
            <option value="Alto" v-html="'Alto'"></option>
            <option value="Ancho" v-html="'Ancho'"></option>
            <option value="Profundidad" v-html="'Profundidad'"></option>
            <option
              value="Diámetro Mínimo"
              v-html="'Di&aacute;metro M&iacute;nimo'"
            ></option>
            <option
              value="Diámetro Máximo"
              v-html="'Di&aacute;metro M&aacute;ximo'"
            ></option>
            <option value="Espesor" v-html="'Espesor'"></option>
          </select>
        </div>
        <div class="split-60-40">
          <div>
            <label v-html="'Medida'"></label>
            <input type="number" step="0.1" v-model="measure" />
          </div>
          <div>
            <label v-html="'Unidad'"></label>
            <div class="add-element">
              <div>
                <select name="unit" id="unit" v-model="unit">
                  <option value="cm" v-html="'Cent&iacute;metro'"></option>
                  <option value="mm" v-html="'Mil&iacute;metro'"></option>
                  <option value="mt" v-html="'Metro'"></option>
                </select>
              </div>
              <button
                class="icon-btn"
                @click="addMeasure"
                title="Agregar"
                :disabled="dimension === 'none' && measure <= 0"
              >
                <i class="material-icons">add</i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <label class="label">Medidas Ingresadas</label>
      <table>
        <tr class="titles">
          <th v-html="'Dimensi&oacute;n'"></th>
          <th>Medida</th>
          <th>Opciones</th>
        </tr>
        <tr v-for="(item, index) in currentItem.measurements" :key="index">
          <td class="left">{{ item.dimension }}</td>
          <td class="left">{{ item.measure }}</td>
          <td class="center">
            <div>
              <button
                class="minimal-icon-btn white"
                @click="currentItem.measurements.splice(index, 1)"
              >
                <i class="material-icons">delete</i>
                <span class="tooltiptext bottom">Eliminar</span>
              </button>
            </div>
          </td>
        </tr>
      </table>
      <div class="nav-buttons">
        <div class="left">
          <button
            class="nav-btn left"
            @click="$router.push('/item/' + $route.params.id + '/edit/1')"
          >
            <div class="grey">
              <i class="material-icons">arrow_back_ios_new</i>
            </div>
            <p>Volver</p>
          </button>
        </div>
        <div class="right">
          <p>
            <button
              class="nav-btn right"
              @click="$router.push('/item/' + $route.params.id + '/edit/3')"
            >
              <p>Continuar</p>
              <div class="grey">
                <i class="material-icons">arrow_forward_ios</i>
              </div>
            </button>
            <button
              class="ok-btn right"
              style="margin-right: 1em"
              @click="updateSection2"
            >
              Guardar
            </button>
          </p>
        </div>
      </div>
    </div>
    <div
      class="form-section"
      v-if="$route.params.step === '3' && user !== null"
    >
      <p v-html="'Ingrese al menos una fotograf&iacute;a del objeto.'"></p>
      <div class="split-60-40 fullsize">
        <div>
          <label class="label" v-html="'Vista del Objeto'"></label>
          <select name="view" id="view" v-model="view">
            <option
              v-for="(item, index) in views"
              :key="index"
              :value="item.name"
              v-html="item.text"
            ></option>
          </select>
        </div>
        <div>
          <label class="label" v-html="'Seleccionar archivo'"></label>
          <div class="add-element">
            <input type="text" disabled v-model="currentImage.name" />
            <input
              class="file"
              type="file"
              ref="fileInput"
              accept="image/*"
              @change="onFilePicked"
            />
            <button
              class="icon-btn"
              @click="onPickFile"
              :disabled="view === 'none'"
            >
              <i class="material-icons">cloud_upload</i>
            </button>
          </div>
        </div>
      </div>
      <label class="label" v-html="'Fotograf&iacute;as Cargadas'"></label>
      <table>
        <tr class="titles">
          <th v-html="'Imagen'"></th>
          <th>Vista del Objeto</th>
          <th>Opciones</th>
        </tr>
        <tr v-if="currentItem.referenceImage" :key="'Referencia'">
          <td class="center">
            <img :src="currentItem.referenceImage.url" alt="" />
          </td>
          <td class="left">Referencia</td>
          <td class="center">
            <div>
              <button
                class="minimal-icon-btn white"
                @click="openGallery('parent', 0)"
              >
                <i class="material-icons">open_in_full</i>
                <span class="tooltiptext bottom">Visualizar</span>
              </button>
              <button
                class="minimal-icon-btn white"
                @click="
                  DESPLEGAR_MENSAJE_CONFIRMACION({
                    icon: 'delete',
                    title: 'Eliminar Fotograf&iacute;a',
                    msg: '&iquest;Deseas eliminar la fotograf&iacute;a del objeto?',
                    response: 'eliminarFotoObjetoPrincipal',
                  });
                  eliminarVista = 'Referencia';
                "
              >
                <i class="material-icons">delete</i>
                <span class="tooltiptext bottom">Eliminar</span>
              </button>
            </div>
          </td>
        </tr>
        <tr v-for="(item, index) in currentItem.images" :key="item.view">
          <td class="center">
            <img :src="item.url" alt="" />
          </td>
          <td class="left">{{ item.view }}</td>
          <td class="center">
            <div>
              <button
                class="minimal-icon-btn white"
                @click="openGallery('parent', index + 1)"
              >
                <i class="material-icons">open_in_full</i>
                <span class="tooltiptext bottom">Visualizar</span>
              </button>
              <button
                class="minimal-icon-btn white"
                @click="
                  DESPLEGAR_MENSAJE_CONFIRMACION({
                    icon: 'delete',
                    title: 'Eliminar Fotograf&iacute;a',
                    msg: '&iquest;Deseas eliminar la fotograf&iacute;a del objeto?',
                    response: 'eliminarFotoObjetoPrincipal',
                  });
                  eliminarVista = index;
                "
              >
                <i class="material-icons">delete</i>
                <span class="tooltiptext bottom">Eliminar</span>
              </button>
            </div>
          </td>
        </tr>
      </table>
      <div class="nav-buttons">
        <div class="left">
          <button
            class="nav-btn left"
            @click="$router.push('/item/' + $route.params.id + '/edit/2')"
          >
            <div class="grey">
              <i class="material-icons">arrow_back_ios_new</i>
            </div>
            <p>Volver</p>
          </button>
        </div>
        <div class="right">
          <p>
            <button
              class="nav-btn right"
              @click="$router.push('/item/' + $route.params.id + '/edit/4')"
            >
              <p>Continuar</p>
              <div class="grey">
                <i class="material-icons">arrow_forward_ios</i>
              </div>
            </button>
          </p>
        </div>
      </div>
    </div>
    <div
      class="form-section"
      v-if="$route.params.step === '4' && user !== null"
    >
      <label
        class="label"
        v-html="'Procedencia (pa&iacute;s, regi&oacute;n, ciudad o localidad)'"
      ></label>
      <input
        type="text"
        placeholder="Ingrese el lugar de procedencia"
        v-model="currentItem.origin"
      />
      <label
        class="label"
        v-html="'Lugar de Hallazgo dentro de Puerto Cristal'"
      ></label>
      <div>
        <label v-html="'Sitio'" v-show="currentItem.found !== ''"></label>
        <select
          name="sitio"
          id="sitio"
          v-model="foundPlace.site"
          v-show="foundPlace.value !== ''"
          @change="foundPlace.room = 'none'"
        >
          <option value="none" v-html="'Seleccione un sitio'"></option>
          <option
            v-for="site in getSitiosInExhibition"
            :key="site.id"
            :value="site.id"
          >
            {{ site.name }}
          </option>
        </select>
      </div>
      <div v-if="foundPlace.site!== undefined&&foundPlace.site !== 'none'">
        <label
          v-html="'Habitaci&oacute;n'"
          v-show="currentItem.found !== ''"
        ></label>
        <select
          name="room"
          id="room"
          v-model="foundPlace.room"
          v-show="foundPlace.site !== 'none'"
        >
          <option
            value="none"
            v-html="'Seleccione una habitaci&oacute;n'"
          ></option>
          <option v-for="room in getRooms1" :key="room.id" :value="room.id">
            {{ room.name }}
          </option>
        </select>
      </div>
      <label class="label" v-html="'Primer propietario o donante'"></label>
      <input
        type="text"
        placeholder="Ingrese el nombre del donante o primer propietario"
        v-model="currentItem.donant"
      />

      <div class="nav-buttons">
        <div class="left">
          <button
            class="nav-btn left"
            @click="$router.push('/item/' + $route.params.id + '/edit/3')"
          >
            <div class="grey">
              <i class="material-icons">arrow_back_ios_new</i>
            </div>
            <p>Volver</p>
          </button>
        </div>
        <div class="right">
          <p>
            <button
              class="nav-btn right"
              @click="$router.push('/item/' + $route.params.id + '/edit/5')"
            >
              <p>Continuar</p>
              <div class="grey">
                <i class="material-icons">arrow_forward_ios</i>
              </div>
            </button>
            <button
              class="ok-btn right"
              style="margin-right: 1em"
              @click="updateSection4"
            >
              Guardar
            </button>
          </p>
        </div>
      </div>
    </div>
    <div
      class="form-section"
      v-if="$route.params.step === '5' && user !== null"
    >
      <label
        class="label"
        v-html="'Indique el Estado de Conservaci&oacute;n del Objeto'"
      ></label>
      <div class="inputs large">
        <div class="questions">
          <div>
            <input
              type="radio"
              name="state"
              :value="'Bueno'"
              v-model="currentItem.pieceStatus.status"
            />
            <label v-html="'Bueno'"></label>
          </div>
          <div>
            <input
              type="radio"
              name="state"
              :value="'Regular'"
              v-model="currentItem.pieceStatus.status"
            />
            <label v-html="'Regular'"></label>
          </div>
          <div>
            <input
              type="radio"
              name="state"
              :value="'Malo'"
              v-model="currentItem.pieceStatus.status"
            />
            <label v-html="'Malo'"></label>
          </div>
        </div>
      </div>
      <label class="label" v-html="'Comentarios:'"></label>
      <div class="inputs">
        <textarea
          rows="5"
          v-model="currentItem.pieceStatus.comments"
          placeholder="Indique las observaciones respecto al Estado de Conservación el objeto"
        ></textarea>
      </div>
    </div>
    <div
      class="form-section"
      v-if="$route.params.step === '5' && user !== null"
    >
      <label
        class="label"
        v-html="'&iquest;Se realizaron acciones preventivas?'"
      ></label>
      <div class="inputs large">
        <div class="questions">
          <div>
            <input
              type="radio"
              name="conservation"
              :value="'Si'"
              v-model="currentItem.conservation.requires"
            />
            <label v-html="'Si'"></label>
          </div>
          <div>
            <input
              type="radio"
              name="conservation"
              :value="'No'"
              v-model="currentItem.conservation.requires"
            />
            <label v-html="'No'"></label>
          </div>
        </div>
      </div>
      <label
        class="label"
        v-html="
          '&iquest;Qu&eacute; acciones de conservaci&oacute;n preventiva se efectuaron?'
        "
        v-show="currentItem.conservation.requires === 'Si'"
      ></label>
      <div class="inputs">
        <textarea
          rows="5"
          v-model="currentItem.conservation.comments"
          v-show="currentItem.conservation.requires === 'Si'"
          placeholder="Ingrece las observaciones"
        ></textarea>
      </div>
    </div>

    <div
      class="form-section"
      v-if="$route.params.step === '5' && user !== null"
    >
      <label
        class="label"
        v-html="'&iquest;Requiere Restauraci&oacute;n?'"
      ></label>
      <div class="inputs large">
        <div class="questions">
          <div>
            <input
              type="radio"
              name="restoration"
              :value="'Si'"
              v-model="currentItem.restoration"
            />
            <label v-html="'Si'"></label>
          </div>
          <div>
            <input
              type="radio"
              name="restoration"
              :value="'No'"
              v-model="currentItem.restoration"
            />
            <label v-html="'No'"></label>
          </div>
        </div>
      </div>
      <label
        class="label"
        v-html="
          'Indicar d&oacute;nde se encuentra el c&oacute;digo identificador del objeto'
        "
      ></label>
      <div class="inputs large">
        <input type="text" name="id" v-model="currentItem.identifier" />
      </div>
      <div class="nav-buttons">
        <div class="left">
          <button
            class="nav-btn left"
            @click="$router.push('/item/' + $route.params.id + '/edit/4')"
          >
            <div class="grey">
              <i class="material-icons">arrow_back_ios_new</i>
            </div>
            <p>Volver</p>
          </button>
        </div>
        <div class="right">
          <p>
            <button
              class="nav-btn right"
              @click="$router.push('/item/' + $route.params.id + '/edit/6')"
            >
              <p>Continuar</p>
              <div class="grey">
                <i class="material-icons">arrow_forward_ios</i>
              </div>
            </button>
            <button
              class="ok-btn right"
              style="margin-right: 1em"
              @click="updateSection5"
            >
              Guardar
            </button>
          </p>
        </div>
      </div>
    </div>
    <div
      class="form-section"
      v-if="$route.params.step === '6' && user !== null"
    >
      <label
        class="label"
        v-html="
          '&iquest;El objeto se encuentra in situ/exhibici&oacute;n, (es decir fuera del dep&oacute;sito)?'
        "
      ></label>
      <div class="inputs large">
        <div class="questions">
          <div>
            <input
              type="radio"
              name="exhibition"
              :value="'Si'"
              v-model="currentItem.exhibition"
              :disabled="currentItem.location !== ''"
            />
            <label v-html="'Si'"></label>
          </div>
          <div>
            <input
              type="radio"
              name="exhibition"
              :value="'No'"
              v-model="currentItem.exhibition"
            />
            <label v-html="'No'"></label>
          </div>
        </div>
      </div>
      <label
        class="label"
        v-html="'&iquest;En qu&eacute; lugar se encuentra exhibido el objeto?'"
        v-show="currentItem.exhibition === 'Si'"
      ></label>
      <div class="inputs large">
        <div class="questions">
          <div v-if="currentItem.exhibition === 'Si'">
            <label
              v-html="'Sitio'"
              v-show="currentItem.exhibition !== ''"
            ></label>
            <select
              name="site"
              id="site"
              v-model="location.site"
              v-show="location.value !== ''"
              @change="
                location.room = 'none';
                location.box = 'none';
              "
            >
              <option value="none" v-html="'Seleccione un sitio'"></option>
              <option
                v-for="site in getSitiosInExhibition"
                :key="site.id"
                :value="site.id"
              >
                {{ site.name }}
              </option>
            </select>
          </div>
          <div
            v-if="currentItem.exhibition === 'Si' && location.site !== 'none'"
          >
            <label
              v-html="'Habitaci&oacute;n'"
              v-show="currentItem.exhibition !== ''"
            ></label>
            <select
              name="room"
              id="room"
              v-model="location.room"
              v-show="currentItem.exhibition !== ''"
            >
              <option
                value="none"
                v-html="'Seleccione una habitaci&oacute;n'"
              ></option>
              <option v-for="room in getRooms" :key="room.id" :value="room.id">
                {{ room.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="text-link" v-if="currentItem.exhibition === 'No'">
        <div v-if="currentItem.location === ''">
          <p
            v-html="
              'El objeto se encuentra en el dep&oacute;sito, puede asignarlo a una caja pinche'
            "
          ></p>
          <span @click="$router.push('/deposit')" v-html="'AQU&Iacute;'"></span>
        </div>
        <div v-else>
          <p v-html="'El objeto se encuentra en la caja '"></p>
          <span @click="$router.push('/deposit')">
            {{ currentItem.location.value }}</span
          >
          <p
            v-html="
              ' del dep&oacute;sito, para cambiar el objeto de lugar se debe eliminar de la caja primero.'
            "
          ></p>
        </div>
      </div>
      <div class="inputs large">
        <textarea
          name="comments"
          rows="5"
          v-model="currentItem.locationComments"
          v-if="currentItem.exhibition === 'Si'"
          placeholder="Indique algún comentario adicional sobre la ubicación del objeto"
        ></textarea>
      </div>
    </div>
    <div
      class="form-section"
      v-if="$route.params.step === '6' && user !== null"
    >
      <label class="label" v-html="'Observaciones'"></label>
      <input
        type="text"
        placeholder="Agregue cualquier dato adicional del objeto"
        v-model="currentItem.others"
      />
      <div class="nav-buttons">
        <div class="left">
          <button
            class="nav-btn left"
            @click="$router.push('/item/' + $route.params.id + '/edit/5')"
          >
            <div class="grey">
              <i class="material-icons">arrow_back_ios_new</i>
            </div>
            <p>Volver</p>
          </button>
        </div>
        <div class="right">
          <p>
            <button
              class="nav-btn right"
              @click="$router.push('/item/' + $route.params.id + '/edit/7')"
            >
              <p>Continuar</p>
              <div class="grey">
                <i class="material-icons">arrow_forward_ios</i>
              </div>
            </button>
            <button
              class="ok-btn right"
              style="margin-right: 1em"
              @click="updateSection6"
            >
              Guardar
            </button>
          </p>
        </div>
      </div>
    </div>
    <div
      class="form-section"
      v-if="$route.params.step === '7' && user !== null"
    >
      <div class="left">
        <label
          class="label"
          v-html="'&iquest;Corresponde a un objeto compuesto?'"
        ></label>
        <div class="onoffswitch">
          <input
            type="checkbox"
            name="onoffswitch"
            class="onoffswitch-checkbox"
            id="compuesto"
            tabindex="0"
            v-model="isConjunto"
            @click="toogleCompuesto"
          />
          <label class="onoffswitch-label" for="compuesto">
            <span class="onoffswitch-inner"></span>
            <span class="onoffswitch-switch"></span>
          </label>
        </div>
      </div>
      <table v-if="currentItem.compuesto">
        <tr class="titles">
          <th v-html="'Imagen'"></th>
          <th>Nombre</th>
          <th v-html="'C&oacute;digo'"></th>
          <th>Opciones</th>
        </tr>
        <tr @click="createNewObject = true" style="cursor: pointer">
          <td>
            <div class="icon"><i class="material-icons">post_add</i></div>
          </td>
          <td>Agregar nuevo objeto</td>
          <td></td>
          <td class="center">
            <div>
              <button class="minimal-icon-btn white">
                <i class="material-icons">add</i>
                <span class="tooltiptext bottom">Agregar</span>
              </button>
            </div>
          </td>
        </tr>
        <tr v-for="item in listaObjetos" :key="item.id">
          <td v-if="item.data.mainImage !== ''" class="center">
            <img :src="item.data.mainImage.url" alt="" />
          </td>
          <td v-else>
            <div class="icon">
              <i class="material-icons">add_photo_alternate</i>
            </div>
          </td>
          <td>{{ item.data.name }}</td>
          <td>{{ item.id }}</td>
          <td class="center">
            <div>
              <button
                class="minimal-icon-btn white"
                @click="
                  editObjectConjunto = true;
                  editObjectId = item.id;
                "
              >
                <i class="material-icons">edit</i>
                <span class="tooltiptext bottom">Editar</span>
              </button>
              <button class="minimal-icon-btn white">
                <i class="material-icons">delete</i>
                <span class="tooltiptext bottom">Eliminar</span>
              </button>
            </div>
          </td>
        </tr>
      </table>
      <div class="nav-buttons">
        <div class="left">
          <button
            class="nav-btn left"
            @click="$router.push('/item/' + $route.params.id + '/edit/6')"
          >
            <div class="grey">
              <i class="material-icons">arrow_back_ios_new</i>
            </div>
            <p>Volver</p>
          </button>
        </div>
        <div class="right">
          <p>
            <button
              class="nav-btn right"
              @click="$router.push('/item/' + $route.params.id + '/edit/8')"
            >
              <p>Continuar</p>
              <div class="grey">
                <i class="material-icons">arrow_forward_ios</i>
              </div>
            </button>
            <button class="ok-btn right" style="margin-right: 1em">
              Guardar
            </button>
          </p>
        </div>
      </div>
    </div>

    <div
      class="form-section"
      v-if="$route.params.step === '8' && user !== null"
    >
      <label class="label" v-html="'Objetos de Inter&eacute;s'"></label>
      <p
        v-html="
          'Agregue objetos similares a este o que tengan alguna relaci&oacute;n que sea de inter&eacute;s.'
        "
      ></p>
      <div class="split-60-40 fullsize">
        <div>
          <label v-html="'Seleccione un Objeto'"></label>
          <div class="autocomplete-container" style="margin-top: 6px">
            <Autocomplete
              :list="listaObjetosEnDB"
              alternativeText="Ingresa el nombre/codigo del objeto"
              v-on:get-result="setRelatedObject"
              :border="true"
              height="30px"
              margin="0px"
              :showInfo="false"
            />
          </div>
        </div>
        <div>
          <label v-html="'Indique el tipo de relaci&oacute;n'"></label>
          <div class="add-element">
            <input type="text" v-model="newRelation" />
            <button
              class="icon-btn"
              @click="addNewRelatedObject"
              title="Agregar"
              :disabled="newRelatedObject.id === '' || newRelation === ''"
            >
              <i class="material-icons">add</i>
            </button>
          </div>
        </div>
      </div>

      <label class="label" v-html="'Objetos ingresados'"></label>
      <p
        v-html="'A&uacute;n no se han agregado objetos relacionados'"
        v-if="currentItem.relatedObjects.length === 0"
      ></p>
      <table>
        <tr class="titles" v-if="currentItem.relatedObjects.length > 0">
          <th v-html="'Imagen'"></th>
          <th v-html="'Identificaci&oacute;n'"></th>
          <th v-html="'Relaci&oacute;n'"></th>
          <th v-html="'Opciones'"></th>
        </tr>
        <tr v-for="(item, index) in currentItem.relatedObjects" :key="item.id">
          <td class="center" v-if="getObjeto(item.id) !== null">
            <img :src="getObjeto(item.id).referenceImage.url" alt="" />
          </td>
          <td class="left" v-if="getObjeto(item.id) !== null">
            <b>{{ item.id }}</b> <br />
            {{ getObjeto(item.id).name }}
          </td>
          <td
            class="left"
            v-if="getObjeto(item.id) !== null && editRelation !== index"
          >
            {{ item.relation }}
          </td>
          <td
            class="left"
            v-if="getObjeto(item.id) !== null && editRelation === index"
          >
            <label class="label" v-html="'Nueva relaci&oacute;n'"></label>
            <div class="add-element">
              <input type="text" v-model="item.relation" />
              <button
                class="icon-btn"
                @click="
                  updateRelatedObject({ id: item.id, relation: item.relation })
                "
                title="Editar"
                :disabled="item.relation === ''"
              >
                <i class="material-icons">save</i>
              </button>
            </div>
          </td>
          <td class="center" v-if="getObjeto(item.id) !== null">
            <div>
              <button
                class="minimal-icon-btn white"
                @click="
                  editRelation !== index
                    ? (editRelation = index)
                    : (editRelation = '')
                "
              >
                <i class="material-icons">edit</i>
                <span
                  class="tooltiptext bottom"
                  v-html="'Editar Relaci&oacute;n'"
                ></span>
              </button>
              <button class="minimal-icon-btn white">
                <i class="material-icons">open_in_full</i>
                <span class="tooltiptext bottom">Visualizar</span>
              </button>
              <button
                class="minimal-icon-btn white"
                @click="removeRelatedObject(item.id)"
              >
                <i class="material-icons">delete</i>
                <span class="tooltiptext bottom">Eliminar</span>
              </button>
            </div>
          </td>
        </tr>
      </table>
      <div class="nav-buttons">
        <div class="left">
          <button
            class="nav-btn left"
            @click="$router.push('/item/' + $route.params.id + '/edit/7')"
          >
            <div class="grey">
              <i class="material-icons">arrow_back_ios_new</i>
            </div>
            <p>Volver</p>
          </button>
        </div>
        <div class="right">
          <p></p>
        </div>
      </div>
    </div>
    <div class="picture-preview" v-show="previewDisplayed || previewConjunto">
      <SimpleBar class="box">
        <div class="box-content">
          <div
            class="image"
            :style="'background-image:url(' + currentImage.preview + ')'"
          ></div>
          <div class="icon">
            <p>
              <i class="material-icons">add_photo_alternate</i>
            </p>
          </div>
          <div class="text">
            <h4>Agregar Imagen</h4>
            <p>
              <strong>Nombre:</strong>
              {{ currentImage.name }}
            </p>
            <p>
              <strong>Vista:</strong>
              {{ view }}
            </p>
            <p class="btn-center">
              <button class="ok-btn" v-if="previewDisplayed" @click="addImage">
                Agregar
              </button>
              <button
                class="ok-btn"
                v-if="previewConjunto"
                @click="addImageConjunto"
              >
                Agregar
              </button>
              <button class="cancel-btn" @click="clearImage">Cancelar</button>
            </p>
          </div>
        </div>
      </SimpleBar>
    </div>
    <div class="picture-preview" v-show="addImageLabel">
      <div class="box input">
        <div class="text">
          <h4>Agregar Nombre de la Imagen</h4>
          <p>
            <strong>Nombre:</strong>
          </p>
          <input type="text" v-model="newView" />
          <p class="btn-center">
            <button class="ok-btn" @click="addImageView">Agregar</button>
            <button
              class="cancel-btn"
              @click="
                newView = '';
                addImageLabel = false;
              "
            >
              Cancelar
            </button>
          </p>
        </div>
      </div>
    </div>
    <div class="modal" v-if="createNewObject">
      <div class="top" @click="createNewObject = false"></div>
      <div class="left" @click="createNewObject = false"></div>
      <div class="right" @click="createNewObject = false"></div>
      <div class="bottom" @click="createNewObject = false"></div>
      <div class="box">
        <p class="icon" style="padding: 0.5em 0.5em 0 0">
          <i class="material-icons" @click="createNewObject = false">close</i>
        </p>
        <h2 v-html="'Agregar objeto al Conjunto'"></h2>
        <div class="content padding">
          <div class="form-section">
            <label class="label">Nombre del Objeto:</label>
            <input
              type="text"
              v-model="newObject.name"
              placeholder="Nombre del Objeto"
            />
            <label
              class="label"
              v-html="'Sub-&iacute;ndice identificador'"
            ></label>
            <input
              type="text"
              v-model="newObject.id"
              placeholder="Identificador único - ejemplo: A"
            />
            <p class="btn-center">
              <button class="ok-btn" @click="addNewObjectConfirmation">
                Agregar
              </button>
              <button
                class="cancel-btn"
                @click="
                  newObject = {};
                  createNewObject = false;
                "
              >
                Cancelar
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" v-if="editObjectConjunto">
      <div
        class="top"
        @click="
          editObjectId = '';
          editObjectConjunto = false;
        "
      ></div>
      <div
        class="left"
        @click="
          editObjectId = '';
          editObjectConjunto = false;
        "
      ></div>
      <div
        class="right"
        @click="
          editObjectId = '';
          editObjectConjunto = false;
        "
      ></div>
      <div
        class="bottom"
        @click="
          editObjectId = '';
          editObjectConjunto = false;
        "
      ></div>
      <SimpleBar class="box">
        <p class="icon" style="padding: 0.5em 0.5em 0 0">
          <i
            class="material-icons"
            @click="
              editObjectId = '';
              editObjectConjunto = false;
            "
            >close</i
          >
        </p>
        <div class="padding">
          <h2 v-html="'Objeto: ' + editObjectId"></h2>
          <div class="nav">
            <div class="nav-items sub-nav">
              <div
                class="items"
                v-for="i in 3"
                :key="i"
                :class="{
                  selected: i === currentSubLebel,
                  checked: i < currentSubLebel,
                }"
                @click="currentSubLebel = i"
              >
                <h4>{{ i }}.</h4>
              </div>
              <div class="item-title sub-nav">
                <h4 v-html="currentNavLabelConjunto"></h4>
              </div>
            </div>
          </div>
          <div class="form-section" v-if="currentSubLebel === 1">
            <label class="label" v-html="'Nombre:'"></label>
            <input type="text" v-model="currentObjetoCompuesto.name" />
            <label class="label" v-html="'Descripci&oacute;n:'"></label>
            <textarea
              name=""
              id=""
              rows="5"
              v-model="currentObjetoCompuesto.description"
            ></textarea>
            <label class="label" v-html="'T&eacute;nicas:'"></label>
            <div class="split-field">
              <div class="add-element">
                <div class="autocomplete-container">
                  <Autocomplete
                    :list="techniques"
                    alternativeText="Ingresar la palabra clave"
                    v-on:get-result="setTechniquesConjunto"
                    :border="true"
                    height="30px"
                    margin="0px"
                    :showInfo="true"
                  />
                </div>
                <button
                  class="icon-btn"
                  @click="addNewTechniqueConjunto"
                  title="Agregar"
                  :disabled="
                    temporalData.newTechnique.name === '' &&
                    temporalData.newTechnique.name.length < 4
                  "
                >
                  <i class="material-icons">add</i>
                </button>
              </div>
              <div>
                <div
                  class="tag"
                  v-for="(item, index) in currentObjetoCompuesto.techniques"
                  :key="index"
                >
                  <p>
                    {{ techniqueName(item) }}
                    <i
                      class="material-icons"
                      @click="
                        currentObjetoCompuesto.techniques.splice(index, 1)
                      "
                      >close</i
                    >
                  </p>
                </div>
              </div>
            </div>
            <label class="label" v-html="'Materiales'"></label>
            <div class="split-field">
              <div class="add-element">
                <div class="autocomplete-container">
                  <Autocomplete
                    :list="materials"
                    alternativeText="Ingresar la palabra clave"
                    v-on:get-result="setMaterialsConjunto"
                    :border="true"
                    height="30px"
                    margin="0px"
                    :showInfo="true"
                  />
                </div>
                <button
                  class="icon-btn"
                  @click="addNewMaterialConjunto"
                  title="Agregar"
                  :disabled="
                    temporalData.newMaterial.name === '' &&
                    temporalData.newMaterial.name.length < 4
                  "
                >
                  <i class="material-icons">add</i>
                </button>
              </div>
              <div>
                <div
                  class="tag"
                  v-for="(item, index) in currentObjetoCompuesto.materials"
                  :key="index"
                >
                  <p>
                    {{ materialName(item) }}
                    <i
                      class="material-icons"
                      @click="currentObjetoCompuesto.materials.splice(index, 1)"
                      >close</i
                    >
                  </p>
                </div>
              </div>
            </div>
            <div class="nav-buttons">
              <div class="left"></div>
              <div class="right">
                <p>
                  <button class="nav-btn right" @click="currentSubLebel = 2">
                    <p>Continuar</p>
                    <div class="grey">
                      <i class="material-icons">arrow_forward_ios</i>
                    </div>
                  </button>
                  <button
                    class="ok-btn right"
                    style="margin-right: 1em"
                    @click="
                      DESPLEGAR_MENSAJE_CONFIRMACION({
                        icon: 'save',
                        title: 'Actualizar Caracter&iacute;sticas',
                        msg: '&iquest;Deseas actualizar las caracter&iacute;sticas del objeto?',
                        response: 'actualizarCaracteristicasObjetoConjunto',
                      })
                    "
                  >
                    Guardar
                  </button>
                </p>
              </div>
            </div>
          </div>
          <div class="form-section" v-if="currentSubLebel === 2">
            <p
              v-html="
                'Ingrese al menos una dimensi&oacute;n y medida del objeto, Los valores se ir&aacute;n desplegando autom&aacute;ticamente a medida que se ingresen nuevas medidas'
              "
            ></p>
            <div class="split-60-40 fullsize">
              <div>
                <label v-html="'Dimensi&oacute;n'"></label>
                <select
                  name="dimension"
                  id="dimension"
                  v-model="temporalData.dimensiones.dimension"
                >
                  <option
                    value="none"
                    v-html="'Seleccione una dimensi&oacute;n'"
                  ></option>
                  <option value="Alto" v-html="'Alto'"></option>
                  <option value="Ancho" v-html="'Ancho'"></option>
                  <option value="Profundidad" v-html="'Profundidad'"></option>
                  <option
                    value="Diámetro Mínimo"
                    v-html="'Di&aacute;metro M&iacute;nimo'"
                  ></option>
                  <option
                    value="Diámetro Máximo"
                    v-html="'Di&aacute;metro M&aacute;ximo'"
                  ></option>
                  <option value="Espesor" v-html="'Espesor'"></option>
                </select>
              </div>
              <div class="split-60-40">
                <div>
                  <label v-html="'Medida'"></label>
                  <input
                    type="number"
                    step="0.1"
                    v-model="temporalData.dimensiones.valor"
                  />
                </div>
                <div>
                  <label v-html="'Unidad'"></label>
                  <div class="add-element">
                    <div>
                      <select
                        name="unit"
                        id="unit"
                        v-model="temporalData.dimensiones.unidad"
                      >
                        <option
                          value="cm"
                          v-html="'Cent&iacute;metro'"
                        ></option>
                        <option value="mm" v-html="'Mil&iacute;metro'"></option>
                        <option value="mt" v-html="'Metro'"></option>
                      </select>
                    </div>
                    <button
                      class="icon-btn"
                      @click="addMeasureToObjetoCompuesto"
                      title="Agregar"
                      :disabled="
                        temporalData.dimensiones.dimension === 'none' &&
                        temporalData.dimensiones.valor <= 0
                      "
                    >
                      <i class="material-icons">add</i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <label
              class="label"
              v-if="currentObjetoCompuesto.dimensions.length > 0"
              >Medidas Ingresadas</label
            >
            <label class="label" v-else>No se han ingresado medidas</label>
            <table v-if="currentObjetoCompuesto.dimensions.length > 0">
              <tr class="titles">
                <th v-html="'Dimensi&oacute;n'"></th>
                <th>Medida</th>
                <th>Opciones</th>
              </tr>
              <tr
                v-for="(item, index) in currentObjetoCompuesto.dimensions"
                :key="index"
              >
                <td class="left">{{ item.dimension }}</td>
                <td class="left">{{ item.value }}</td>
                <td class="center">
                  <div>
                    <button class="minimal-icon-btn white">
                      <i class="material-icons">delete</i>
                      <span class="tooltiptext bottom">Eliminar</span>
                    </button>
                  </div>
                </td>
              </tr>
            </table>
            <div class="nav-buttons">
              <div class="left">
                <button class="nav-btn left" @click="currentSubLebel = 1">
                  <div class="grey">
                    <i class="material-icons">arrow_back_ios_new</i>
                  </div>
                  <p>Volver</p>
                </button>
              </div>
              <div class="right">
                <p>
                  <button class="nav-btn right" @click="currentSubLebel = 3">
                    <p>Continuar</p>
                    <div class="grey">
                      <i class="material-icons">arrow_forward_ios</i>
                    </div>
                  </button>
                  <button
                    class="ok-btn right"
                    style="margin-right: 1em"
                    @click="
                      DESPLEGAR_MENSAJE_CONFIRMACION({
                        icon: 'save',
                        title: 'Actualizar Medidas',
                        msg: '&iquest;Deseas actualizar las medidas del objeto?',
                        response: 'actualizarMedidasObjetoConjunto',
                      })
                    "
                  >
                    Guardar
                  </button>
                </p>
              </div>
            </div>
          </div>
          <div class="form-section" v-if="currentSubLebel === 3">
            <p
              v-html="'Ingrese al menos una fotograf&iacute;a del objeto.'"
            ></p>
            <div class="split-60-40 fullsize">
              <div>
                <label class="label" v-html="'Vista del Objeto'"></label>
                <select name="view" id="view" v-model="view">
                  <option
                    v-for="(item, index) in views"
                    :key="index"
                    :value="item.name"
                    v-html="item.text"
                  ></option>
                </select>
              </div>
              <div>
                <label class="label" v-html="'Seleccionar archivo'"></label>
                <div class="add-element">
                  <input type="text" disabled v-model="currentImage.name" />
                  <input
                    class="file"
                    type="file"
                    ref="fileInput2"
                    accept="image/*"
                    @change="onFilePickedConjunto"
                  />
                  <button
                    class="icon-btn"
                    @click="$refs.fileInput2.click()"
                    :disabled="view === 'none'"
                  >
                    <i class="material-icons">cloud_upload</i>
                  </button>
                </div>
              </div>
            </div>
            <label class="label" v-html="'Fotograf&iacute;as Cargadas'"></label>
            <table>
              <tr class="titles">
                <th v-html="'Imagen'"></th>
                <th>Vista del Objeto</th>
                <th>Opciones</th>
              </tr>
              <tr
                v-if="
                  currentObjetoCompuesto.mainImage !== undefined &&
                  currentObjetoCompuesto.mainImage !== null &&
                  currentObjetoCompuesto.mainImage !== ''
                "
              >
                <td class="center">
                  <img :src="currentObjetoCompuesto.mainImage.url" alt="" />
                </td>
                <td class="left">Referencia</td>
                <td class="center">
                  <div>
                    <button
                      class="minimal-icon-btn white"
                      @click="openGallery(editObjectId, 0)"
                    >
                      <i class="material-icons">open_in_full</i>
                      <span class="tooltiptext bottom">Visualizar</span>
                    </button>
                    <button
                      class="minimal-icon-btn white"
                      @click="
                        DESPLEGAR_MENSAJE_CONFIRMACION({
                          icon: 'delete',
                          title: 'Eliminar Fotograf&iacute;a',
                          msg: '&iquest;Deseas eliminar la fotograf&iacute;a del objeto?',
                          response: 'eliminarFotoObjetoConjunto',
                        });
                        eliminarVista = 'Referencia';
                      "
                    >
                      <i class="material-icons">delete</i>
                      <span class="tooltiptext bottom">Eliminar</span>
                    </button>
                  </div>
                </td>
              </tr>
              <tr
                v-for="(item, index) in currentObjetoCompuesto.photos"
                :key="item.view"
              >
                <td class="center">
                  <img :src="item.url" alt="" />
                </td>
                <td class="left">{{ item.view }}</td>
                <td class="center">
                  <div>
                    <button
                      class="minimal-icon-btn white"
                      @click="openGallery(editObjectId, index + 1)"
                    >
                      <i class="material-icons">open_in_full</i>
                      <span class="tooltiptext bottom">Visualizar</span>
                    </button>
                    <button
                      class="minimal-icon-btn white"
                      @click="
                        DESPLEGAR_MENSAJE_CONFIRMACION({
                          icon: 'delete',
                          title: 'Eliminar Fotograf&iacute;a',
                          msg: '&iquest;Deseas eliminar la fotograf&iacute;a del objeto?',
                          response: 'eliminarFotoObjetoConjunto',
                        });
                        eliminarVista = item.view;
                      "
                    >
                      <i class="material-icons">delete</i>
                      <span class="tooltiptext bottom">Eliminar</span>
                    </button>
                  </div>
                </td>
              </tr>
            </table>
            <div class="nav-buttons">
              <div class="left">
                <button class="nav-btn left" @click="currentSubLebel = 2">
                  <div class="grey">
                    <i class="material-icons">arrow_back_ios_new</i>
                  </div>
                  <p>Volver</p>
                </button>
              </div>
              <div class="right"></div>
            </div>
          </div>
        </div>
      </SimpleBar>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapGetters, mapMutations } from "vuex";
import app from "@/firebase";
import {
  getFirestore,
  doc,
  addDoc,
  collection,
  updateDoc,
} from "firebase/firestore";
const db = getFirestore(app);
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
const storage = getStorage(app);
//let storageRef=firebase.storage();
import Autocomplete from "@/components/Autocomplete.vue";
import { SimpleBar } from "simplebar-vue3";
export default {
  name: "Edit-Object-Data",
  components: {
    Autocomplete,
    SimpleBar,
  },
  data: function () {
    return {
      previewConjunto: false,
      editObjectConjunto: false,
      isConjunto: false,
      createNewObject: false,
      currentSubLebel: 1,
      editObjectId: "",
      editRelation: "",
      temporalData: {
        dimensiones: { dimension: "none", valor: 0, unidad: "cm" },
        newMaterial: { id: "", name: "", definition: "" },
        newTechnique: { id: "", name: "", definition: "" },
        addedMaterials: [],
        addedTechniques: [],
      },
      objectCopy: {},
      newObject: {
        name: "",
        id: "",
      },
      id: "",
      eliminarVista: "",
      newKeyWord: { id: "", name: "", definition: "" },
      newMaterial: { id: "", name: "", definition: "" },
      newTechnique: { id: "", name: "", definition: "" },
      newRelatedObject: { text: "", id: "" },
      newRelation: "",
      dimension: "none",
      measure: 0,
      unit: "cm",
      view: "none",
      views: [],
      newView: "",
      currentImage: {
        name: "",
        preview: "",
        file: null,
      },
      idLocation: "",
      previewDisplayed: false,
      addImageLabel: false,
      imageRemove: 0,
      originPlace: "",
      compuesto: "",
      donantName: "",
      pieceStatus: {
        value: "",
        comments: "",
      },
      conservation: {
        value: "",
        observations: "",
      },
      restoration: "",
      location: {
        site: "none",
        room: "none",
        box: "none",
      },
      foundPlace: {
        site: "none",
        room: "none",
      },
      notes: "",
      newBox: "",
      progress: "",
      uploadTask: null,
    };
  },
  computed: {
    currentNavLabel() {
      switch (parseInt(this.$route.params.step)) {
        case 1:
          return "Caracter&iacute;sticas del Objeto";
        case 2:
          return "Dimensiones del Objeto";
        case 3:
          return "Fotograf&iacute;as del Objeto";
        case 4:
          return "Origen del Objeto";
        case 5:
          return "Estado de Conservaci&oacute;n";
        case 6:
          return "Ubicaci&oacute;n y Observaciones";
        case 7:
          return "Objetos Compuestos";
        case 8:
          return "Objetos relacionados";
        default:
          return "Caracter&iacute;sticas del Objeto";
      }
    },
    currentNavLabelConjunto() {
      switch (this.currentSubLebel) {
        case 1:
          return "Caracter&iacute;sticas del Objeto";
        case 2:
          return "Dimensiones del Objeto";
        case 3:
          return "Fotograf&iacute;as del Objeto";
        default:
          return "Caracter&iacute;sticas del Objeto";
      }
    },
    listaObjetos() {
      return this.getConjuntoObjetos(this.$route.params.id);
    },
    currentObjetoCompuesto() {
      if (this.editObjectId !== "") {
        let el = this.listaObjetos.find(
          (item) => item.id === this.editObjectId
        );
        return el.data;
      } else {
        return "";
      }
    },
    ...mapGetters([
      "currentItem",
      "getMessageAction",
      "sites",
      "boxes",
      "user",
      "getConjuntoObjetos",
      "indexedItems",
      "getItem",
      "allItems",
    ]),
    ...mapGetters("materials", { materials: "items", materialName: "name" }),
    ...mapGetters("keywords", { keywords: "items", keywordName: "name" }),
    ...mapGetters("techniques", { techniques: "items", techniqueName: "name" }),

    ...mapGetters("sitios", [
      "getSiteById",
      "getRoomById",
      "sitios",
      "getSitiosInExhibition",
      "getDepositSites",
    ]),
    getRooms() {
      if (this.location.site !== "none") {
        return this.getSiteById(this.location.site).rooms;
      } else {
        return [];
      }
    },
    getRooms1() {
      if (this.foundPlace.site !== "none") {
        return this.getSiteById(this.foundPlace.site).rooms;
      } else {
        return [];
      }
    },
    listaObjetosEnDB() {
      let arr = [];
      this.indexedItems.forEach((item) => {
        if (
          item.id !== this.currentItem.id &&
          !this.currentItem.relatedObjects.find((elem) => elem.id === item.id)
        ) {
          arr.push({ id: item.id, name: item.id + ": " + item.name });
        }
      });
      return arr;
    },

    getArrayImages() {
      let arr = [];
      let i = 0;
      if (this.currentItem && this.currentItem.referenceImage) {
        arr.push({
          ...this.currentItem.referenceImage,
          view: "Referencia",
          position: i,
        });
        i++;
      }
      if (this.currentItem && this.currentItem.images) {
        this.currentItem.images.forEach((item) => {
          arr.push({ ...item, position: i });
          i++;
        });
      }
      return arr;
    },
  },
  watch: {
    editObjectId(newValue) {
      if (newValue !== "") {
        let el = this.listaObjetos.find((item) => item.id === newValue);
        this.objectCopy = Object.assign({}, el.data);
      }
    },
    getMessageAction(payload) {
      switch (payload.component) {
        case "addKeyWord":
          if (payload.action === 1) {
            this.createNewKeyWord();
            this.SET_MESSAGE_PARAMETERS({});
          } else if (payload.action === -1) {
            this.newKeyWord = { id: "", name: "", definition: "" };
            this.SET_MESSAGE_PARAMETERS({});
          }
          break;
        case "alertMeasurement":
          if (payload.action === 1) {
            this.measurements.find(
              (doc) => doc.dimension === this.dimension
            ).measure = this.measure + " " + this.unit;
            this.SET_MESSAGE_PARAMETERS({});
          } else if (payload.action === -1) {
            this.dimension = "none";
            this.measure = 0;
            this.SET_MESSAGE_PARAMETERS({});
          }
          break;
        case "errorViewExists":
          if (payload.action === 1) {
            let newImage = {
              view: this.view,
              name: this.currentImage.name,
              preview: this.currentImage.preview,
              file: this.currentImage.file,
            };
            this.uploadSingleImage(newImage);
            this.currentImage = {
              name: "",
              preview: "",
              file: null,
            };
          } else if (payload.action === -1) {
            this.currentImage = {
              name: "",
              preview: "",
              file: null,
            };
            this.SET_MESSAGE_PARAMETERS({});
          }
          break;
        case "errorViewExistsConjunto":
          if (payload.action === 1) {
            let newImage = {
              view: this.view,
              name: this.currentImage.name,
              preview: this.currentImage.preview,
              file: this.currentImage.file,
            };
            this.uploadSingleImageCompuesto(newImage);
            this.currentImage = {
              name: "",
              preview: "",
              file: null,
            };
          } else if (payload.action === -1) {
            this.currentImage = {
              name: "",
              preview: "",
              file: null,
            };
            this.SET_MESSAGE_PARAMETERS({});
          }
          break;
        case "successUpdateObject":
          if (payload.action === 1) {
            console.log("/item/" + this.$route.params.id);
            this.$router.push("/item/" + this.$route.params.id);
            this.SET_MESSAGE_PARAMETERS({});
          }
          break;
        case "alertConjunto":
          if (payload.action === 1) {
            this.SET_LOADING_TEXT("Cargando...");
            this.SET_LOADING_OPACITY(true);
            this.SET_LOADING(true);
            this.setCompuesto({ id: this.$route.params.id, value: true }).then(
              () => {
                this.isConjunto = true;
                this.SET_LOADING_OPACITY(false);
                this.SET_LOADING(false);
              }
            );
          } else if (payload.action === -1) {
            this.isConjunto = false;
          }
          break;
        case "alertObjeto":
          if (payload.action === 1) {
            this.SET_LOADING_TEXT("Cargando...");
            this.SET_LOADING_OPACITY(true);
            this.SET_LOADING(true);
            this.setCompuesto({ id: this.$route.params.id, value: false }).then(
              () => {
                this.isConjunto = false;
                this.SET_LOADING_OPACITY(false);
                this.SET_LOADING(false);
              }
            );
          } else if (payload.action === -1) {
            this.isConjunto = true;
          }
          break;
        case "addNewObject":
          if (payload.action === 1) {
            this.SET_LOADING_TEXT("Cargando...");
            this.SET_LOADING_OPACITY(true);
            this.SET_LOADING(true);
            this.createNewComposedObject({
              parent: this.$route.params.id,
              id: this.newObject.id,
              name: this.newObject.name,
            }).then(() => {
              this.createNewObject = false;
              this.newObject.id = "";
              this.newObject.name = "";
              this.SET_LOADING_OPACITY(false);
              this.SET_LOADING(false);
              this.SET_MESSAGE_PARAMETERS({
                isVisible: true,
                type: "success",
                icon: "done",
                title: "Objeto agregado al Conjunto",
                message: "Se ha agregado correctamente el objeto al conjunto",
                buttonAction: "",
                button: "Aceptar",
                respondTo: "successObjectCreate",
                action: 0,
              });
            });
          } else if (payload.action === -1) {
            this.newObject.id = "";
            this.newObject.name = "";
            this.createNewObject = false;
            this.SET_MESSAGE_PARAMETERS({});
          }
          break;
        case "actualizarMedidasObjetoConjunto":
          if (payload.action === 1) {
            this.SET_LOADING_TEXT("Cargando...");
            this.SET_LOADING_OPACITY(true);
            this.SET_LOADING(true);
            this.updateDimensionsOfObjetoConjunto({
              id: this.editObjectId,
              data: this.currentObjetoCompuesto.dimensions,
            }).then(() => {
              this.SET_LOADING_OPACITY(false);
              this.SET_LOADING(false);
              this.SET_MESSAGE_PARAMETERS({
                isVisible: true,
                type: "success",
                icon: "done",
                title: "Dimensiones Actualizadas",
                message: "Se ha actualizado correctamente el objeto.",
                buttonAction: "",
                button: "Aceptar",
                respondTo: "successUpdateObjectDimensions",
                action: 0,
              });
            });
          } else if (payload.action === -1) {
            this.currentObjetoCompuesto = this.objectCopy;
            this.SET_MESSAGE_PARAMETERS({});
          }
          break;
        case "actualizarCaracteristicasObjetoConjunto":
          if (payload.action === 1) {
            this.SET_LOADING_TEXT("Cargando...");
            this.SET_LOADING_OPACITY(true);
            this.SET_LOADING(true);
            this.updateFeaturesOfObjetoConjunto({
              id: this.editObjectId,
              data: {
                name: this.currentObjetoCompuesto.name,
                techniques: this.currentObjetoCompuesto.techniques,
                materials: this.currentObjetoCompuesto.materials,
                description: this.currentObjetoCompuesto.description,
              },
            }).then(() => {
              this.SET_LOADING_OPACITY(false);
              this.SET_LOADING(false);
              this.SET_MESSAGE_PARAMETERS({
                isVisible: true,
                type: "success",
                icon: "done",
                title: "Caracter&iacute;sticas Actualizadas",
                message: "Se ha actualizado correctamente el objeto.",
                buttonAction: "",
                button: "Aceptar",
                respondTo: "successUpdateObjectFeatures",
                action: 0,
              });
            });
          } else if (payload.action === -1) {
            this.currentObjetoCompuesto = this.objectCopy;
            this.SET_MESSAGE_PARAMETERS({});
          }
          break;
        case "eliminarFotoObjetoConjunto":
          if (payload.action === 1) {
            this.SET_LOADING_TEXT("Cargando...");
            this.SET_LOADING_OPACITY(true);
            this.SET_LOADING(true);
            if (this.eliminarVista === "Referencia") {
              this.updateReferenceImageConjunto({
                id: this.editObjectId,
                data: "",
              }).then(() => {
                this.eliminarVista = "";
                this.SET_LOADING_OPACITY(false);
                this.SET_LOADING(false);
                this.SET_MESSAGE_PARAMETERS({
                  isVisible: true,
                  type: "success",
                  icon: "done",
                  title: "Fotograf&iacute;a Eliminada",
                  message: "Se ha eliminado la fotograf&iaucte; correctamente.",
                  buttonAction: "",
                  button: "Aceptar",
                  respondTo: "successRemoveObjectPicture",
                  action: 0,
                });
              });
            } else {
              let arr = this.currentObjetoCompuesto.photos;
              let pos = arr.findIndex(
                (item) => item.view === this.eliminarVista
              );
              arr.splice(pos, 1);
              this.updateImagesConjunto({
                id: this.editObjectId,
                data: arr,
              }).then(() => {
                this.eliminarVista = "";
                this.SET_LOADING_OPACITY(false);
                this.SET_LOADING(false);
                this.SET_MESSAGE_PARAMETERS({
                  isVisible: true,
                  type: "success",
                  icon: "done",
                  title: "Fotograf&iacute;a Eliminada",
                  message: "Se ha eliminado la fotograf&iaucte; correctamente.",
                  buttonAction: "",
                  button: "Aceptar",
                  respondTo: "successRemoveObjectPicture",
                  action: 0,
                });
              });
            }
          } else if (payload.action === -1) {
            this.currentObjetoCompuesto = this.objectCopy;
            this.SET_MESSAGE_PARAMETERS({});
          }
          break;
        case "eliminarFotoObjetoPrincipal":
          if (payload.action === 1) {
            this.SET_LOADING_TEXT("Cargando...");
            this.SET_LOADING_OPACITY(true);
            this.SET_LOADING(true);
            if (this.eliminarVista === "Referencia") {
              this.removeReferenciaFromItem(this.$route.params.id).then(() => {
                this.eliminarVista = "";
                this.SET_LOADING_OPACITY(false);
                this.SET_LOADING(false);
                this.SET_MESSAGE_PARAMETERS({
                  isVisible: true,
                  type: "success",
                  icon: "done",
                  title: "Fotograf&iacute;a Eliminada",
                  message: "Se ha eliminado la fotograf&iaucte; correctamente.",
                  buttonAction: "",
                  button: "Aceptar",
                  respondTo: "successEliminarObjectPicture",
                  action: 0,
                });
              });
            } else {
              console.log(this.$route.params.id, this.eliminarVista);
              this.currentItem.images.splice(this.eliminarVista, 1);
              this.removePhotoFromItem({
                id: this.$route.params.id,
                list: this.currentItem.images,
              }).then(() => {
                this.eliminarVista = "";
                this.SET_LOADING_OPACITY(false);
                this.SET_LOADING(false);
                this.SET_MESSAGE_PARAMETERS({
                  isVisible: true,
                  type: "success",
                  icon: "done",
                  title: "Fotograf&iacute;a Eliminada",
                  message: "Se ha eliminado la fotograf&iaucte; correctamente.",
                  buttonAction: "",
                  button: "Aceptar",
                  respondTo: "successRemoveObjectPicture",
                  action: 0,
                });
              });
            }
          }
          break;
        default:
          break;
      }
    },
    getSites() {
      if (
        this.currentItem !== null &&
        this.currentItem.exhibition !== this.location.value
      ) {
        this.location.site = "none";
      }
    },
    currentItem(newValue) {
      this.receiveObject(newValue);
    },
    boxes() {},
    progress(payload) {
      console.log(payload);
    },
    uploadTask() {
      var p = new Promise((resolve, reject) => {
        this.uploadTask.on(
          "state_changed",
          function (snapshot) {
            //this.progress =
            //  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            //console.log(this.progress)
            //this.SET_LOADING_TEXT("Cargando: " + this.progress + "%");
            switch (snapshot.state) {
              case "paused": // or 'paused'
                console.log("Upload is paused");
                break;
              case "running": // or 'running'
                console.log("Upload is running");
                break;
            }
          },
          function (error) {
            // Handle unsuccessful uploads
            console.log(error);
            reject(error);
          },
          function () {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            //this.SET_LOADING_TEXT("");
            resolve(true);
          }
        );
      });
      p.then((val) => {
        if (val) {
          this.SET_LOADING(false);
        }
      });
    },
    view(payload) {
      if (payload === "Otro") {
        this.addImageLabel = true;
      }
    },
  },
  mounted() {
    this.receiveObject(this.currentItem);
    this.views = [];
    this.views.push({ name: "none", text: "Seleccione una cara del objeto" });
    this.views.push({ name: "Referencia", text: "Imagen de Referencia" });
    this.views.push({ name: "Frontal", text: "Frontal" });
    this.views.push({ name: "Posterior", text: "Posterior" });
    this.views.push({ name: "Lateral derecha", text: "Lateral derecha" });
    this.views.push({ name: "Lateral izquierda", text: "Lateral Izquierda" });
    this.views.push({ name: "Superior", text: "Superior" });
    this.views.push({ name: "Inferior", text: "Inferior" });
    this.views.push({ name: "Tapa", text: "Tapa - Libros y Documentos" });
    this.views.push({
      name: "Contratapa",
      text: "Contratapa - Libros y Documentos",
    });
    this.views.push({ name: "Lomo", text: "Lomo - Libros y Documentos" });
    this.views.push({
      name: "Interior",
      text: "Interior - Libros y Documentos",
    });
    this.views.push({ name: "Detalle", text: "Detalles" });
    this.views.push({
      name: "Referencia de Dimensiones",
      text: "Referencia de Dimensiones",
    });
    this.views.push({ name: "Otro", text: "Otro..." });
  },
  methods: {
    ...mapMutations([
      "SET_CURRENT_OBJECT_ID",
      "SET_MESSAGE_PARAMETERS",
      "SET_LOADING",
      "SET_LOADING_OPACITY",
      "ADD_KEYWORD",
      "ADD_BOX",
      "SET_LOADING_TEXT",
      "SET_GALLERY",
      "SET_GALLERY_INDEX",
      "SET_GALLERY_ITEMS",
    ]),
    ...mapActions([
      "setCompuesto",
      "createNewComposedObject",
      "updateDimensionsOfObjetoConjunto",
      "updateFeaturesOfObjetoConjunto",
      "updateReferenceImageConjunto",
      "updateImagesConjunto",
      "removePhotoFromItem",
      "removeReferenciaFromItem",
      "updateFeatures",
      "updateDimensions",
      "updateOrigin",
      "updateConservation",
      "addRelatedObject",
      "updateRelatedObjectList",
      "removeRelatedObjectLink",
      "updateRelatedObjectRelation",
      "updateLocation",
      "updateDescriptions",
    ]),
    addMeasureToObjetoCompuesto() {
      let el = this.currentObjetoCompuesto.dimensions.find(
        (item) => item.dimension === this.temporalData.dimensiones.dimension
      );
      if (el) {
        el.value =
          this.temporalData.dimensiones.valor +
          " " +
          this.temporalData.dimensiones.unidad;
      } else {
        this.currentObjetoCompuesto.dimensions.push({
          dimension: this.temporalData.dimensiones.dimension,
          value:
            this.temporalData.dimensiones.valor +
            " " +
            this.temporalData.dimensiones.unidad,
        });
      }
      this.temporalData.dimensiones.dimension = "none";
      this.temporalData.dimensiones.valor = 0;
      this.temporalData.dimensiones.unidad = "cm";
    },
    addMaterialToObjetoCompuesto() {
      let el = this.currentObjetoCompuesto.materials.find(
        (item) => item === this.temporalData.materialId
      );
      if (el) {
        el.value =
          this.temporalData.dimensiones.valor +
          " " +
          this.temporalData.dimensiones.unidad;
      } else {
        this.currentObjetoCompuesto.dimensions.push({
          dimension: this.temporalData.dimensiones.dimension,
          value:
            this.temporalData.dimensiones.valor +
            " " +
            this.temporalData.dimensiones.unidad,
        });
      }
      this.temporalData.dimensiones.dimension = "none";
      this.temporalData.dimensiones.valor = 0;
      this.temporalData.dimensiones.unidad = "cm";
    },
    DESPLEGAR_MENSAJE_CONFIRMACION(payload) {
      this.SET_MESSAGE_PARAMETERS({
        isVisible: true,
        type: "error",
        icon: payload.icon,
        title: payload.title,
        message: payload.msg,
        buttonAction: "Aceptar",
        button: "Cancelar",
        respondTo: payload.response,
        action: 0,
      });
    },

    DESPLEGAR_MENSAJE_ADVERTENCIA(payload) {
      this.SET_MESSAGE_PARAMETERS({
        isVisible: true,
        type: "error",
        icon: payload.icon,
        title: payload.title,
        message: payload.msg,
        buttonAction: "Aceptar",
        button: "Cancelar",
        respondTo: payload.response,
        action: 0,
      });
    },
    addNewObjectConfirmation() {
      this.SET_MESSAGE_PARAMETERS({
        isVisible: true,
        type: "alert",
        icon: "post_add",
        title: "Agregar nuevo Objeto al Conjunto",
        message:
          "&iquest;Confirmas que deseas agregar un nuevo objeto al conjunto?",
        buttonAction: "Confirmar",
        button: "Cancelar",
        respondTo: "addNewObject",
        action: 0,
      });
    },
    toogleCompuesto() {
      console.log("entra");
      if (!this.currentItem.compuesto) {
        console.log("es falso");
        this.SET_MESSAGE_PARAMETERS({
          isVisible: true,
          type: "alert",
          icon: "warning",
          title: "Configurar Ficha como Objeto Conjunto",
          message:
            "Se configurar&aacute; esta ficha como Objeto Conjunto, una vez que se agreguen objetos no se podr&aacute; deshacer el cambio, &iquest;Confirma que desea cambiar la configuraci&oacute;n?",
          buttonAction: "Confirmar",
          button: "Cancelar",
          respondTo: "alertConjunto",
          action: 0,
        });
      } else if (
        this.currentItem.compuesto &&
        this.currentItem.conjuntoObjetos.length === 0
      ) {
        console.log("es verdadero");
        this.SET_MESSAGE_PARAMETERS({
          isVisible: true,
          type: "alert",
          icon: "warning",
          title: "Configurar Ficha como Objeto",
          message:
            "Se configurar&aacute; esta ficha como Objeto, no se podr&aacute;n agregar objetos a esta ficha, &iquest;Confirma que desea cambiar la configuraci&oacute;n?",
          buttonAction: "Confirmar",
          button: "Cancelar",
          respondTo: "alertObjeto",
          action: 0,
        });
      } else {
        console.log("es verdadero con objeto");
        this.SET_MESSAGE_PARAMETERS({
          isVisible: true,
          type: "error",
          icon: "error",
          title: "No es posible cambiar la configuraci&oacute;n de la ficha",
          message:
            "La ficha ya contiene objetos asociados al conjunto, por lo que no se puede configurar como objeto.",
          buttonAction: "",
          button: "Aceptar",
          respondTo: "alertConjuntoNegativo",
          action: 0,
        });
      }
    },
    openGallery(id, payload) {
      let arr = [];
      let pos = 0;
      let el = {};
      let i = 0;
      if (id === "parent") {
        if (this.currentItem.referenceImage) {
          arr.push({
            url: this.currentItem.referenceImage.url,
            view: "Referencia",
            position: i,
          });
          i++;
        }
        this.currentItem.images.forEach((item) => {
          arr.push({ ...item, position: i });
          i++;
        });
      } else {
        let obj = this.listaObjetos.find((item) => item.id === id);
        if (obj) {
          let i = 0;
          if (obj.data.mainImage) {
            arr.push({
              url: obj.data.mainImage.url,
              view: "Referencia",
              position: i,
            });
            i++;
          }
          obj.data.photos.forEach((item) => {
            arr.push({ ...item, position: i });
            i++;
          });
        }
      }
      pos = arr.findIndex((item) => item.position === payload);
      el = arr[pos];
      arr.splice(pos, 1);
      arr.splice(0, 0, el);
      this.SET_GALLERY_ITEMS(arr);
      this.SET_GALLERY_INDEX(payload);
      this.SET_GALLERY(true);
    },
    receiveObject(fbItem) {
      if (fbItem !== null && fbItem !== undefined) {
        if (fbItem.compuesto) {
          this.isConjunto = true;
        } else {
          this.isConjunto = false;
        }
        if (
          fbItem.location !== undefined &&
          fbItem.location !== "" &&
          fbItem.exhibition === "Si"
        ) {
          fbItem.location.forEach((item) => {
            if (item.label === "Sitio") {
              this.location.site = item.value;
            } else if (item.label === "Habitacion") {
              this.location.room = item.value;
            } else {
              this.location.box = item.value;
            }
          });
        }
        if (fbItem.identifier !== undefined) {
          this.idLocation = fbItem.identifier;
        }
        if (fbItem.keyWords !== undefined) {
          this.keyWords = fbItem.keyWords;
        }
        if (fbItem.materials !== undefined) {
          this.currentMaterials = fbItem.materials;
        }
        if (fbItem.techniques !== undefined) {
          this.currentTechniques = fbItem.techniques;
        }
        if (fbItem.measurements !== undefined) {
          this.measurements = fbItem.measurements;
        }
        if (fbItem.origin !== undefined) {
          this.originPlace = fbItem.origin;
        }
        if (fbItem.donant !== undefined) {
          this.donantName = fbItem.donant;
        }
        if (fbItem.found !== undefined && fbItem.found !== "") {
          fbItem.found.forEach((item) => {
            if (item.label === "sitio") {
              this.foundPlace.site = item.value;
            } else {
              this.foundPlace.room = item.value;
            }
          });
        }
        if (fbItem.others !== undefined) {
          this.notes = fbItem.others;
        }
        if (fbItem.conservation !== undefined) {
          this.conservation = {
            value: fbItem.conservation.requires,
            observations: fbItem.conservation.comments,
            state: fbItem.conservationState,
          };
        }
        if (fbItem.pieceStatus !== undefined) {
          this.pieceStatus = {
            value: fbItem.pieceStatus.status,
            comments: fbItem.pieceStatus.comments,
          };
        }
        if (fbItem.restoration !== undefined) {
          this.restoration = fbItem.restoration;
        }
      }
    },
    setResult(payload) {
      if (payload.doc !== null && payload.doc != undefined) {
        if (payload.text === payload.doc.name) {
          this.newKeyWord = payload.doc;
        } else {
          this.newKeyWord = { id: "", name: payload.text, definition: "" };
        }
      } else {
        this.newKeyWord = { id: "", name: payload.text, definition: "" };
      }
    },
    setRelatedObject(payload) {
      if (payload.doc === null || payload.doc === undefined) {
        this.newRelatedObject = {
          text: payload.text,
          id: "",
        };
      } else {
        if (payload.text === payload.doc.name) {
          this.newRelatedObject = {
            text: payload.text,
            id: payload.doc.id,
          };
        } else {
          this.newRelatedObject = {
            text: payload.text,
            id: "",
          };
        }
      }
    },
    onPickFile: function () {
      this.$refs.fileInput.click();
    },
    onFilePicked: function (event) {
      const file = event.target.files;
      let fileName = file[0].name;
      if (fileName.lastIndexOf(".") <= 0) {
        return alert("Ingrese una imagen valida");
      }
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        this.currentImage.preview = fileReader.result;
      });
      this.currentImage.name = fileName;
      fileReader.readAsDataURL(file[0]);
      this.currentImage.file = file[0];
      this.previewDisplayed = true;
    },
    getObjeto(id) {
      let el = this.allItems.find((item) => item.id === id);
      if (el) {
        return el;
      } else {
        return null;
      }
    },
    addNewRelatedObject() {
      this.currentItem.relatedObjects.push({
        id: this.newRelatedObject.id,
        relation: this.newRelation,
      });
      this.SET_LOADING(true);
      this.SET_LOADING_TEXT("Cargando...");
      this.addRelatedObject({
        id: this.currentItem.id,
        list: this.currentItem.relatedObjects,
        newItem: this.newRelatedObject.id,
        newRelation: this.newRelation,
      }).then(() => {
        this.SET_LOADING(false);
        this.newRelatedObject = { text: "", id: "" };
        this.newRelation = "";
      });
    },
    removeRelatedObject(payload) {
      this.SET_LOADING(true);
      this.SET_LOADING_TEXT("Cargando...");
      this.removeRelatedObjectLink({
        id: this.currentItem.id,
        rId: payload,
      }).then(() => {
        this.SET_LOADING(false);
        this.newRelatedObject = { text: "", id: "" };
        this.newRelation = "";
        this.editRelation = "";
      });
    },
    updateRelatedObject(payload) {
      this.SET_LOADING(true);
      this.SET_LOADING_TEXT("Cargando...");
      this.updateRelatedObjectRelation({
        id: this.currentItem.id,
        rId: payload.id,
        relation: payload.relation,
      }).then(() => {
        this.SET_LOADING(false);
        this.newRelatedObject = { text: "", id: "" };
        this.newRelation = "";
        this.editRelation = "";
      });
    },

    onFilePickedConjunto: function (event) {
      const file = event.target.files;
      let fileName = file[0].name;
      if (fileName.lastIndexOf(".") <= 0) {
        return alert("Ingrese una imagen valida");
      }
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        this.currentImage.preview = fileReader.result;
      });
      this.currentImage.name = fileName;
      fileReader.readAsDataURL(file[0]);
      this.currentImage.file = file[0];
      this.previewConjunto = true;
    },
    removeImage(payload) {
      this.imageRemove = payload;
      this.SET_MESSAGE_PARAMETERS({
        isVisible: true,
        type: "alert",
        icon: "warning",
        title: "Eliminar Fotograf&iacute;a",
        message:
          "&iquest;Confirma que desea eliminar esta fotograf&iacute;a del objeto?",
        buttonAction: "Eliminar",
        button: "Cancelar",
        respondTo: "alertRemoveImage",
        action: 0,
      });
    },
    addImage() {
      if (
        this.currentItem.images.find(
          (doc) => doc.name === this.currentImage.name
        ) !== undefined
      ) {
        this.SET_MESSAGE_PARAMETERS({
          isVisible: true,
          type: "error",
          icon: "error",
          title: "Foto Ingresada",
          message:
            "Esta foto ya se ha agregado a este objeto, seleccione otra.",
          buttonAction: "",
          button: "Aceptar",
          respondTo: "errorPhotoExists",
          action: 0,
        });
      } else if (
        this.currentItem.images.find((doc) => doc.view === this.view) !==
        undefined
      ) {
        this.SET_MESSAGE_PARAMETERS({
          isVisible: true,
          type: "alert",
          icon: "warning",
          title: "Vista Ingresada",
          message:
            "Ya se ha agregado una fotograf&iacute;a a la vista: " +
            this.view +
            " &iquest;desea reemplazarla por la foto nueva?",
          buttonAction: "Actualizar",
          button: "Cancelar",
          respondTo: "errorViewExists",
          action: 0,
        });
      } else {
        let newImage = {
          view: this.view,
          name: this.currentImage.name,
          preview: this.currentImage.preview,
          file: this.currentImage.file,
        };
        this.uploadSingleImage(newImage);
        this.currentImage = {
          name: "",
          preview: "",
          file: null,
        };
      }

      this.previewDisplayed = false;
    },
    addImageConjunto() {
      if (
        this.currentObjetoCompuesto.photos.find(
          (doc) => doc.name === this.currentImage.name
        ) !== undefined
      ) {
        this.SET_MESSAGE_PARAMETERS({
          isVisible: true,
          type: "error",
          icon: "error",
          title: "Foto Ingresada",
          message:
            "Esta foto ya se ha agregado a este objeto, seleccione otra.",
          buttonAction: "",
          button: "Aceptar",
          respondTo: "errorPhotoExistsCojunto",
          action: 0,
        });
      } else if (
        this.currentObjetoCompuesto.photos.find(
          (doc) => doc.view === this.view
        ) !== undefined
      ) {
        this.SET_MESSAGE_PARAMETERS({
          isVisible: true,
          type: "alert",
          icon: "warning",
          title: "Vista Ingresada",
          message:
            "Ya se ha agregado una fotograf&iacute;a a la vista: " +
            this.view +
            " &iquest;desea reemplazarla por la foto nueva?",
          buttonAction: "Actualizar",
          button: "Cancelar",
          respondTo: "errorViewExistsConjunto",
          action: 0,
        });
      } else {
        let newImage = {
          view: this.view,
          name: this.currentImage.name,
          preview: this.currentImage.preview,
          file: this.currentImage.file,
        };
        this.uploadSingleImageCompuesto(newImage);
        this.currentImage = {
          name: "",
          preview: "",
          file: null,
        };
      }

      this.previewConjunto = false;
    },
    clearImage() {
      this.previewDisplayed = false;
      this.previewConjunto = false;
      this.currentImage = {
        name: "",
        preview: "",
        file: null,
      };
    },
    setTechniques(payload) {
      if (payload.doc !== null && payload.doc != undefined) {
        if (payload.text === payload.doc.name) {
          this.newTechnique = payload.doc;
        } else {
          this.newTechnique = { id: "", name: payload.text, definition: "" };
        }
      } else {
        this.newTechnique = { id: "", name: payload.text, definition: "" };
      }
    },

    setTechniquesConjunto(payload) {
      if (payload.doc !== null && payload.doc != undefined) {
        if (payload.text === payload.doc.name) {
          this.temporalData.newTechnique = payload.doc;
        } else {
          this.temporalData.newTechnique = {
            id: "",
            name: payload.text,
            definition: "",
          };
        }
      } else {
        this.temporalData.newTechnique = {
          id: "",
          name: payload.text,
          definition: "",
        };
      }
    },
    setMaterials(payload) {
      if (payload.doc !== null && payload.doc != undefined) {
        if (payload.text === payload.doc.name) {
          this.newMaterial = payload.doc;
        } else {
          this.newMaterial = { id: "", name: payload.text, definition: "" };
        }
      } else {
        this.newMaterial = { id: "", name: payload.text, definition: "" };
      }
    },
    setMaterialsConjunto(payload) {
      if (payload.doc !== null && payload.doc != undefined) {
        if (payload.text === payload.doc.name) {
          this.temporalData.newMaterial = payload.doc;
        } else {
          this.temporalData.newMaterial = {
            id: "",
            name: payload.text,
            definition: "",
          };
        }
      } else {
        this.temporalData.newMaterial = {
          id: "",
          name: payload.text,
          definition: "",
        };
      }
    },
    addNewKeyWord() {
      if (this.newKeyWord.id !== "") {
        if (
          this.currentItem.keyWords.find(
            (elem) => elem.id === this.newKeyWord.id
          ) === undefined
        ) {
          this.currentItem.keyWords.push(this.newKeyWord.id);
          this.newKeyWord = { id: "", name: "" };
        }
      } else {
        this.SET_MESSAGE_PARAMETERS({
          isVisible: true,
          type: "alert",
          icon: "warning",
          title: "Palabra no Existe",
          message:
            "La palabra ingresada no existe en la base de datos, &iquest;desea agregarla?",
          buttonAction: "Agregar",
          button: "Cancelar",
          respondTo: "addKeyWord",
          action: 0,
        });
      }
    },
    addNewTechnique() {
      if (this.newTechnique.id !== "") {
        if (
          this.currentItem.techniques.find(
            (elem) => elem.id === this.newTechnique.id
          ) === undefined
        ) {
          this.currentItem.techniques.push(this.newTechnique.id);
          this.newTechnique = { id: "", name: "", definition: "" };
        }
      } else {
        this.SET_MESSAGE_PARAMETERS({
          isVisible: true,
          type: "error",
          icon: "error",
          title: "T&eacute;cnica no Existe",
          message:
            "La t&eacute;cnica ingresada no existe en la base de datos, por favor seleccione una del listado.",
          buttonAction: "",
          button: "Aceptar",
          respondTo: "errorTechnique",
          action: 0,
        });
      }
    },
    addNewTechniqueConjunto() {
      if (this.temporalData.newTechnique.id !== "") {
        if (
          this.currentObjetoCompuesto.techniques.find(
            (elem) => elem.id === this.temporalData.newTechnique.id
          ) === undefined
        ) {
          this.currentObjetoCompuesto.techniques.push(
            this.temporalData.newTechnique.id
          );
          this.temporalData.newTechnique = { id: "", name: "", definition: "" };
        }
      } else {
        this.SET_MESSAGE_PARAMETERS({
          isVisible: true,
          type: "error",
          icon: "error",
          title: "T&eacute;cnica no Existe",
          message:
            "La t&eacute;cnica ingresada no existe en la base de datos, por favor seleccione una del listado.",
          buttonAction: "",
          button: "Aceptar",
          respondTo: "errorTechnique",
          action: 0,
        });
      }
    },
    addNewMaterial() {
      if (this.newMaterial.id !== "") {
        if (
          this.currentItem.materials.find(
            (elem) => elem.id === this.newMaterial.id
          ) === undefined
        ) {
          this.currentItem.materials.push(this.newMaterial.id);
          this.newMaterial = { id: "", name: "", definition: "" };
        }
      } else {
        this.SET_MESSAGE_PARAMETERS({
          isVisible: true,
          type: "error",
          icon: "error",
          title: "El Material no Existe",
          message:
            "El material ingresado no existe en la base de datos, por favor seleccione una del listado.",
          buttonAction: "",
          button: "Aceptar",
          respondTo: "errorMaterial",
          action: 0,
        });
      }
    },
    addNewMaterialConjunto() {
      if (this.temporalData.newMaterial.id !== "") {
        if (
          this.currentObjetoCompuesto.materials.find(
            (elem) => elem.id === this.temporalData.newMaterial.id
          ) === undefined
        ) {
          this.currentObjetoCompuesto.materials.push(
            this.temporalData.newMaterial.id
          );
          this.temporalData.newMaterial = { id: "", name: "", definition: "" };
        }
      } else {
        this.SET_MESSAGE_PARAMETERS({
          isVisible: true,
          type: "error",
          icon: "error",
          title: "El Material no Existe",
          message:
            "El material ingresado no existe en la base de datos, por favor seleccione una del listado.",
          buttonAction: "",
          button: "Aceptar",
          respondTo: "errorMaterial",
          action: 0,
        });
      }
    },
    async createNewKeyWord() {
      try {
        this.SET_LOADING(true);
        this.SET_LOADING_OPACITY(true);
        console.log(this.newKeyWord);
        let ref = await addDoc(collection(db, "PalabrasClave"), {
          name: this.newKeyWord.name,
        });

        this.newKeyWord.id = ref.id;
        this.keyWords.push(this.newKeyWord.id);
        this.SET_LOADING(false);
        this.SET_MESSAGE_PARAMETERS({
          isVisible: true,
          type: "success",
          icon: "done",
          title: "Palabra Agregada",
          message: "La palabra ingresada ha sido agregada exitosamente",
          buttonAction: "",
          button: "Aceptar",
          respondTo: "success",
          action: 0,
        });
      } catch (e) {
        this.SET_LOADING(false);
        this.SET_MESSAGE_PARAMETERS({
          isVisible: true,
          type: "error",
          icon: "error",
          title: "Algo Fall&oacute;",
          message:
            "Ocurri&oacute; un error y La palabra no pudo se pudo agregar, error: <br>" +
            e,
          buttonAction: "",
          button: "Aceptar",
          respondTo: "error",
          action: 0,
        });
      }
    },
    addMeasure() {
      if (
        this.currentItem.measurements.find(
          (doc) => doc.dimension === this.dimension
        ) === undefined
      ) {
        this.currentItem.measurements.push({
          dimension: this.dimension,
          measure: this.measure + " " + this.unit,
        });
      } else {
        this.SET_MESSAGE_PARAMETERS({
          isVisible: true,
          type: "alert",
          icon: "warning",
          title: "La Medida ya Existe",
          message:
            "Esta medida ya ha sido registrada, &iquest;desea reemplazarla por el valor actual?",
          buttonAction: "Aceptar",
          button: "Cancelar",
          respondTo: "alertMeasurement",
          action: 0,
        });
      }
    },
    async uploadSingleImage(payload) {
      try {
        this.SET_LOADING(true);
        if (payload.name) {
          var ext = payload.name.slice(payload.name.lastIndexOf("."));
          let refImg = ref(
            storage,
            payload.view + "/" + this.currentItem.id + ext
          );
          this.uploadTask = uploadBytesResumable(refImg, payload.file);

          this.uploadTask.on(
            "state_changed",
            (snap) => {
              var progress = Math.trunc(
                (snap.bytesTransferred / snap.totalBytes) * 100
              );
              this.SET_LOADING_TEXT("Cargando " + progress + "%");
              console.log("Upload is " + progress + "% done");
            },
            (error) => {
              this.SET_MESSAGE_PARAMETERS({
                isVisible: true,
                type: "error",
                icon: "error",
                title: "Ocurri&oacute; un error al cargar la imagen",
                message:
                  "Ocurri&oacute; un error al subir la imagen al servidor, por favor intentelo m&aacute;s tarde.",
                buttonAction: "",
                button: "Aceptar",
                respondTo: "error",
                action: 0,
              });
              this.SET_LOADING(false);
              console.log(error);
            },
            () => {
              getDownloadURL(this.uploadTask.snapshot.ref).then((url) => {
                console.log(url);
                if (payload.view === "Referencia") {
                  updateDoc(doc(db, "ListaObjetos", this.currentItem.id), {
                    referenceImage: {
                      url: url,
                      name: payload.name,
                    },
                  });
                  this.currentItem.referenceImage = {
                    url: url,
                    name: payload.name,
                  };
                } else {
                  let newImg = {
                    preview: url,
                    url: url,
                    view: payload.view,
                  };
                  if (this.currentItem.images.length === 0) {
                    this.currentItem.images.push(newImg);
                  } else {
                    let position = 0;
                    this.currentItem.images.forEach((element) => {
                      if (element.view.localeCompare(newImg.view) === -1) {
                        position++;
                      }
                    });
                    this.currentItem.images.splice(position, 0, newImg);
                    updateDoc(doc(db, "ListaObjetos", this.currentItem.id), {
                      images: this.currentItem.images,
                    });
                  }
                }
                this.SET_LOADING(false);
                this.SET_LOADING_TEXT("Cargando...");
                this.previewDisplayed = false;
                this.SET_MESSAGE_PARAMETERS({
                  isVisible: true,
                  type: "success",
                  icon: "done",
                  title: "Foto Agregada",
                  message: "Se ha agregado correctamente la fotograf&iacute;a.",
                  buttonAction: "",
                  button: "Aceptar",
                  respondTo: "successUpdateObject",
                  action: 0,
                });
              });
            }
          );
        }
      } catch (error) {
        this.SET_MESSAGE_PARAMETERS({
          isVisible: true,
          type: "error",
          icon: "error",
          title: "Ocurri&oacute; un error al cargar la imagen",
          message:
            "Ocurri&oacute; un error al subir la imagen al servidor, por favor intentelo m&aacute;s tarde.",
          buttonAction: "",
          button: "Aceptar",
          respondTo: "error",
          action: 0,
        });
        this.SET_LOADING(false);
        console.log(error);
      }
    },
    uploadSingleImageCompuesto(payload) {
      try {
        this.SET_LOADING(true);
        if (payload.name) {
          var ext = payload.name.slice(payload.name.lastIndexOf("."));
          let refImg = ref(
            storage,

            "/Objetos Compuestos/" +
              payload.view +
              "/" +
              this.currentItem.id +
              "/" +
              this.editObjectId +
              ext
          );
          this.uploadTask = uploadBytesResumable(refImg, payload.file);
          this.uploadTask.on(
            "state_changed",
            (snap) => {
              var progress = Math.trunc(
                (snap.bytesTransferred / snap.totalBytes) * 100
              );
              this.SET_LOADING_TEXT("Cargando " + progress + "%");
              console.log("Upload is " + progress + "% done");
            },
            (error) => {
              console.log(error);
            },
            () => {
              getDownloadURL(this.uploadTask.snapshot.ref).then((url) => {
                if (payload.view === "Referencia") {
                  updateDoc(doc(db, "ObjetosCompuestos", this.editObjectId), {
                    "data.mainImage": {
                      url: url,
                      name: payload.name,
                    },
                  });
                  this.currentObjetoCompuesto.mainImage = {
                    preview: url,
                    url: url,
                  };
                } else {
                  let newImg = {
                    preview: url,
                    url: url,
                    view: payload.view,
                  };
                  if (this.currentObjetoCompuesto.photos.length === 0) {
                    this.currentObjetoCompuesto.photos.push(newImg);
                  } else {
                    let position = 0;
                    this.currentObjetoCompuesto.photos.forEach((element) => {
                      if (element.view.localeCompare(newImg.view) === -1) {
                        position++;
                      }
                    });
                    this.currentObjetoCompuesto.photos.splice(
                      position,
                      0,
                      newImg
                    );
                    updateDoc(doc(db, "ObjetosCompuestos", this.editObjectId), {
                      "data.photos": this.currentObjetoCompuesto.photos,
                    });
                  }
                  this.previewConjunto = false;
                  this.SET_LOADING(false);
                  this.SET_LOADING_TEXT("Cargando...");
                  this.SET_MESSAGE_PARAMETERS({
                    isVisible: true,
                    type: "success",
                    icon: "done",
                    title: "Foto Agregada",
                    message:
                      "Se ha agregado correctamente la fotograf&iacute;a al Objeto.",
                    buttonAction: "",
                    button: "Aceptar",
                    respondTo: "successUpdateObject",
                    action: 0,
                  });
                }
              });
            }
          );
        }
      } catch (error) {
        this.SET_MESSAGE_PARAMETERS({
          isVisible: true,
          type: "error",
          icon: "error",
          title: "Ocurri&oacute; un error al cargar la imagen",
          message:
            "Ocurri&oacute; un error al subir la imagen al servidor, por favor intentelo m&aacute;s tarde.",
          buttonAction: "",
          button: "Aceptar",
          respondTo: "error",
          action: 0,
        });
        this.SET_LOADING(false);
        console.log(error);
      }
    },
    addImageView() {
      let idx = this.views.findIndex(
        (item) => item.name.toUpperCase() === this.newView.toUpperCase()
      );
      if (idx < 0) {
        this.views.splice(this.views.length - 1, 0, {
          name: this.newView,
          text: this.newView,
        });
        this.view = this.newView;
        this.newView = "";
        this.SET_MESSAGE_PARAMETERS({
          isVisible: true,
          type: "success",
          icon: "done",
          title: "Vista Agregada",
          message: "Nueva vista de imagen agregada al objeto",
          buttonAction: "",
          button: "Aceptar",
          respondTo: "",
          action: 0,
        });
        this.addImageLabel = false;
      } else {
        this.SET_MESSAGE_PARAMETERS({
          isVisible: true,
          type: "error",
          icon: "error",
          title: "La Vista ya Existe",
          message: "La vista que desea agregar ya existe.",
          buttonAction: "",
          button: "Aceptar",
          respondTo: "",
          action: 0,
        });
      }
    },
    updateSection1() {
      this.SET_LOADING(true);
      this.SET_LOADING_TEXT("Cargando...");
      this.updateFeatures({
        id: this.currentItem.id,
        data: {
          descripcion: this.currentItem.description,
          palabras: this.currentItem.keyWords,
          materiales: this.currentItem.materials,
          tecnicas: this.currentItem.techniques,
        },
      }).then(() => {
        this.SET_LOADING(false);
        this.SET_LOADING_TEXT("Cargando...");
        this.SET_MESSAGE_PARAMETERS({
          isVisible: true,
          type: "success",
          icon: "done",
          title: "Caracter&iacute;sticas Actualizadas",
          message:
            "Se han actualizado correctamente las caracter&iacute;sticas del objeto.",
          buttonAction: "",
          button: "Aceptar",
          respondTo: "successUpdateObject",
          action: 0,
        });
      });
    },
    updateSection2() {
      this.SET_LOADING(true);
      this.SET_LOADING_TEXT("Cargando...");
      this.updateDimensions({
        id: this.currentItem.id,
        data: this.currentItem.measurements,
      }).then(() => {
        this.SET_LOADING(false);
        this.SET_LOADING_TEXT("Cargando...");
        this.SET_MESSAGE_PARAMETERS({
          isVisible: true,
          type: "success",
          icon: "done",
          title: "Medidas Actualizadas",
          message: "Se han actualizado correctamente las medidas del objeto.",
          buttonAction: "",
          button: "Aceptar",
          respondTo: "successUpdateObject",
          action: 0,
        });
      });
    },
    updateSection4() {
      this.SET_LOADING(true);
      this.SET_LOADING_TEXT("Cargando...");
      let found = [
        { label: "sitio", value: this.foundPlace.site },
        { label: "hab", value: this.foundPlace.room },
      ];
      this.updateOrigin({
        id: this.currentItem.id,
        donante: this.currentItem.donant,
        origen: this.currentItem.origin,
        encontrado: found,
      }).then(() => {
        this.SET_LOADING(false);
        this.SET_LOADING_TEXT("Cargando...");
        this.SET_MESSAGE_PARAMETERS({
          isVisible: true,
          type: "success",
          icon: "done",
          title: "Origen Actualizado",
          message:
            "Se ha actualizado correctamente la informaci&oacute;n de origen del objeto.",
          buttonAction: "",
          button: "Aceptar",
          respondTo: "successUpdateObject",
          action: 0,
        });
      });
    },

    updateSection5() {
      let data = {
        id: this.currentItem.id,
        conservacion: this.currentItem.conservation,
        estado: this.currentItem.pieceStatus,
        restauracion: this.currentItem.restoration,
        rotulado: this.currentItem.identifier,
      };
      this.SET_LOADING(true);
      this.SET_LOADING_TEXT("Cargando...");
      this.updateConservation(data).then(() => {
        this.SET_LOADING(false);
        this.SET_LOADING_TEXT("Cargando...");
        this.SET_MESSAGE_PARAMETERS({
          isVisible: true,
          type: "success",
          icon: "done",
          title: "Estado de Conservaci&oacute;n Actualizado",
          message:
            "Se ha actualizado correctamente la informaci&oacute;n del estado de conservaci&oacute;n del objeto.",
          buttonAction: "",
          button: "Aceptar",
          respondTo: "successUpdateObject",
          action: 0,
        });
      });
    },
    updateSection6() {
      let ubicacion = "";
      if (this.currentItem.exhibition === "Si") {
        ubicacion = [
          { label: "Sitio", value: this.location.site },
          { label: "Habitacion", value: this.location.room },
        ];
      } else {
        ubicacion = { label: "Caja", value: this.location.box };
      }
      let data = {
        id: this.currentItem.id,
        exhibicion: this.currentItem.exhibition,
        ubicacion: ubicacion,
        comentarios: this.currentItem.locationComments,
        notas: this.currentItem.others,
      };
      this.SET_LOADING(true);
      this.SET_LOADING_TEXT("Cargando...");
      this.updateLocation(data).then(() => {
        this.SET_LOADING(false);
        this.SET_LOADING_TEXT("Cargando...");
        this.SET_MESSAGE_PARAMETERS({
          isVisible: true,
          type: "success",
          icon: "done",
          title: "Ubicaci&oacute;n actualizada",
          message:
            "Se ha actualizado correctamente la ubicaci&oacute del objeto.",
          buttonAction: "",
          button: "Aceptar",
          respondTo: "successUpdateObject",
          action: 0,
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/sass/variables";
@import "./../assets/sass/buttons";
@import "./../assets/sass/ficha";
@import "./../assets/sass/modal";
select {
  background-color: white;
}
.label {
  position: inherit;
  margin-top: 0.75em;
  margin-bottom: 0.5em;
  font-size: 1.2em;
  font-weight: 400;
  box-sizing: border-box;
}
.section {
  display: grid;
  grid-template-columns: 100%;
  row-gap: 0.5em;
}
.modal {
  .nav,
  .form-section {
    box-shadow: none !important;
  }
}
.section,
.modal {
  .nav {
    border-radius: 5px;
    background-color: white;
    padding: 15px;
    width: 100%;
    .nav-items {
      display: grid;
      width: fit-content;
      position: relative;
      margin: 0 auto;
      column-gap: 0.5em;
      width: 100%;
      min-width: 50%;
      &.sub-nav {
        grid-template-columns: repeat(3, 1fr);
      }
      &.main-nav {
        grid-template-columns: repeat(8, 1fr);
      }
      .items {
        text-align: center;
        border-bottom-color: $grey;
        border-bottom-style: solid;
        border-bottom-width: 5px;
        cursor: pointer;
        h4 {
          color: $grey;
        }
        &.selected,
        &.checked {
          border-bottom-color: $theme-d1;
          h4 {
            color: $theme-d1;
          }
        }
        &.selected,
        &:hover {
          h4 {
            font-weight: 700;
          }
        }
      }
      .item-title {
        padding-top: 1em;
        grid-row-start: 2;
        grid-row-end: 3;
        grid-column-start: 1;
        grid-column-end: 9;
        &.sub-nav {
          grid-column-end: 4;
        }
        align-content: center;
        h4 {
          font-weight: 500;
          color: $theme-d1;
          text-align: center;
        }
      }
    }
  }
  .form-section {
    border-radius: 5px;
    background-color: white;
    padding: 15px;
    border-radius: 5px;
    border-color: $theme-d2;
    .split-field {
      display: grid;
      gap: 8px;
      grid-template-columns: 100%;
    }
    .add-element {
      width: 100%;
      display: grid;
      height: 40px;
      grid-template-columns: auto 40px;
      grid-gap: 5px;
      .autocomplete-container {
        overflow: visible;
        margin-top: 5px;
        height: 30px;
      }
    }
    .tag {
      width: auto;
      float: left;
      position: relative;
      background-color: lightgray;
      border: none;
      padding: 3px 5px 3px 10px;
      border-radius: 50px;
      width: fit-content;
      margin-right: 3px;
      margin-bottom: 3px;
      p {
        line-height: 18px;
        vertical-align: middle;
        margin-block-end: 0px;
        margin-block-start: 0px;
        i.material-icons {
          vertical-align: middle;
          background-color: transparent;
          border-radius: 50px;
          padding: 3px;
          margin-left: 10px;
        }
        i:hover {
          background-color: white;
          cursor: pointer;
        }
      }
    }
    .split-60-40 {
      width: calc(100% - 8px);
      display: grid;
      grid-template-columns: 60% 40%;
      gap: 8px;
      &.fullsize {
        grid-template-columns: 100%;
      }
    }
    table {
      width: 100%;
      margin-top: 1em;
      border-spacing: 0;
      border-radius: 5px;
      th {
        background-color: $blue-grey;
        color: white;
        padding: 1em;
        font-weight: 500;
        font-size: 1.17em;
      }
      td {
        border-bottom: 1px solid $blue-grey;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        text-align: center;
        div.icon {
          background-color: $theme-d1;
          border-radius: 5px;
          i {
            padding: 0.5em;
            font-size: 2em;
            color: white;
          }
        }
        &.center {
          div {
            position: relative;
            background-color: white;
          }
        }
        p {
          display: inline;
          i {
            margin-left: 0.25em;
            margin-right: 0.25em;
            &:hover {
              color: $theme-d1;
              cursor: pointer;
            }
          }
        }
        img {
          margin-top: 0.5em;
          margin-bottom: 0.25em;
          max-width: 100px;
          height: auto;
          border-radius: 5px;
        }
      }
    }
    .file {
      display: none;
    }
    .left {
      margin-top: 4.5px;
      position: relative;
      label.label {
        display: inline;
        color: $text-dark;
        line-height: 30px;
        margin-block-end: 0;
        margin-block-start: 0;
        vertical-align: middle;
        top: 0%;
        margin-right: 1em;
      }
    }
  }
  .nav-buttons {
    display: grid;
    grid-template-columns: auto auto;
    grid-auto-rows: auto;
    margin-top: 1em;
    .left {
      float: left;
      grid-row-start: 1;
      grid-row-end: 2;
      grid-column-start: 1;
      grid-column-end: 2;
    }
    .right {
      float: right;
      grid-row-start: 1;
      grid-row-end: 2;
      grid-column-start: 2;
      grid-column-end: 3;
    }
  }
  .inputs {
    label,
    input,
    textarea {
      width: 100%;
    }
  }
  .picture-preview {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    margin: 0px;
    background-color: #22190880;
    z-index: 21;
    div.box {
      overflow-y: auto;
      top: 50%;
      left: 50%;
      width: 85%;
      border-radius: 10px;
      -webkit-box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.2),
        0 8px 20px 0 rgba(0, 0, 0, 0.19);
      -moz-box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.2),
        0 8px 20px 0 rgba(0, 0, 0, 0.19);
      box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.2),
        0 8px 20px 0 rgba(0, 0, 0, 0.19);
      margin-bottom: 10px;
      background-color: white;
      transform: translate(-50%, -50%) !important;
      position: absolute;
      max-height: 90vh;
      .box-content {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-rows: auto 25px 25px auto;
        grid-template-columns: 100%;
        grid-gap: 5px;
        div.image {
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          grid-row-start: 1;
          grid-row-end: 3;
          grid-column-start: 1;
          grid-column-end: 2;
          padding-top: 80%;
        }
        div.icon {
          grid-row-start: 2;
          grid-row-end: 4;
          grid-column-start: 1;
          grid-column-end: 2;
          p {
            text-align: center;
            margin-block-start: 0px;
            margin-block-end: 0px;
            i.material-icons {
              background-color: $theme-l1;
              width: 50px;
              border-radius: 50px;
              height: 50px;
              color: whitesmoke;
              font-size: 35px;
              line-height: 50px;
              vertical-align: middle;

              -webkit-box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2),
                0 4px 10px 0 rgba(0, 0, 0, 0.19);
              -moz-box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2),
                0 4px 10px 0 rgba(0, 0, 0, 0.19);
              box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2),
                0 4px 10px 0 rgba(0, 0, 0, 0.19);
            }
          }
        }
        div.text {
          padding: 0px 25px 12px 25px;
          grid-column-start: 1;
          grid-column-end: 2;
          grid-row-start: 4;
          grid-row-end: 5;
          h4 {
            text-align: center;
          }
          p.btn {
            text-align: center;
            button:first-child {
              border-radius: 50px;
              color: whitesmoke;
              margin-right: 15px;
              background-color: $theme;
              text-decoration: none;
            }
            button {
              color: $text;
              background-color: white;
              text-decoration: underline;
              width: auto;
              padding: 12px 18px;
              height: auto;
              cursor: pointer;
              font-weight: 300;
              border: none;
              font-size: 18px;
            }
          }
        }
        &.input {
          grid-template-rows: auto;
        }
      }
    }
  }
  .inputs.large {
    margin-top: 0.75em;
    margin-bottom: 0.5em;
    height: fit-content;
    position: relative;
    .questions {
      display: grid;
      grid-gap: 5px;
      grid-template-columns: auto;
      div {
        input[type="radio"] {
          width: 15px !important;
          height: 15px !important;
          background-color: whitesmoke;
          margin-block-start: 0px;
          margin-block-end: 0px;
        }
      }
      .note {
        font-size: 14px;
        font-style: italic;
      }
    }
  }
  .text-link {
    div {
      p,
      span {
        display: inline;
        margin-left: 0.1em;
      }
      span {
        font-family: "Roboto", sans-serif;
        font-size: 1.1em;
        font-weight: 500;
        text-decoration: underline;
        color: $theme-d1;
        cursor: pointer;
      }
    }
  }
  .save-btn {
    width: 100%;
    text-align: center;
    button {
      padding: 12px 18px;
      border: none;
      width: auto !important;
      height: auto !important;
      font-size: 16px;
      font-weight: 300;
    }
    button.ok {
      color: whitesmoke;
      background-color: $theme;
      border-radius: 50px;
    }
    button.cancel {
      margin-left: 15px;
      background-color: transparent;
      color: $text;
      text-decoration: underline;
    }
  }
}

@media (min-width: 576px) {
  .section {
    .nav {
      .nav-items {
        width: auto;
      }
    }
    .gallery {
      grid-template-columns: 1fr;
    }
    .inputs.large {
      .questions {
        grid-template-columns: auto auto auto;
      }
    }
  }
}
@media (min-width: 768px) {
  .section {
    .form-section {
      .split-field {
        grid-template-columns: minmax(290px, 35%) auto;
      }
      .split-60-40 {
        &.fullsize {
          grid-template-columns: 40% 60%;
        }
      }
      table {
        td,
        th {
          padding-left: 1.5rem;
          padding-right: 1.5rem;
          img {
            max-width: 150px;
          }
        }
      }
    }
    .dimensions {
      grid-template-columns: 3fr 2fr 1fr 40px;
      .long {
        grid-column-end: 5;
      }
      .mid {
        grid-column-end: 2;
      }
    }
    .pictures {
      grid-template-columns: 1fr 1fr;
      .long {
        grid-column-start: 1;
        grid-column-end: 3;
      }
    }
    .picture-preview {
      div.box {
        width: 50%;
      }
    }
    .gallery {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}
@media (min-width: 992px) {
  .section {
    .gallery {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }
}
</style>