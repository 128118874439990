<template>
  <div class="gallery" v-show="gallery">
    <div
      class="next"
      @click="(galleryIndex===galleryItems.length-1) ? setIndex(0) : setIndex(galleryIndex+1)"
      title="Siguiente"
    >
      <i class="material-icons">chevron_right</i>
    </div>
    <div
      class="prev"
      @click="(galleryIndex===0) ? setIndex(galleryItems.length-1) : setIndex(galleryIndex-1)"
      title="Anterior"
    >
      <i class="material-icons">chevron_left</i>
    </div>
    <div class="elements">
      <transition-group
        class="items"
        :style="{width:'calc((100vw - 4rem)*'+galleryItems.length+')'}"
      >
      <template v-for="item in galleryItems"
          v-bind:key="item.view">
        <div
          :style="{backgroundImage:'url('+item.url+')'}"
          class="item"
          name="flip-list"
          v-if="galleryIndex===item.position && galleryType==='COLECCION'"
        ></div>
        <div class="split" v-if="galleryIndex===item.position && galleryType==='AVDA'">
          <div
          :style="{backgroundImage:'url('+item.foto+')'}"
          class="item"
          name="flip-list"
        ></div>
        <div class="data">
          <div class="container">
            <p><b>T&iacute;tulo:</b> {{ item.data[0].value }}</p>
            <p><b>N&uacute;mero de Registro: </b> {{ item.id }}</p>
            <p><b>Propietario: </b> {{ item.propietario }}</p>
            <p><b>Fecha en que se tom&oacute;: </b> {{ getDate(item.data[4].value) }}</p>
            <p><b>Lugar d&oacute;nde se tom&oacute;: </b> {{ item.data[3].value }}</p>
            <p><b>Autor: </b> {{ item.data[5].value }}</p>
            <p><b>Dimensiones: </b> {{ item.data[6].value }}</p>
            <p><b>Descripci&oacute;n: </b> {{ item.data[1].value }}</p>
          </div>
        </div>
        </div>
        
      </template>
        
      </transition-group>
    </div>

    <p class="close">
      <i class="material-icons" @click="close">close</i>
    </p>
    <div class="nav-bar">
      <div
        class="dot"
        v-for="item in galleryItems.length"
        :key="item-1"
        :class="{'active-dot':item-1===galleryIndex}"
        @click="setIndex(item-1)"
        :title="item.view"
      ></div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "Gallery-component",
  computed: {
    ...mapGetters(["gallery", "galleryIndex", "galleryItems","galleryType"]),
  },
  watch: {
    gallery: function() {},
    galleryIndex: function() {},
    galleryItems: function() {},
    
    galleryType: function() {}
  },
  methods: {
    ...mapMutations(["SET_GALLERY", "SET_GALLERY_INDEX"]),
    close() {
      this.SET_GALLERY(false);
    },
    setIndex(payload) {
      this.SET_GALLERY_INDEX(payload);
    },
    
    getDate(payload){
      if(payload.seconds){
        var t = new Date(1970, 0, 0); // Epoch
    t.setSeconds(payload.seconds);
    return t.getDate()+"/"+(t.getMonth()+1)+"/"+t.getFullYear();
      }else{
      return payload }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "./../assets/sass/variables";
.transparent {
  background-color: #22190880;
}
.move-flip-list {
  transition: transform 5s;
}
/* .flip-list {
  display: inline-block;
  margin-right: 10px;
}
.flip-list-active, .flip-list-active {
  transition: all 1s;
}
.flip-list, .flip-list-to {
  opacity: 0;
  transform: translateY(30px);
} */
div.gallery {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  margin: 0px;
  background-color: #181715ea;
  z-index: 21;
  padding: 1rem 1rem 1rem 0rem;
  display: grid;
  grid-template-columns: 3rem auto 3rem;
  grid-template-rows: 3rem auto auto;

  .close {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: 4;
    padding-right: 1rem;
    width: 100%;
    text-align: right;
    i.material-icons {
      color: white;
      font-size: 2rem;
      cursor: pointer;
    }
    top: 0%;
    position: absolute;
  }
  div.elements {
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column-start: 2;
    grid-column-end: 3;
    position: relative;
    width: calc(100vw - 7rem);
    overflow: hidden;
    div.item {
        width: calc(100vw - 5rem);
        height: calc(100vh - 6rem) !important;
        position: relative;
        margin: 10px 0px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        display: inline-block;
      }
    .items {
      height: calc(100vh - 7rem);
      margin: 0px;
      position: relative;
      float: left;      
    }
    div.split{
      display: grid;
      grid-template-columns: 3fr 1fr;
      div.item{
        
        width: calc(75vw - 5rem);
        height: calc(100vh - 6rem) !important;
        position: relative;
        margin: 10px 0px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        display: inline-block;
      }
      div.data{
        width: calc(25vw - 2rem);
        height: calc(100vh - 6rem) !important;
        position: relative;
        div.container{
          position: absolute;
          margin-left: 5%;
          margin-right: 5%;
          padding: 8% 5% 8% 5%;
          height: fit-content;
          top: 50%;
          transform: translateY(-50%);
          border-radius: 10px;
          border: 1px solid $white;
          p{
            font-size: 1.05em;
            color: $white;
          }
          max-height: calc(100vh - 6rem);
        }
      }
    }
  }
  .nav-bar {
    grid-row-start: 3;
    grid-row-end: 4;
    grid-column-start: 2;
    grid-column-end: 3;
    position: relative;
    width: fit-content;
    height: fit-content;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .dot {
      width: 12px;
      height: 12px;
      margin: 0px 6px;
      border-radius: 8px;
      background-color: white;
      display: inline-block;
      cursor: pointer;
    }
    .active-dot {
      width: 36px;
      background-color: $theme;
    }
  }
  .next,
  .prev {
    grid-row-start: 1;
    grid-row-end: 4;
    position: relative;
    cursor: pointer;
    height: 100%;
    i.material-icons {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      font-size: 3rem;
      position: absolute;
      text-align: center;
    }
  }
  .prev {
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .next {
    grid-column-start: 3;
    grid-column-end: 4;
  }
}
</style>
