import app from '@/firebase'
import {
    getFirestore,
    getDocs,
    collection,
    doc,
    query,
    updateDoc,
    addDoc,
    onSnapshot,
    orderBy,
    deleteDoc,
    runTransaction,
} from "firebase/firestore";
const db = getFirestore(app)
export default {
    state: {
        materias: [],
        items: [],
        albums: []
    },
    namespaced: true,
    actions: {
        async getAlbums({
            commit
        }) {
            try {
                let qS = await getDocs(collection(db, 'AVDA'))
                qS.forEach(async elem => {
                    if(elem.id==="CLASIFICACION"){
                        commit('SET_MATERIAS',elem.data().temas)
                        commit('CLASIFICAR_MATERIA')
                        commit('SET_MATERIAS_STATUS')
                        
                commit('SET_MATERIAS_PICTURE',elem.id)
                    }else{
                    commit('ADD_ALBUM', {
                        id: elem.id,
                        data: elem.data()
                    })
                    commit('CLASIFICAR_MATERIA')
                    commit('SET_MATERIAS_STATUS')
                    commit('SET_ALBUM_PICTURE',elem.id)
                }
                })
                commit('SET_ALBUM_STATUS')
            } catch (e) {
                console.log("error", e)
            }
        },
        async importPhotos({
            commit
        }, payload) {
            try {
                let obj = await doc(db, "AVDA", payload.id);
                let result = await runTransaction(db, async (transaction) => {
                    let album = await transaction.get(obj)
                    let prom = new Promise(function (myResolve, myReject) {
                        try {
                            let pictures = album.data().fotografias
                            payload.fotos.forEach(item => {
                                let pic = pictures.find(elem => elem.id === item.id)
                                if (pic) {
                                    pic.data = item.data
                                    if (pic.reverso === "NO" && item.reverso === "") {
                                        pic.reverso = ""
                                    }
                                } else {
                                    pictures.push(item)
                                }
                            })
                            myResolve(pictures)
                        } catch (e) {
                            myReject(e)
                        }
                    })
                    return prom.then(res => {
                        transaction.update(obj, {
                            fotografias: res
                        })
                        return res
                    })
                })
                commit("UPDATE_ALBUM_PHOTOS", {
                    id: payload.id,
                    fotos: result
                });
                commit('SET_ALBUM_STATUS')
                commit('CLASIFICAR_MATERIA')
                commit('SET_MATERIAS_STATUS')
                return result
            } catch (e) {
                console.log(e)
            }
        },
        async updatePhotoLink({
            commit
        }, payload) {
            try {
                let obj = await doc(db, "AVDA", payload.album);
                let result = await runTransaction(db, async (transaction) => {
                    let album = await transaction.get(obj)
                    let prom = new Promise(function (myResolve, myReject) {
                        try {
                            let pictures = album.data().fotografias
                            let image = pictures.find(elem => elem.id === payload.id)
                            if (payload.side === "foto") {
                                image.foto = payload.link
                            } else {
                                image.reverso = payload.link
                            }
                            myResolve(pictures)
                        } catch (e) {
                            myReject(e)
                        }
                    })
                    return prom.then(res => {
                        transaction.update(obj, {
                            fotografias: res
                        })
                        return res
                    })
                })
                commit("UPDATE_ALBUM_PHOTOS", {
                    id: payload.id,
                    fotos: result
                });
                commit('SET_ALBUM_STATUS')
                commit('CLASIFICAR_MATERIA')
                return result
            } catch (e) {
                console.log(e)
            }
        },
        async getItems({
            commit
        }) {

            onSnapshot(query(collection(db, 'AVDA'), orderBy('name')), qS => {
                qS.docChanges().forEach(change => {
                    if (change.type === "added") {
                        commit('ADD_ITEMS', {
                            id: change.doc.id,
                            ...change.doc.data()
                        })
                    }
                    if (change.type === "modified") {
                        commit('UPDATE_ITEM_NAME', {
                            id: change.doc.id,
                            name: change.doc.data().name
                        })
                        commit('UPDATE_ITEM_DEFINITION', {
                            id: change.doc.id,
                            name: change.doc.data().definition
                        })
                    }
                    if (change.type === "removed") {
                        commit('REMOVE_ITEM_BY_ID', change.doc.id)
                    }
                })
            })
        },
        async addItem({
            commit
        }, payload) {
            let doc = await addDoc(collection(db, 'Glosario'), {
                ...payload
            })
            commit('ADD_ITEM', {
                id: doc.id,
                ...doc.data()
            })
        },
        async updateItem({
            commit
        }, payload) {
            await updateDoc(doc(db, 'Glosario', payload.id), {
                ...payload.data
            })
            commit('UPDATE_ITEM', payload)
        },
        async updateItemName({
            commit
        }, payload) {
            await updateDoc(doc(db, 'Glosario', payload.id), {
                name: payload.name
            })
            commit('UPDATE_ITEM_NAME', payload)
        },
        async updateItemDefinition({
            commit
        }, payload) {
            await updateDoc(doc(db, 'Glosario', payload.id), {
                definition: payload.definition
            })
            commit('UPDATE_ITEM_DEFINITION', payload)
        },
        async deleteItemById({
            commit
        }, payload) {
            await deleteDoc(doc(db, 'Glosario', payload))
            commit('REMOVE_ITEM_BY_ID', payload)
        },

    },
    getters: {
        items: state => {
            return state.items
        },
        itemsByMateria: state => {
            return state.materias
        },
        itemsByPropietario: state => {
            return state.albums
        },
        itemsByIndex: state => payload => {
            let arr = []
            state.items.forEach(item => {
                let char = item.name.split('')[0]
                if (char.toUpperCase() === payload) {
                    if (item.definition.split('').length >= 200) {
                        arr.push({
                            ...item,
                            shortDefinition: item.definition.slice(0, 199),
                            isLong: true
                        })
                    } else {
                        arr.push({
                            ...item,
                            shortDefinition: item.definition,
                            isLong: false
                        })
                    }
                }
            })
            return arr
        },
        name: state => payload => {
            let item = state.items.find(el => el.id === payload)
            if (item) {
                return item.name
            } else {
                return ''
            }
        }
    },
    mutations: {
        SET_ITEMS(state, payload) {
            state.items = payload
        },
        SET_MATERIAS(state,payload){
            state.materias=payload
            state.materias.forEach(item=>{
                item.fotografias=[]
            })
        },
        ADD_ALBUM(state, payload) {
            if(payload.id==="counter"){
                return
            }
            let album = state.albums.find(item => item.id === payload.id)
            if (album) {
                album.descripcion = payload.data.descripcion
                album.propietario = payload.data.propietario
                album.fotografias = payload.data.fotografias
                album.periodo = payload.data.periodo
            } else {
                state.albums.push({
                    id: payload.id,
                    ...payload.data
                })
            }
            let fotos = payload.data.fotografias
            let pic = null
            if (fotos !== undefined && fotos.length > 0) {
                fotos.forEach(item => {
                    pic = state.items.find(elem => elem.id === item.id)
                    if (pic) {
                        pic.foto = item.foto,
                            pic.reverso = item.reverso,
                            pic.data = item.data
                    } else {
                        let pos = 0;
                        state.items.forEach((photo) => {
                            let arrPos = Number.parseInt(photo.id.slice(4));
                            let elemPos = Number.parseInt(item.id.slice(4));
                            if (arrPos < elemPos) {
                                pos++;
                            }
                        });
                        state.items.splice(pos, 0, item);
                    }
                })
            }
        },
        SET_ALBUM_PICTURE(state,payload){
            let album=state.albums.find(item=>item.id===payload)
            if(album){
            album.fotografias.forEach(item=>{
                if(item.foto!==""){
                    album.picture=item.foto
                    return
                }
            })}
        },
        SET_MATERIAS_PICTURE(state){
            state.materias.forEach(album=>{
            album.fotografias.forEach(item=>{
                if(item.foto!==""){
                    album.picture=item.foto
                    return
                }
            })
        })
        },
        UPDATE_ALBUM_PHOTOS(state, payload) {
            let album = state.albums.find(item => item.id === payload.id)
            if (album) {
                album.fotografias = payload.fotos
            }
            payload.fotos.forEach(elem => {
                let pic = state.items.find(item => item.id === elem.id)
                if (pic) {
                    pic.foto = elem.foto
                    pic.reverso = elem.reverso
                    pic.data = elem.data
                }
            })
        },
        SET_ALBUM_STATUS(state){
            state.albums.forEach(item=>{
                let i=0
                item.fotografias.forEach(pic=>{
                    if(pic.foto!==""){
                        i++
                    }
                })
                item.largo=i
            })
        },
        CLASIFICAR_MATERIA(state){
            if(state.materias.length>0){
            state.items.forEach(item=>{
                item.data.forEach(info=>{
                    if(info.position===3){
                        info.value.forEach(tema=>{
                            let materia=state.materias.find(mat=>mat.id===tema)
                            let pic=materia.fotografias.find(foto=>foto.id===item.id)
                            if(pic){
                                pic.data=item.data
                                pic.foto=item.foto
                                pic.reverso=item.reverso
                            }else{
                                materia.fotografias.push(item)
                            }
                        })
                    }
                })
            })}
        },
        SET_MATERIAS_STATUS(state){
            state.materias.forEach(item=>{
                let i=0
                item.fotografias.forEach(pic=>{
                    if(pic.foto!==""){
                        i++
                    }
                })
                item.largo=i
            })
        },
        CLEAR_ITEMS(state) {
            state.items = []
        },
        REMOVE_ITEM_BY_ID(state, payload) {
            let index = state.items.findIndex(item => item.id === payload)
            if (index >= 0) {
                state.items.splice(index, 1)
            }
        },
        UPDATE_ITEM(state, payload) {
            let elem = state.items.find(item => item.id === payload.id)
            if (elem) {
                elem.name = payload.data.name
                elem.definition = payload.data.definition
                elem.source = payload.data.source
            }
        },
        UPDATE_ITEM_NAME(state, payload) {
            let elem = state.items.find(item => item.id === payload.id)
            if (elem) {
                elem.name = payload.name
            }
        },
        UPDATE_ITEM_DEFINITION(state, payload) {
            let elem = state.items.find(item => item.id === payload.id)
            if (elem) {
                elem.name = payload.definition
            }
        },
        SET_ITEM_OBJECTS(state, payload) {
            let result = state.items.find(elem => elem.id === payload.id)
            if (result) {
                result.elements = payload.data
            }
        }
    }
}