<template>
  <div class="section">
    <div class="left-wide white" v-show="currentItem.description !== undefined">
      <h4 v-html="'Descripci&oacute;n'"></h4>
      <p v-html="currentItem.description"></p>
    </div>
    <div
      class="right-narrow theme"
      v-show="
        currentItem.measurements !== undefined &&
        currentItem.measurements.length > 0
      "
    >
      <h4 v-html="'Dimensiones'"></h4>
      <div>
        <li v-for="item in currentItem.measurements" :key="item.dimension">
          <strong>{{ item.dimension }}:</strong>
          {{ item.measure }}
        </li>
      </div>
    </div>
    <div class="half-l theme-light">
      <h4 v-html="'Caracter&iacute;sticas'"></h4>
      <div v-if="currentItem.techniques">
        <p class="inline">
          <strong v-html="'T&eacute;cnica: '"></strong>
        </p>
        <p
          class="inline"
          v-for="(item, index) in currentItem.techniques"
          :key="item"
        >
          {{ techniqueName(item) }}
          <span v-if="index < currentItem.techniques.length - 1">,</span>
        </p>
      </div>
      <div v-if="currentItem.materials">
        <p class="inline">
          <strong v-html="'Material: '"></strong>
        </p>
        <p
          class="inline"
          v-for="(item, index) in currentItem.materials"
          :key="item"
        >
          {{ materialName(item) }}
          <span v-if="index < currentItem.materials.length - 1">,</span>
        </p>
      </div>
      <div v-if="currentItem.pieceStatus">
        <p class="inline">
          <strong
            v-html="'Estado del Objeto: ' + currentItem.pieceStatus.status"
          ></strong>
          , {{ currentItem.pieceStatus.comments }}
        </p>
      </div>
    </div>
    <div class="half-r dark-gray">
      <h4 v-html="'Ubicaci&oacute;n'"></h4>
      <div v-if="currentItem.origin">
        <p class="inline">
          <strong v-html="'Lugar de Origen: '"></strong>
          {{ currentItem.origin }}
        </p>
      </div>
      <div v-if="currentItem.found">
        <p class="inline">
          <strong v-html="'Lugar donde fue Encontrado: '"></strong>
          {{ whereWasFound }}
        </p>
      </div>
      <div>
        <p
          class="inline"
          v-if="currentItem.exhibition && currentItem.exhibition === 'Si'"
        >
          <strong v-html="'Ubicaci&oacute;n Actual: '"></strong>
          <span v-html="'En exhibici&oacute;n, ' + location"></span>
        </p>
        <p
          class="inline"
          v-if="currentItem.exhibition && currentItem.exhibition === 'No'"
        >
          <strong v-html="'Ubicaci&oacute;n Actual: '"></strong>
        </p>
        <p
          class="inline"
          v-if="
            currentItem.exhibition === 'No' &&
            currentItem.location !== undefined &&
            currentItem.location.label === 'Caja'
          "
        >
          <span v-html="'Dep&oacute;sito,'" v-if="user"></span>
          <span v-html="'Dep&oacute;sito'" v-else></span>
          <template v-if="user">
            <strong> Caja:</strong>
            <span
              style="text-decoration: underline; cursor: pointer"
              @click="$router.push('/deposit')"
              >{{ currentItem.location.value }}</span
            >
          </template>
        </p>
        <p
          class="inline"
          v-else-if="
            currentItem.exhibition === 'No' &&
            currentItem.location !== undefined &&
            currentItem.location.label === 'Deposito' &&
            user
          "
        >
          <span v-html="'Dep&oacute;sito'"></span>
          <template v-if="user">
            <strong v-html="': '"></strong>
            <span
              style="text-decoration: underline; cursor: pointer"
              @click="$router.push('/deposit')"
              >{{ currentItem.location.value }}</span
            >
          </template>
        </p>

        <p
          class="inline"
          v-if="
            currentItem.exhibition === 'No' &&
            (currentItem.location === undefined || currentItem.location === '')
          "
        >
          Desconocido
        </p>
      </div>
    </div>
    <div class="left-narrow orange" v-if="user">
      <h4 v-html="'Conceptos Relacionados'"></h4>
      <p
        class="inline"
        v-for="(item, index) in currentItem.keyWords"
        :key="item.id"
      >
        #{{ keywordName(item) }}
        <span v-if="index < currentItem.keyWords.length - 1">,</span>
      </p>
    </div>
    <div class="right-wide white" v-if="user">
      <h4 v-html="'Gesti&oacute;n del Objeto'"></h4>
      <div v-if="currentItem.conservation">
        <p class="inline">
          <strong v-html="'Se han realizado acciones preventivas: '"></strong>
          {{ currentItem.conservation.requires }}
        </p>
      </div>
      <div
        v-if="
          currentItem.conservation && currentItem.conservation.comments !== ''
        "
      >
        <p class="inline">
          <strong v-html="'Acciones preventivas realizadas: '"></strong>
          {{ currentItem.conservation.comments }}
        </p>
      </div>
      <div v-if="currentItem.restoration">
        <p class="inline">
          <strong v-html="'Requiere restauraci&oacute;n: '"></strong>
          {{ currentItem.restoration }}
        </p>
      </div>
      <div v-if="currentItem.donant">
        <p class="inline">
          <strong v-html="'Primer propietario o Donante: '"></strong>
          {{ currentItem.donant }}
        </p>
      </div>
      <div v-if="currentItem.identifier">
        <p class="inline">
          <strong v-html="'Ubicaci&oacute;n del Rotulado: '"></strong>
          {{ currentItem.identifier }}
        </p>
      </div>
      <div v-if="currentItem.others">
        <p class="inline">
          <strong v-html="'Observaciones: '"></strong>
          {{ currentItem.others }}
        </p>
      </div>
    </div>
    <div
      :class="[
        {
          'half-l': currentItem.relatedObjects.length > 0,
          wide: currentItem.relatedObjects.length === 0,
        },
        'grey-1',
      ]"
      v-if="currentItem.compuesto"
    >
      <h4 v-html="'Estructura del Objeto'"></h4>
      <p
        v-html="
          'Este objeto est&aacute; compuesto por piezas o partes, aqu&iacute; podr&aacute; visualizar la informaci&oacute;n de sus partes:'
        "
      ></p>
      <div class="card-container-h">
        <div
          class="card-h"
          v-for="(item, index) in getConjuntoObjetos(currentItem.id)"
          :key="index"
        >
          <div
            class="img"
            :style="{
              'background-image': 'url(' + item.data.mainImage.url + ')',
            }"
          ></div>
          <div class="data">
            <h5 v-html="item.data.name"></h5>
            <p><b>Id: </b>{{ item.id }}</p>
            <p class="inline" v-if="item.data.materials.length > 0">
              <b>Materiales: </b>
            </p>
            <p
              class="inline"
              v-for="(el, index) in item.data.materials"
              :key="el"
            >
              {{ materialName(el)
              }}<span v-if="index < item.data.materials.length"></span>
            </p>
          </div>
          <div class="full-content right" @click="objectId = item.id">
            <i class="material-icons icon">add</i>
            <div class="inner-content">
              <div class="action">
                <i class="material-icons">assignment</i>
                <p>Abrir Ficha del Objeto</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      :class="[
        { 'half-r': currentItem.compuesto, wide: !currentItem.compuesto },
        'theme-light',
      ]"
      v-if="currentItem.relatedObjects.length > 0"
    >
      <h4 v-html="'Objetos de Inter&eacute;s'"></h4>
      <p
        v-html="
          'Aqu&iacute; podr&aacute;s revisar otros objetos similares o que tienen alguna relaci&oacute;n con este:'
        "
      ></p>
      <div class="card-container-h">
        <template v-if="user">
          <div
            class="card-h"
            v-for="item in currentItem.relatedObjects"
            :key="item.id"
          >
            <div
              class="img"
              :style="{
                'background-image':
                  'url(' + getItem(item.id).referenceImage.url + ')',
              }"
            ></div>
            <div class="data">
              <h5 v-html="getItem(item.id).name"></h5>
              <p><b>Id: </b>{{ item.id }}</p>
              <p>
                <b v-html="'Tipo de Relaci&oacute;n: '"></b>{{ item.relation }}
              </p>
            </div>
            <div
              class="full-content right"
              @click="id = $router.push('/item/' + item.id)"
            >
              <i class="material-icons icon">add</i>
              <div class="inner-content">
                <div class="action">
                  <i class="material-icons">assignment</i>
                  <p>Abrir Ficha del Objeto</p>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="
              currentItem.relatedObjects.length === 1 && !currentItem.compuesto
            "
          ></div>
        </template>
        <template v-else>
          <div
            class="card-h"
            v-for="item in currentItem.relatedObjects.filter((el) => {
              el.published === false;
            })"
            :key="item.id"
          >
            <div
              class="img"
              :style="{
                'background-image':
                  'url(' + getItem(item.id).referenceImage.url + ')',
              }"
            ></div>
            <div class="data">
              <h5 v-html="getItem(item.id).name"></h5>
              <p><b>Id: </b>{{ item.id }}</p>
              <p>
                <b v-html="'Tipo de Relaci&oacute;n: '"></b>{{ item.relation }}
              </p>
            </div>
            <div
              class="full-content right"
              @click="$router.push('/item/' + item.id)"
            >
              <i class="material-icons icon">add</i>
              <div class="inner-content">
                <div class="action">
                  <i class="material-icons">assignment</i>
                  <p>Abrir Ficha del Objeto</p>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="wide white">
      <h4 v-html="'Galer&iacute;a'"></h4>
      <div class="gallery">
        <div
          class="item"
          v-for="(item, index) in getArrayImages"
          :key="item.url"
          :style="'background-image:url(' + item.url + ')'"
        >
          <div class="hover" @click="openGallery(index)">
            <div>
              <i class="material-icons">fullscreen</i>
              <h5>{{ item.view }}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" v-if="objectId !== ''">
      <div class="top" @click="objectId = ''"></div>
      <div class="left" @click="objectId = ''"></div>
      <div class="right" @click="objectId = ''"></div>
      <div class="bottom" @click="objectId = ''"></div>
      <SimpleBar class="box">
        <p class="icon" style="padding: 0.5em 0.5em 0 0">
          <i class="material-icons" @click="objectId = ''">close</i>
        </p>
        <div class="padding">
          <div class="split-30-70">
            <img :src="getPieza.data.mainImage.url" />
            <div>
              <div class="bottom">
                <h4>{{ getPieza.data.name }}</h4>
                <p><b>Id:</b> {{ getPieza.id }}</p>
              </div>
            </div>
          </div>
          <div class="ficha">
            <p
              v-if="getPieza.data.description !== ''"
              style="text-align: right"
            >
              <b v-html="'Descripci&oacute;n:'"></b>
            </p>
            <p v-if="getPieza.data.description !== ''" style="text-align: left">
              {{ getPieza.data.description }}
            </p>
            <p
              style="text-align: right"
              v-if="getPieza.data.materials.length > 0"
            >
              <b>Materiales:</b>
            </p>
            <div v-if="getPieza.data.materials.length > 0">
              <p
                class="inline"
                v-for="(el, index) in getPieza.data.materials"
                :key="el"
                style="text-align: left"
              >
                {{ materialName(el)
                }}<span v-if="index < getPieza.data.materials.length">, </span>
              </p>
            </div>

            <p
              style="text-align: right"
              v-if="getPieza.data.techniques.length > 0"
            >
              <b v-html="'T&eacute;cnicas:'"></b>
            </p>
            <div v-if="getPieza.data.techniques.length > 0">
              <p
                class="inline"
                v-for="(el, index) in getPieza.data.techniques"
                :key="el"
                style="text-align: left"
              >
                {{ techniqueName(el)
                }}<span v-if="index < getPieza.data.techniques.length">, </span>
              </p>
            </div>

            <p style="text-align: right"><b>Dimensiones:</b></p>
            <div>
              <p
                v-for="(el, index) in getPieza.data.dimensions"
                :key="index"
                style="text-align: left"
              >
                {{ el.dimension }}: {{ el.value }}
              </p>
            </div>

            <p v-if="getPieza.data.photos.length > 0" style="text-align: right">
              <b>Galeria:</b>
            </p>
          </div>
          <div class="gallery">
            <div
              class="item"
              v-for="(item, index) in photosCompuesto"
              :key="item.url"
              :style="'background-image:url(' + item.url + ')'"
            >
              <div class="hover" @click="openGalleryCompuesto(index)">
                <div>
                  <i class="material-icons">fullscreen</i>
                  <h5>{{ item.view }}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SimpleBar>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import { mapGetters, mapMutations } from "vuex";
import { SimpleBar } from "simplebar-vue3";
export default {
  name: "Display-Object-Info",
  components: {
    SimpleBar,
  },
  data: function () {
    return {
      id: "",
      objectId: "",
    };
  },
  computed: {
    ...mapGetters([
      "getMessageAction",
      "user",
      "getConjuntoObjetos",
      "currentItem",
      "getItem",
    ]),
    ...mapGetters("sitios", { sites: "sitios" }),
    ...mapGetters("materials", { materials: "items", materialName: "name" }),
    ...mapGetters("techniques", { techniques: "items", techniqueName: "name" }),
    ...mapGetters("keywords", { keywords: "items", keywordName: "name" }),

    whereWasFound() {
      let text;
      let siteId;
      this.currentItem.found.forEach((elem) => {
        if (elem.label === "sitio") {
          if (elem.value === "Desconocido") {
            text = elem.value;
          } else {
            text = this.getSite(elem.value);
            siteId = elem.value;
          }
        } else {
          if (elem.value !== "none") {
            text = text + ", Habitación: " + this.getRoom([siteId, elem.value]);
          }
        }
      });
      return text;
    },
    location() {
      var text = "";
      if (this.user) {
        if (
          this.currentItem !== undefined &&
          this.currentItem.location !== undefined &&
          this.currentItem.location !== ""
        ) {
          let s;
          this.currentItem.location.forEach((element) => {
            if (element.label === "Sitio") {
              text +=
                " " + element.label + ": " + this.getSite(element.value) + ",";
              s = element.value;
            } else if (element.label === "Habitacion") {
              text += " Habitaci&oacute;n: " + this.getRoom([s, element.value]);
            }
          });
        }
      } else {
        if (
          this.currentItem !== undefined &&
          this.currentItem.location !== undefined &&
          this.currentItem.location !== ""
        ) {
          this.currentItem.location.forEach((element) => {
            if (element.label === "Sitio") {
              text = this.getSite(element.value);
            }
          });
        }
      }
      return text;
    },
    getPieza() {
      return this.getConjuntoObjetos(this.currentItem.id).find(
        (el) => el.id === this.objectId
      );
    },

    getArrayImages() {
      let arr = [];
      let i = 0;
      if (this.currentItem && this.currentItem.referenceImage) {
        arr.push({
          ...this.currentItem.referenceImage,
          view: "Referencia",
          position: i,
        });
        i++;
      }
      if (this.currentItem && this.currentItem.images) {
        this.currentItem.images.forEach((item) => {
          arr.push({ ...item, position: i });
          i++;
        });
      }
      return arr;
    },
    photosCompuesto() {
      let arr = [];
      let i = 0;
      if (this.objectId !== "") {
        let item = this.getConjuntoObjetos(this.currentItem.id).find(
          (el) => el.id === this.objectId
        );
        if (item.data.mainImage !== "") {
          arr.push({
            ...item.data.mainImage,
            view: "Referencia",
            position: i,
          });
          i++;
        }
        if (item.data.photos.length > 0) {
          item.data.photos.forEach((el) => {
            arr.push({
              ...el,
              position: i,
            });
            i++;
          });
        }
      }
      return arr;
    },
  },
  watch: {
    currentItem(newValue) {
      if (newValue) {
        this.id = newValue.id;
      }
    },
    id(newValue) {
      this.SET_CURRENT_ITEM(newValue);
    },
    "$route.params.id"(newValue) {
      this.id = newValue;
      this.SET_CURRENT_ITEM(newValue);
    },
  },
  methods: {
    ...mapMutations([
      "SET_CURRENT_ITEM",
      "SET_CURRENT_OBJECT_ID",
      "SET_MESSAGE_PARAMETERS",
      "SET_LOADING",
      "ADD_KEYWORD",
      "SET_GALLERY",
      "SET_GALLERY_INDEX",
      "SET_GALLERY_ITEMS",
      "SET_GALLERY_TYPE"
    ]),
    getSite(payload) {
      let elem = this.sites.find((item) => item.id === payload);
      if (elem) {
        return elem.name;
      } else {
        return "";
      }
    },
    getRoom(payload) {
      let el = this.sites.find((item) => item.id === payload[0]);
      if (el) {
        el = el.rooms.find((el) => el.id === payload[1]);
        if (el) {
          return el.name;
        }
      }
      return "";
    },
    openGallery(payload) {
      let pos = this.getArrayImages.findIndex(
        (item) => item.position === payload
      );
      let el = this.getArrayImages[pos];
      let arr = this.getArrayImages;
      arr.splice(pos, 1);
      arr.splice(0, 0, el);
      this.SET_GALLERY_ITEMS(arr);
      this.SET_GALLERY_INDEX(payload);
      this.SET_GALLERY(true);
      this.SET_GALLERY_TYPE("COLECCION")
    },
    openGalleryCompuesto(payload) {
      let pos = this.photosCompuesto.findIndex(
        (item) => item.position === payload
      );
      let el = this.photosCompuesto[pos];
      let arr = this.photosCompuesto;
      arr.splice(pos, 1);
      arr.splice(0, 0, el);
      this.SET_GALLERY_ITEMS(arr);
      this.SET_GALLERY_INDEX(payload);
      this.SET_GALLERY(true);
      this.SET_GALLERY_TYPE("COLECCION")
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/sass/variables";
@import "./../assets/sass/modal";
@import "./../assets/sass/cards";
select {
  background-color: white;
}
button {
  width: 40px;
  height: 40px;
  background-color: $theme;
  border: none;
  border-radius: 30px;
  padding: 0px;
  i.material-icons {
    vertical-align: middle;
    color: white;
  }
}
button:disabled {
  opacity: 0.5;
  cursor: no-drop;
}
label {
  margin-block-start: 0px;
  margin-block-end: 0px;
}
.section {
  position: relative;
  padding: 1rem 0;
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 20px;
  strong {
    letter-spacing: 1px;
  }
  li {
    list-style: none;
  }
  .white {
    background-color: white;
    h4 {
      color: $orange;
    }
  }
  p.inline {
    display: inline;
  }

  .theme {
    background-color: $theme-d1;
    h4 {
      color: rgba($color: white, $alpha: 0.75);
    }
    p,
    li {
      color: white;
    }
  }
  .theme-light {
    background-color: $theme-l3;
    h4 {
      color: rgba($color: $theme-d3, $alpha: 0.9);
    }
    p,
    li {
      color: rgba($color: $theme-d4, $alpha: 0.9);
    }
  }
  .dark-gray {
    background-color: $text-dark;
    h4 {
      color: rgba($color: white, $alpha: 0.65);
    }
    p,
    li {
      color: white;
    }
  }
  .orange {
    background-color: $orange;
    h4 {
      color: rgba($color: white, $alpha: 0.65);
    }
    p,
    li {
      color: white;
    }
  }
  .grey-1 {
    background-color: $grey-1;
    h4 {
      color: $blue-grey-dark;
    }
    p,
    li {
      color: $blue-grey;
    }
  }
  .left-narrow,
  .left-wide,
  .right-narrow,
  .right-wide,
  .half-l,
  .half-r,
  .wide {
    border-radius: 5px;

    padding: 1rem;
    h4 {
      text-transform: uppercase;
      font-weight: 500;
      font-size: 1.8rem;
      margin-bottom: 0.8rem;
    }
    p,
    li {
      line-height: 1.7rem;
      font-weight: 300;
    }
  }
  .gallery {
    display: grid;
    grid-gap: 8px;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    position: relative;
    .item {
      padding-top: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      position: relative;
      border-radius: 5px;
      overflow: hidden;
      .hover {
        height: 100%;
        width: 100%;
        position: absolute;
        background-color: rgba($color: #000000, $alpha: 0.65);
        top: 0%;
        margin-top: 0%;
        opacity: 0;
        div {
          width: 100%;
          margin-top: calc(50% - 32px);
          i.material-icons {
            font-size: 35px;
            color: whitesmoke;
            text-align: center;
            width: 100%;
            vertical-align: middle;
          }
          h5 {
            color: whitesmoke;
            text-align: center;
          }
        }
      }
      .hover:hover {
        cursor: pointer;
        opacity: 1;
      }
    }
  }
}

@media (min-width: 768px) {
  .section {
    grid-gap: 15px;

    grid-template-columns: 33.5% 13.5% 13.5% 33.5%;
    .left-narrow,
    .left-wide,
    .right-narrow,
    .right-wide,
    .half-l,
    .half-r,
    .wide {
      h4 {
        text-transform: uppercase;
        font-weight: 500;
        font-size: 1.7rem;
        margin-bottom: 1rem;
      }
      p,
      li {
        line-height: 1.7rem;
      }
    }
    .half-l {
      grid-column-start: 1;
      grid-column-end: 3;
      padding: 1.5rem 1rem 1.5rem 1.5rem;
    }
    .half-r {
      padding: 1.5em 1rem 1.5rem 1.5rem;
      grid-column-start: 3;
      grid-column-end: 5;
    }
    .wide {
      grid-column-end: 5;
      grid-column-start: 1;
      padding: 1.5rem 1.5rem;
    }
    .left-wide {
      grid-column-start: 1;
      grid-column-end: 4;
      padding: 1.5rem 1.5rem;
    }
    .right-narrow {
      grid-column-start: 4;
      grid-column-end: 5;
      padding: 1.5rem 1rem;
    }
    .left-narrow {
      grid-column-start: 1;
      grid-column-end: 2;
      padding: 1.5rem 1.5rem;
    }
    .right-wide {
      grid-column-start: 2;
      grid-column-end: 5;

      padding: 1.5rem 1.5rem;
    }
    .field {
      display: grid;
      grid-gap: 5px;
      grid-template-columns: 1.5fr 3fr;
      .long {
        grid-column-start: 1;
        grid-column-end: 3;
      }
    }
  }
}
@media (min-width: 992) {
  .section {
    .left-narrow,
    .left-wide,
    .right-narrow,
    .right-wide,
    .half-l,
    .half-r,
    .wide {
      border-radius: 5px;
      h4 {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 2rem;
        margin-bottom: 1rem;
      }
      p,
      li {
        line-height: 1.7rem;
        font-weight: 300;
      }
    }

    .half-l {
      grid-column-start: 1;
      grid-column-end: 3;
      padding: 1.5rem 2rem 1.5rem 4rem;
    }

    .half-r {
      grid-column-start: 3;
      grid-column-end: 5;
      padding: 1.5em 2em 1.5em 2em;
    }
    .left-wide {
      grid-column-start: 1;
      grid-column-end: 4;
      padding: 1.5rem 4rem;
    }
    .right-narrow {
      grid-column-start: 4;
      grid-column-end: 5;
      padding: 1.5rem 2rem;
    }

    .wide {
      grid-column-start: 1;
      grid-column-end: 5;
      padding: 1.5rem 2rem;
    }
    .left-narrow {
      grid-column-start: 1;
      grid-column-end: 2;
      padding: 1.5em 2em;
    }
    .right-wide {
      grid-column-start: 2;
      grid-column-end: 5;

      padding: 1.5em 4em;
    }
  }
}
</style>