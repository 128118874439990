//import * as firebase from './firebase'

import { initializeApp } from 'firebase/app';
//import { getFirestore } from 'firebase/firestore/lite';
//import firebase from "firebase/app";
//import "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyD0joJJ-Z1CCN2JUHo40sgDrA2SJvOOuw4",
    authDomain: "colecciones-e9ef9.firebaseapp.com",
    databaseURL: "https://colecciones-e9ef9.firebaseio.com",
    projectId: "colecciones-e9ef9",
    storageBucket: "gs://colecciones-e9ef9.appspot.com",
    messagingSenderId: "391508726221",
    appId: "1:391508726221:web:27b02b1602ade6cd5cf484",
    measurementId: "G-9Z7ZFMDNBP"
}
// Initialize Firebase
//export default firebase.initializeApp(firebaseConfig)

//export default firebase.initializeApp(firebaseConfig);
//const db = getFirestore(firebaseApp);

const app= initializeApp(firebaseConfig);
export default app