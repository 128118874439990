<template>
  <div class="grapper">
    <div class="image-page">
      <div>
        <h1
          v-html="'Configuraci&oacute;n - Archivo Visual de Puerto Cristal'"
        ></h1>
      </div>
      <h4 v-html="'Museo de Sitio Puerto Cristal'"></h4>
      <div></div>
    </div>
    <div class="main-container">
      <h3 v-html="'Gestionar AVDA'"></h3>
      <div class="card-container">
        <div
          class="card"
          @click="addModuleDisplay = true"
          style="cursor: pointer"
        >
          <div class="card-img">
            <i class="material-icons">perm_media</i>
          </div>
          <div class="middle-icon">
            <div>
              <i class="material-icons">add</i>
            </div>
          </div>
          <div class="card-content">
            <h4>Importar Fotografías</h4>
          </div>
        </div>
        <div
          class="card"
          @click="addCGroupModuleDisplay = true"
          style="cursor: pointer"
        >
          <div class="card-img">
            <i class="material-icons">category</i>
          </div>
          <div class="middle-icon">
            <div>
              <i class="material-icons">add</i>
            </div>
          </div>
          <div class="card-content">
            <h4>Agregar Conjunto</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="main-container">
      <h3 v-html="'Cargar fotograf&iacute;as faltantes'"></h3>
      
      <input
        class="file"
        type="file"
        ref="filePhoto"
        accept="image/*"
        @change="onFilePickedFoto"
      />
      <table v-if="emptyPictures.length > 0">
        <tr>
          <th>Id</th>
          <th v-html="'T&iacute;tulo'"></th>
          <th>Imagen</th>
          <th>Reverso</th>
        </tr>
        <tr v-for="item in emptyPictures" :key="item.id">
          <td>{{ item.id }}</td>
          <td>{{ item.titulo }}</td>
          <td v-if="item.fotoLink !== ''">Ingresada</td>
          <td v-else>
            <button
              class="icon-btn"
              @click="
                $refs.filePhoto.click();
                currentElem.id = item.id;
                currentElem.name = item.titulo;
                currentElem.album = item.album;
                currentElem.side = 'foto';
              "
            >
              <i class="material-icons">attach_file</i>
            </button>
          </td>
          <td v-if="item.reversoLink === 'NO'">No tiene</td>
          <td v-else>
            <button
              class="icon-btn"
              @click="
                $refs.filePhoto.click();
                currentElem.id = item.id;
                currentElem.name = item.titulo;
                currentElem.album = item.album;
                currentElem.side = 'reverso';
              "
            >
              <i class="material-icons">attach_file</i>
            </button>
          </td>
        </tr>
      </table>
    </div>
    <div class="picture-preview" v-show="previewDisplayed">
      <SimpleBar class="box">
        <div class="box-content">
          <div
            class="image"
            :style="'background-image:url(' + currentImage.preview + ')'"
          ></div>
          <div class="icon">
            <p>
              <i class="material-icons">add_photo_alternate</i>
            </p>
          </div>
          <div class="text">
            <h4>Agregar Imagen</h4>
            <p>
              <strong>Id:</strong>
              {{ currentElem.id }}
            </p>
            <p>
              <strong>Nombre:</strong>
              {{ currentElem.name }}
            </p>
            <p class="btn-center">
              <button
                class="ok-btn"
                v-if="previewDisplayed"
                @click="uploadFoto"
              >
                Agregar
              </button>
              <button class="cancel-btn" @click="clearImage;previewDisplayed=false">Cancelar</button>
            </p>
          </div>
        </div>
      </SimpleBar>
    </div>
    <div class="modal" v-show="addModuleDisplay" @response="hideAddModule">
      <div class="top" @click="addModuleDisplay = false"></div>
      <div class="left" @click="addModuleDisplay = false"></div>
      <div class="right" @click="addModuleDisplay = false"></div>
      <div class="bottom" @click="addModuleDisplay = false"></div>
      <div class="box">
        <p class="icon">
          <i class="material-icons" @click="addModuleDisplay = false">close</i>
        </p>
        <div class="content">
          <Import v-on:get-result="setResult" />
        </div>
      </div>
    </div>

    <Loading text="cargando" />
    <Message />
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import Message from "@/components/Message.vue";
import Loading from "@/components/Loading.vue";
import Import from "@/components/AVDA_import_photos.vue";

import app from "@/firebase";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
const storage = getStorage(app);
//import firebase from "@/firebase";
//let db = firebase.firestore();
import { SimpleBar } from "simplebar-vue3";
export default {
  name: "avda_dashbord",
  components: {
    Message,
    Loading,
    Import,
    SimpleBar,
  },
  data: function () {
    return {
      addModuleDisplay: false,
      addGroupModuleDisplay: false,
      currentImage: {
        name: "",
        preview: "",
        file: null,
      },
      currentElem: {
        id: "",
        name: "",
        album: "",
        side: "",
      },
      previewDisplayed: false,
    };
  },
  computed: {
    ...mapGetters("avda_config", {
      fotos: "items",
    }),
    emptyPictures() {
      let arr=[]
      this.fotos.forEach((elem) => {
        if (elem.foto === ""/* || elem.reverso === ""*/) {
          let tit = elem.data.find((item) => item.position === 1);
          arr.push({
            id: elem.id,
            album: elem.album,
            fotoLink: elem.foto,
            reversoLink: elem.reverso,
            titulo: tit.value,
          });
        }
      });
      return arr
    },
  },
  watch: {
    
    fotos(old,newVal) {
      console.log("change"+old.length+" / "+newVal)
    },
    
  },
  methods: {
    ...mapActions("avda_config",{
      updatePhoto:"updatePhotoLink"
    }),
    ...mapMutations([
      "SET_LOADING",
      "SET_SUCCESS_MESSAGE_PARAMETERS",
      "SET_CURRENT_OBJECT_ID",
      "SET_MESSAGE_PARAMETERS",
      
      "SET_LOADING_TEXT",
    ]),
    hideAddModule() {
      this.addModuleDisplay = false;
    },
    hideAddGroupModule() {
      this.addGroupModuleDisplay = false;
    },
    setResult(result) {
      this.addModuleDisplay = false;
      this.SET_MESSAGE_PARAMETERS({
        isVisible: true,
        type: "success",
        icon: "done",
        title: "Álbumes cargados'",
        message:
          "Se han cargado las fotograf&iacute;s a " +
          result +
          " &aacute;lbumes",
        buttonAction: "",
        button: "Aceptar",
        respondTo: "add",
        action: 0,
      });
    },
    onPickPhotoFile: function () {
      this.$refs.filePhoto.click();
    },
    onFilePickedFoto(event) {
      const file = event.target.files;
      let fileName = file[0].name;
      if (fileName.lastIndexOf(".") <= 0) {
        return alert("Ingrese una imagen valida");
      }
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        this.currentImage.preview = fileReader.result;
      });
      this.currentImage.name = fileName;
      fileReader.readAsDataURL(file[0]);
      this.currentImage.file = file[0];
      this.previewDisplayed = true;
    },
    async uploadFoto() {
      try {
        this.SET_LOADING(true);
        if (this.currentImage.name) {

          let refImg = ref(
            storage,
            "AVDA/" + this.currentElem.side + "/" + this.currentImage.name
          );
          this.uploadTask = uploadBytesResumable(
            refImg,
            this.currentImage.file
          );

          this.uploadTask.on(
            "state_changed",
            (snap) => {
              var progress = Math.trunc(
                (snap.bytesTransferred / snap.totalBytes) * 100
              );
              this.SET_LOADING_TEXT("Cargando " + progress + "%");
              console.log("Upload is " + progress + "% done");
            },
            (error) => {
              this.SET_MESSAGE_PARAMETERS({
                isVisible: true,
                type: "error",
                icon: "error",
                title: "Ocurri&oacute; un error al cargar la imagen",
                message:
                  "Ocurri&oacute; un error al subir la imagen al servidor, por favor intentelo m&aacute;s tarde.",
                buttonAction: "",
                button: "Aceptar",
                respondTo: "error",
                action: 0,
              });
              this.SET_LOADING(false);
              console.log(error);
            },
            () => {
              getDownloadURL(this.uploadTask.snapshot.ref).then(async (url) => {
                console.log(url);
                await this.updatePhoto({
                  album: this.currentElem.album,
                  id: this.currentElem.id,
                  side: this.currentElem.side,
                  link: url,
                });
                
                this.SET_LOADING(false);
                this.SET_LOADING_TEXT("Cargando...");
                this.previewDisplayed = false;
                this.SET_MESSAGE_PARAMETERS({
                  isVisible: true,
                  type: "success",
                  icon: "done",
                  title: "Foto Agregada",
                  message: "Se ha agregado correctamente la fotograf&iacute;a.",
                  buttonAction: "",
                  button: "Aceptar",
                  respondTo: "successUpdateObject",
                  action: 0,
                });
              });
            }
          );
        }
      } catch (error) {
        this.SET_MESSAGE_PARAMETERS({
          isVisible: true,
          type: "error",
          icon: "error",
          title: "Ocurri&oacute; un error al cargar la imagen",
          message:
            "Ocurri&oacute; un error al subir la imagen al servidor, por favor intentelo m&aacute;s tarde.",
          buttonAction: "",
          button: "Aceptar",
          respondTo: "error",
          action: 0,
        });
        this.SET_LOADING(false);
        console.log(error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/sass/variables";
@import "../../assets/sass/buttons";
@import "../../assets/sass/cards";
div.grapper {
  width: 100%;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: auto;
  grid-template-rows: 70vh auto;
  h4 {
    color: $theme-d1;
    padding: 5%;
    text-align: center;
  }
  .image-page {
    background-image: linear-gradient(
        to bottom,
        transparent,
        rgba(0, 0, 0, 0.75)
      ),
      url("../../assets/media/AVDA_BACKGROUND.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: grid;
    grid-template-rows: calc(100% - 170px) 50px 120px;
    h1,
    h4 {
      position: relative;
      text-align: center;
      padding-left: 10%;
      padding-right: 10%;
      margin-block-end: 0px;
      margin-block-start: 0px;
    }
    div {
      padding-left: 10%;
      padding-right: 10%;
      position: relative;
      h1 {
        text-align: center;
        display: block;
        width: 100%;
        bottom: 0%;
        left: 50%;
        margin-bottom: 0px;
        position: absolute;
        transform: translateX(-50%);
      }
    }
    h1 {
      color: $theme-l4;
      vertical-align: text-bottom;
    }
    h4 {
      color: wheat;
    }
    div {
      padding: 25px 10% 45px 10%;
      div {
        display: grid;
        grid-template-columns: auto 60px;
        border-radius: 50px;
        background-color: white;
        padding: 0px;
        overflow: hidden;
        input {
          height: 50px;
          border: none;
          margin-block-start: 0%;
          margin-block-end: 0%;
          margin-left: 20px;
          cursor: text;
        }
        button {
          margin-block-end: 0px;
          margin-block-start: 0px;
          background-color: $theme;
          border: none;
          cursor: pointer;
          i.material-icons {
            vertical-align: middle;
            font-size: 35px;
            color: white;
          }
        }
        button:disabled {
          opacity: 0.5;
          cursor: no-drop;
        }
      }
      .options {
        display: inline-block;
        position: relative;
        width: calc(100% - 80px);
        margin-left: 20px;
        height: 0px;
        z-index: 5 !important;
        overflow: visible;
        margin-top: 0px;
        height: fit-content;
        ul {
          background-color: white;
          border: solid 1px $text;
          margin-block-start: 0px;
          margin-block-end: 0px;
          padding-inline-start: 0px;
          li {
            padding-left: 10px;
            line-height: 27px;
            color: $text;
            margin-block-start: 0px;
            margin-block-end: 0px;
            padding: 3px 0px;
            cursor: pointer;
            list-style: none;
          }
          li.selected,
          li:hover {
            background-color: $theme;
            color: white;
            font-weight: 500;
          }
        }
      }
    }
  }

  .picture-preview {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    margin: 0px;
    background-color: #22190880;
    z-index: 21;
    div.box {
      overflow-y: auto;
      top: 50%;
      left: 50%;
      width: 85%;
      border-radius: 10px;
      -webkit-box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.2),
        0 8px 20px 0 rgba(0, 0, 0, 0.19);
      -moz-box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.2),
        0 8px 20px 0 rgba(0, 0, 0, 0.19);
      box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.2),
        0 8px 20px 0 rgba(0, 0, 0, 0.19);
      margin-bottom: 10px;
      background-color: white;
      transform: translate(-50%, -50%) !important;
      position: absolute;
      max-height: 90vh;
      .box-content {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-rows: auto 25px 25px auto;
        grid-template-columns: 100%;
        grid-gap: 5px;
        div.image {
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          grid-row-start: 1;
          grid-row-end: 3;
          grid-column-start: 1;
          grid-column-end: 2;
          padding-top: 80%;
        }
        div.icon {
          grid-row-start: 2;
          grid-row-end: 4;
          grid-column-start: 1;
          grid-column-end: 2;
          p {
            text-align: center;
            margin-block-start: 0px;
            margin-block-end: 0px;
            i.material-icons {
              background-color: $theme-l1;
              width: 50px;
              border-radius: 50px;
              height: 50px;
              color: whitesmoke;
              font-size: 35px;
              line-height: 50px;
              vertical-align: middle;

              -webkit-box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2),
                0 4px 10px 0 rgba(0, 0, 0, 0.19);
              -moz-box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2),
                0 4px 10px 0 rgba(0, 0, 0, 0.19);
              box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2),
                0 4px 10px 0 rgba(0, 0, 0, 0.19);
            }
          }
        }
        div.text {
          padding: 0px 25px 12px 25px;
          grid-column-start: 1;
          grid-column-end: 2;
          grid-row-start: 4;
          grid-row-end: 5;
          h4 {
            text-align: center;
          }
          p.btn {
            text-align: center;
            button:first-child {
              border-radius: 50px;
              color: whitesmoke;
              margin-right: 15px;
              background-color: $theme;
              text-decoration: none;
            }
            button {
              color: $text;
              background-color: white;
              text-decoration: underline;
              width: auto;
              padding: 12px 18px;
              height: auto;
              cursor: pointer;
              font-weight: 300;
              border: none;
              font-size: 18px;
            }
          }
        }
        &.input {
          grid-template-rows: auto;
        }
      }
    }
  }
  div.main-container {
    margin: 0px 5%;
    background-color: white;
    border-radius: 15px;
    width: auto;
    padding: 25px;

    table {
      width: 100%;
      margin-top: 1em;
      border-spacing: 0;
      border-radius: 5px;
      th {
        background-color: $blue-grey;
        color: white;
        padding: 1em;
        font-weight: 500;
        font-size: 1.17em;
      }
      td {
        border-bottom: 1px solid $blue-grey;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        text-align: center;
        div.icon {
          background-color: $theme-d1;
          border-radius: 5px;
          i {
            padding: 0.5em;
            font-size: 2em;
            color: white;
          }
        }
        &.center {
          div {
            position: relative;
            background-color: white;
          }
        }
        p {
          display: inline;
          i {
            margin-left: 0.25em;
            margin-right: 0.25em;
            &:hover {
              color: $theme-d1;
              cursor: pointer;
            }
          }
        }
        img {
          margin-top: 0.5em;
          margin-bottom: 0.25em;
          max-width: 100px;
          height: auto;
          border-radius: 5px;
        }
      }
    }
    .file {
      display: none;
    }
  }
  .modal {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    margin: 0px;
    background-color: #22190880;
    z-index: 5;
    padding: 5%;
    div.box {
      top: 50%;
      left: 50%;
      width: 85%;
      border-radius: 10px;
      -webkit-box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.2),
        0 8px 20px 0 rgba(0, 0, 0, 0.19);
      -moz-box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.2),
        0 8px 20px 0 rgba(0, 0, 0, 0.19);
      box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.2),
        0 8px 20px 0 rgba(0, 0, 0, 0.19);
      margin-bottom: 10px;
      background-color: white;
      transform: translate(-50%, -50%) !important;
      position: absolute;
      padding: 10px 12px;
      max-height: 90vh;
      overflow-y: auto;
      p.icon {
        text-align: right;
        i {
          font-size: 24px;
          cursor: pointer;
        }
        i:hover {
          font-weight: bold;
        }
        padding: 0px;
        color: $text;
        margin-block-start: 0px;
        width: 100%;
        margin-block-end: 0px;
      }
      h2 {
        margin-block-start: 0px;
        text-align: center;
      }
      p {
        color: $text;
        text-align: center;
      }
      .content {
        margin: 0px 12px 8px 12px;
        width: auto;
      }
    }
  }
}
@media (min-width: 768px) {
  div.grapper {
    .modal {
      div.box {
        width: 70%;
      }
    }
    .picture-preview {
      div.box {
        width: 50%;
      }
    }
  }
}
@media (min-width: 992px) {
  div.grapper {
    .modal {
      div.box {
        width: 50%;
      }
    }
  }
}
</style>
