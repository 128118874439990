<template>
  <div class="home">
    <div class="search">
      <div>
        <h1 v-html="'Archivo Visual de Puerto Cristal'"></h1>
      </div>
      <h4 v-html="'Museo de Sitio Puerto Cristal'"></h4>
    </div>
    <div class="section color1 split">
      <div>
        <div class="title">
          <h3 v-html="'Archivo'"></h3>
          <h3 v-html="'Visual de'"></h3>
          <h1 v-html="'PUERTO CRISTAL'"></h1>
        </div>
      </div>
      <div>
        <div class="content">
          <p
            v-html="
              'El Archivo Visual de Puerto Cristal, es el resultado de un trabajo riguroso por conservar y disponibilizar los registros gr&aacute;ficos de este Monumento Hist&oacute;rico a lo largo de los a&ntilde;os, para esto se ha contactado a personas que vivieron en Puerto Cristal y que han participado activamente en la donaci&oacute;n y pr&eacute;stamo de sus archivos familiares, compartiendo sus recuerdos y relatos en torno a estas las im&aacute;genes.'"
          ></p>
          <p
            v-html="
              'Esto ha permitido no s&oacute;lo digitalizar las fotograf&iacute;as, sino que catalogarlas y reconocer el contexto hist&oacute;rico en el fueron tomadas. Este Archivo Visual se encuentra disponible gratuitamente para que toda la comunidad pueda verlo y conocer su historia. Si requieres hacer uso de este material cont&aacute;ctanos a puertocristal@outlook.com.'
            "
          ></p>
        </div>
      </div>
    </div>
    <div class="section color2 full">
      <div>
        <div class="content">
          <div class="split">
            <img src="../assets/media/LOGO_FONDART.png" alt="" />
            <div>
              <p
                v-html="
                  'Esta iniciativa ha sido ejecutada gracias al financiamiento del Fondo Nacional de Desarrollo Cultural y las Artes - FONDART, que ha permitido contactar a la comunidad Cristalina, recopilar sus fotograf&iacute;as, digitalizarlas y obtener su informaci&oacute;n o antecedentes, para que a trav&eacute;s de esta plataforma est&eacute;n disponibles para la comunidad.'
                "
              ></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section full color3">
      <div>
        <div class="content">
          <h1 v-html="'Explora el Archivo Visual de Puerto Cristal'"></h1>
          <p
            v-html="
              'Conoce las fotograf&iacute;as hist&oacute;ricas de Puerto Cristal, podr&aacute;s agruparlas por el tipo de contenido o por su propietario, selecciona el &aacute;lbum de tu inter&eacute;s y descubre su contenido'
            "
          ></p>
          <div class="show-selector">
            <div>
              <label>Agrupar por:</label>
              <select name="show" id="show" v-model="group">
                <option :value="'album'" v-html="'Propietario'"></option>
                <option :value="'tema'" v-html="'Contenido'"></option>
              </select>
            </div>
          </div>
          <div class="album-container">
            <div
              class="album"
              :class="{selected:selectAlbum===item.id}"
              v-for="item in groupList"
              :key="item.id"
              @click="selectAlbum = item.id"
            >
              <i class="material-icons" v-if="group === 'album'">person</i>
              <i class="material-icons" v-else>label</i>
              <div class="album-text">
                <h5 v-if="group === 'album'">
                  <b>{{ item.propietario }}</b>
                </h5>
                <h5 v-else>
                  <b>{{ item.materia }}</b>
                </h5>
                <p v-html="'N&uacute;mero de Fotos: ' + item.largo"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="fotos-container" v-if="selectAlbum !== ''">
        <div class="row">
          <div class="column">
            <img
              v-for="item in fotografias.colum1"
              :key="item.id"
              :src="item.foto"
              :alt="item.data[0].value"
              @click="openGallery(item.position)"
            />
          </div>
          <div class="column">
            <img
              v-for="item in fotografias.colum2"
              :key="item.id"
              :src="item.foto"
              :alt="item.data[0].value"
              @click="openGallery(item.position)"
            />
          </div>
          <div class="column">
            <img
              v-for="item in fotografias.colum3"
              :key="item.id"
              :src="item.foto"
              :alt="item.data[0].value"
              @click="openGallery(item.position)"
            />
          </div>
          <div class="column">
            <img
              v-for="item in fotografias.colum4"
              :key="item.id"
              :src="item.foto"
              :alt="item.data[0].value"
              @click="openGallery(item.position)"
            />
          </div>
        </div>
      </div>
    </div>
    <Loading text="cargando" />
    <Message />
    
    <Gallery />
  </div>
</template>
<script>
// @ is an alias to /src
import Loading from "@/components/Loading.vue";
import Message from "@/components/Message.vue";

import Gallery from "@/components/Gallery.vue";
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  name: "avda-landingpage",
  components: {
    Loading,
    Message,
    Gallery
  },
  data: function () {
    return {
      windowWidth: window.innerWidth,
      selectAlbum: "",
      group: "album",
      groupList: [],
      fotografias: { colum1: [], colum2: [], colum3: [], colum4: [] },
      fotoList: [],
    };
  },
  created() {
    this.SET_LOADING_TEXT("Cargando...");
    this.SET_LOADING(false);
  },
  watch: {
    selectAlbum(newVal, oldVal) {
      if (oldVal !== newVal) {
        this.fotografias = { colum1: [], colum2: [], colum3: [], colum4: [] };
        let cont = 1;
        let pos = 0;

        this.fotoList = this.groupList.find((item) => item.id === newVal).fotografias;
        this.fotoList.forEach((element) => {
          element.position = pos;
          element.propietario=this.propietarios.find(el=>el.id===element.album).propietario
          switch (cont) {
            case 1:
              this.fotografias.colum1.push(element);
              break;
            case 2:
              this.fotografias.colum2.push(element);
              break;
            case 3:
              this.fotografias.colum3.push(element);
              break;
            case 4:
              this.fotografias.colum4.push(element);
              break;
            default:
              this.fotografias.colum1.push(element);
              break;
          }
          if (cont === 4) {
            cont = 1;
          } else {
            cont++;
          }
          pos++;
        });
      }
    },
    group(newVal, oldVal) {
      if (oldVal !== newVal) {
        if (newVal === "album") {
          this.groupList = this.propietarios;
        } else {
          this.groupList = this.materias;
        }
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    this.groupList = this.propietarios;
  },
  beforeUnmountd() {
    window.removeEventListener("resize", this.onResize);
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters("avda_config", {
      materias: "itemsByMateria",
      propietarios: "itemsByPropietario",
    }),
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    ...mapMutations("archive", {
      SET_FONDO_ACTUAL: "SET_FONDO_ACTUAL",
      SET_SERIE_ACTUAL: "SET_SERIE_ACTUAL",
      SET_LEVEL_LIST: "SET_LEVEL_LIST",
      SET_LEVEL_FONDO: "SET_LEVEL_FONDO",
      SET_LEVEL_SERIE: "SET_LEVEL_SERIE",
    }),
    ...mapMutations([
      "SET_LOADING",
      "SET_LOADING_OPACITY",
      "SET_LOADING_TEXT",
      "SET_MESSAGE_PARAMETERS",
      "SET_GALLERY_ITEMS",
      "SET_GALLERY_INDEX",
      "SET_GALLERY",
      "SET_GALLERY_TYPE",
    ]),
    ...mapActions("archive", {
      addNewFondo: "addNewFondo",
      addNewSerie: "addNewSerie",
      addNewDocument: "addNewDocument",
      getDocumentosFromSerie: "getDocumentosFromSerie",
      getPublishedDocumentosFromSerie: "getPublishedDocumentosFromSerie",
      setSeriesPublicData: "setSeriesPublicData",
      updatePrivateDataOfSerie: "updatePrivateDataOfSerie",
      loadDocumento: "loadDocumento",
      loadFONDOS: "loadFONDOS",
      loadSERIES: "loadSERIES",
      cargarDocumentos: "cargarDocumentos",
      setPublished: "setPublished",
      addDocToSerie: "addDocToSerie",
      publishAll: "publishAll",
    }),
    addFondo() {
      this.SET_LOADING(true);
      this.addNewFondo({
        code: this.newFondo.code,
        titulo: this.newFondo.title,
        fechas: this.newFondo.dates,
        productor: this.newFondo.producer,
      }).then(() => {
        this.SET_LOADING(false);
        this.addFondoModuleDisplay = false;
        this.SET_MESSAGE_PARAMETERS({
          isVisible: true,
          type: "success",
          icon: "done",
          title: "Fondo Creado",
          message: "Se ha creado exitosamente el fondo " + this.newFondo.title,
          buttonAction: "",
          button: "Aceptar",
          respondTo: "success",
          action: 0,
        });
      });
    },
    addSerie() {
      this.SET_LOADING(true);
      this.addNewSerie({
        fondo: this.newSerie.fondo,
        serie: {
          titulo: this.newSerie.title,
          fechas: this.newSerie.dates,
          productor: this.newSerie.producer,
        },
      }).then((result) => {
        if (result) {
          this.SET_LOADING(false);
          this.addSerieModuleDisplay = false;
          this.SET_MESSAGE_PARAMETERS({
            isVisible: true,
            type: "success",
            icon: "done",
            title: "Serie Creada",
            message:
              "Se ha creado exitosamente la serie " + this.newSerie.title,
            buttonAction: "",
            button: "Aceptar",
            respondTo: "success",
            action: 0,
          });
        }
      });
    },
    openGallery(payload) {
      let pos = this.fotoList.findIndex(
        (item) => item.position === payload
      );
      let el = this.fotoList[pos];
      let arr = this.fotoList;
      arr.splice(pos, 1);
      arr.splice(0, 0, el);
      this.SET_GALLERY_ITEMS(arr);
      this.SET_GALLERY_INDEX(payload);
      this.SET_GALLERY(true);
      this.SET_GALLERY_TYPE("AVDA");
    },
    addDocumento() {
      this.SET_LOADING(true);
      this.addNewDocument({
        fondo: this.newDoc.fondo,
        serie: this.newDoc.serie,
        doc: {
          titulo: this.newDoc.title,
          fecha: this.newDoc.dates,
          productor: this.newDoc.productor,
        },
      }).then((result) => {
        if (result) {
          this.SET_LOADING(false);
          this.addDocumentModuleDisplay = false;
          this.SET_MESSAGE_PARAMETERS({
            isVisible: true,
            type: "success",
            icon: "done",
            title: "Serie Creada",
            message:
              "Se ha creado exitosamente la serie " + this.newSerie.title,
            buttonAction: "",
            button: "Aceptar",
            respondTo: "success",
            action: 0,
          });
        }
      });
    },
    loadDocuments() {
      if (this.serieActual !== "") {
        let s = this.series.find(
          (item) =>
            item.fondo === this.fondoActual && item.id === this.serieActual
        );
        if (s !== undefined) {
          if (s.documentos.length === 0) {
            if (this.user !== null) {
              this.SET_LOADING(true);
              this.getDocumentosFromSerie({
                fondo: this.fondoActual,
                serie: this.serieActual,
              }).then((result) => {
                console.log(result);
                this.SET_LOADING(false);
              });
            } else {
              this.SET_LOADING(true);
              this.getPublishedDocumentosFromSerie({
                fondo: this.fondoActual,
                serie: this.serieActual,
              }).then((result) => {
                console.log(result);
                this.SET_LOADING(false);
              });
            }
          }
        }
      }
      this.navigation.showDocumentList = !this.navigation.showDocumentList;
    },
    displayDocumento() {
      let doc = this.documentos.find(
        (item) => item.id === this.navigation.docActual
      );
      if (doc === undefined || doc === null) {
        this.SET_LOADING(true);
        this.loadDocumento(this.navigation.docActual).then((ans) => {
          if (ans !== false) {
            this.currentDocumento = ans;
            this.displayDocumentData = true;
            this.SET_LOADING(false);
          } else {
            console.log("Documento no encontrado");
          }
        });
      } else {
        this.currentDocumento = doc;
        this.displayDocumentData = true;
      }
    },
    getFondosSeries(payload) {
      let fon = this.fondos.find((item) => item.id === payload);
      if (fon.series.length > 0) {
        return fon.series;
      }
      return false;
    },
    updatePublicDataOfSerie() {
      let update = {
        fondo: this.currentFondo.id,
        serie: {
          id: this.updateSerie.serie.id,
          publicData: this.updateSerie.serie.publicData,
        },
      };
      this.SET_LOADING(true);
      this.setSeriesPublicData(update).then((result) => {
        this.SET_LOADING(false);
        if (result) {
          this.SET_MESSAGE_PARAMETERS({
            isVisible: true,
            type: "success",
            icon: "done",
            title: "Informaci&oacute;n Actualizada",
            message:
              "Se ha actualizado exitosamente la informaci&oacute;n de la serie " +
              this.newFondo.title,
            buttonAction: "",
            button: "Aceptar",
            respondTo: "success",
            action: 0,
          });
        } else {
          this.SET_MESSAGE_PARAMETERS({
            isVisible: true,
            type: "error",
            icon: "error",
            title: "Ocurri&oacute; un Error",
            message:
              "Ocurri&oacute; un error al tratar de actualizar la Serie, es posible que no se hayan guardado los cambios.",
            buttonAction: "",
            button: "Aceptar",
            respondTo: "",
            action: 0,
          });
        }
      });
    },
    updatePrivateDataOfSerie() {
      let update = {
        fondo: this.currentFondo.id,
        serie: {
          id: this.updateSerie.serie.id,
          privateData: this.updateSerie.serie.privateData,
        },
      };
      this.SET_LOADING(true);
      this.setSeriesPrivateData(update).then((result) => {
        this.SET_LOADING(false);
        if (result) {
          this.SET_MESSAGE_PARAMETERS({
            isVisible: true,
            type: "success",
            icon: "done",
            title: "Informaci&oacute;n Actualizada",
            message:
              "Se ha actualizado exitosamente la informaci&oacute;n de la serie " +
              this.newFondo.title,
            buttonAction: "",
            button: "Aceptar",
            respondTo: "success",
            action: 0,
          });
        } else {
          this.SET_MESSAGE_PARAMETERS({
            isVisible: true,
            type: "error",
            icon: "error",
            title: "Ocurri&oacute; un Error",
            message:
              "Ocurri&oacute; un error al tratar de actualizar la Serie, es posible que no se hayan guardado los cambios.",
            buttonAction: "",
            button: "Aceptar",
            respondTo: "",
            action: 0,
          });
        }
      });
    },
    setResult(payload) {
      if (payload.doc === null || payload.doc === undefined) {
        this.searchedValue = payload.text;
        this.result.doc = null;
      } else {
        if (payload.text === payload.doc.name) {
          this.searchedValue = payload.doc.name;
          this.result.doc = payload.doc;
        } else {
          this.searchedValue = payload.text;
          this.result.doc = null;
        }
      }
    },
    getId(payload) {
      if (payload.doc === null || payload.doc === undefined) {
        this.searchId = payload.text;
        this.id = "";
      } else {
        if (payload.text === payload.doc.name) {
          this.searchId = payload.doc.name;
          this.id = payload.doc.name;
        } else {
          this.searchId = payload.text;
          this.id = "";
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/sass/variables";
@import "../assets/sass/buttons";
@import "../assets/sass/cards";
@import "../assets/sass/modal";

.home {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 70vh auto;
  width: 100vw;
  .warning {
    color: $orange-d2 !important;
  }
  div.autocomplete-container {
    padding: 25px 13em 45px 3em;
    div {
      display: grid;
      grid-template-columns: auto 60px;
      grid-template-rows: 50px;
      border-radius: 50px;
      background-color: white;
      padding: 0px;
      overflow: visible;
      .radius-left {
        border-bottom-left-radius: 50px;
        border-top-left-radius: 50px;
      }
      .radius-right {
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
      }

      button {
        margin-block-end: 0px;
        margin-block-start: 0px;
        background-color: $theme;
        border: none;
        cursor: pointer;
        i.material-icons {
          vertical-align: middle;
          font-size: 35px;
          color: white;
        }
      }
      button:disabled {
        opacity: 0.5;
        cursor: no-drop;
      }
    }
  }
  .search {
    background-image: linear-gradient(
        to bottom,
        transparent,
        rgba(0, 0, 0, 0.75)
      ),
      url("../assets/media/AVDA_LANDPAGE.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: grid;
    grid-template-rows: calc(100% - 220px) 50px 120px 50px;
    h1,
    h4 {
      position: relative;
      text-align: center;
      padding-left: 10%;
      padding-right: 10%;
      margin-block-end: 0px;
      margin-block-start: 0px;
    }
    div.search-options {
      align-content: center;
      input[type="radio"] {
        margin-block-start: 5px;
        margin-block-end: 5px;
        height: 20px;
        width: 20px;
        margin-right: 5px;
        display: inline-block;
        vertical-align: middle;
      }
      label {
        color: white;
        display: inline-block;
        margin-right: 30px;
        margin-block-end: 0px;
        margin-block-start: 0px;
        vertical-align: middle;
        margin-top: 0px;
        line-height: 20px;
      }
    }
    div {
      padding-left: 10%;
      padding-right: 10%;
      position: relative;
      h1 {
        text-align: center;
        display: block;
        width: 100%;
        bottom: 0%;
        left: 50%;
        margin-bottom: 0px;
        position: absolute;
        transform: translateX(-50%);
      }
    }
    h1 {
      color: $theme-l4;
      vertical-align: text-bottom;
    }
    h4 {
      color: wheat;
    }
  }
  .border-shadow {
    -webkit-box-shadow: 0px -1px 0px 0px $grey-1 inset,
      -1px 0px 0px 0px $grey-1 inset;
    box-shadow: 0px -1px 0px 0px $grey-1 inset, -1px 0px 0px 0px $grey-1 inset;

    -webkit-box-shadow: 3px 3px 7px -2px $grey-1, -1px -1px 7px -3px $grey-1;
    box-shadow: 3px 3px 7px -2px $grey-1, -1px -1px 7px -3px $grey-1;
  }
  .bottom-shadow {
    -webkit-box-shadow: 0px -1px 0px 0px $grey-1 inset;
    box-shadow: 0px -1px 0px 0px $grey-1 inset;
  }
  .section {
    position: relative;
    padding: 2em 2em;
    display: grid;
    grid-template-rows: auto;
    gap: 1em;

    .show-selector {
      width: 100%;
      position: relative;
      div {
        width: fit-content;
        label {
          width: fit-content;
          margin-right: 5px;
          margin-left: 5px;
        }
        select {
          min-width: 75px;
          width: fit-content;
        }
      }
    }
    &.color1 {
      background-color: $blue-grey;
      h1,
      h2,
      h3,
      h4,
      h5 {
        color: $grey-2;
      }
      p,
      li {
        color: $grey-1;
      }
    }
    &.color2 {
      background-color: $grey-1;
      h1,
      h2,
      h3,
      h4,
      h5 {
        color: $theme-d1;
      }
      p,
      li {
        color: $text-dark;
      }
    }
    &.color3 {
      background-color: $white;
      h1,
      h2,
      h3,
      h4,
      h5 {
        color: $blue-grey-light;
      }
      p,
      li {
        color: $blue-grey;
      }
    }
    &.split,
    &.full {
      height: 100%;
      img {
        width: 100%;
      }
      div {
        position: relative;
        display: block;
        .title,
        .content {
          width: 100%;
          display: block;
          height: fit-content;
          h1 {
            font-size: 3em;
            font-weight: 700;
            margin-block-start: 0;
            margin-block-end: 0;
          }
          h3 {
            margin-block-start: 0;
            margin-block-end: 0;
          }
          li {
            list-style-type: none;
            margin-left: 0;
            font-size: 1.2rem;
            display: inline-block;
            strong,
            p {
              display: inline-block;
              font-size: 1.2rem;
            }
          }
          p {
            font-size: 1.2em;
            font-weight: 300;
          }
          .split {
            display: grid;
          }
        }
        .content {
          h1 {
            text-align: center;
            margin-block-end: 1em;
          }
        }
        .image {
          border-radius: 1em;
          width: 100%;
          padding-top: 100%;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          &#img1 {
            background-image: url("../assets/media/documentos.jpg");
          }
        }
      }
    }
    .album-container {
      display: grid !important;
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      grid-gap: 10px;
      margin-bottom: 10px;
      .album {
        display: grid !important;
        grid-template-columns: 1fr 4fr;
        grid-gap: 10px;
        border-radius: 5px;
        border: 1px solid $blue-grey;
        cursor: pointer;
        i.material-icons {
          vertical-align: middle;
          align-content: center;
          text-align: center;
          width: 100%;
          font-size: 35px;
          line-height: 100%;
          color: $blue-grey;
        }
        &.selected{
          background-color: $blue-grey;
          i.material-icons,h5,p{
            color:$grey-1
          }
        }
      }
    }
    .fotos-container {
      .row {
        display: flex;
        flex-wrap: wrap;
        padding: 0 4px;
      }

      /* Create four equal columns that sits next to each other */
      .column {
        flex: 100%;
        max-width: 100%;
        padding: 0 4px;
      }

      .column img {
        margin-top: 8px;
        vertical-align: middle;
        width: 100%;
        cursor: pointer;
      }
    }
  }
  .main-block {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto 35px 35px auto;
    .top-section,
    .conection-btn,
    .bottom-section {
      grid-column-start: 1;
      grid-column-end: 2;
    }
    .top-section {
      grid-row-start: 1;
      grid-row-end: 3;
      div.gallery {
        width: 100%;
        display: grid;
        padding: 0;
        grid-template-columns: 3rem auto 3rem;
        grid-template-rows: auto auto auto 3rem;
        .title {
          grid-column-start: 1;
          grid-column-end: 4;
          cursor: pointer;
          padding-left: 1em;
          padding-right: 1.5em;
          &.color1 {
            background-color: $theme-light;
            h3 {
              color: $theme-dark;
              font-size: 1.8em;
              font-weight: 500;
              padding-left: 1em;
              i.material-icons {
                vertical-align: middle;
              }
            }
          }
          &.color2 {
            background-color: $theme;
            h3 {
              color: $theme-l3;
              font-size: 1.8em;
              font-weight: 500;
              padding-left: 1em;
              i.material-icons {
                vertical-align: middle;
              }
            }
          }
        }
        &.color1 {
          background-color: $theme-light;
          h1 {
            color: $theme-dark;
            font-size: 3em;
            font-weight: 700;
            margin-block-start: 0%;
          }
          p {
            color: $blue-grey-dark;
          }
          div {
            div.item-list {
              background-color: $theme-dark;
              h3 {
                color: $theme-light;
              }
              ul.list-container {
                li {
                  p,
                  i.material-icons {
                    color: white;
                  }
                  &:hover,
                  &.selected {
                    background-color: $theme;
                  }
                }
              }
            }
          }
        }
        &.color2 {
          background-color: $theme;
          h2 {
            color: $theme-l3;
            font-size: 2.5em;
            font-weight: 600;
            margin-block-start: 0%;
            margin-block-end: 0%;
          }
          h6 {
            color: $theme-l2;
          }
          p,
          li {
            color: $theme-l4;
          }
          div {
            div.item-list {
              background-color: $theme-light;
              h3 {
                color: $theme-dark;
              }
              ul.list-container {
                li {
                  p,
                  i.material-icons {
                    color: white;
                  }
                  &:hover,
                  &.selected {
                    background-color: $theme;
                  }
                }
              }
            }
          }
        }
        &.color3 {
          background-color: $theme-d3;
          h2 {
            color: $theme-l3;
            font-size: 2em;
            font-weight: 600;
            margin-block-start: 0%;
            margin-block-end: 0%;
          }
          h6 {
            color: $theme-l2;
          }
          p,
          li {
            color: $theme-l4;
          }
          div {
            div.item-list {
              background-color: $theme-light;
              h3 {
                color: $theme-dark;
              }
              ul.list-container {
                li {
                  p,
                  i.material-icons {
                    color: white;
                  }
                  &:hover,
                  &.selected {
                    background-color: $theme;
                  }
                }
              }
            }
          }
        }
        div.elements {
          padding-top: 2em;
          grid-row-start: 3;
          grid-row-end: 4;
          grid-column-start: 2;
          grid-column-end: 3;
          display: grid;
          gap: 1em;
          .header {
            display: grid;
            grid-template-rows: auto;
            grid-template-columns: auto min-content;
          }

          div.info {
            h6 {
              font-size: 1.4em;
              text-decoration: underline;
              margin-block-end: 2em;
              margin-block-start: 0%;
            }
            p.large {
              font-size: 1.5em;
            }
            ul {
              list-style-type: none;
              margin: 0;
              padding: 0;
              li {
                font-size: 1.1em;
              }
            }
          }
          div {
            height: 100%;
            .item-list {
              width: 100%;
              border-radius: 1em;
              overflow: hidden;
              height: 100%;
              h3 {
                text-decoration: underline;
                font-size: 1.4em;
                padding-left: 1em;
                font-weight: 400;
              }
              ul.list-container {
                list-style-type: none;
                margin: 0;
                padding: 3px 0 0.5em 0;
                li {
                  display: grid;
                  grid-template-columns: 90% 10%;
                  padding: 0.35em 1em 0.35em 1em;
                  margin-block-end: 0px !important;
                  margin-block-start: 0px !important;
                  p {
                    width: calc(100% - 30px);
                    display: inline;
                    font-size: 1.1em;
                    line-height: 24px;
                    margin-block-end: 0px !important;
                    margin-block-start: 0px !important;
                  }
                  i {
                    text-align: right;
                  }
                  &.add {
                    display: inline-block;
                    line-height: 24px;
                    color: $theme;
                    width: 100%;
                    p {
                      width: calc(100% - 30px);
                      display: inline;
                      line-height: 24px;
                      margin-block-end: 0px !important;
                      margin-block-start: 0px !important;
                      font-weight: 500;
                      color: $theme;
                      vertical-align: middle;
                      color: white;
                    }
                    i {
                      vertical-align: middle;
                    }
                  }
                }
                li:hover,
                li.selected {
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }
    .conection-btn {
      grid-row-start: 2;
      grid-row-end: 4;
      position: relative;
      padding-top: 0;
      div.btn {
        height: 70px;
        width: auto;
        position: absolute;
        left: 50%;
        padding-top: 0;
        transform: translateX(-50%);
        border-radius: 40px;
        background-color: $theme;
        padding-left: 2em;
        padding-right: 2em;
        min-width: fit-content;
        p {
          color: white;
          font-weight: 500;
          font-size: 1.2em;
          line-height: 70px;
          margin-block-end: 0%;
          margin-block-start: 0%;
          i.material-icons {
            line-height: 70px;
            vertical-align: middle;
            font-size: 2em;
          }
        }
      }
    }
    .bottom-section {
      grid-row-start: 3;
      grid-row-end: 5;
      background-color: white;
      padding: 3rem 3rem 1rem 3rem;
    }
  }
}
@media (min-width: 768px) {
  .home {
    .section {
      padding: 3em 5em;
      gap: 2em;
      &.split {
        grid-template-columns: 1.5fr 2.5fr;

        div {
          .content {
            .split {
              gap: 1em;
              grid-template-columns: 1fr 3fr;
              div {
                display: flex;
                align-items: center;
              }
            }
          }
        }
      }
      &.full {
        div {
          .content {
            .split {
              gap: 1em;
              grid-template-columns: 1fr 3fr;
              grid-template-rows: auto;
              div {
                display: flex;
                align-items: center;
              }
            }
          }
        }
      }
    }
    .main-block {
      .top-section {
        .gallery {
          grid-template-columns: min-content min-content auto 4em !important;
          grid-template-rows: auto !important;
          .title {
            writing-mode: vertical-rl;
            padding: 1em 0 1.5em 0 !important;
            width: fit-content;
            transform: rotate(180deg);
            h3 {
              padding-left: 0 !important;
              i.material-icons {
                transform: rotate(90deg);
              }
            }
            &.color1 {
              grid-column-start: 1 !important;
              grid-column-end: 2 !important;
              grid-row-end: 2 !important;
              grid-row-start: 1 !important;
            }
            &.color2 {
              grid-column-start: 2 !important;
              grid-column-end: 3 !important;
              grid-row-end: 2 !important;
              grid-row-start: 1 !important;
            }
          }
          .elements {
            padding-left: 3em;
            padding-bottom: 3em;
            grid-column-start: 3 !important;
            grid-column-end: 4 !important;
            grid-row-end: 2 !important;
            grid-row-start: 1 !important;
            grid-template-rows: min-content auto;
            grid-template-columns: 2fr 1fr;
            &.state-2 {
              .header {
                grid-column-start: 1;
                grid-column-end: 3;
                grid-row-end: 2;
                grid-row-start: 1;
              }
            }
            &.state-3 {
              .header {
                grid-column-start: 1;
                grid-column-end: 3;
                grid-row-end: 2;
                grid-row-start: 1;
              }
              .info {
                grid-column-start: 1;
                grid-column-end: 3;
                grid-row-end: 3;
                grid-row-start: 2;
              }
            }
          }
        }
        .layout {
          grid-template-columns: 1fr 1fr;
          grid-template-rows: auto;
          .data-block {
            .block {
              grid-template-columns: 2fr 1fr;
              grid-template-rows: auto;
              &.wide {
                grid-template-columns: 100%;
              }
            }
            &.wide {
              grid-column-start: 1;
              grid-column-end: 3;
            }
          }
        }
      }
    }
  }
}
@media (min-width: 992px) {
  .home {
    .main-block {
      .top-section {
        .gallery {
          grid-template-columns: min-content min-content auto 4em !important;
          grid-template-rows: auto !important;
          .title {
            writing-mode: vertical-rl;
            padding: 1em 0 1.5em 0 !important;
            width: fit-content;
            transform: rotate(180deg);
            h3 {
              padding-left: 0 !important;
              i.material-icons {
                transform: rotate(90deg);
              }
            }
            &.color1 {
              grid-column-start: 1 !important;
              grid-column-end: 2 !important;
              grid-row-end: 2 !important;
              grid-row-start: 1 !important;
            }
            &.color2 {
              grid-column-start: 2 !important;
              grid-column-end: 3 !important;
              grid-row-end: 2 !important;
              grid-row-start: 1 !important;
            }
          }
          .elements {
            padding-left: 13em;
            padding-right: 10em;
            padding-bottom: 3em;
            grid-column-start: 3 !important;
            grid-column-end: 4 !important;
            grid-row-end: 2 !important;
            grid-row-start: 1 !important;
            grid-template-rows: min-content auto;
            grid-template-columns: 2fr 1fr;
            &.state-2 {
              padding-left: 7.5em;
              .header {
                grid-column-start: 1;
                grid-column-end: 3;
                grid-row-end: 2;
                grid-row-start: 1;
              }
            }
            &.state-3 {
              padding-left: 5.5em;
              .header {
                grid-column-start: 1;
                grid-column-end: 3;
                grid-row-end: 2;
                grid-row-start: 1;
              }
              .info {
                grid-column-start: 1;
                grid-column-end: 3;
                grid-row-end: 3;
                grid-row-start: 2;
              }
            }
          }
        }
      }
    }
    .section {
      padding: 3em 13em;
      &.split {
        grid-template-columns: 1.5fr 2.5fr;
      }
      .fotos-container {
        .column {
          flex: 25% !important;
          max-width: 25%;
        }
      }
    }
  }
}

@media screen and (min-width: 400px) and (max-width: 992px) {
  .section {
    .fotos-container {
      .column {
        flex: 50% !important;
        max-width: 50%;
      }
    }
  }
}
</style>
